import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CompanyList } from 'src/app/data/models/company-list.model';
import { CustomerList } from 'src/app/data/models/customer-list.model';

import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ServicesService } from 'src/app/data/services/services.service';
import { Company } from '../../company/companies';
import { Customer } from '../../customer/customer';
import { Hierarchy, TypeEmployee, User } from '../users';
import { customerData } from '../../../data/models/customer.model';

@Component({
  selector: 'app-general-user',
  templateUrl: './general-user.component.html',
  styleUrls: ['./general-user.component.css']
})
export class GeneralUserComponent implements OnInit {

  @Output() goBack = new EventEmitter();
  @Input() id_User: Number;
  customer: customerData = new customerData();
  dropdownSettingsCus: IDropdownSettings = {};
  dropdownSettingsCom: IDropdownSettings = {};
  customerLst = new CustomerList();
  public isNew: boolean = false;
  public isEdit: boolean = false;
  public UserSelected: User = new User();
  public isView: boolean = true;
  public isResults: boolean = true;
  public Companies: Company[] = [];
  public UsersCustomer: any = [];
  public Customers: Customer[] = [];
  public Hierarchies: Hierarchy[] = [];
  public TypeEmployees: TypeEmployee[] = [];
  public listStatusGral = [];
  companyLst = new CompanyList();
  titleNewEditUser = '';
  lstCustomerGet: any[] = [];
  lstCompanyGet: any[];
  selectedItems: any = [];
  selectedItems1: any = [];

  constructor(
    private services: ServicesService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    translate.get('GENERAL.active').subscribe((res: string) => {
      this.listStatusGral.push({
        name: res,
        id: 'A'
      });
    });
    translate.get('GENERAL.inactive').subscribe((res: string) => {
      this.listStatusGral.push({
        name: res,
        id: 'I'
      });
    });
    translate.get('GENERAL.resetPassword').subscribe((res: string) => {
      this.listStatusGral.push({
        name: res,
        id: 'R'
      });
    });
    this.dropdownSettingsCus = {
      singleSelection: true,
      idField: 'Id_Customer',
      textField: 'Name',
      searchPlaceholderText: 'Buscar:',
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
      enableCheckAll: true,
      defaultOpen: true
    };
    this.dropdownSettingsCom = {
      singleSelection: true,
      idField: 'Id_Company',
      textField: 'Name',
      searchPlaceholderText: 'Buscar:',
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
      enableCheckAll: true,
      defaultOpen: true
    };
  }

  ngOnInit(): void {
    if (this.UserSelected.Id_Customer === 0) {
      this.selectedItems = [];
    }
    if (this.UserSelected.Id_Company === 0) {
      this.selectedItems1 = [];
    }
    if (this.id_User === 0) {
      this.isNew = true;
      this.isView = true;
      this.isResults = false;
      this.UserSelected.Id_Company = 0;
      this.UserSelected.CompanyName = '';
      this.UserSelected.Id_Customer = 0;
      this.UserSelected.CustomerName = '';
      this.Catalog_Type_Employee();
      this.Catalog_Hierarchy();
      this.loadCustomers();
      // this.loadCompanies(0);
    } else {
      this.User_By_Id();
    }
  }

  onSubmitNewEdit(e) {
    if (this.UserSelected.Status === 'I' && this.UserSelected.Crew === 'Y') {
      this.toastr.warning(`No se puede inactivar a un usuario si se encuentra dentro de uno o varios grupos. Primero elimine al usuario del grupo, por favor.`, 'Usuarios');
      return;
    } else {
      if (this.id_User === 0) {
        this.User_New_Edit(this.UserSelected);
        this.goBack.emit();
        this.User_By_Id();
      } else {
        this.User_New_Edit(this.UserSelected);
        this.isEdit = false;
        //this.isView = true;
      }
    }
  }

  LoadUsers(Id_User: Number) {
    this.spinner.show();
    this.services.Users_By_Customer(this.UserSelected.Id_Customer).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        let data = resp.data.filter(x => x.Id_User != Id_User);
        this.UsersCustomer = data;
      }
    });
  }

  onItemSelect1(e) {
    this.UserSelected.Id_Customer = e.Id_Customer;
    this.selectedItems1 = [];
    this.UserSelected.Id_Company = 0;
    this.UserSelected.CompanyName = '';
    this.LoadUsers(0);
    this.loadCompanies(e.Id_Customer);
  }

  onItemSelect2(e) {
    this.UserSelected.Id_Company = e.Id_Company;
    this.UserSelected.CompanyName = e.NameCompany;
  }

  onItemDeselect1(e) {
    this.selectedItems = [];
    this.selectedItems1 = [];
    this.UsersCustomer = [];
    this.UserSelected.Id_Company = 0;
    this.UserSelected.CompanyName = '';
    this.UserSelected.Id_Customer = 0;
    this.UserSelected.CustomerName = '';
    this.UserSelected.Id_Boss_User = 0;
    this.loadCompanies(0);
  }

  onItemDeselect2(e) {
    this.selectedItems1 = [];
    this.UserSelected.Id_Company = 0;
    this.UserSelected.CompanyName = '';
  }

  SelectUser(user: User) {
    this.isView = true;
    this.isNew = false;
    this.isEdit = false;
    this.isResults = false;

    this.UserSelected = user;
    // this.LoadUsers(user.Id_Customer, user.Id_User);
  }

  User_By_Id() {
    this.spinner.show();
    this.services.User_By_Id(this.id_User).subscribe(resp => {
      this.spinner.hide();

      if (resp.result) {
        this.UserSelected = resp.data[0];
        this.selectedItems = [
          { Id_Customer: this.UserSelected.Id_Customer, Name: this.UserSelected.CustomerName }
        ];
        if (this.UserSelected.Id_Company !== 0) {
          this.selectedItems1 = [
            { Id_Company: this.UserSelected.Id_Company, Name: this.UserSelected.CompanyName }
          ];
        } else {
          this.selectedItems1 = [];
        }
      }
    });
  }

  EditUser() {
    this.isNew = false;
    this.isEdit = true;

    this.isView = true;
    this.isResults = false;
    this.Catalog_Type_Employee();
    this.Catalog_Hierarchy();
    this.loadCustomers();
    this.LoadUsers(0);
    this.loadCompanies(this.UserSelected.Id_Customer);
  }

  CancelEdit() {
    this.isEdit = false;
    this.isView = true;
    this.User_By_Id();
  }

  changeCompany(Id_Company) {
    this.UserSelected.Id_Company = Id_Company;
  }

  changeBossUser(Id_Boss_User: Number) {
    this.UserSelected.Id_Boss_User = Id_Boss_User;
  }

  changeHierarchy(hierarchy) {
    this.UserSelected.Id_Hierarchy = hierarchy;
  }

  changeCreditorType(Id_CreditorType) {
    this.UserSelected.Id_CreditorType = Id_CreditorType;
  }

  changeStatus(status) {
    this.UserSelected.Status = status;
  }

  Catalog_Hierarchy() {
    this.spinner.show();
    this.services.Catalog_Hierarchy().subscribe(resp => {
      this.spinner.hide();
      if (resp.result)
        this.Hierarchies = resp.data;
    });
  }

  Catalog_Type_Employee() {
    this.spinner.show();
    this.services.Catalog_Type_Employee().subscribe(resp => {
      this.spinner.hide();
      if (resp.result)
        this.TypeEmployees = resp.data;
    });
  }

  User_New_Edit(user: User) {
    this.spinner.show();
    this.services.User_New_Edit(user).subscribe(resp => {

      this.spinner.hide();

      if (resp.result) {
        this.toastr.success((user.Id_User == 0 ? this.translate.instant('GENERAL.saved') : this.translate.instant('GENERAL.modified')) + ' ' + this.translate.instant('GENERAL.correctly'));

        if (user.Id_User != 0) {
          this.User_By_Id();
        }
        else {
          this.spinner.show();
          this.services.SendNotificationNewUser(user).subscribe();
          this.spinner.hide();
          this.UserSelected = new User();
          this.isView = false;
          this.isNew = false;
          this.isEdit = false;
          this.isResults = true;
        }
      }
    });
  }

  loadCustomers() {
    this.services
      .post({
        entidad: 'Customer',
        funcion: 3,
        data: {
          Page: -1
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.lstCustomerGet = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

  loadCompanies(Id_CustomerU: Number) {
    this.services
      .post({
        entidad: 'Company',
        funcion: 1,
        data: {
          Id_Customer: Id_CustomerU,
          Page: -1,
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.lstCompanyGet = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

}
