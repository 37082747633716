<select [(ngModel)]="typeMod" (change)="selectMod($event)" class="form-select form-select-sm" aria-label=".form-select-sm example">
    <option value="">Selecciona estatus de pago:</option>
    <option value="P">
        Pagado
    </option>
    <option value="N">
        No Pagado
    </option>
    <option value="*">
        Todos
    </option>
</select>