<!-- Comienza encabezado -->
<ng-container>
    <app-title-payment [titleP]="Title"></app-title-payment>
</ng-container>

<ng-container *ngIf="isFormCrew">
    <div class="row ">
        <div class="col-auto position-absolute pos mt-2 positionTop">
            <button (click)="backToCrewList()" class="btnBackTop">
                <i class="fas fa-arrow-left"></i>
            </button>
        </div>
    </div>
</ng-container>

<div class="row justify-content-between mt-2">
    <div class="col-auto">
        <div class="row ">
            <div class="col-auto">
                <input name="search" [(ngModel)]="searchTerm" type="text" (input)="searchCrew($event)"
                    (click)="backToCrewList()" class="input-search form-control"
                    placeholder="{{ 'GENERAL.searchby' | translate }}...">
                <a class="ico-btn-search">
                    <i class="ti-search"></i>
                </a>
            </div>
        </div>
    </div>
    <div *ngIf="!isFormCrew" class="col-auto mt-2 mt-sm-0">
        <button (click)="newCrew()" class="btnSta mt-1" placement="top" ngbTooltip="Nuevo">
            <i class="fas fa-plus-square fa-lg"></i>
        </button>
    </div>
</div>

<!-- <div *ngIf="isFormCrew" class="row mt-4">
    <div class="col-auto ps-4">
        <button (click)="backToCrewList()" class="btn btn-info btnBack text-white p-1" style="width: 40px; height:40px; margin-left: auto; margin-right: auto;">
        <i class="fa fa-arrow-left" aria-hidden="true"></i>
      </button>
    </div>
</div> -->

<!--Canvas-->
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <button type="button" class="btnArrow" data-bs-dismiss="offcanvas">
            <span><i class="fa fa-arrow-left"></i></span>
        </button>
        <h5 class="ps-2 mt-1" id="offcanvasRightLabel">Filtros</h5>
    </div>
    <div class="offcanvas-body">
        <div class="col-auto px-0">
            <ng-container *ngIf="filterType === 'O'">
                <app-organization (customerSel)="filterByCustomer($event)"></app-organization>
                <div class="d-grid gap-2 mt-3">
                    <button (click)="loadData()" type="button" data-bs-dismiss="offcanvas"
                        class="btn btn-success btnFilter">Filtrar</button>
                </div>
            </ng-container>
            <ng-container *ngIf="filterType === 'S'">
                <div class="col-auto mt-2 mt-sm-0">
                    <div class="row text-center">
                        <div class="col-12">
                            <label class="switch">
                                <input [(ngModel)]="isStatusCrew" (change)="viewStatus($event);backToCrewList();"
                                    class="form-check-input" type="checkbox" id="cartaPorte">
                                <span class="slider round-in"></span>
                            </label>
                        </div>
                        <div class="col-12 ps-2 labelActive">
                            <label class="form-check-label labelStatus" for="cartaPorte">{{ "GENERAL.activeInactive" |
                                translate}}</label>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<!--termina canvas-->

<div class="row mt-2 pb-2">
    <div class="col-md-12 col-lg-12 col-sm-12" style="margin-left: auto; margin-right: auto;">
        <ng-container *ngIf="isTable">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="table-responsive tablePayments" style="min-height: 250px;">
                            <table sortable-table (sorted)="onSorted($event)" class="table table-striped">
                                <thead>
                                    <tr class="purple">
                                        <th style="width: 50px;">
                                            <button *ngIf="isRest || id_Customer_Selected != 0 || statusCrew != '' || Pagination != 10"
                                                (click)="resetTable()" class="tooltipp btnRes" placement="top"
                                                ngbTooltip="Restablecer Filtros"><i
                                                    class="fas fa-trash-restore-alt p-1"></i></button>
                                        </th>
                                        <th class="th-sort" sortable-table="padre" sortable-column="des" scope="col">
                                            <div class="dropdown ps-1">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                                    <span *ngIf="AZdes"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="ZAdes"><i class="fas fa-sort-alpha-down-alt"></i></span>
                                                    Grupo
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="onSortedA($event)" class="dropdown-item"
                                                            href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD($event)" class="dropdown-item"
                                                            href="javascript:void(0);">De la Z a la A</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="padre" sortable-column="sub" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <span *ngIf="AZsub"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="ZAsub"><i class="fas fa-sort-alpha-down-alt"></i></span>
                                                    Subtítulo
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="onSortedA($event)" class="dropdown-item"
                                                            href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD($event)" class="dropdown-item"
                                                            href="javascript:void(0);">De la Z a la A</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="padre" sortable-column="cus" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <span *ngIf="AZcus"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="ZAcus"><i class="fas fa-sort-alpha-down-alt"></i></span>
                                                    <span *ngIf="id_Customer_Selected != 0" class="icon"><i
                                                            class="fas fa-filter"></i></span>
                                                    Organización
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="onSortedA($event)" class="dropdown-item"
                                                            href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD($event)" class="dropdown-item"
                                                            href="javascript:void(0);">De la Z a la A</a></li>
                                                    <li><a (click)="filterCrew('O')" class="dropdown-item" type="button"
                                                            data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                                                            aria-controls="offcanvasRight">Filtrar organización</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="padre" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button"
                                                    id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <span *ngIf="statusCrew != ''" class="icon"><i
                                                            class="fas fa-filter"></i></span>
                                                    Estatus
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="filterCrew('S')" class="dropdown-item" type="button"
                                                            data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                                                            aria-controls="offcanvasRight">Filtrar estatus</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let des of lstSearchItems; let i = index">
                                        <td style="width: 50px;">
                                            <ng-container>
                                                <button class="btnEdit ps-1" (click)="detailCrew(des.id)"><i class="fas fa-file-invoice"></i></button>
                                                <button *ngIf="des.Active_Users === 'N'" class="btnEdit ps-1" (click)="deleteCrew(des.id, des.title)"><i class="fas fa-trash-alt ml-1"></i></button>
                                            </ng-container>
                                        </td>
                                        <td scope="row" style="width:150px;">{{des.title}}</td>
                                        <td class="center">{{des.subtitle}}</td>
                                        <td class="center">{{des.subtitle2}}</td>
                                        <td style="width:150px;" class="center">
                                            <ng-container>
                                                <span *ngIf="des.status === 'A'"
                                                    class="badge badge-pill badge-success mb-1">Activo</span>
                                                <span *ngIf="des.status === 'I'"
                                                    class="badge badge-pill badge-secondary mb-1">Inactivo</span>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- <div class="row gx-1  justify-content-between">
                    <div class="col-auto ps-5 ">
                        <select class="select-css" id="dropAmounts" aria-label="Default select example"
                            (change)="changeAmountView($event)" [(ngModel)]="this.Pagination" name="dropAmounts">
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div class="col-auto pe-5 mt-2 mt-sm-0">
                        <ngb-pagination [collectionSize]="collectionSize" [(page)]="this.Page" [maxSize]="4"
                            (pageChange)="changePage($event)" [rotate]="true"></ngb-pagination>
                    </div>
                </div> -->
                <app-pagination-global [callbackFunction]="crewGet" [actualPagination]="Pagination" [collectionDinamic]="collectionSize" (changePagination)="changeAmountView($event)" (changePageEmi)="changePage($event)"></app-pagination-global>
            </div>
        </ng-container>
    </div>
</div>

<ng-container *ngIf="isFormCrew">
    <div class="" style="margin-left: auto; margin-right: auto;">
        <app-mytab-groups [id]="idCrew" [operation]="operationCrew" [lstCustomer]="Customers"
            (backCrewList)="backToCrewList()"></app-mytab-groups>
    </div>
</ng-container>
<app-back-to-top></app-back-to-top>