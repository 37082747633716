import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-annuity',
  templateUrl: './annuity.component.html',
  styleUrls: ['./annuity.component.css']
})
export class AnnuityComponent implements OnInit {

  @Output() annSel = new EventEmitter<string>();

  typeAnn = '';

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    if(localStorage.getItem('annSto') !== null) {
      this.typeAnn = localStorage.getItem('annSto');
    }
  }

  selectAnnuity(e) {
    this.typeAnn = ''; 
    this.typeAnn = e.target.value;
    localStorage.setItem('annSto', String(this.typeAnn));
    this.annSel.emit(this.typeAnn);
  }


}
