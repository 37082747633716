<!-- Comienza encabezado -->
<ng-container>
    <app-title-payment [titleP]="Title"></app-title-payment>
</ng-container>

<ng-container *ngIf="isReturn">
    <div class="row ">
        <div class="col-auto position-absolute pos mt-2 positionTop">
            <button (click)="returnToList()" class="btnBackTop">
                <i class="fas fa-arrow-left"></i> 
            </button>
        </div>
    </div>
</ng-container>

<div class="row justify-content-between ">
    <div class="col-auto">
        <div class="row ">
            <div class="col-auto">
                <input name="search" [(ngModel)]="customerLst.SearchKey" type="text" (input)="onSearchChange($event.target.value)" (click)="returnToList()" class="input-search form-control" placeholder="{{ 'GENERAL.searchby' | translate }}...">
                <a class="ico-btn-search">
                    <i class="ti-search"></i>
                </a>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!isReturn">
        <div class="col-auto mt-2 mt-sm-0">
            <button (click)="newEditCustomer(false)" class="btnSta mt-1" placement="top" ngbTooltip="Nuevo">
                <i class="fas fa-plus-square fa-lg"></i> 
            </button>
        </div>
    </ng-container>
</div>

<!-- Termina encabezado -->
<!--Canvas-->
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <button type="button" class="btnArrow" data-bs-dismiss="offcanvas">
            <span><i class="fa fa-arrow-left"></i></span>
        </button>
        <div class="col-auto">
            <div>
                <app-filters-customer (filSelC)="filterSel($event)"></app-filters-customer>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class="col-auto px-0">
            <ng-container *ngIf="filterType === 'O'">
                <app-organization (customerSel)="filterByCustomer($event)"></app-organization>
                <div class="d-grid gap-2 mt-3">
                    <button (click)="filterData()" type="button" data-bs-dismiss="offcanvas" class="btn btn-success btnFilter">Filtrar</button>
                </div>
            </ng-container>
            <ng-container *ngIf="filterType === 'C'">
                <app-channel-payment (channelSel)="filterByChannel($event)"></app-channel-payment>
                <div class="d-grid gap-2 mt-3">
                    <button (click)="filterData()" type="button" data-bs-dismiss="offcanvas" class="btn btn-success btnFilter">Filtrar</button>
                </div>
            </ng-container>
            <ng-container *ngIf="filterType === 'E'">
                <div class="row text-center">
                    <div class="col-12">
                        <p class="mb-1">Filtra por estatus: </p>
                        <label class="switch hideShow mb-3">
                            <input #status [(ngModel)]="StatusList" (change)="updateStatus($event)" name="status" type="checkbox" id="status"/>
                            <span class="slider round-in "></span>
                        </label>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="filterType === 'M'">
                <div class="row text-center">
                    <div class="col-12">
                        <p class="mb-1">Filtra por estatus MFA: </p>
                        <label class="switch hideShow mb-3">
                            <input #status [(ngModel)]="StatusListMFA" (change)="updateStatusMFA($event)" name="status" type="checkbox" id="status"/>
                            <span class="slider round-in "></span>
                        </label>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<!--termina canvas-->

<!-- Comienza contenido -->
<div class="row mt-2 pb-2">
    <ng-container *ngIf="results">
        <div class="col-md-12 col-lg-12 col-sm-12" style="margin-left: auto; margin-right: auto;">
            <div class="card">
                <div class="card-body">

                    <div class="row">
                        <div class="table-responsive tablePayments" style="min-height: 250px;">
                            <table sortable-table (sorted)="onSortedC($event)" class="table table-striped">
                                <thead>
                                    <tr class="purple">
                                        <th style="width: 50px;">
                                            <button *ngIf="isRest || customerLst.Id_Customer != 0 || customerLst.Id_Channel != 0 || customerLst.Status != '' || customerLst.MFA != '' || customerLst.Pagination != 10" class="tooltipp btnRes" (click)="resetTable()" placement="top" ngbTooltip="Restablecer Filtros"><i
                                                    class="fas fa-trash-restore-alt p-1"></i></button>
                                        </th>
                                        <th class="th-sort" sortable-table="padre" sortable-column="cus" scope="col">
                                            <div class="dropdown ps-1">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                                    <span *ngIf="AZcus"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="ZAcus"><i
                                                            class="fas fa-sort-alpha-down-alt"></i></span>
                                                    <span *ngIf="customerLst.Id_Customer != 0" class="icon"><i
                                                            class="fas fa-filter"></i></span>
                                                    Organización
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">De la Z a la A</a></li>
                                                    <li><a (click)="filterChannel('O')" class="dropdown-item" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Filtrar organización</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="padre" sortable-column="ema" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span *ngIf="AZema"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="ZAema"><i
                                                            class="fas fa-sort-alpha-down-alt"></i></span>
                                                    Correo
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">De la Z a la A</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th *ngIf="isAccess" sortable-table="padre" sortable-column="cha" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span *ngIf="AZcha"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="ZAcha"><i
                                                            class="fas fa-sort-alpha-down-alt"></i></span>
                                                    <span *ngIf="customerLst.Id_Channel != 0" class="icon"><i
                                                            class="fas fa-filter"></i></span>
                                                    Canal
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">De la Z a la A</a></li>
                                                    <li><a (click)="filterChannel('C')" class="dropdown-item" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Filtrar canal</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="padre" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span *ngIf="customerLst.Status != ''" class="icon"><i class="fas fa-filter"></i></span>
                                                  Estatus
                                              </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="filterChannel('E')" class="dropdown-item" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Filtrar estatus</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="padre" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span *ngIf="customerLst.MFA != ''" class="icon"><i class="fas fa-filter"></i></span>
                                                  Estatus MFA
                                              </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="filterChannel('M')" class="dropdown-item" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Filtrar estatus MFA</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="padre" sortable-column="dat" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span *ngIf="AZdat"><i class="fas fa-sort-numeric-up-alt"></i></span>
                                                    <span *ngIf="ZAdat"><i class="fas fa-sort-numeric-down-alt"></i></span>
                                                    Fecha
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">Ascendente</a></li>
                                                    <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">Descendente</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let customer of listCustomers; let i = index">
                                        <td style="width: 50px;">
                                            <ng-container *ngIf="i != idx">
                                                <button (click)="selectCustomerId(customer.Id_Customer)" class="btnEdit ps-3"><i class="fas fa-file-invoice"></i></button>
                                            </ng-container>
                                        </td>
                                        <td scope="row">&#160;{{customer.Name}}</td>
                                        <td>{{customer.Email}}</td>
                                        <td *ngIf="isAccess" class="center">{{customer.NameChannel}}</td>
                                        <td style="width:150px;" class="center">
                                            <ng-container>
                                                <span *ngIf="customer.Status === 'A'" class="badge badge-pill badge-success mb-1">Activa</span>
                                                <span *ngIf="customer.Status === 'I'" class="badge badge-pill badge-secondary mb-1">Inactiva</span>
                                            </ng-container>
                                        </td>
                                        <td style="width:150px;" class="center">
                                            <ng-container>
                                                <span *ngIf="customer.MFA === 'A'" class="badge badge-pill badge-success mb-1">Activa</span>
                                                <span *ngIf="customer.MFA === 'I'" class="badge badge-pill badge-secondary mb-1">Inactiva</span>
                                            </ng-container>
                                        </td>
                                        <td style="width:150px;" class="center">{{customer.Date_Add | date:"dd-MMM-YY"}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- <div class="row gx-1  justify-content-between">
                    <div class="col-auto ps-5 ">
                        <select class="select-css" id="dropAmounts" aria-label="Default select example" (change)="changeAmountView($event)" [(ngModel)]="customerLst.Pagination" name="dropAmounts">
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div class="col-auto pe-5 mt-2 mt-sm-0">
                        <ngb-pagination [collectionSize]="collectionSize" [(page)]="customerLst.Page" [maxSize]="4" (pageChange)="changePage($event)" [rotate]="true"></ngb-pagination>
                    </div>
                </div> -->
                <app-pagination-global [callbackFunction]="LoadCustomers" [actualPagination]="customerLst.Pagination" [collectionDinamic]="collectionSize" (changePagination)="changeAmountView($event)" (changePageEmi)="changePage($event)"></app-pagination-global>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isView">
        <div class="col-md-12 col-lg-12 col-sm-12 generalTab" style="margin-left: auto; margin-right: auto;">
            <!-- <div class="card" style="background-color:#dcdcdc; ">
                <div class="card-body"> -->
                    <ng-container>
                        <app-mytab (goBack)="returnToList()" [newEdit]="EditCustomer" [idCustomer]="idCust"></app-mytab>
                    </ng-container>
                <!-- </div>
            </div> -->
        </div>
    </ng-container>

</div>

<app-back-to-top></app-back-to-top>
<!-- Termina contenido -->
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>