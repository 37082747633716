<div class="card ms-3 me-4 mt-4">
    <div class="card-header bg-transparent border-0">
        <h5 class="card-title" style="margin-top: 20px;">{{ "GENERAL.QR" | translate }}
        </h5>
        <div style="display: flex;">
            <div class="u-img image-upload2 text-center">
                <label for="qr-input" class="ejemplo-1">
          <ng-container *ngIf="companieSelected.QR_Fiscal">
            <a style="cursor: pointer;" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightQr"
            aria-controls="offcanvasRight"> <img style="max-width: 200px;" 
            src="{{companieSelected.QR_Fiscal}}?r={{getNumber()}}" alt="user"><br><u>Cambiar QR
            </u></a>
          </ng-container>
          <ng-container *ngIf="!companieSelected.QR_Fiscal" >
            <a style="cursor: pointer;" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightQr"
            aria-controls="offcanvasRight"> <img style="max-width: 200px;" 
            src="../../../assets/images/QR_Upload.png" alt="user"><br><u>Subir QR
            </u></a>
          </ng-container>
        </label>
            </div>
        </div>
    </div>
</div>

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRightQr" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <button type="button" class="btnArrow" data-bs-dismiss="offcanvas">
          <span><i class="fa fa-arrow-left"></i></span>
      </button>
        <h5 class="ps-4 mt-1" id="offcanvasRightLabel">Arrastra tu Imagen</h5>
    </div>
    <div class="offcanvas-body">
        <div class="col-auto px-0">
            <div class="row gx-0 mt-3 px-3">
                <div class="col- d-flex justify-content-center">
                    <ng-container>
                        <div class="csvPolitic form-group dragg">
                            <div class="form-group dragAndDrop text-center" style="width: 100%">
                                <input #qrinput (change)="UploadQRFiscal($event)" id="qr-input" name="fielName" id="fielName" type="file" class="custom-file-input form-control-file text-primary font-weight-bold" accept="image/png, image/gif, image/jpeg">
                                <span id="span-click" class="custom-file-control span-click" *ngIf="!fileNameChangeQr">
                                  <div class="row justify-content-center">
                                      <div class="col-12">
                                          <p class="h4">
                                              <i class="fa fa-upload" aria-hidden="true"></i>
                                          </p>
                                      </div>
                                  </div>
                                  <div class="row justify-content-center">
                                      <div class="col-">
                                          <p>Arrastra o da click para subir tu QR</p>
                                      </div>
                                  </div>
                              </span>
                                <span id="span-click" class="custom-file-control span-click" *ngIf="fileNameChangeQr">
                                  <div class="row justify-content-center">
                                      <div class="col-12">
                                          <p class="h4 archivoSubido">
                                              <i class="far fa-check-circle"></i>
                                          </p>
                                      </div>
                                  </div>
                                  <div class="row justify-content-center">
                                      <div class="col-">
                                          <p>{{ fileNameChangeQr }}</p>
                                      </div>
                                  </div>
                              </span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>