import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-column-company',
  templateUrl: './column-company.component.html',
  styleUrls: ['./column-company.component.css']
})
export class ColumnCompanyComponent implements OnInit {

  @Output() companySel = new EventEmitter<string>();

  lstCompanyGet: any [];
  selectedItems: any = [];
  typeComp = '';
  dropdownSettings: IDropdownSettings = {};

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'Id_Company',
      textField: 'Name',
      searchPlaceholderText: 'Buscar:',
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
      enableCheckAll: true,
      defaultOpen: true
    };

    if (localStorage.getItem('comStoId') == null || localStorage.getItem('comStoId') == undefined) {
      this.selectedItems = [];
    }else{
      this.selectedItems = [
        { Id_Customer: +localStorage.getItem('comStoId'), Name: localStorage.getItem('comStoNa') },
      ];
    }

    if (localStorage.getItem('comStoId') !== null) {
      this.typeComp = localStorage.getItem('comStoId');
    }

    this.typeCompany();
  }

  onItemSelect(e) {
    this.typeComp = e.Id_Company;
    localStorage.setItem('comStoId', (this.typeComp));
    localStorage.setItem('comStoNa', String(e.Name));
    this.companySel.emit(this.typeComp);
  }

  onItemDeselect(e) {
    this.selectedItems = [];
    this.typeComp = '';
    localStorage.removeItem('comStoId');
    localStorage.removeItem('comStoNa');
    this.companySel.emit(this.typeComp);
  }

  //services
  typeCompany() {
    this.services
      .post({
        entidad: 'Company',
        funcion: 1,
        data: {
          Page: -1,
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.lstCompanyGet = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

}
