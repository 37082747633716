import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServicesService } from '../data/services/services.service';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {
  videoLst = [];
  videosGrouped = [];
  initialItemCount: number = 3;

  constructor(
    private route: ActivatedRoute,
    private services: ServicesService
  ) {}

  ngOnInit(): void {
    this.newListService();
  }

  getVideo(nv: string) {
    return '<iframe width="100%" src="' + nv + '" frameborder="0" allowfullscreen="allowfullscreen"></iframe>';
  }

  newListService() {
    this.services.Video_Get(this.getAmb()).subscribe((resp) => {
      if (resp.code == 1) {
        this.videoLst = resp.data;
        this.groupVideos();
      } 
    });
  }

  getAmb(): number {
    let amb = +localStorage.getItem('amb');
    if (amb < 1) {
      amb = this.route.snapshot.params['amb'] || 0;
    }
    return amb;
  }

  groupVideos() {
    for (let i = 0; i < this.videoLst.length; i += this.initialItemCount) {
      this.videosGrouped.push(this.videoLst.slice(i, i + this.initialItemCount));
    }
  }
}
