export class LoginData {
  username: String;
  password: String;
  appToken: String = localStorage.getItem('appToken');
}

export class PassRecoveryData {
  email: String;
  appToken: String = localStorage.getItem('appToken');
}
