<!--  -->
<div class="col-md-12 col-lg-12 col-sm-12" style="margin-left: auto; margin-right: auto;">
    <div class="card mt-3">
        <div class="card-body ">
        <h4 class="card-title"><b>{{ "GENERAL.organization" |
                translate }}:</b> {{customerSelected.Name}}
        </h4>

        <div class="table-responsive">
            <table sortable-table (sorted)="onSorted($event)" class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>Mod</th>
                        <th sortable-column="Application_Name" style="cursor: pointer; min-width: 120px;">
                            {{ "GENERAL.application" | translate }}</th>
                        <th style="min-width: 120px;">{{ "GENERAL.tipePay" | translate }}
                        </th>
                        <th style="min-width: 120px;">{{ "GENERAL.status" | translate }}
                        </th>

                        <th sortable-column="Date_Init" style="cursor: pointer; min-width: 120px;">{{ "GENERAL.dateInit"
                            |
                            translate }}</th>
                        <th sortable-column="Date_Expiration" style="cursor: pointer; min-width: 120px;">{{
                            "GENERAL.dateEnd" |
                            translate }}</th>

                    </tr>
                </thead>
                <tbody *ngFor="let cApp of listCustomersApp; let i = index" [attr.data-index]="i">
                    <tr>
                        <td *ngIf="!cApp.Hide">
                            <i (click)="viewModule(cApp)" class="far fa-plus-square btnApli" 
                            style="font-size: 22px; cursor: pointer;"></i>
                        </td>
                        <td *ngIf="cApp.Hide">
                            <i (click)="cApp.Hide = !cApp.Hide" class="far fa-minus-square btnApli"
                                style="font-size: 22px; cursor: pointer;"></i>
                        </td>
                        <td>{{cApp.Application_Name}}</td>
                        <td>
                            <label class="switch">
                                <select (change)="changeTypePay($event.target.value, cApp)" name="typePay"
                                    class="pull-left custom-select col-12" id="typePay" 
                                    style="max-width: 160px; min-width: 120px;">
                                     <option [disabled]="cApp.isActive" value="" selected="">Seleccionar</option> 
                                    <option *ngFor="let typeP of cApp.typePayList" value="{{typeP.Id}}"
                                        [selected]="typeP.Id == cApp.Folio_Descount_Type" >
                                        {{typeP.Name}}
                                    </option>
                                </select>
                            </label>
                        </td>
                        <td>
                            <label class="switch">
                                <input type="checkbox" [value]="cApp.Id_Application" [checked]="cApp.isActive"
                                    (change)="changeStatusApp($event, cApp)">
                                <span class="slider round-in"></span>
                            </label>
                        </td>
                        <td class="tae">
                            <a (click)="updateCustomerApp('#dat1'+i, '#dat2'+i)" 
                                id="dat1{{i}}" style="display: block;" name="dpSC">
                                {{cApp.Date_Init | date:'mediumDate'}}
                            </a>
                            <input (keyup)="keyFunc($event,'#dat1'+i, '#dat2'+i)"
                                (keyup.enter)="changeDate($event, $event.target.value, '#dat1'+i, '#dat2'+i, cApp, i, 1)"
                                placeholder="{{cApp.Date_Init}}" id="dat2{{i}}" class="form-control"
                                [ngModel]="cApp.Date_Init | date:'yyyy-MM-dd'"
                                (ngModelChange)="cApp.Date_Init = $event"
                                type="date" style="display: none; max-width: 160px;" name="dpSCC" #dpSCC="ngModel">
                            
                        </td>
                        <td class="tae">
                            <a (click)="updateCustomerApp('#date1'+i, '#date2'+i)" href="javascript:void(0)"
                                id="date1{{i}}" style="display: block;" name="dpSC2">
                                {{cApp.Date_Expiration | date:'mediumDate'}}
                            </a>
                            <input (keyup)="keyFunc($event,'#date1'+i, '#date2'+i)"
                                (keyup.enter)="changeDate($event, $event.target.value, '#date1'+i, '#date2'+i, cApp, i, 2)"
                                placeholder="{{cApp.Date_Expiration}}" id="date2{{i}}" class="form-control"
                                [ngModel]="cApp.Date_Expiration | date:'yyyy-MM-dd'"
                                (ngModelChange)="cApp.Date_Expiration = $event" type="date"
                                style="display: none; max-width: 160px;" name="dpSC22">
                        </td>
                    </tr>
                    <tr *ngIf="cApp.Hide">
                        <td colspan="4">
                            <table class="table table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th style="min-width: 120px;">{{ "GENERAL.moduleName" |
                                            translate }}</th>
                                        <th style="min-width: 120px;">{{ "GENERAL.status" |
                                            translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let CML of CustomerModuleList; let j = index">
                                        <td>{{CML.Module_Name}}</td>
                                        <td>
                                            <label class="switch">
                                                <input [checked]="(CML.Status == 'V'? true: false)"
                                                    (change)="changeStatusModule($event.target.checked, CML.Id_Module, cApp.Id_Customer)"
                                                    name="status{{i}}" type="checkbox" id="status{{i}}">
                                                <span class="slider round-in"></span>
                                            </label>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
    </div>
</div>
    <!-- </div> -->


<!--  -->