import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Menu } from '../../../data/models/menu.model';

@Component({
  selector: 'app-mytab-menus',
  templateUrl: './mytab-menus.component.html',
  styleUrls: ['./mytab-menus.component.css']
})
export class MytabMenusComponent implements OnInit {

  @Input() idMenuDetail: number;
  @Output() goBack = new EventEmitter();

  menuSelected = new Menu();
  isNew = false;
  detailView = true;
  languageView = false;

  constructor() { }

  ngOnInit(): void {
    if (this.idMenuDetail === 0) {
      this.isNew = true;
    } else {
     this.isNew = false;
    }
  }

  viewDetail() {
    this.detailView = true;
    this.languageView = false;
  }

  viewLanguages() {
    this.detailView = false;
    this.languageView = true;
  }

  returnToList(){
    this.goBack.emit();
  }

}
