import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Profile } from 'src/app/data/models/profile.model';

@Component({
  selector: 'app-mytab-profiles',
  templateUrl: './mytab-profiles.component.html',
  styleUrls: ['./mytab-profiles.component.css']
})
export class MytabProfilesComponent implements OnInit {

  @Input() idProfileTab: number;
  @Output() goBack = new EventEmitter();

  profileSelected = new Profile();
  isNew = false;
  detailView = true;
  menuView = false;
  accessView = false;

  constructor() { }

  ngOnInit(): void {
    if (this.idProfileTab === 0) {
      this.isNew = true;
    } else {
     this.isNew = false;
    }
  }

  viewDetail() {
    this.detailView = true;
    this.menuView = false;
    this.accessView = false;
  }

  viewMenu() {
    this.detailView = false;
    this.menuView = true;
    this.accessView = false;
  }

  viewAccess() {
    this.detailView = false;
    this.menuView = false;
    this.accessView = true;
  }

  returnToList(){
    this.goBack.emit();
  }

}
