<form ngNativeValidate #frmDimension="ngForm" (ngSubmit)="Company_Center_Cost_New()" id="loginform" class="form row">
    <div class="row me-2">
        <div class="text-end mt-1 mb-1">
            <button (click)="agregar()" class=" btnEd" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightCost"
                aria-controls="offcanvasRightCost">
                <i class="fas fa-plus"></i> {{ "GENERAL.add" | translate }}
            </button>
        </div>
    </div>
    <div class="col- ">
        <div class="card dataCompany ms-3 me-4 ">
            <div class="card-header bg-transparent border-0">
                <div class="table-responsive mt-2">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th class="text-center">{{ "GENERAL.dimention" | translate }}</th>
                                <th class="text-center" style="min-width: 80px;">{{ "GENERAL.code" | translate }}
                                </th>
                                <th class="text-center" style="min-width: 250px;">{{ "GENERAL.description" |
                                    translate
                                    }}</th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let cc of CostCenters">

                                <td class="text-center">{{cc.Dimension}}</td>
                                <td class="text-center">{{cc.ERP_Code}}</td>
                                <td class="text-center">{{cc.Description}}</td>

                                <td class="text-center">
                                    <i class="fas fa-trash text-danger" (click)="deleteCenterCost(cc.Id_CostCentre)"
                                        style="font-size: 22px; cursor: pointer;"></i>
                                </td>
                                <td class="text-center">

                                    <i (click)="UsersCostCenters(cc.Id_CostCentre, cc.All_Creditors)"
                                        data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightCost"
                                        aria-controls="offcanvasRightCost" class="fas fa-users textP"
                                        style="font-size: 22px; cursor: pointer;"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRightCost" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
            <button type="button" class="btnArrow" data-bs-dismiss="offcanvas">
                <span><i class="fa fa-arrow-left"></i></span>
            </button>
            <h5 *ngIf="this.canva === 1" class="ps-4 mt-1" id="offcanvasRightLabel">Agregar Centro de Costos</h5>
            <h1 *ngIf="this.canva === 2" class="ps-4 mt-1 creditorsTittle">{{ "GENERAL.creditors" | translate }}</h1>
        </div>
        <div class="offcanvas-body p-0">
            <div class="">
                <ng-container *ngIf="this.canva === 1">
                    <div class="col-auto mt-2">
                        <span>Selecciona la Dimensión</span><br>
                       <select [(ngModel)]="dimension.Dimension" class="custom-select pull-right"
                            id="inlineFormCustomSelect2" name="inlineFormCustomSelect2"
                            style="width: 345px; margin-right: 10px;" required>
                            <option value="0" selected="true">{{ "GENERAL.dimention" | translate }}</option>
                            <option *ngFor="let dimension of Dimensions" value="{{dimension.Id_Dimension}}"
                                id="{{dimension.Id_Dimension}}">
                                {{dimension.Description}}</option>
                        </select>  
                    </div>
                    <div class="col-auto mt-2">
                        <span>Ingresa un Código</span>
                        <input [(ngModel)]="dimension.ERP_Code" name="code" class="form-control" type="text"
                            placeholder="{{ 'GENERAL.code' | translate }}..." id="code" style="width: 345px;" required>  
                    </div>
                    <div class="col-auto mt-2">
                        <span>Ingresa la Descripción</span>
                        <input [(ngModel)]="dimension.Description" name="description" class="form-control" type="text"
                            placeholder="{{ 'GENERAL.description' | translate }}..." id="description"
                            style="width: 345px;" required>  
                    </div>
                    <button class="btn btnSave ms-3 mt-2" style="width: 345px;" type="submit"
                        data-bs-dismiss="offcanvas"><i class="fas fa-plus"></i> 
                        {{ "GENERAL.add" | translate }}
                    </button>
                </ng-container>
                <ng-container *ngIf="this.canva === 2">
                    <div class="col-auto">
                        <div class="row">
                            <div class="col m-t-15 ">
                                <label for="example-text-input" class="col-auto col-form-label">{{
                                    "GENERAL.activate" | translate }} {{"GENERAL.all" | translate }}
                                </label>
                                <label class="switch">
                                    <input (change)="activateAllCreditors($event.target.checked)"
                                        [checked]="All_Creditors" name="activateAll" type="checkbox" id="activateAll">
                                    <span class="slider round-in"></span>
                                </label>
                            </div>
                        </div>
                        <div *ngIf="!All_Creditors" class="">
                            <div class="col-auto">
                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th class="text-center">{{ "GENERAL.creditor" | translate }}</th>
                                                <th class="text-center" style="min-width: 80px;">{{ "GENERAL.status" |
                                                    translate }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let user of UsersCostCenter">

                                                <td class="text-center">{{user.UserName}}</td>
                                                <td class="text-center">
                                                    <label class="switch">
                                                        <input
                                                            (change)="activateCreditor($event.target.checked, user.Id_User, user.Id_UserCostCenter)"
                                                            [checked]="user.STATUS== 'A'? true:false" name="activateAll"
                                                            type="checkbox" id="activateAll">
                                                        <span class="slider round-in"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</form>