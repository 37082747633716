import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-year',
  templateUrl: './year.component.html',
  styleUrls: ['./year.component.css']
})
export class YearComponent implements OnInit {

  @Output() yearSel = new EventEmitter<string>();

  ltsYearGet: any [];
  typeYea = '';

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    if(localStorage.getItem('yearSto') !== null) {
      this.typeYea = localStorage.getItem('yearSto');
    }

    this.typeYear();
  }

  selectYear(e) {
    this.typeYea = e.target.value;
    localStorage.setItem('yearSto', String(this.typeYea));
    this.yearSel.emit(this.typeYea);
  }

  //Services
  typeYear() {
    this.services
      .post({
        entidad: 'Payments',
        funcion: 4,
        data: {
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.ltsYearGet = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

}
