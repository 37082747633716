<div class="backgroundAnima">

    <div class="header-title">
      <h3>Ximplify Store</h3>
    </div>
  
    <div class="d-flex justify-content-center">
        <!-- <nav class="navbar bg-body-tertiary">
          <form class="container-fluid justify-content-start">
            <a href="/store" class="btn btn-outline-success me-2" type="button">Home</a>
            <a href="/store/apps" class="btn btn-sm btn-outline-secondary" type="button">Instaladores</a>
            <a href="/store" class="btn btn-sm btn-outline-secondary" type="button">Manuales</a>
            <a href="/store" class="btn btn-sm btn-outline-secondary" type="button">Recursos</a>
            <a href="/store" class="btn btn-sm btn-outline-secondary" type="button">Videos</a>
            <a href="/store" class="btn btn-sm btn-outline-secondary" type="button" (click)="logOff()">Salir</a>
          </form>
        </nav> -->
        <ul class="nav nav-underline">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="/store">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/store/apps">Instaladores</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/store">Manuales</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/store">Recursos</a>
          </li>          
          <li class="nav-item">
            <a class="nav-link" href="/store">Videos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" (click)="logOff()">Salir</a>
          </li>
        </ul>        
    </div>
  
        <router-outlet></router-outlet>
  
  </div>
  