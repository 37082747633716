<div class="card dataCompany ms-3 me-4 mt-2">
    <div class="card-header bg-transparent border-0 generalTab">
        <div>
            <h4 class="card-title"><b>{{ "GENERAL.user" | translate }}:</b> {{UserSelected.Name}}</h4>
        </div>
        <div *ngIf="UserSelected.Crew === 'Y'" class="row gx-0 mt-3">
            <div class="col-lg-12 col-md-9">
                <div class="table-responsive">
                    <table sortable-table (sorted)="onSorted($event)" class="table table-striped smallTable">
                        <thead>
                            <tr>
                                <th sortable-column="Crew_Name" style="cursor: pointer;">Grupo</th>
                                <th class="text-nowrap">
                                    <button class="btnDelete" (click)="deleteAllGroups(UserSelected.Id_User, UserSelected.Name)"><i class="fas fa-trash-alt mr-1"></i>Eliminar todos</button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let group of groupsLst">
                                <td>{{group.CrewName}}</td>
                                <td>
                                    <button class="btnEdit ps-5" (click)="deleteUser(group.Id_User_Crew, UserSelected.Id_User, group.Id_Crew, UserSelected.Name, group.CrewName)"><i class="fas fa-trash-alt mr-1"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="UserSelected.Crew === 'N'" class="alert alert-primary" role="alert">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </svg>
            Este usuario no pertenece a ningún grupo.
        </div>
    </div>
</div>