<form class="form-signin">
    <div class="form-group">
        <div *ngFor="let category of Preferences_Notification_List" class="col-12">
            <div class="row">
                <div class="col-12 pt-2">
                    <p class="font-weight-bold mb-2 h5">{{category.category_Name}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <ul class="list-group list-group-flush p-3">
                            <li *ngFor="let preference of category.oList"
                                class="list-group-item d-flex justify-content-between align-items-center pb-1 pr-0">
                                <div class="row mr-0 ml-0">
                                    <div class="col-">
                                        <div class="row mr-0 ml-0">
                                            <div class="col-">
                                                <p class="mb-0 font-weight-bold">{{preference.parameter_Name}}</p>
                                            </div>
                                        </div>
                                        <div class="row mr-0 ml-0">
                                            <div class="col-">
                                                <small class="text-muted">{{preference.description}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span>
                                    <label class="switch hideShow">
                                        <input [(ngModel)]="preference.value" [disabled]="!preference.modifiable"
                                            (change)="changeNotif(preference.id_Preferences_Notification, $event.target.checked)"
                                            name="chk{{preference.id_Preferences_Notification}}"
                                            id="chk{{preference.id_Preferences_Notification}}" type="checkbox">
                                        <span class="slider round-in"></span>
                                    </label>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <button (click)="enterSystem()" [disabled]="loading" class="btn btn-success btn-block mt-3 btnLogin" type="submit">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        <i class="far fa-arrow-alt-circle-right"></i>
        Siguiente
    </button>

    <hr>

    <div class="row justify-content-between">
        <div class="col- ml-2">
            <a (click)="backToLogin()" href="javascript:void(0)" id="cancel_reset"><i class="fas fa-angle-left"></i>
                Regresar</a>
        </div>

    </div>
</form>