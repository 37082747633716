<form [formGroup]="loginUser" (ngSubmit)="onSubmit()" class="form-signin">
    <span class="version">Versión {{appVersion}}</span>
    <img class="logo" src="https://xam-app.s3.us-west-2.amazonaws.com/images/simplesuit/ss-sinslogan-simple-suite.png" /> 
    <h1 class="h5 mb-4 font-weight-normal mt-3" style="text-align: center"> Inicia Sesión
    </h1>

    <div class="form-group">
        <label for="email">Email</label>
        <input type="text" formControlName="email" placeholder="Ingresa tu email" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />

            
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">El usuario es requerido</div>
        </div>
    </div>

    

    <div class="d-flex mt-4 btn-group mb-2" role="group" aria-label="Botones">
        <button type="button" class="btn btn-primary btnLogin" (click)="backApps()">
            <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
            <i class="fas fa-th"></i>
        </button>
        <button type="button" class="btn btn-info btnLogin" (click)="backUserSessions()">
            <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
            <i class="fas fa-users"></i>
        </button>
        <button [disabled]="loading" class="btn btn-success mt-0 flex-grow-1 btnLogin" type="submit">
                <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
                <i class="far fa-arrow-alt-circle-right"></i>
                Siguiente
        </button>
    </div>

    <!-- <hr> -->
</form>
