<form ngNativeValidate #f="ngForm" (ngSubmit)="onSubmitSMTP(f)" id="smtpForm" class="form">
    <div class="row me-2">
        <div class="text-end mt-1 mb-1">
            <button *ngIf="!isNewEditSMTP" (click)="resetSMTP()" class="btn btnEd"><i class="fas fa-trash-restore"></i> {{ "GENERAL.reset" | translate }}</button>
            <button *ngIf="!isNewEditSMTP" (click)="NewEditSMTP(true)" class="btn btnEd" ><i class="fas fa-edit"></i> {{ "GENERAL.edit" | translate }}</button>
            <!-- <button *ngIf="isNewEditSMTP" (click)="cancelSMTP()" class="btn btnEd"><i class="fas fa-times"></i> {{ "GENERAL.cancel" | translate }}</button> -->
            <!-- <button *ngIf="isNewEditSMTP" type="submit" class="btn btnEd"><i class="far fa-save"></i> {{ "GENERAL.save" | translate }}</button> -->
        </div>
    </div>

    <!-- Vista -->
    <div class="card dataCompany ms-3 me-4 ">
        <div class="card-header bg-transparent border-0">
            <ng-container >
                <!-- <h4 class="card-title" style="margin-top: 20px;"><b>{{ "GENERAL.company" | translate
                    }}:</b> {{companieSelected.Name}}
            </h4> -->
            <div class="row mt-3 mb-2">
                <h5 *ngIf="isNewEditSMTP" class="card-title" style="margin-top: 20px;">{{ titleNewEditCompanie | translate }} {{"GENERAL.company" | translate }}</h5>
                <h6 *ngIf="isNewEditSMTP" class="card-subtitle"> {{ "GENERAL.fillfields" | translate }} </h6>

                <div class="col-auto">
                    <label for="example-text-input" class="textP">{{"GENERAL.serverSMTP" | translate }}</label>
                    <div *ngIf="!isNewEditSMTP" class="">
                        <label for="example-text-input" class="ms-3 col-form-label">{{selectedSMTP.Server}}</label>
                    </div>
                    <div *ngIf="isNewEditSMTP" class="col-md-10">
                        <input [(ngModel)]="selectedSMTP.Server" required name="serverSMTP" class="form-control"
                            type="text" placeholder="{{ 'GENERAL.serverSMTP' | translate }}..." id="serverSMTP">
                    </div>
                </div>

                <div class="col-auto">
                    <label for="example-text-input" class="textP">{{ "GENERAL.portSMTP" | translate }}</label>
                    <div *ngIf="!isNewEditSMTP" class="">
                        <label for="example-text-input" class="ms-3 col-form-label">{{selectedSMTP.Port}}</label>
                    </div>
                    <div *ngIf="isNewEditSMTP" class="col-md-10">
                        <input [(ngModel)]="selectedSMTP.Port" required name="portSMTP" class="form-control" type="text"
                            placeholder="{{ 'GENERAL.portSMTP' | translate }}..." id="portSMTP">
                    </div>
                </div>

                <div class="col-auto">
                    <label for="example-text-input" class="textP">{{ "GENERAL.user" | translate }}</label>
                    <div *ngIf="!isNewEditSMTP" class="">
                        <label for="example-text-input"
                            class="ms-3 col-form-label">{{selectedSMTP.User_smtp}}</label>
                    </div>
                    <div *ngIf="isNewEditSMTP" class="col-md-10">
                        <input [(ngModel)]="selectedSMTP.User_smtp" required name="userSMTP" class="form-control"
                            type="text" placeholder="{{ 'GENERAL.user' | translate }}..." id="userSMTP">
                    </div>
                </div>

                <div *ngIf="isNewEditSMTP" class="col-auto">
                    <label for="example-text-input" class="textP">{{ "GENERAL.password" | translate }}</label>
                     <div class="ms-3">
                        <input [(ngModel)]="selectedSMTP.Password_smtp" required name="passSMTP" class="form-control"
                            type="password" placeholder="{{ 'GENERAL.password' | translate }}..." id="passSMTP" style="width:250px;">
                    </div> 
                </div>

                <div class="col-auto mb-2">
                    <label for="example-text-input" class="textP">{{ "GENERAL.sender" | translate }}</label>
                    <div *ngIf="!isNewEditSMTP" class="">
                        <label for="example-text-input" class="ms-3 col-form-label">{{selectedSMTP.Sender}}</label>
                    </div>
                    <div *ngIf="isNewEditSMTP" class="col-md-10">
                        <input [(ngModel)]="selectedSMTP.Sender" required name="sender" class="form-control" type="text"
                            placeholder="{{ 'GENERAL.sender' | translate }}..." id="sender">
                    </div>
                </div>

                <!-- <div class="col-auto">
                    <label for="example-text-input" class="textP">SSL</label>
                    <div *ngIf="!isNewEditSMTP" class="">
                        <label for="example-text-input"
                            class="ms-3 col-form-label">{{selectedSMTP.SSLName}}</label>
                    </div>
                    <div *ngIf="isNewEditSMTP" class="col-md-10"> -->
                        <!-- <label class="switch">
                            <input #statusSSL (change)="ChangeStatusSSL(statusSSL.checked)"
                                [checked]="selectedSMTP.SSL == 'A'? true:false" ngc name="statusSSL" type="checkbox"
                                id="statusSSL">
                            <span class="slider round-in"></span>
                        </label> -->
                    <!-- </div>
                </div> -->

                <!-- <div class="col-auto">
                    <label for="example-text-input" class="textP">{{ "GENERAL.status" | translate }}</label>
                    <div *ngIf="!isNewEditSMTP" class="">
                        <label for="example-text-input"
                            class="ms-3 col-form-label">{{selectedSMTP.StatusName}}</label>
                    </div>
                    <div *ngIf="isNewEditSMTP" class="col-md-10"> -->
                        <!-- <label class="switch">
                            <input #statusSMTP (change)="ChangeStatusSMTP(statusSMTP.checked)"
                                [checked]="selectedSMTP.Status == 'A'? true:false" name="statusSMTP" type="checkbox"
                                id="statusSMTP">
                            <span class="slider round-in"></span>
                        </label> -->
                    <!-- </div>
                </div> -->

                <div class="col-sm-auto px-0 text-center position-absolute contDate">
                    <div class="row margFech w-100">
                        <div class="col- text-center px-0">
                            <p class="mb-0 textP">SSL</p>
                            <label class="switch" [ngClass]="{'fade': !isNewEditSMTP}">
                                <input #statusSSL (change)="ChangeStatusSSL(statusSSL.checked)"
                                    [checked]="selectedSMTP.SSL == 'A'? true:false" ngc name="statusSSL" type="checkbox"
                                    id="statusSSL" [disabled]="!isNewEditSMTP">
                                <span class="slider round-in sli"></span>
                            </label>
                        </div>
                    </div>

                    <div class="row margFech w-100">
                        <div class="col- text-center">
                            <p class="mb-0 textP">{{ "GENERAL.status" | translate }}</p>
                            <label class="switch" [ngClass]="{'fade': !isNewEditSMTP}">
                                <input #statusSMTP (change)="ChangeStatusSMTP(statusSMTP.checked)"
                                    [checked]="selectedSMTP.Status == 'A'? true:false" name="statusSMTP" type="checkbox"
                                    id="statusSMTP" [disabled]="!isNewEditSMTP">
                                <span class="slider round-in sli"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
                <!-- <button (click)="NewEditSMTP(true)" class="btn btn-success waves-effect waves-light"
                style="float: right; width: 88px;">{{ "GENERAL.edit" | translate }}</button>

            <button (click)="resetSMTP()" class="btn btn-secondary waves-effect waves-light"
                style="float: right; width: 88px; margin-right: 10px;">{{ "GENERAL.reset" |
                translate }}</button> -->

            </ng-container>

            <!-- Nuevo / Editar -->
            <!-- <ng-container > -->
                <!-- <h5 class="card-title" style="margin-top: 20px;">{{ titleNewEditCompanie | translate }} {{"GENERAL.company" | translate }}</h5>
                <h6 class="card-subtitle"> {{ "GENERAL.fillfields" | translate }} </h6> -->


                <!-- <div  class="col-auto">
                    <label for="example-text-input" class="col-md-2 col-form-label">{{
                        "GENERAL.serverSMTP" | translate }}</label>
                   
                </div>

                <div class="m-t-15 row">
                    <label for="example-text-input" class="col-md-2 col-form-label">{{
                        "GENERAL.portSMTP" | translate }}</label>
                    
                </div>

                <div class="form-group m-t-15 row">
                    <label for="example-text-input" class="col-md-2 col-form-label">SSL</label>
                    
                </div>

                <div class="m-t-15 row">
                    <label for="example-text-input" class="col-md-2 col-form-label">{{
                        "GENERAL.user" | translate }}</label> -->
                    <!-- <div class="col-md-10">
                        <input [(ngModel)]="selectedSMTP.User_smtp" required name="userSMTP" class="form-control"
                            type="text" placeholder="{{ 'GENERAL.user' | translate }}..." id="userSMTP">
                    </div> -->
                <!-- </div> -->

                <!-- <div class="  m-t-15 row">
                    <label for="example-text-input" class="col-md-2 col-form-label">{{
                        "GENERAL.password" | translate }}</label>
                     <div class="col-md-10">
                        <input [(ngModel)]="selectedSMTP.Password_smtp" required name="passSMTP" class="form-control"
                            type="password" placeholder="{{ 'GENERAL.password' | translate }}..." id="passSMTP">
                    </div> 
                </div> -->

                <!-- <div class="  m-t-15 row">
                    <label for="example-text-input" class="col-md-2 col-form-label">{{
                        "GENERAL.sender" | translate }}</label>
                    
                </div>

                <div *ngIf="isEditSMTP" class="form-group m-t-15 row">
                    <label for="example-text-input" class="col-md-2 col-form-label">{{
                        "GENERAL.status" | translate }}</label> -->
                    <!-- <div class="col-md-10">
                        <label class="switch">
                            <input #statusSMTP (change)="ChangeStatusSMTP(statusSMTP.checked)"
                                [checked]="selectedSMTP.Status == 'A'? true:false" name="statusSMTP" type="checkbox"
                                id="statusSMTP">
                            <span class="slider round-in"></span>
                        </label>
                    </div> -->
                <!-- </div> -->
                <!-- <button type="submit" class="btn btn-success waves-effect waves-light m-r-10 m-t-15"
                    style="float: right; width: 88px; margin-left: 10px;">{{ "GENERAL.save" |
                    translate }}</button>
                <button (click)="cancelSMTP()" class="btn btn-secondary waves-effect waves-light m-t-15"
                    style="float: right; width: 88px;">{{ "GENERAL.cancel" |
                    translate }}</button> -->

            <!-- </ng-container> -->
        </div>
    </div>
    <div class="row me-3">
        <div class="text-end mt-1 mb-1">
            <button *ngIf="isNewEditSMTP" (click)="cancelSMTP()" class="btn btnCancel waves-effect waves-light mr-2"><i class="fas fa-times"></i> {{ "GENERAL.cancel" | translate }}</button>
            <button *ngIf="isNewEditSMTP" type="submit" class="btn btnSave waves-effect waves-light"><i class="far fa-save"></i> {{ "GENERAL.save" | translate }}</button>
        </div>
    </div>
</form>