// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appName: 'SSO',
  appVersion: '1.4.2',
  AppToken: '65E22B2C-C250-4E0B-987A-BAD078412F05',
  Id_Module: 0,
  Id_Tag: 34,
  AdminToken: '4E51D5CC-DB36-48A3-BF49-1CAF982FC571',
  Id_TagAdmin: 53,
  UrlStorageService: 'https://apps.ximplify.mx/Storage/FileUpload'
  //UrlStorageService: 'https://storage.ximplify.mx/Storage/FileUpload'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
