import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
    { path: 'login/:amb/:at', component: LoginComponent, pathMatch: 'full' },
    { path: 'login/:amb', component: LoginComponent, pathMatch: 'full' },
    { path: 'login/:at', component: LoginComponent, pathMatch: 'full' },
    { path: 'login', component: LoginComponent, pathMatch: 'full' },
    { path: 'admin', loadChildren: () => import('./ssadmin/ssadmin.module').then(m => m.SsadminModule) },
    // { path: 'store', loadChildren: () => import('./store/store.module').then(m => m.StoreModule) },
    { path: '', component: LoginComponent, pathMatch: 'full' },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
