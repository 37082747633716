<!-- <form  class="form-signin">
  <div class="email-list">
      <div *ngFor="let email of sesUsers; let i = index" class="email-item">
        <span (click)="selectEmail(email)">{{ email }}</span>
        <button class="action-button" (click)="deleteEmail(i)"> <i class="fas fa-times"></i> </button>
      </div>
      <button class="add-button" (click)="backToLogin()">Agregar otra cuenta</button>
  </div>
  
</form>
   -->

<!-- <form class="form-signin">
  <div class="email-list">
    <h1 class="form-heading mt-2">Selección de la cuenta</h1>
    <div *ngFor="let email of sesUsers; let i = index" class="email-item">
      <span (click)="selectEmail(email)">{{ email }}</span>
      <button class="action-button" (click)="deleteEmail(i)"> <i class="fas fa-times"></i> </button>
    </div>
    <button class="add-button" (click)="backToLogin()">Usar otra cuenta</button>
  </div>
</form> -->

<!-- <form class="form-signin">
  <div class="email-list">
    <h1 class="form-heading h3 mb-4 font-weight-normal mt-3">Selecciona tu cuenta</h1>
    <div *ngFor="let email of sesUsers; let i = index" class="email-item">
      <span class="person-icon"><i class="fas fa-user"></i></span>
      <span (click)="selectEmail(email)">{{ email }}</span>
      <button class="action-button" (click)="deleteEmail(i)"> <i class="fas fa-times"></i> </button>
    </div>
    <button class="add-button" (click)="backToLogin()">
      <span class="person-icon"><i class="fas fa-plus"></i></span>
      Usar otra cuenta
    </button>
  </div>
</form> -->
<form class="form-signin">
  <span class="version">Versión {{appVersion}}</span>
  <img class="logo" src="https://xam-app.s3.us-west-2.amazonaws.com/images/simplesuit/ss-sinslogan-simple-suite.png" /> 
  <h1 class="h5 form-heading mb-4 font-weight-normal mt-3">Selecciona tu cuenta</h1>
  <div class="email-list" [ngClass]="{'scrollable': sesUsers.length >= 5}">
    <div *ngFor="let email of sesUsers; let i = index" class="email-item">
      <span class="person-icon icn"><i class="fas fa-user"></i></span>
      <span (click)="selectEmail(email)">{{ email }}</span>
      <button class="action-button" (click)="deleteEmail(i)"> <i class="fas fa-times"></i> </button>
    </div>
  </div>
  <div class="d-flex justify-content-start" style="text-align: left;"> 
    <button class="add-button text" (click)="backToLogin()">
      <span class="person-icon"><i class="fas fa-plus"></i></span>
      Usar otra cuenta
    </button>
  </div>
</form>

