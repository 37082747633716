<div class="card dataCompany ms-3 me-4 mt-2">
    <div class="card-header bg-transparent border-0 generalTab">
        <h4 class="card-title mt-1"><b>{{ "GENERAL.user" | translate }}:</b> {{UserSelected.Name}}</h4>
        <h5 class="card-title mt-2">{{ "USERS.titleProfiles" | translate }}</h5>
        <h6 class="card-subtitle sub">{{ "USERS.headerProfiles" | translate }}</h6>

        <div class="row gx-0">
            <div class="col-auto">
              <h5 class="card-title pull-left mt-1">Filtrar por status</h5>
            </div>
            <div class="col-auto">
              <label class="switch">
                <input name="status" [(ngModel)]="isStatusUser" (change)="updateStatus($event)" type="checkbox"
                  id="status" />
                <span class="slider round-in"></span>
              </label>
            </div>
            <ng-container *ngIf="statusUser !== -1">
              <div class="col-auto">
                <h5 class="card-title pull-left mt-1">Limpiar filtro por status</h5>
              </div>
              <div class="col-auto">
                <button class="noP ps-1" (click)="clearStatus()"><i class="fas fa-eraser"></i></button>
              </div>
            </ng-container>
          </div>
            
        <div *ngFor="let app of data" class="table-striped mt-3">
            <table *ngIf="app.Profiles" sortable-table (sorted)="onSorted($event)" class="table table-striped">
                <thead>
                    <tr>
                        <th style="cursor: pointer; width: 200px;">{{app.Name}}</th>
                        <th sortable-column="Description" style="cursor: pointer; width: 200px;">{{ "GENERAL.type" | translate }}</th>
                        <th style="width: 200px;" class="text-nowrap">{{ "GENERAL.status" | translate }}</th>
                    </tr>
                </thead>
                <tbody >
                    <tr *ngFor="let Profiles of app.Profiles">
                        <td></td>
                        <td>{{Profiles.Description}}</td>
                        <td class="text-nowrap">
                            <div class="col-">
                                <label class="switch">
                                    <input [checked]="Profiles.IsActive == 1? true:false"
                                        (change)="ChangeStatusProfile(Profiles.Id_Profile,$event.target.checked)"
                                        name="status{{Profiles.Id_Profile}}" type="checkbox"
                                        id="status{{Profiles.Id_Profile}}" />
                                    <span class="slider round-in"></span>
                                </label>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    