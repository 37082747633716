import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-config-kpi',
  templateUrl: './config-kpi.component.html',
  styleUrls: ['./config-kpi.component.css'],
})
export class ConfigKpiComponent implements OnInit {
  @Output() addNewKPI = new EventEmitter();
  @Output() editKPI = new EventEmitter();
  @Output() closeC = new EventEmitter();

  @Input() newEditKpi: any;
  @Input() title = '';
  @Input() isforNew: any = {};
  @Input() dtlofKPI: any;

  lstChartGenerate = [];
  ltsSizeGenerate = [];
  lstVisibilityGenerate = [];
  kpiGenerateInfo = [];
  typeGraph = '';
  typeSize = '';
  typeVisi = '';
  isActive = 'I';
  descriptonKpi = '';
  numberTop = 0;


  Titulo = '';
  Status = '';
  Id_KPI = 0;

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    if (this.dtlofKPI !== undefined) {
      this.Titulo = this.dtlofKPI.Titulo;
      this.Status = this.dtlofKPI.Status;
      this.typeGraph = this.dtlofKPI.Id_KPI_Chart_Type;
      this.typeVisi = this.dtlofKPI.Id_Accesss;
      this.typeSize = this.dtlofKPI.Id_KPI_Size;
      this.descriptonKpi = this.dtlofKPI.Descripcion;
      this.numberTop = this.dtlofKPI.Top_Data;
      this.Id_KPI = this.dtlofKPI.Id_KPI;
      this.isforNew.type = this.dtlofKPI.Id_KPI_Type;
      this.isforNew.app = this.dtlofKPI.Id_Project;
    }
    this.typeChartGet();
    this.typeSizeGet();
    this.typeVisibility();
  }

  onTitle(e) {
    this.Titulo = e.target.value;
  }

  onCheck(e) {
    if (e.target.checked) {
      this.isActive = 'A';
    } else {
      this.isActive = 'I';
    }
  }

  closeCb() {
    this.closeC.emit();
  }

  selectGraph(e) {
    this.typeGraph = e.target.value;
  }

  selectVisibility(e) {
    this.typeVisi = e.target.value;
  }

  selectSize(e) {
    this.typeSize = e.target.value;
  }

  selectTop(e) {
    this.numberTop = e.target.value;
  }

  addNewConfig() {
    this.generateNewKPI();
  }

  // Servicios

  typeChartGet() {
    if (this.dtlofKPI === undefined) {
      this.isforNew.type = this.newEditKpi.type;
    }
    this.services
      .post({
        entidad: 'KPIs',
        funcion: 6,
        data: {
          id_kpi_type: this.isforNew.type,
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.lstChartGenerate = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

  typeVisibility() {
    this.services
      .post({
        entidad: 'KPIs',
        funcion: 12,
        data: {},
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.lstVisibilityGenerate = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

  typeSizeGet() {
    this.services
      .post({
        entidad: 'KPIs',
        funcion: 7,
        data: {},
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.ltsSizeGenerate = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

  generateNewKPI() {
    if (this.Titulo === '') {
      this.toastr.warning('Tienes el siguiente campo vacio: Titulo', 'KPIs');
    } else if (this.typeGraph === '') {
      this.toastr.warning('Tienes el siguiente campo vacio: Grafica', 'KPIs');
    } else if (this.typeVisi === '') {
      this.toastr.warning('Tienes el siguiente campo vacio: Visibilidad', 'KPIs');
    } else if (this.typeSize === '') {
      this.toastr.warning('Tienes el siguiente campo vacio: Tamaño', 'KPIs');
    }
    this.services
      .post({
        entidad: 'KPIs',
        funcion: 4,
        data: {
          status: this.isActive,
          id_kpi_type: this.isforNew.type,
          id_project: this.isforNew.app,
          title: this.Titulo,
          description: this.descriptonKpi,
          top_data: this.numberTop,
          id_kpi_chart_type: this.typeGraph,
          id_kpi_size: this.typeSize,
          id_accesss: this.typeVisi,
          id_kpi: this.Id_KPI
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.kpiGenerateInfo = result.data[0];

            if (this.newEditKpi.e === 'N') {
              this.addNewKPI.emit(this.kpiGenerateInfo);
              this.toastr.success('Se genero correctamente, completa los filtros', 'KPIs');
            } else {
              this.editKPI.emit();
              this.toastr.success('Se actualizo correctamente', 'KPIs');
            }
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }
}
