<!-- Comienza encabezado -->
<ng-container>
    <app-title-payment [titleP]="isTitle"></app-title-payment>
</ng-container>

<ng-container *ngIf="isReturn">
    <div class="row ">
        <div class="col-auto position-absolute pos mt-2 positionTop">
            <button (click)="returnToList()" class="btnBackTop">
                <i class="fas fa-arrow-left"></i>
            </button>
        </div>
    </div>
</ng-container>

<div class="row justify-content-between ">
    <div class="col-auto">
        <div class="row ">
            <div class="col-auto">
                <input name="search" [(ngModel)]="searchTerm" type="text" (input)="searchInput($event)"
                    (click)="returnToList()" class="input-search form-control"
                    placeholder="{{ 'GENERAL.searchby' | translate }}...">
                <a class="ico-btn-search">
                    <i class="ti-search"></i>
                </a>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!isReturn">
        <div class="col-auto mt-2 mt-sm-0">
            <button (click)="createNewProfile()" class="btnSta mt-1" placement="top" ngbTooltip="Nuevo">
                <i class="fas fa-plus-square fa-lg"></i>
            </button>
        </div>
    </ng-container>
</div>
<!-- Termina encabezado -->

<!--Canvas-->
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <button type="button" class="btnArrow" data-bs-dismiss="offcanvas">
            <span><i class="fa fa-arrow-left"></i></span>
        </button>
        <div class="col-auto">
            <select (change)="selectFilter($event.target.value)" class="form-select form-select-sm" aria-label=".form-select-sm example">
                <option value="">Selecciona filtro:</option>
                <option value="1">
                    Aplicación
                </option>
                <option value="2">
                    Tag
                </option>
            </select>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class="col-auto px-0">
            <div *ngIf="nameColumn === 'A'" class="multiS">
                <app-application-multiselect (idAppC)="filterByApp($event)"></app-application-multiselect>
            </div>
            <div *ngIf="nameColumn === 'T'" class="multiS">
                <app-tag-multiselect (idTagC)="filterByTag($event)"></app-tag-multiselect>
            </div>
            <div class="d-grid gap-2 mt-3">
                <button (click)="filterData()" type="button" data-bs-dismiss="offcanvas" class="btn btn-success btnFilter">Filtrar</button>
            </div>
        </div>
    </div>
</div>
<!--termina canvas-->

<!-- Comienza contenido -->
<div class="row mt-2 pb-2">
    <ng-container *ngIf="showPrincipal">
        <div class="col-md-12 col-lg-12 col-sm-12 divPrinc">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="table-responsive tablePayments heig">
                            <table sortable-table (sorted)="onSorted($event)" class="table table-striped">
                                <thead>
                                    <tr class="purple">
                                        <th style="width: 50px;">
                                            <button
                                            *ngIf="isRest || idApp != 0 || idTag != 0 || pagination != 10"
                                            class="tooltipp btnRes" (click)="resetTable()" placement="top"
                                            ngbTooltip="Restablecer Filtros"><i class="fas fa-trash-restore-alt p-1"></i></button>
                                        </th>
                                        <th sortable-table="profile" sortable-column="pro" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown"
                                                    type="button" id="profile" data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <span *ngIf="azPro"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="zaPro"><i class="fas fa-sort-alpha-down-alt"></i></span>
                                                    Perfil
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="profile">
                                                    <li><a (click)="onSortedA()" class="dropdown-item"
                                                            href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD()" class="dropdown-item"
                                                            href="javascript:void(0);">De la Z a la A</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="app" sortable-column="app" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown"
                                                    type="button" id="app" data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <span *ngIf="azApp"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="zaApp"><i class="fas fa-sort-alpha-down-alt"></i></span>
                                                    <span *ngIf="idApp != 0" class="icon"><i class="fas fa-filter"></i></span>
                                                    Aplicación
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="app">
                                                    <li><a (click)="onSortedA()" class="dropdown-item"
                                                            href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD()" class="dropdown-item"
                                                            href="javascript:void(0);">De la Z a la A</a></li>
                                                    <li><a (click)="selectColumn('A')" class="dropdown-item"
                                                        type="button" data-bs-toggle="offcanvas"
                                                        data-bs-target="#offcanvasRight"
                                                        aria-controls="offcanvasRight">Filtrar aplicación</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="tag" sortable-column="tag" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown"
                                                    type="button" id="tag" data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <span *ngIf="azTag"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="zaTag"><i class="fas fa-sort-alpha-down-alt"></i></span>
                                                    <span *ngIf="idTag != 0" class="icon"><i class="fas fa-filter"></i></span>
                                                    Tag
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="tag">
                                                    <li><a (click)="onSortedA()" class="dropdown-item"
                                                            href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD()" class="dropdown-item"
                                                            href="javascript:void(0);">De la Z a la A</a></li>
                                                    <li><a (click)="selectColumn('T')" class="dropdown-item"
                                                            type="button" data-bs-toggle="offcanvas"
                                                            data-bs-target="#offcanvasRight"
                                                            aria-controls="offcanvasRight">Filtrar Tag</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let profile of listProfiles">
                                        <td style="width: 50px;">
                                            <button (click)="selectProfile(profile.Id_Profile)" class="btnEdit ps-1"><i class="fas fa-file-invoice"></i></button>
                                            <button *ngIf="profile.AssignedAccess === 0 && profile.AssignedMenus === 0 && profile.AssignedUsers === 0" class="btnEdit ps-1" (click)="deleteProfile(profile.Id_Profile, profile.ProfileDescription)"><i class="fas fa-trash-alt ml-1"></i></button>
                                        </td>
                                        <td>{{profile.ProfileDescription}}</td>
                                        <td class="center">{{profile.ApplicationName}}</td>
                                        <td class="center">{{profile.TagDescription}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <app-pagination-global [callbackFunction]="getProfiles" [actualPagination]="pagination" [collectionDinamic]="collectionSize" (changePagination)="changeAmountView($event)" (changePageEmi)="changePage($event)"></app-pagination-global>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isView">
        <div class="col-md-12 col-lg-12 col-sm-12 generalTab divPrinc">
            <!-- <div class="card grey pb-3">
                <app-detail-profile [idProfileDetail]="idProfile" (goBack)="returnToList()"></app-detail-profile>
            </div> -->
            <app-mytab-profiles (goBack)="returnToList()" [idProfileTab]="idProfile"></app-mytab-profiles>
        </div>
    </ng-container>

</div>

<app-back-to-top></app-back-to-top>
<!-- Termina contenido -->
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>