import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filters-customer',
  templateUrl: './filters-customer.component.html',
  styleUrls: ['./filters-customer.component.css']
})
export class FiltersCustomerComponent implements OnInit {

  @Output() filSelC = new EventEmitter<string>();

  typeFilC = '';

  constructor(
    private translate: TranslateService,
  ) { 
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
  }

  selectFilter(e) {
    this.typeFilC = e.target.value;
    this.filSelC.emit(this.typeFilC);
  }

}
