<form [formGroup]="newPassForm" (ngSubmit)="onSubmit()" class="form-signin">

    <h1 class="h3 mb-2 font-weight-normal mt-3" style="text-align: center"> Ingresa tu
        contraseña</h1>
    <p class="mb-2" id="forgot_pswd">{{email}}</p>


    <div class="form-group">
        <label for="password">Nueva Contraseña</label>
        <div class="input-group">
            <!-- Change input type to be dynamic -->

                <input [type]="fieldTextType ? 'text' : 'password'" type="password" formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div class="input-group-append">
                <span class="input-group-text">
                    <i class="fa" [ngClass]="{
                      'fa-eye-slash': !fieldTextType,
                      'fa-eye': fieldTextType
                    }" (click)="toggleFieldTextType()"></i>
                </span>
            </div>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">La contraseña es obligatoria</div>
            </div>
        </div>

        <label for="passwordConfirm mt-3">Confirmar Nueva Contraseña</label>
        <div class="input-group ">
            <!-- Change input type to be dynamic -->

                <input [type]="fieldTextTypeConfirm ? 'text' : 'password'" type="password" formControlName="passwordConfirm" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.passwordConfirm.errors }" />
            <div class="input-group-append">
                <span class="input-group-text">
                    <i class="fa" [ngClass]="{
                      'fa-eye-slash': !fieldTextTypeConfirm,
                      'fa-eye': fieldTextTypeConfirm
                    }" (click)="toggleFieldTextType1()"></i>
                </span>
            </div>
            <div *ngIf="submitted && f.passwordConfirm.errors" class="invalid-feedback">
                <div *ngIf="f.passwordConfirm.errors.required">Confirmar la contraseña es obligatoria</div>
            </div>
        </div>
    </div>

    <button [disabled]="loading" class="btn btn-success btn-block mt-3 btnLogin" type="submit">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        <i class="fas fa-sign-in-alt"></i> Establecer nueva contraseña
    </button>

    <hr>

    <div class="row justify-content-between">
        <div class="col- ml-2">
            <a (click)="backToLogin()" href="javascript:void(0)" id="cancel_reset"><i class="fas fa-angle-left"></i>
                Regresar</a>
        </div>
    </div>



</form>