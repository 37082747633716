import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-top-title',
  templateUrl: './top-title.component.html',
  styleUrls: ['./top-title.component.css']
})
export class TopTitleComponent implements OnInit {
  @Output() addNew = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  addNewKPI(){
    this.addNew.emit();
  }

}
