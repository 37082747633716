import { AbstractControl, ValidatorFn } from '@angular/forms'


export function SelectedValue(): ValidatorFn {
  return (control: AbstractControl):{[key:string]:any} | null => {

    const valor = <string>control.value;
    const result = {
      selectedValue: {
        mensaje: 'Debe seleccionar algún valor'
      }
    };

    if (!valor) return result;
    if (valor.length === 0) return result;

    if (valor == "" || valor == " " || valor == "0") {
      return result;
    }

    return null;
  }
}
