<div class="row me-2">
    <div class="text-end mt-1 mb-1 ">
        <button *ngIf="viewCompanie" (click)="newEditCompanie(true)" class="btnEd">
            <i class="fas fa-edit"></i>
            {{ "GENERAL.edit" | translate }}
        </button>
        <!-- <ng-container *ngIf="isNewEdit">
            <button *ngIf="isEdit" (click)="cancelEdit()" class="btnEd">
                <i class="fas fa-times"></i>
                Cancelar
            </button>
        </ng-container> -->
    </div>
</div>

<form ngNativeValidate #f="ngForm" (ngSubmit)="Save_New_Company()" id="loginform" class="form row">
    <ng-container>
        <div class="col-md-12 col-lg-12 col-sm-12" style="margin-left: auto; margin-right: auto;">
            <div class="card ms-3 me-3">
                <div class="card-body">
                    <div class="row justify-content-left ps-3 mt-3 dataChannel">
                        <div class="col-auto">
                            <span class=" pl-3  textP">{{ "GENERAL.businessname" | translate }}</span>
                            <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.Name}}</p>
                            <p *ngIf="isNewEdit"><input [(ngModel)]="companieSelected.Name" required name="name" class="form-control" type="text" placeholder="{{ 'GENERAL.name' | translate }}..." id="name"></p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3  textP">{{ "GENERAL.rfc" | translate }}</span>
                            <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.RFC}}</p>
                            <p *ngIf="isNewEdit"><input [(ngModel)]="companieSelected.RFC" required name="rfc" class="form-control input1" type="text" placeholder="{{ 'GENERAL.rfc' | translate }}..." id="rfc"></p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3  textP">CURP</span>
                            <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.CURP}}</p>
                            <p *ngIf="isNewEdit"><input [(ngModel)]="companieSelected.CURP" name="curp" class="form-control input1" type="text" placeholder="CURP..." id="curp"></p>
                        </div>
                        <div class="row">
                            <div class="col-auto">
                                <span class=" pl-3  textP">{{ "GENERAL.taxReg" | translate }}</span>
                                <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.Name_Tax_Regime}}</p>
                                <p *ngIf="isNewEdit">
                                    <select [(ngModel)]="companieSelected.Id_Tax_Regime" class="custom-select col-12" id="SelectTaxReg" name="SelectTaxReg" required>
                                        <option *ngIf="!companieSelected.Name_Tax_Regime" value="0" selected="">{{
                                            "GENERAL.selectTaxReg" | translate}}...</option>
                                        <option *ngIf="companieSelected.Name_Tax_Regime != ''" value="0" selected="">
                                            {{companieSelected.Name_Tax_Regime}}</option>
                                        <option *ngFor="let taxReg of listTaxReg" value="{{taxReg.Id_Tax_Regime}}">
                                            {{taxReg.Name}}
                                        </option>
                                    </select>
                                </p>
                            </div>
                            <div *ngIf="isNewEdit" class="col-auto">
                                <span class=" pl-3  textP">{{ "GENERAL.shortname" | translate }}</span>
                                <p><input [(ngModel)]="companieSelected.Alter_Name" name="alter_name" class="form-control" type="text" placeholder="{{ 'GENERAL.shortname' | translate }}..." id="email" required></p>
                            </div>
                            <div class="col-auto">
                                <span class=" pl-3  textP">{{ "GENERAL.organization" | translate }}</span>
                                <p *ngIf="viewCompanie || isEdit" class="pl-4 ml-3">{{companieSelected.NameCustomer}}
                                </p>
                                <p *ngIf="isNewEdit && !isEdit" class="muul mi input1">
                                    <app-customer-multiselect (custSelect)="sendCustomer($event)"></app-customer-multiselect>
                                    <!-- <ng-multiselect-dropdown [placeholder]="'Selecciona organización:'"
                                        [settings]="dropdownSettingsCus" [data]="lstCustomerGet" [(ngModel)]="selectedItems"
                                        (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeselect($event)"
                                        name="customer" required>
                                    </ng-multiselect-dropdown> -->
                                </p>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isEdit || viewCompanie" class="col-sm-auto px-0 text-center position-absolute contDate">
                        <br />
                        <div class="row margFech w-100">
                            <div class="col- text-center">
                                <p class="mb-0 textP">{{ "GENERAL.status" | translate }}</p>
                                <label class="switch hideShow" [ngClass]="{'fade': !isNewEdit}">
                                    <input #statusInt [checked]="companieSelected.Status == 'A'? true:false"
                                        (change)="changeCompanyStatus($event.target.checked)" name="statusMFA"
                                        type="checkbox" id="statusMFA" [disabled]="!isNewEdit" />
                                    <span class="slider round-in"></span>
                                </label>
                            </div>
                        </div>
                        <div class="row margFech w-100">
                            <div class="col- text-center">
                                <p class="mb-0 textP">{{ "GENERAL.status" | translate }} MFA</p>
                                <label class="switch hideShow" [ngClass]="{'fade': !isNewEdit}">
                                    <input #statusInt [checked]="companieSelected.MFA == 'A'? true:false"
                                        (change)="changeCompanyMFA($event.target.checked)" name="status" type="checkbox"
                                        id="status" [disabled]="!isNewEdit" />
                                    <span class="slider round-in"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <b class=" pl-3  textP">{{ "GENERAL.address" | translate }}</b>
            <div class="card ms-3 me-3 cadBTNHead">
                <div class="card-body pb-2 ps-2 pe-3 pt-1 he">
                    <div class="row justify-content-left ps-3 mt-4 dataChannel">
                        <div class="col-auto">
                            <span class=" pl-3  textP">{{ "GENERAL.street" | translate }}</span>
                            <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.Street}}</p>
                            <p *ngIf="isNewEdit"><input [(ngModel)]="companieSelected.Street" required name="street" class="form-control" type="text" placeholder="{{ 'GENERAL.street' | translate }}..." id="street"></p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3  textP">{{"GENERAL.nExt" | translate }}</span>
                            <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.Num_Ext}}</p>
                            <p *ngIf="isNewEdit"><input [(ngModel)]="companieSelected.Num_Ext" required name="nExt" class="form-control" type="text" placeholder="{{ 'GENERAL.nExt' | translate }}..." id="nExt"></p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3  textP">{{ "GENERAL.nInt" | translate }}</span>
                            <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.Num_Int}}</p>
                            <p *ngIf="isNewEdit"><input [(ngModel)]="companieSelected.Num_Int" name="nInt" class="form-control" type="text" placeholder="{{ 'GENERAL.nInt' | translate }}..." id="nInt"></p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3  textP">{{ "GENERAL.colony" | translate }}</span>
                            <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.Colony}}</p>
                            <p *ngIf="isNewEdit"><input [(ngModel)]="companieSelected.Colony" required name="colony" class="form-control" type="text" placeholder="{{ 'GENERAL.colony' | translate }}..." id="colony"></p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3  textP">{{ "GENERAL.municipality" | translate }}</span>
                            <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.Municipality}}</p>
                            <p *ngIf="isNewEdit"><input [(ngModel)]="companieSelected.Municipality" required name="municipality" class="form-control" type="text" placeholder="{{ 'GENERAL.municipality' | translate }}..." id="municipality"></p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3  textP">{{ "GENERAL.location" | translate }}</span>
                            <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.Location}}</p>
                            <p *ngIf="isNewEdit"><input [(ngModel)]="companieSelected.Location" name="location" class="form-control" type="text" placeholder="{{ 'GENERAL.location' | translate }}..." id="location"></p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3  textP">{{ "GENERAL.estate" | translate }}</span>
                            <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.Estate}}</p>
                            <p *ngIf="isNewEdit"><input [(ngModel)]="companieSelected.Estate" required name="estate" class="form-control" type="text" placeholder="{{ 'GENERAL.estate' | translate }}..." id="estate"></p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3  textP">{{ "GENERAL.pc" | translate }}</span>
                            <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.Postal_Code}}</p>
                            <p *ngIf="isNewEdit"><input [(ngModel)]="companieSelected.Postal_Code" required name="pc" class="form-control" type="text" placeholder="{{ 'GENERAL.pc' | translate }}..." id="pc"></p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3  textP">{{ "GENERAL.reference" | translate }}</span>
                            <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.Reference}}</p>
                            <p *ngIf="isNewEdit"><input [(ngModel)]="companieSelected.Reference" name="reference" class="form-control" type="text" placeholder="{{ 'GENERAL.reference' | translate }}..." id="reference"></p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3  textP">{{ "GENERAL.branchOffice" | translate }}</span>
                            <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.Branch_Office}}</p>
                            <p *ngIf="isNewEdit"><input [(ngModel)]="companieSelected.Branch_Office" required name="branchOffice" class="form-control" type="text" placeholder="{{ 'GENERAL.branchOffice' | translate }}..." id="branchOffice"></p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3  textP">{{ "GENERAL.placeExpe" | translate }}*</span>
                            <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.Expedition_Place_Postal_Code}}
                            </p>
                            <p *ngIf="isNewEdit"><input [(ngModel)]="companieSelected.Expedition_Place_Postal_Code" required name="placeExpe" class="form-control" type="text" placeholder="{{ 'GENERAL.placeExpe' | translate }}..." id="placeExpe"></p>
                        </div>
                    </div>
                </div>
            </div>
            <b class=" pl-3  textP">{{ "GENERAL.contactaData" | translate }}</b>
            <div class="card ms-3 me-3 cadBTNHead">
                <div class="card-body pb-2 ps-2 pe-3 pt-1 he">
                    <div class="row justify-content-left ps-3 mt-4 dataChannel">
                        <div class="col-auto">
                            <span class=" pl-3  textP">{{ "GENERAL.email" | translate }}*</span>
                            <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.E_Mail}}</p>
                            <p *ngIf="isNewEdit"><input [(ngModel)]="companieSelected.E_Mail" required name="email" class="form-control" type="text" placeholder="{{ 'GENERAL.email' | translate }}..." id="email"></p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3  textP">{{ "GENERAL.phone" | translate }}</span>
                            <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.Phone_Number}}</p>
                            <p *ngIf="isNewEdit"><input [(ngModel)]="companieSelected.Phone_Number" name="phone" class="form-control" type="text" placeholder="{{ 'GENERAL.phone' | translate }}..." id="phone"></p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3  textP">{{ "GENERAL.adminContact" | translate }}</span>
                            <p *ngIf="viewCompanie" class="pl-4 ml-3">{{companieSelected.Admin_Contact}}</p>
                            <p *ngIf="isNewEdit"><input [(ngModel)]="companieSelected.Admin_Contact" required name="adminContact" class="form-control" type="text" placeholder="{{ 'GENERAL.adminContact' | translate }}..." id="adminContact"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isNewEdit" class="col-md-12">
                <button type="submit" class="btn btnSave waves-effect waves-light m-r-10  m-t-15" style="float: right; width: 88px; margin-left: 10px;">{{ "GENERAL.save"
                    | translate }}</button>
                <button *ngIf="isEdit" (click)="cancelEdit()" class="btn btnCancel waves-effect waves-light m-t-15" style="float: right; width: 88px; margin-left: 10px;"> {{"GENERAL.cancel" | translate }} </button>
                <button *ngIf="!isEdit" (click)="cancelCompanie()" class="btn btnCancel waves-effect waves-light m-t-15" style="float: right; width: 88px;">{{
                    "GENERAL.cancel" | translate }}</button> 
            </div>
        </div>
    </ng-container>
</form>