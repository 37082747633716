<div class="row animate__animated animate__flip">
  <div class="col">
    <div class="row mt-1 justify-content-between">
      <div class="col-auto">
        <div class="row">
          <div class="col-auto tittleKPICard">
            <h2 class="mb-0">{{dtlofKPI.Titulo}}</h2>
            <small>{{dtlofKPI.Proyecto}}</small>
          </div>

          <div class="col-auto">
            <h5>
              <span *ngIf="dtlofKPI.Status === 'A'" class="badge badge-success">Status: Activo</span>
              <span *ngIf="dtlofKPI.Status === 'I'" class="badge badge-warning">Status: Inactivo</span>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-auto closeBtn">
        <div class="col-auto mt-4">
          <button class="close" (click)="closeCb()">
            <i class="far fa-times-circle"></i>
          </button>
        </div>
      </div>
    </div>

    <hr class="mt-2 mb-1 subTittleCard" />
    <p>Log de Actualizaciones</p>
    <div class="row mt-1 tableLOG">
      <div class="col">
        <div class="table-responsive">
          <table class="table table-striped table-hover">
            <thead class="thead-light">
              <tr>
                <th scope="col">Evento</th>
                <th scope="col">Persona</th>
                <th scope="col">Fecha</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let tLog of lstLogGenerate">
                <tr>
                  <td scope="row">{{tLog.EventDesc}}</td>
                  <td>{{tLog.Name}}</td>
                  <td>{{tLog.Date | date: 'dd/MM/yyyy hh:mm a'}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>