import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './account/account.component';
import { AppAccountComponent } from './app-account.component';
import { CompanyComponent } from './company/company.component';
import { UserComponent } from './user/user.component';
import { GruposComponent } from './grupos/grupos.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { ChannelComponent } from './channel/channel.component';
import { CustomerComponent } from './customer/customer.component';
import { SideMenuComponent } from './shared/side-menu/side-menu.component';
import { SidebarDirective } from './shared/side-menu/side-menu.directive';
import { ToastrModule } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SortableColumnComponent } from './shared/sortable-table/sortable-column.component';
import { SortableTableDirective } from './shared/sortable-table/sortable-table.directive';
import { SortService } from './shared/sortable-table/sort.service';
import { ExcelService } from '../data/services/excel.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { SearchItemComponent } from './shared/search-item/search-item.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { BrowserModule } from '@angular/platform-browser';
import { IndicatorsComponent } from './indicators/indicators.component';
import { TopTitleComponent } from './indicators/top-title/top-title.component';
import { TopNewIndicatorComponent } from './indicators/top-new-indicator/top-new-indicator.component';
import { ConfigKpiComponent } from './indicators/config-kpi/config-kpi.component';
import { FiltersKpiComponent } from './indicators/filters-kpi/filters-kpi.component';
import { GraphComponent } from './indicators/graph/graph.component';
import { TableInfoComponent } from './indicators/table-info/table-info.component';
import { LogKpisComponent } from './indicators/log-kpis/log-kpis.component';
import { ChartsModule } from 'ng2-charts';
import { PaymentsControlComponent } from './payments-control/payments-control.component';
import { TablePaymentsComponent } from './payments-control/table-payments/table-payments.component';
import { TitlePaymentComponent } from './shared/title-payment/title-payment.component';
import { OrganizationComponent } from './payments-control/organization/organization.component';
import { ProjectComponent } from './payments-control/project/project.component';
import { YearComponent } from './payments-control/year/year.component';
import { MonthComponent } from './payments-control/month/month.component';
import { ChannelPaymentComponent } from './payments-control/channel-payment/channel-payment.component';
import { FiltersComponent } from './payments-control/filters/filters.component';
import { AnnuityComponent } from './payments-control/annuity/annuity.component';
import { ModuleComponent } from './payments-control/module/module.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DetailChannelComponent } from './channel/detail-channel/detail-channel.component';
import { FileUploadModule } from 'ng2-file-upload';
import { FiltersChannelComponent } from './channel/filters-channel/filters-channel.component';
import { MytabComponent } from './customer/mytab/mytab.component';
import { GeneralComponent } from './customer/general/general.component';
import { AplicationComponent } from './customer/aplication/aplication.component';
import { FoliosComponent } from './customer/folios/folios.component';
import { ColumnCompanyComponent } from './user/column-company/column-company.component';
import { ColumnStatusComponent } from './user/column-status/column-status.component';
import { FiltersUserComponent } from './user/filters-user/filters-user.component';
import { FiltersCustomerComponent } from './customer/filters-customer/filters-customer.component';
import { BackToTopComponent } from './shared/back-to-top/back-to-top.component';
import { GeneralCompanyComponent } from './company/general-company/general-company.component';
import { LogoCompanyComponent } from './company/logo-company/logo-company.component';
import { QrCompanyComponent } from './company/qr-company/qr-company.component';
import { PacsimpleCompanyComponent } from './company/pacsimple-company/pacsimple-company.component';
import { SatCompanyComponent } from './company/sat-company/sat-company.component';
import { SmtpCompanyComponent } from './company/smtp-company/smtp-company.component';
import { CostCenterCompanyComponent } from './company/cost-center-company/cost-center-company.component';
import { ProjectCompanyComponent } from './company/project-company/project-company.component';
import { BranchOfficesCompanyComponent } from './company/branch-offices-company/branch-offices-company.component';
import { MyTabUserComponent } from './user/my-tab-user/my-tab-user.component';
import { GeneralUserComponent } from './user/general-user/general-user.component';
import { ProfilesUserComponent } from './user/profiles-user/profiles-user.component';
import { CompaniesUserComponent } from './user/companies-user/companies-user.component';
import { FiltersCompanyComponent } from './company/filters-company/filters-company.component';
import { MyTabCompanyComponent } from './company/my-tab-company/my-tab-company.component';
import { MytabGroupsComponent } from './grupos/mytab-groups/mytab-groups.component';
import { GeneralGroupsComponent } from './grupos/general-groups/general-groups.component';
import { UsersGroupsComponent } from './grupos/users-groups/users-groups.component';
import { DragulaModule } from 'ng2-dragula';
import { GroupsUserComponent } from './user/groups-user/groups-user.component';
import { CustomerMultiselectComponent } from './customer/customer-multiselect/customer-multiselect.component';
import { PaginationGlobalComponent } from './shared/pagination-global/pagination-global.component';
import { AccessComponent } from './access/access.component';
import { DetailAccessComponent } from './access/detail-access/detail-access.component';
import { ApplicationMultiselectComponent } from './shared/application-multiselect/application-multiselect.component';
import { TagMultiselectComponent } from './shared/tag-multiselect/tag-multiselect.component';
import { MenuComponent } from './menu/menu.component';
import { DetailMenuComponent } from './menu/detail-menu/detail-menu.component';
import { MytabMenusComponent } from './menu/mytab-menus/mytab-menus.component';
import { LanguagesMenuComponent } from './menu/languages-menu/languages-menu.component';
import { MenuMultiselectComponent } from './shared/menu-multiselect/menu-multiselect.component';
import { ProfileComponent } from './profile/profile.component';
import { DetailProfileComponent } from './profile/detail-profile/detail-profile.component';
import { MytabProfilesComponent } from './profile/mytab-profiles/mytab-profiles.component';
import { AccessProfilesComponent } from './profile/access-profiles/access-profiles.component';
import { MenusProfilesComponent } from './profile/menus-profiles/menus-profiles.component';

export function createTranslateLoader(http: HttpClient) {

  return new TranslateHttpLoader(http, './assets/i18n/', '.json');

}

@NgModule({
  declarations: [
    AccountComponent,
    AppAccountComponent,
    CompanyComponent,
    UserComponent,
    GruposComponent,
    MyAccountComponent,
    SideMenuComponent,
    SidebarDirective,
    ChannelComponent,
    CustomerComponent,
    SortableColumnComponent,
    SearchItemComponent,
    PreferencesComponent,
    DetailChannelComponent,
    ChangePasswordComponent,
    IndicatorsComponent,
    TopTitleComponent,
    TopNewIndicatorComponent,
    ConfigKpiComponent,
    FiltersKpiComponent,
    GraphComponent,
    TableInfoComponent,
    LogKpisComponent,
    PaymentsControlComponent,
    TablePaymentsComponent,
    TitlePaymentComponent,
    OrganizationComponent,
    ProjectComponent,
    YearComponent,
    MonthComponent,
    ChannelPaymentComponent,
    FiltersComponent,
    AnnuityComponent,
    ModuleComponent,
    ColumnCompanyComponent,
    ColumnStatusComponent,
    MytabComponent,
    GeneralComponent,
    AplicationComponent,
    FoliosComponent,
    FiltersUserComponent,
    FiltersCustomerComponent,
    BackToTopComponent,
    GeneralCompanyComponent,
    LogoCompanyComponent,
    QrCompanyComponent,
    PacsimpleCompanyComponent,
    SatCompanyComponent,
    SmtpCompanyComponent,
    CostCenterCompanyComponent,
    ProjectCompanyComponent,
    BranchOfficesCompanyComponent,
    FiltersCompanyComponent,
    MyTabCompanyComponent,
    FiltersChannelComponent,
    MyTabUserComponent,
    GeneralUserComponent,
    ProfilesUserComponent,
    CompaniesUserComponent,
    SortableTableDirective,
    MytabGroupsComponent,
    GeneralGroupsComponent,
    UsersGroupsComponent,
    GroupsUserComponent,
    CustomerMultiselectComponent,
    PaginationGlobalComponent,
    AccessComponent,
    DetailAccessComponent,
    ApplicationMultiselectComponent,
    TagMultiselectComponent,
    MenuComponent,
    DetailMenuComponent,
    MytabMenusComponent,
    LanguagesMenuComponent,
    MenuMultiselectComponent,
    ProfileComponent,
    DetailProfileComponent,
    MytabProfilesComponent,
    AccessProfilesComponent,
    MenusProfilesComponent,
  ],
  providers: [
    SortService,
    ExcelService
  ],
  exports: [
    SortableColumnComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AccountRoutingModule,
    ShowHidePasswordModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    NgbModule,
    FormsModule,
    ToastrModule.forRoot(),
    ChartsModule,
    FileUploadModule,
    NgMultiSelectDropDownModule.forRoot(),
    DragulaModule.forRoot(),
    NgBootstrapFormValidationModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AccountModule {
}
