<div class="row ">
    <div class="col-auto position-absolute pos mt-2">
        <button (click)="return()" class="btnBackTop">
            <i class="fas fa-arrow-left"></i>
        </button>
    </div>
</div>

<div class="row align-items-end">
    <div class="col-auto position-absolute end-0 mt-1 tpa">
        <button *ngIf="isDetail" (click)="editChannel()" class="btnEd">
            <i class="fas fa-edit"></i>
            {{ "GENERAL.edit" | translate }}
        </button>
        <ng-container *ngIf="newEdit != 'N'">
            <button *ngIf="isEdit" (click)="cancelEdit()" class="btnEd">
                <i class="fas fa-times"></i>
                Cancelar
            </button>
        </ng-container>
    </div>
</div>

<form [formGroup]="form" ngNativeValidate (ngSubmit)="saveChangesForm()">
    <ng-container *ngIf="isdetailsChannel">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 ">
                <div class="card cadBTNHead">
                    <div class="card-body pb-2 ps-2 pe-3 pt-1">

                        <div class="row justify-content-between align-items-center">
                            <div class="col-auto">
                                <div class="row">
                                    <ng-container *ngIf="editChannelSelected.Url_Logo">
                                        <div class="col-sm-auto col-12 text-center ps-5 mt-4 posiImg">
                                            <img class="w-Src" src="{{editChannelSelected.Url_Logo}}" alt=""><br>
                                            <a target="_blank" data-bs-toggle="offcanvas"
                                                data-bs-target="#offcanvasRightC" aria-controls="offcanvasRight" (click)="saveImg()">
                                                <u class="sp-2" > Cambiar Logo</u></a>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!editChannelSelected.Url_Logo">
                                        <div class="col-sm-auto col-12 text-center ps-5 mt-4 posiImg">
                                            <img class="w-Src" src="../../../assets/images/starbucks-logo.png" alt="">
                                            <br>
                                            <a href="javascript:void(0)" target="_blank" data-bs-toggle="offcanvas"
                                                data-bs-target="#offcanvasRightC" aria-controls="offcanvasRight" (click)="saveImg()">
                                                <u> Subir Logo </u></a>
                                        </div>
                                        <div class="col-auto ps-5 mt-3 mt-sm-5 margin1">
                                            <span class=" textP contText">Nombre del Canal:</span>
                                            <p *ngIf="isDetail" class=" nameChannel  mb-0">
                                                <span>{{editChannelSelected.Name}}</span></p>
                                            <p *ngIf="isEdit"><input formControlName="ChannelName"
                                                    (change)="changeName($event.target.value)" required name="channel"
                                                    class="form-control" type="text"
                                                    placeholder="Ingresa el Nombre de Canal" id="channel"
                                                    value="{{editChannelSelected.Name}}" /></p>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-sm-auto px-0 text-center position-absolute contDate">
                                <div class="row margFech w-100">
                                    <div class="col- text-center px-0">
                                        <p class="mb-0 textP">{{ "GENERAL.dischargeDate" | translate }}
                                        </p>
                                        <p class="mb-0">{{editChannelSelected.Date_Add | date:'dd/MM/yyyy'}}</p>
                                    </div>
                                </div>
                                <div class="row margFech w-100">
                                    <div class="col- text-center">
                                        <p class="mb-0 textP">{{ "GENERAL.activate" | translate }}/{{
                                            "GENERAL.desactivate" | translate }}
                                        </p>
                                        <label class="switch hideShow" [ngClass]="{'fade': !isEdit}">
                                            <input #statusInt [checked]="editChannelSelected.Status =='A' ? true:false"
                                                (change)="changeStatusChannelInt(statusInt.checked, editChannelSelected.Id_Channel)"
                                                name="statusInt" type="checkbox" id="statusInt" [disabled]="!isEdit" />
                                            <span class="slider round-in"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Datos Canal -->

                        <ng-container>
                            <div class="row justify-content-left ps-3 mt-4 dataChannel">
                                <div class="col-auto">
                                    <span class=" pl-3  textP">Contacto General</span>
                                    <p *ngIf="isDetail" class="pl-4 ml-3">{{editChannelSelected.Contact_Person}}</p>
                                    <p *ngIf="isEdit"><input formControlName="ContactPerson"
                                            (change)="changeContact_Person($event.target.value)" required
                                            name="nameContact" class="form-control" type="text"
                                            placeholder="Ingresa el Nombre de Contacto" id="nameContact" /></p>
                                </div>

                                <div class="col-auto">
                                    <span class=" pl-3  textP"> {{ "GENERAL.email" | translate }}</span>
                                    <p *ngIf="isDetail" class="pl-4 ml-3">{{editChannelSelected.Email}}</p>
                                    <p *ngIf="isEdit"><input formControlName="Email"
                                            (change)="changeEmail($event.target.value)" required name="email"
                                            class="form-control" type="text" placeholder="Ingresa el Email"
                                            id="email" /></p>
                                </div>

                                <div class="col-auto">
                                    <span class=" pl-3  textP">{{
                                        "GENERAL.phone" | translate }}</span>
                                    <p *ngIf="isDetail" class="pl-4 ml-3">{{editChannelSelected.Phone}}</p>
                                    <p *ngIf="isEdit"><input formControlName="Phone"
                                            (change)="changePhone($event.target.value)" required name="phone"
                                            class="form-control" type="text" placeholder="Ingresa el Celular"
                                            id="phone" /></p>
                                </div>

                                <div class="col-auto">
                                    <span class=" pl-3  textP">{{
                                        "GENERAL.businessname" | translate }}</span>
                                    <p *ngIf="isDetail" class="pl-4 ml-3">{{editChannelSelected.Business_Name}}</p>
                                    <p *ngIf="isEdit"><input formControlName="BusinessName"
                                            (change)="changeBusiness_Name($event.target.value)" required
                                            name="businessname" class="form-control" type="text"
                                            placeholder="Ingresa la Razon Social" id="businessname" /></p>
                                </div>

                                <div class="col-auto">
                                    <span class=" pl-3  textP">{{
                                        "GENERAL.rfc" | translate }}</span>
                                    <p *ngIf="isDetail" class="pl-4 ml-3">{{editChannelSelected.RFC}}</p>
                                    <p *ngIf="isEdit"><input formControlName="RFC" required name="rfc"
                                            (change)="changeRFC($event.target.value)" class="form-control" type="text"
                                            placeholder="Ingresa RFC" id="rfc" /></p>
                                </div>

                                <div class="col-auto">
                                    <span class=" pl-3  textP"> {{ "GENERAL.country" | translate
                                        }}</span>
                                    <p *ngIf="isDetail" class="pl-4 ml-3">{{editChannelSelected.CountryName}}</p>
                                    <p *ngIf="isEdit">
                                        <select (change)="changeCountry($event.target.value)"
                                            class="custom-select col-12" id="country" name="country">
                                            <option value="0" selected="">{{ editChannelSelected.CountryName}}
                                                Selecciona el País</option>
                                            <option *ngFor="let country of listCountry" value="{{country.Id_Country}}"
                                                [selected]="editChannelSelected.Id_Country == country.Id_Country">
                                                {{country.Description}}</option>
                                        </select>
                                    </p>
                                </div>

                                <div class="col-auto">
                                    <span class=" pl-3  textP">{{ "GENERAL.state" | translate }}</span>
                                    <p *ngIf="isDetail" class="pl-4 ml-3">{{editChannelSelected.StateName}}</p>
                                    <p *ngIf="isEdit">
                                        <select (change)="changeState($event.target.value)" class="custom-select col-12"
                                            id="state" name="state">
                                            <option value="0" selected="">{{ editChannelSelected.StateName }} Selecciona
                                                el Estado</option>
                                            <option *ngFor="let state of listState" value="{{state.Id_State}}"
                                                [selected]="editChannelSelected.Id_State == state.Id_State">
                                                {{state.Description}}</option>
                                        </select>
                                    </p>
                                </div>
                            </div>
                        </ng-container>

                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <!--Cards con info-->
    <ng-container>


        <div
            class="row px-2 justify-content-between justify-content-sm-start justify-content-md-between mt-0 dataChannel pb-5 justifyP">
            <ng-container *ngIf="isDetail">
                <div *ngFor="let cont of editChannelSelected.Contacts" class="col-auto px-1 mt-1">
                    <div class="card cadBTNInfo mt-2">
                        <p class="mt-2"><span class="font-weight-bold textP h6 ps-3">{{cont.Name_Position}}</span></p>
                        <p class="m-0 textP"><span class="textP ps-3">Nombre:</span> </p>
                        <p class="pl-5 m-0"> {{cont.Name}}</p>

                        <p class="m-0 textP"><span class="textP ps-3">E-mail:</span> </p>
                        <p class="pl-5  m-0">{{cont.Email}}</p>

                        <p class="m-0 textP"><span class="textP ps-3">Cel:</span></p>
                        <p *ngIf="cont.Cellular != ''" class="pl-5 m-0">{{cont.Cellular}}</p>

                        <p class="m-0 textP"><span class=" textP ps-3">Tel:</span></p>
                        <p *ngIf="cont.Phone != ''" class="pl-5 m-0">{{cont.Phone}}</p>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="isEdit">
                <div *ngFor="let cont of editChannelSelected.Contacts" class="col-auto px-1 mt-1">
                    <div class="card cadBTNInfo mt-2">
                        <p class="mt-2"><span class="font-weight-bold textP h6 ps-3">{{cont.Name_Position}}</span></p>
                        <p class="m-0 textP"><span class="textP ps-3">Nombre:</span></p>
                        <p class="pl-3 inSi"> <input [(ngModel)]="cont.Name" [ngModelOptions]="{standalone: true}"
                                name="name" class="form-control" type="text"
                                placeholder="{{ 'GENERAL.name' | translate }}" id="name" />
                        </p>
                        <p class="m-0 textP"><span class="textP ps-3">E-mail:</span> </p>
                        <p class="pl-3 inSi"> <input [(ngModel)]="cont.Email" [ngModelOptions]="{standalone: true}"
                                name="email" class="form-control" type="text"
                                placeholder="{{ 'GENERAL.email' | translate }}" id="email" />
                        </p>
                        <p class="m-0 textP"><span class="textP ps-3">Cel:</span></p>
                        <p class="pl-3 inSi"> <input [(ngModel)]="cont.Cellular" [ngModelOptions]="{standalone: true}"
                                name="cellphone" class="form-control" type="text"
                                placeholder="{{ 'GENERAL.cellphone' | translate }}" id="cellphone" />
                        </p>
                        <p class="m-0 textP"><span class=" textP ps-3">Tel:</span></p>
                        <p class="pl-3 inSi"> <input [(ngModel)]="cont.Phone" [ngModelOptions]="{standalone: true}"
                                name="phone" class="form-control" type="text"
                                placeholder="{{ 'GENERAL.phone' | translate }}" id="phone" />
                        </p>
                    </div>
                </div>
            </ng-container>

            <div class="col-auto mt-1 ps-1 pe-2 px-1">
                <div class="card cadBTNInfo mt-2">
                    <div class="col">
                        <p class="mt-2"> <span class="font-weight-bold textP h6">Ejecutivo</span></p>
                        <p class="m-0 textP"><span class="textP ">Vendedor:</span> </p>
                        <p *ngIf="isDetail" class="pl-5 ">{{editChannelSelected.Seller}}</p>
                        <p *ngIf="isEdit">
                            <select (change)="changeSeller($event.target.value)" class="custom-select col-12" id="boss"
                                name="boss">
                                <option *ngIf="!editChannelSelected.Seller" value="0" selected="">{{
                                    "GENERAL.selectuser" | translate }}...</option>
                                <option *ngIf="editChannelSelected.Seller != ''" value="0" selected="">{{
                                    editChannelSelected.Seller }}</option>
                                <option *ngFor="let user of List_Users_Ximplify" value="{{user.Id_User}}"
                                    [selected]="editChannelSelected.Id_User_Ximplify == user.Id_User_Ximplify">
                                    {{user.Name}}</option>
                            </select>
                        </p>
                        <hr>
                    </div>

                    <div class="col-auto ">
                        <p><span class=" textP">Contrato</span>
                            <button *ngIf="editChannelSelected.Url_Contract != ''" class="changeDoc"
                                data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightC"
                                aria-controls="offcanvasRight" (click)="saveDoc()">Cambiar Contrato</button>
                        </p>

                        <p><i class="fa fa-file-pdf-o" style="font-size: 50px; padding-left: 15px;"
                                aria-hidden="true"></i>
                            <u>
                                <a class="ps-2 textP" *ngIf="editChannelSelected.Url_Contract != ''"
                                    href="{{editChannelSelected.Url_Contract}}" target="_blank" >Ver Contrato </a>

                                <a class="ps-2 textP" *ngIf="editChannelSelected.Url_Contract == ''"
                                    href="javascript:void(0)" target="_blank" data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasRightC" aria-controls="offcanvasRight" (click)="saveDoc()">Subir
                                    Contrato</a></u>
                        </p>
                    </div>
                </div>
            </div>
            <ng-container>

                <div class="row justify-content-between ps-3 mt-4">
                    <div class="col-auto">
                        <div *ngIf="isEdit && form.invalid" class="alert alert-warning" role="alert">
                            <label> *{{getErrorMessage(2)}}</label>
                        </div>
                    </div>
                    <div *ngIf="isEdit" class="col-auto ml-3 ml-ms-3 pl-3">
                       <button (click)="cancelChannel()" class="mt-3 mb-2 btnCancel" style="width: 140px;">
                            {{ "GENERAL.cancel" | translate }}</button> 
                        <button class="mt-3 ml-sm-2 mb-2 btnSave" type="submit" style="width: 140px;">
                            {{ "GENERAL.save" | translate }}
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</form>
<!--Termina Cards con info-->

<!--Canvas-->
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRightC" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <button type="button" class="btnArrow" data-bs-dismiss="offcanvas">
            <span><i class="fa fa-arrow-left"></i></span>
        </button>
        <h5 *ngIf="this.canva === 1" class="ps-4 mt-1" id="offcanvasRightLabel">Arrastra tu Imagen</h5>
        <h5 *ngIf="this.canva === 2" class="ps-4 mt-1" id="offcanvasRightLabel">Arrastra tu Documento</h5>
    </div>
    <div class="offcanvas-body">
        <div class="col-auto px-0">
            <div class="row gx-0 mt-3 px-3">
                <div class="col- d-flex justify-content-center">
                    <ng-container *ngIf="this.canva === 1">
                        <div class="csvPolitic form-group">
                            <div class="form-group dragAndDrop text-center" style="width: 100%">
                                <input #imageInputUnic (change)="saveChanges($event)" name="fielName" id="fielName"
                                    type="file" ng2FileSelect [ngModel]="imagetoUpload"
                                    class="custom-file-input form-control-file text-primary font-weight-bold"
                                    accept="image/png, image/gif, image/jpeg">
                                <span id="span-click" class="custom-file-control span-click" *ngIf="!fileNameChangeLo">
                                    <div class="row justify-content-center">
                                        <div class="col-12">
                                            <p class="h4">
                                                <i class="fa fa-upload" aria-hidden="true"></i>
                                            </p>
                                        </div>
                                    </div>

                                    <div class="row justify-content-center">
                                        <div class="col-">
                                            <p>Arrastra o da click para subir tu archivo</p>
                                        </div>
                                    </div>

                                </span>
                                <span id="span-click" class="custom-file-control span-click" *ngIf="fileNameChangeLo">

                                    <div class="row justify-content-center">
                                        <div class="col-12">
                                            <p class="h4 archivoSubido">
                                                <i class="far fa-check-circle"></i>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-">
                                            <p>{{ fileNameChangeLo }}</p>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="this.canva === 2">
                        <div class="csvPolitic form-group">
                            <div class="form-group dragAndDrop text-center" style="width: 100%">
                                <input #pdfInputUnic (change)="saveChanges($event)" name="fielName" id="fielName"
                                    type="file" ng2FileSelect [ngModel]="imagetoUpload"
                                    class="custom-file-input form-control-file text-primary font-weight-bold"
                                    accept=".pdf">
                                <span id="span-click" class="custom-file-control span-click" *ngIf="!fileNameChangeLo">
                                    <div class="row justify-content-center">
                                        <div class="col-12">
                                            <p class="h4">
                                                <i class="fa fa-upload" aria-hidden="true"></i>
                                            </p>
                                        </div>
                                    </div>

                                    <div class="row justify-content-center">
                                        <div class="col-">
                                            <p>Arrastra o da click para subir tu archivo</p>
                                        </div>
                                    </div>

                                </span>
                                <span id="span-click" class="custom-file-control span-click" *ngIf="fileNameChangeLo">

                                    <div class="row justify-content-center">
                                        <div class="col-12">
                                            <p class="h4 archivoSubido">
                                                <i class="far fa-check-circle"></i>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-">
                                            <p>{{ fileNameChangeLo }}</p>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
<!--termina canvas-->