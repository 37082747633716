import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import { GlobalService } from 'src/app/global/global.service';



@Component({
  selector: 'app-code-recovery-pass',
  templateUrl: './code-recovery-pass.component.html',
  styleUrls: ['./code-recovery-pass.component.css']
})
export class CodeRecoveryPassComponent implements OnInit {

  @Output() backLogin = new EventEmitter();
  @Output() newPass = new EventEmitter();

  codePassRecovery: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';



  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private allService: ServicesService,
    private globalService: GlobalService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.codePassRecovery = this.formBuilder.group({
      code: ['', Validators.required],
    });
  }

  get f() { return this.codePassRecovery.controls; }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.codePassRecovery.invalid) {
      return;
    }

    this.loading = true;
    this.Password_Reset_Code();

    this.loading = false;

  }

  backToLogin() {
    this.backLogin.emit();
    localStorage.removeItem('email');
  }

  Password_Reset_Code() {
    this.allService.Password_Reset_Code(this.codePassRecovery.value.code).subscribe(resp => {
      if (this.globalService.verifyResponse(resp)) {
        if (resp.result === false){
          this.toastr.warning('Código invalido, intentalo nuevamente!', 'Alerta');
        } else {
          this.toastr.success('Código valido, haz cambio de tu contraseña!', 'Éxito');
          this.newPass.emit();
          localStorage.setItem('Code', this.codePassRecovery.value.code);
        }
      }
    });
  }

}
