<div class="container-fluid backOops">
    <div class="row">
        <div class="col-12">
            <svg class="enlivenem" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 1080" style="enable-background:new 0 0 1920 1080;" xml:space="preserve" data-global-elvn="enableViewport, disableClick, none, startVisible, responsive, 0, notLoop, 500">
                <style type="text/css">
                                .st0{fill:#E7E7E7;}
                                .st1{fill:#FFFFFF;}
                                .st2{fill:#C9C8C7;}
                                .st3{fill:#6D6D6C;}
                                .st4{fill:#78689C;}
                                .st5{fill:#5A2D7D;}
                                .st6{fill:#C2B7D3;}
                                .st7{fill:#463480;}
                                .st8{fill:#5F5F5F;}
                                .st9{fill:#474747;}
                                .st10{fill:#FFD150;}
                                .st11{fill:#414042;}
                                .st12{fill:#474647;}
                                .st13{fill:#515151;}
                                .st14{fill:#686868;}
                                .st15{fill:#565656;}
                                .st16{clip-path:url(#SVGID_2_);}
                                .st17{fill:#73E54F;}
                                .st18{fill:#6DD845;}
                                .st19{fill:#7DED54;}
                                .st20{fill:#4C4E4B;}
                                .st21{fill:#5FC932;}
                                .st22{fill:#ED3E77;}
                                .st23{fill:#D13769;}
                                .st24{fill:#F96794;}
                                .st25{fill:#6CD844;}
                                .st26{fill:#FFDF78;}
                                .st27{fill:#FEFBF2;}
                                .st28{fill:#FF6B03;}
                                .st29{fill:#999999;}
                </style>
                <g id="switchGris" data-elvn="fadeLongL, in, 250, 450, backout" class="elvn-layer" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                               <path class="st0" d="M218.55,432.29c-5.04,8.98-7.94,19.43-7.94,30.58c0,33.32,25.79,60.42,57.49,60.42
                                                               c2.19,0,4.36-0.14,6.49-0.39c2.57-0.01,5.1-0.23,7.58-0.63h101.35c31.49,0,57.02-26.83,57.02-59.93l0,0
                                                               c0-33.1-25.53-59.93-57.02-59.93H281.54c-2.37-0.35-4.79-0.55-7.24-0.55c-2.41,0-4.78,0.19-7.1,0.53
                                                               c-20.06,0.33-37.66,11.5-47.7,28.19c-0.32,0.53-0.63,1.07-0.94,1.61"></path>
                                               <path class="st1" d="M408.83,493.53H246.66c-2.34,0-4.26-1.92-4.26-4.26v-53.01c0-2.34,1.92-4.26,4.26-4.26h162.17
                                                               c2.34,0,4.26,1.92,4.26,4.26v53.01C413.09,491.61,411.17,493.53,408.83,493.53z"></path>
                                               <g>
                                                               <g>
                                                                               <g>
                                                                                               <g>
                                                                                                              <path class="st2" d="M272.67,411.83c28.24,0,51.21,22.97,51.21,51.21c0,28.24-22.97,51.21-51.21,51.21
                                                                                                                              c-28.24,0-51.21-22.97-51.21-51.21C221.46,434.8,244.44,411.83,272.67,411.83z"></path>
                                                                                               </g>
                                                                                               <g>
                                                                                                              <path class="st3" d="M272.47,411.83c28.24,0,51.21,22.97,51.21,51.21c0,28.24-22.97,51.21-51.21,51.21
                                                                                                                              c-28.24,0-51.21-22.97-51.21-51.21C221.26,434.8,244.23,411.83,272.47,411.83z"></path>
                                                                                               </g>
                                                                               </g>
                                                               </g>
                                               </g>
                                               
                                                               <ellipse transform="matrix(0.9744 -0.2246 0.2246 0.9744 -97.0512 73.065)" class="st1" cx="272.57" cy="463.04" rx="30.28" ry="30.28"></ellipse>
                                               <path class="st0" d="M247.62,463.04c0-13.78,11.17-24.95,24.95-24.95c13.78,0,24.95,11.17,24.95,24.95
                                                               c0,13.78-11.17,24.95-24.95,24.95C258.79,487.99,247.62,476.82,247.62,463.04z"></path>
                                               <g>
                                                               <path class="st3" d="M270.71,398.59h109.95c35.74,0,64.82,29.08,64.82,64.82c0,35.74-29.08,64.82-64.82,64.82H270.71
                                                                               c-35.45,0-64.29-28.84-64.29-64.29v-1.07C206.42,427.43,235.26,398.59,270.71,398.59z M380.66,521.88
                                                                               c32.24,0,58.46-26.23,58.46-58.46s-26.23-58.46-58.46-58.46H270.71c-31.94,0-57.93,25.99-57.93,57.93v1.07
                                                                               c0,31.94,25.99,57.93,57.93,57.93H380.66z"></path>
                                               </g>
                                </g>
                </g>
                <g id="switchMorado" data-elvn="fadeLongR, in, 250, 550, backout" class="elvn-layer" xmlns="http://www.w3.org/2000/svg">
                                <path class="st4" d="M477.36,548.97c5.04-8.98,7.94-19.43,7.94-30.58c0-33.32-25.79-60.42-57.49-60.42c-2.19,0-4.36,0.14-6.49,0.39
                                               c-2.57,0.01-5.1,0.23-7.58,0.63H312.39c-31.49,0-57.02,26.83-57.02,59.93l0,0c0,33.1,25.53,59.93,57.02,59.93h101.97
                                               c2.37,0.35,4.79,0.55,7.24,0.55c2.41,0,4.78-0.19,7.1-0.53c20.06-0.33,37.66-11.5,47.7-28.19c0.32-0.53,0.63-1.07,0.94-1.61"></path>
                                <g>
                                               <path class="st5" d="M425.2,583.24H315.24c-35.74,0-64.82-29.08-64.82-64.82c0-35.74,29.08-64.82,64.82-64.82H425.2
                                                               c35.45,0,64.29,28.84,64.29,64.29v1.07C489.48,554.4,460.64,583.24,425.2,583.24z M315.24,459.95
                                                               c-32.24,0-58.46,26.23-58.46,58.46s26.23,58.46,58.46,58.46H425.2c31.94,0,57.93-25.99,57.93-57.93v-1.07
                                                               c0-31.94-25.99-57.93-57.93-57.93H315.24z"></path>
                                </g>
                                <path class="st6" d="M449.13,549.46H284.64c-2.34,0-4.26-1.92-4.26-4.26v-53.01c0-2.34,1.92-4.26,4.26-4.26h164.49
                                               c2.34,0,4.26,1.92,4.26,4.26v53.01C453.39,547.54,451.47,549.46,449.13,549.46z"></path>
                                <g>
                                               <g>
                                                               <g>
                                                                               <g>
                                                                                               <path class="st7" d="M423.23,570c-28.24,0-51.21-22.97-51.21-51.21c0-28.24,22.97-51.21,51.21-51.21
                                                                                                               c28.24,0,51.21,22.97,51.21,51.21C474.44,547.03,451.47,570,423.23,570z"></path>
                                                                               </g>
                                                                               <g>
                                                                                               <path class="st5" d="M423.44,570c-28.24,0-51.21-22.97-51.21-51.21c0-28.24,22.97-51.21,51.21-51.21
                                                                                                               c28.24,0,51.21,22.97,51.21,51.21C474.65,547.03,451.67,570,423.44,570z"></path>
                                                                               </g>
                                                               </g>
                                               </g>
                                </g>
                                
                                               <ellipse transform="matrix(0.9213 -0.3889 0.3889 0.9213 -168.4498 205.506)" class="st1" cx="423.33" cy="518.79" rx="30.28" ry="30.28"></ellipse>
                                <path class="st5" d="M448.29,518.79c0,13.78-11.17,24.95-24.95,24.95c-13.78,0-24.95-11.17-24.95-24.95
                                               c0-13.78,11.17-24.95,24.95-24.95C437.11,493.84,448.29,505.01,448.29,518.79z"></path>
                </g>
                <g id="textXimplify" data-elvn="fadeShortB, in, 700, 750, linear" class="elvn-layer" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                               <path class="st1" d="M256.05,620.97c0,2.4,1.95,4.35,4.35,4.35c2.4,0,4.35-1.95,4.35-4.35c0-2.4-1.95-4.35-4.35-4.35
                                                               C258,616.63,256.05,618.57,256.05,620.97z"></path>
                                               <path class="st1" d="M412.55,620.97c0,2.4,1.95,4.35,4.35,4.35c2.4,0,4.35-1.95,4.35-4.35c0-2.4-1.95-4.35-4.35-4.35
                                                               C414.49,616.63,412.55,618.57,412.55,620.97z"></path>
                                               <g>
                                                               <path class="st1" d="M253.42,635.53l-17.45,24.56l17.45,24.56h-4.47l-15.22-21.4l-15.22,21.4h-4.47l17.45-24.56l-17.45-24.56
                                                                               h4.47l15.22,21.44l15.22-21.44H253.42z"></path>
                                                               <path class="st1" d="M262.19,684.66h-3.61v-49.3h3.61V684.66z"></path>
                                                               <path class="st1" d="M340.28,684.66h-3.44v-31.57c-0.07-4.09-1.58-7.59-4.53-10.48c-2.99-2.92-6.53-4.36-10.68-4.36
                                                                               c-4.16,0-7.69,1.44-10.68,4.36c-2.95,2.89-4.47,6.39-4.53,10.48v31.57h-3.47v-31.57c-0.07-4.09-1.58-7.59-4.53-10.48
                                                                               c-2.95-2.92-6.53-4.36-10.68-4.36c-4.16,0-7.69,1.44-10.65,4.36c-2.99,2.89-4.5,6.39-4.53,10.48v31.57h-3.47v-31.57
                                                                               c0.07-5.05,1.92-9.38,5.56-12.92c3.64-3.54,8-5.32,13.09-5.32c3.71,0,7.11,1,10.2,2.99c2.99,1.96,5.26,4.54,6.77,7.76
                                                                               c1.48-3.23,3.74-5.8,6.77-7.76c3.09-1.99,6.49-2.99,10.17-2.99c5.12,0,9.52,1.79,13.16,5.32c3.64,3.61,5.46,7.93,5.5,13.02
                                                                               V684.66z"></path>
                                                               <path class="st1" d="M350.78,701.76h-3.57v-41.81c-0.03-6.97,2.44-12.95,7.39-17.9c4.95-4.95,10.92-7.42,17.9-7.42
                                                                               c6.97,0,12.95,2.47,17.9,7.42c4.95,4.95,7.42,10.92,7.45,17.9c0,6.97-2.47,12.95-7.42,17.9c-4.95,4.95-10.92,7.42-17.9,7.42
                                                                               c-6.97,0-12.95-2.47-17.9-7.42c-1.51-1.51-2.78-3.13-3.85-4.84V701.76z M394.27,659.96c0-6.01-2.13-11.13-6.36-15.39
                                                                               c-4.26-4.23-9.38-6.36-15.39-6.36c-6.01,0-11.13,2.13-15.39,6.36c-4.22,4.26-6.35,9.38-6.35,15.39c0,6.01,2.13,11.13,6.35,15.39
                                                                               c4.26,4.23,9.38,6.35,15.39,6.35c6.01,0,11.13-2.13,15.39-6.35C392.14,671.09,394.27,665.97,394.27,659.96z"></path>
                                                               <path class="st1" d="M407.13,684.66h-3.61v-59.81h3.61V684.66z"></path>
                                                               <path class="st1" d="M417.62,684.66h-3.61v-49.3h3.61V684.66z"></path>
                                                               <path class="st1" d="M428.47,638.97c-0.24,1.2-0.34,2.47-0.38,3.78l0.07,41.94l-3.68-0.03v-41.91
                                                                               c0.07-6.39,2.13-11.85,6.18-16.42c4.05-4.54,8.9-6.8,14.53-6.8h0.24v3.68h-0.24c-4.6,0-8.59,1.99-11.99,5.98
                                                                               c-1.65,1.89-2.89,3.95-3.74,6.18h15.97v3.61H428.47z"></path>
                                                               <path class="st1" d="M492,635.33v46.07c-0.07,6.39-2.13,11.85-6.18,16.42c-4.05,4.54-8.9,6.8-14.53,6.8
                                                                               c-4.64,0-8.79-1.61-12.47-4.81c-3.74-3.19-6.22-7.32-7.42-12.4l-0.14-0.38H455l0.1,0.24c1.17,3.98,3.23,7.21,6.18,9.65
                                                                               c2.99,2.68,6.32,4.02,10,4.02c4.6,0,8.59-1.99,11.99-5.98c3.33-3.75,5.01-8.28,5.12-13.57l-0.07-5.6v-1.44
                                                                               c-1.82,3.09-4.22,5.57-7.21,7.46c-3.06,1.86-6.32,2.78-9.79,2.78c-5.67,0-10.55-2.27-14.6-6.84c-4.02-4.57-6.08-10.03-6.11-16.45
                                                                               v-25.97h3.61l-0.03,25.94c-0.1,4.57,1.51,9.17,4.84,13.74c3.54,3.92,7.63,5.87,12.3,5.87c4.43,0,8.38-1.75,11.85-5.22
                                                                               c3.33-3.68,5.05-8.04,5.12-13.09l0.03-27.28L492,635.33z"></path>
                                               </g>
                                </g>
                </g>
                <g id="cerca" data-elvn="expandB, in, 2000, 2500, elastic" class="elvn-layer" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                               <g>
                                                               <g>
                                                                               <rect x="902.06" y="277.29" class="st8" width="20.98" height="349.41"></rect>
                                                                               <rect x="912.54" y="277.29" class="st9" width="10.49" height="349.41"></rect>
                                                               </g>
                                                               <g>
                                                                               <rect x="1517.29" y="277.29" class="st8" width="20.98" height="349.41"></rect>
                                                                               <rect x="1527.78" y="277.29" class="st9" width="10.49" height="349.41"></rect>
                                                               </g>
                                                               <path class="st10" d="M1577.28,285.88v115.23c0,4.74-3.84,8.58-8.58,8.58H870.5c-2.59,0-4.9-1.14-6.49-2.97
                                                                               c-1.3-1.51-2.09-3.44-2.09-5.62V285.88c0-4.61,3.6-8.37,8.16-8.55c0.13-0.03,0.29-0.03,0.42-0.03h698.21
                                                                               c1.88,0,3.63,0.61,5.03,1.64c1.85,1.32,3.12,3.39,3.47,5.75C1577.26,285.09,1577.28,285.49,1577.28,285.88z"></path>
                                                               <path class="st11" d="M930.9,277.3l-66.9,129.43c-1.3-1.51-2.09-3.44-2.09-5.62v-54.18l35.99-69.63H930.9z"></path>
                                                               <polygon class="st11" points="1003.14,277.29 934.71,409.71 901.71,409.71 970.14,277.29                                      "></polygon>
                                                               <polygon class="st11" points="1075.38,277.29 1006.94,409.71 973.95,409.71 1042.38,277.29                                "></polygon>
                                                               <polygon class="st11" points="1147.61,277.29 1079.18,409.71 1046.18,409.71 1114.61,277.29                                               "></polygon>
                                                               <polygon class="st11" points="1219.85,277.29 1151.42,409.71 1118.42,409.71 1186.84,277.29                                               "></polygon>
                                                               <polygon class="st11" points="1292.08,277.29 1223.65,409.71 1190.65,409.71 1259.08,277.29                                               "></polygon>
                                                               <polygon class="st11" points="1364.32,277.29 1295.88,409.71 1262.89,409.71 1331.31,277.29                                               "></polygon>
                                                               <polygon class="st11" points="1436.55,277.29 1368.12,409.71 1335.12,409.71 1403.54,277.29                                               "></polygon>
                                                               <polygon class="st11" points="1508.79,277.29 1440.35,409.71 1407.35,409.71 1475.78,277.29                                               "></polygon>
                                                               <path class="st11" d="M1577.2,284.69l-64.62,125h-33l68.43-132.39h20.69c1.88,0,3.63,0.61,5.03,1.64
                                                                               C1575.59,280.27,1576.86,282.34,1577.2,284.69z"></path>
                                                               <path class="st11" d="M1577.28,360.43v40.68c0,4.74-3.84,8.58-8.58,8.58h-16.87L1577.28,360.43z"></path>
                                                               <g>
                                                                               <path class="st10" d="M1577.28,499.49v30.64c0,4.73-3.84,8.58-8.59,8.58H870.5c-4.75,0-8.58-3.85-8.58-8.58v-30.64
                                                                                               c0-4.6,3.6-8.35,8.15-8.56c0.14-0.02,0.28-0.02,0.43-0.02h698.19c1.89,0,3.65,0.61,5.06,1.66c1.32,0.95,2.35,2.28,2.95,3.82
                                                                                               C1577.08,497.34,1577.28,498.38,1577.28,499.49z"></path>
                                                                               <path class="st11" d="M898.47,490.9l-24.7,47.8h-3.26c-4.75,0-8.58-3.85-8.58-8.58v-13.9L875,490.9H898.47z"></path>
                                                                               <polygon class="st11" points="947.12,490.9 922.41,538.71 898.95,538.71 923.65,490.9                                                    "></polygon>
                                                                               <polygon class="st11" points="995.76,490.9 971.05,538.71 947.6,538.71 972.3,490.9                                                  "></polygon>
                                                                               <polygon class="st11" points="1044.41,490.9 1019.7,538.71 996.24,538.71 1020.95,490.9                                                  "></polygon>
                                                                               <polygon class="st11" points="1093.05,490.9 1068.35,538.71 1044.9,538.71 1069.6,490.9                                                    "></polygon>
                                                                               <polygon class="st11" points="1141.7,490.9 1117,538.71 1093.55,538.71 1118.25,490.9                                                  "></polygon>
                                                                               <polygon class="st11" points="1190.36,490.9 1165.65,538.71 1142.2,538.71 1166.9,490.9                                                    "></polygon>
                                                                               <polygon class="st11" points="1239,490.9 1214.3,538.71 1190.84,538.71 1215.55,490.9                                                  "></polygon>
                                                                               <polygon class="st11" points="1287.65,490.9 1262.95,538.71 1239.48,538.71 1264.18,490.9                                                  "></polygon>
                                                                               <polygon class="st11" points="1336.3,490.9 1311.59,538.71 1288.13,538.71 1312.83,490.9                                                  "></polygon>
                                                                               <polygon class="st11" points="1384.94,490.9 1360.23,538.71 1336.77,538.71 1361.48,490.9                                                  "></polygon>
                                                                               <polygon class="st11" points="1433.59,490.9 1408.88,538.71 1385.42,538.71 1410.13,490.9                                                  "></polygon>
                                                                               <polygon class="st11" points="1482.23,490.9 1457.53,538.71 1434.08,538.71 1458.78,490.9                                                  "></polygon>
                                                                               <polygon class="st11" points="1530.88,490.9 1506.18,538.71 1482.72,538.71 1507.43,490.9                                                  "></polygon>
                                                                               <path class="st11" d="M1576.7,496.38l-21.88,42.32h-23.45l24.7-47.8h12.61c1.89,0,3.65,0.61,5.06,1.66
                                                                                               C1575.07,493.52,1576.1,494.84,1576.7,496.38z"></path>
                                                               </g>
                                                               <rect x="884.59" y="615.76" class="st9" width="67.84" height="10.94"></rect>
                                                               <rect x="1499.1" y="615.76" class="st9" width="67.84" height="10.94"></rect>
                                               </g>
                                </g>
                </g>
                <g id="Agujero" data-elvn="pulse, in, 1500, 1250, elastic" class="elvn-layer" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                               <polygon class="st11" points="1430.27,804.5 1334.23,786.39 1355.48,814.73 1212.21,807.24 1231.91,837.94 1025.81,818.48 
                                                               1025.76,818.48 977.66,813.95 985.52,793.48 935.15,788.75 968.99,774.58 872.98,735.22 1026.47,727.36 990.24,705.32 
                                                               1074.47,717.13 1057.94,687.99 1127.2,693.52 1198.05,666.74 1196.49,690.35 1438.91,695.08 1349.18,743.89 1402.71,756.47 
                                                               1361.79,770.64                                "></polygon>
                                               <polygon class="st12" points="1074.47,717.13 990.24,705.32 1026.47,727.36 1025.74,818.48 1074.47,823.08                                "></polygon>
                                               <polygon class="st13" points="1026.47,727.36 1025.81,818.48 1025.76,818.48 977.66,813.95 985.52,793.48 935.15,788.75 
                                                               968.99,774.58 872.98,735.22                    "></polygon>
                                               <polygon class="st14" points="935.46,788.6 968.95,774.58 968.95,791.92                          "></polygon>
                                               <polygon class="st14" points="985.48,793.47 985.48,814.68 977.61,813.94                       "></polygon>
                                               <polygon class="st14" points="1212.17,807.24 1212.17,836.08 1231.85,837.94                                "></polygon>
                                               <polygon class="st14" points="1334.18,786.39 1334.18,813.41 1355.43,814.72                                "></polygon>
                                               <polygon class="st14" points="1362.16,770.69 1362.16,791.66 1430.21,804.49                                "></polygon>
                                               <polygon class="st15" points="1349.13,743.88 1349.13,789.2 1362.16,791.66 1362.16,770.69 1402.66,756.48                                "></polygon>
                                </g>
                </g>
                <g id="personaje" xmlns="http://www.w3.org/2000/svg">
                                <g data-elvn="fadeShortB, in, 4000, 1500, easeinout" class="elvn-layer">
                                               <defs>
                                                               <polygon id="SVGID_1_" points="1069.27,287.4 985.05,275.59 768.21,348.78 874.08,735.23 970.11,774.59 936.26,788.76 
                                                                               986.64,793.48 978.77,813.95 1233.01,837.95 1213.33,807.25 1356.59,814.73 1335.34,786.4 1431.37,804.5 1362.89,770.66 
                                                                               1403.82,756.49 1350.29,743.89 1440.02,695.09 1191.28,260.64 1159.59,281.1 1122.01,263.78 1052.74,258.27                                               "></polygon>
                                               </defs>
                                               <clipPath id="SVGID_2_">
                                                               <use xlink:href="#SVGID_1_" style="overflow:visible;"></use>
                                               </clipPath>
                                               <g class="st16">
                                                               <path class="st17" d="M1385.69,601.69c-0.83,0.22-1.49,1.67-2.32,2.03c-1.28,0.56-2.87,0.31-4.26,0.31
                                                                               c-4.08,0.01-5.47-1.4-5.87-3.58c0-0.1,0-0.19,0.01-0.28l-0.03,0.21c-0.37-2.12,0.17-4.96,0.08-7.95
                                                                               c-0.21-7.46-1.2-14.9-2.89-22.16c-3.79-16.31-13.5-30.42-26.54-40.76c-6.59-5.22-13.9-9.48-21.57-12.9
                                                                               c-2.92-1.3-6.24-1.34-9.04-2.43c-3.04-1.19-2.73-4.3-2.43-7.25c0.33-3.21,4.69-5.69,3.88-8.83c-1.34-5.24-4.84,2.15-5.5,3.85
                                                                               c-0.41,1.06-2.69,9.03-4.48,7.5c-2.54-2.17,3.15-13.77,4.29-15.49c1.78-2.71,7.86-8.18,2.42-9.72
                                                                               c-6.95-1.97-12.92,3.97-13.31,21.07c-1.97-3.26-3.45-12.87-5.71-16.23c-3.14-4.68-6.64-3.51-5.36,1.99
                                                                               c1.05,4.51,4.63,7.71,3.57,12.89c-0.87,4.25-3.63,4.36-6.77,3.34c-3.56-1.15-6.91-1.51-10.82-1.64
                                                                               c-8.43-0.27-16.89,0.21-25.22,1.57c-14.95,2.43-30.04,7.67-41.84,17.43c-5.99,4.95-11.73,10.21-17.14,15.78
                                                                               c-1.87,1.92-3.5,4.74-5.56,6.37c-3.07,2.42-5.83,0.23-8.91-0.57c-1.68-0.43-3.37-0.22-2.94,2.03c0.33,1.71,1.67,1.76,3.01,2.42
                                                                               c-3.8,0.27-5.44-1.52-8.38-3.46c-1.38,1.52-0.2,4.37,1.4,5.35c1.84,1.13,4.31,0.2,6.33,0.68c-1.92,1.3-4.23,0.98-6.44,0.59
                                                                               c1.13,1.66,1.58,3.29,4.04,2.75c1.73-0.38,3.13-2.71,5.03-2.22c0.5,0.13-1.55,2.01-1.67,2.15c-1.09,1.2-2.02,2.7-2.98,4.01
                                                                               c-1.97,2.69-3.87,5.42-5.7,8.21c-3.53,5.39-6.78,10.97-9.72,16.69c-5.62,10.93-10.13,22.4-13.69,34.16
                                                                               c-3.79,12.56-6.5,25.43-8.53,38.38c-7.93,50.54-4.26,99.73,4.84,134.73c17.21,66.18,146.47,66.32,183.08,29.71
                                                                               c36.62-36.62,26.71-69.86,24.63-112c-1.59-32.29,21.29-62.51,25.86-101.59c1.83,3.39,10.52,8.39,3.74,0.36
                                                                               c1.86,1.31,8.82,5.18,9.66,2.58c0.65-2.06-0.25-2.36-3.4-2.71c-1.82-0.2-3.8-1.06-4.48-2.29c3.37,1.25,8.26,0.43,9.47-1.32
                                                                               C1388.77,603.7,1387.64,601.18,1385.69,601.69z"></path>
                                                               <g>
                                                                               <g>
                                                                                               <path class="st18" d="M1259.13,608.79c-6.07,15.93-26.65,22.86-45.97,15.49c-19.32-7.37-30.07-26.23-24-42.16
                                                                                                              c0.46-1.22,1.01-2.39,1.64-3.5c7.57-13.4,26.49-18.79,44.33-11.99c17.84,6.8,28.37,23.42,25.11,38.45
                                                                                                              C1259.96,606.34,1259.6,607.58,1259.13,608.79z"></path>
                                                                                               <path class="st1" d="M1259.74,607.18c-5.11,13.41-24.91,18.29-44.23,10.93c-19.32-7.36-30.85-24.19-25.74-37.6
                                                                                                              c0.39-1.02,0.87-2,1.42-2.93c6.71-11.15,24.97-14.8,42.81-8c17.84,6.8,29.03,21.67,26.63,34.46
                                                                                                              C1260.43,605.11,1260.14,606.16,1259.74,607.18z"></path>
                                                                                               <path class="st19" d="M1260.63,604.04l-69.43-26.46c6.71-11.15,24.97-14.8,42.81-8
                                                                                                              C1251.85,576.38,1263.04,591.26,1260.63,604.04z"></path>
                                                                                               <path class="st20" d="M1219.87,593.92c-2.57,6.75-10.12,10.13-16.86,7.56c-6.75-2.57-10.13-10.12-7.56-16.86
                                                                                                              c0.64-1.67,1.59-3.15,2.75-4.36l22.53,8.58C1220.78,590.52,1220.51,592.25,1219.87,593.92z"></path>
                                                                               </g>
                                                                               <g>
                                                                                               <path class="st18" d="M1342.23,640.47c-6.07,15.92-26.64,22.86-45.96,15.49c-19.33-7.36-30.07-26.23-24-42.15
                                                                                                              c0.47-1.22,1.01-2.39,1.65-3.5c7.56-13.39,26.48-18.79,44.32-11.99c17.84,6.8,28.36,23.41,25.1,38.45
                                                                                                              C1343.07,638.01,1342.7,639.25,1342.23,640.47z"></path>
                                                                                               <path class="st1" d="M1342.85,638.86c-5.11,13.41-24.91,18.29-44.23,10.93c-19.32-7.36-30.84-24.19-25.73-37.6
                                                                                                              c0.39-1.02,0.87-2.01,1.43-2.93c6.71-11.15,24.96-14.8,42.8-8c17.84,6.8,29.04,21.67,26.63,34.46
                                                                                                              C1343.53,636.78,1343.23,637.83,1342.85,638.86z"></path>
                                                                                               <path class="st19" d="M1343.74,635.72l-69.43-26.46c6.71-11.15,24.96-14.8,42.8-8
                                                                                                              C1334.95,608.06,1346.15,622.93,1343.74,635.72z"></path>
                                                                                               <path class="st20" d="M1302.98,625.6c-2.57,6.75-10.11,10.13-16.85,7.56c-6.75-2.57-10.13-10.12-7.56-16.86
                                                                                                              c0.64-1.67,1.59-3.15,2.74-4.37l22.52,8.58C1303.88,622.2,1303.61,623.92,1302.98,625.6z"></path>
                                                                               </g>
                                                               </g>
                                                               <g>
                                                                               <g>
                                                                                               <path class="st21" d="M1168.4,679.49c5.89-3.03,11.83-5.37,17.93-7.55c6.08-2.14,12.27-3.96,18.52-5.49
                                                                                                              c12.5-3.08,25.26-5,38.08-5.7c12.82-0.74,25.69-0.17,38.38,1.5c12.68,1.79,25.19,4.73,37.3,8.81
                                                                                                              c-12.21-3.76-24.76-6.37-37.42-7.81c-12.67-1.33-25.45-1.57-38.12-0.5c-12.67,1.02-25.23,3.25-37.46,6.6
                                                                                                              c-6.11,1.68-12.15,3.63-18.07,5.88c-5.88,2.27-11.78,4.79-17.18,7.75l-0.08,0.04c-0.97,0.53-2.18,0.18-2.72-0.79
                                                                                                              s-0.18-2.18,0.79-2.72C1168.37,679.51,1168.39,679.49,1168.4,679.49z"></path>
                                                                               </g>
                                                                               <g>
                                                                                               <path class="st22" d="M1183.45,674.89c0,0-4.75,26.91,21.16,26.91c25.91,0,20.15-38,20.15-38S1198.91,667.68,1183.45,674.89z"></path>
                                                                                               <path class="st23" d="M1224.76,663.8c0,0-25.85,3.88-41.31,11.09c0,0-3.48,19.8,11.9,25.42c-8.7-4.75-11.48-17.94-7.49-25.12
                                                                                                              c3.1-2.18,10.62-4.49,12.69-1.38c2.07,2.08-0.43,10.02,1.64,14.17c-1.04-9.33,10.19-24.87,17.45-17.62
                                                                                                              c6.34,5.55,2.29,20.95-2.78,27.28C1228.39,687.8,1224.76,663.8,1224.76,663.8z"></path>
                                                                                               <path class="st24" d="M1206.96,676.19c0,0-3.59,3.88-3.59,11.53c0,4.31,2.51,3.5,2.46,0c-0.05-2.99,0.27-4.92,1.91-8.09
                                                                                                              C1209.34,676.52,1208.48,674.9,1206.96,676.19z"></path>
                                                                               </g>
                                                               </g>
                                               </g>
                                </g>
                                <g data-elvn="maskSlideTL, in, 4550, 650, backin" class="elvn-layer">
                                               <path class="st25" d="M1332.16,720.93c0,0,32.68,10.06,52.12,64.77c13.74,38.67,15.36,55.99,15.36,55.99s23.15,17.38,21.26-5.23
                                                               c-1.9-22.6-24.07-136.17-78.85-149.95C1287.27,672.73,1332.16,720.93,1332.16,720.93z"></path>
                                               <path class="st25" d="M1398.73,871.43c0,0,41.67,0.18,42.92-15.34c1.26-15.51-13.99-26.52-21.55-30.95
                                                               c-7.55-4.43-16.22-3.97-23.18,18.19C1389.97,865.5,1398.73,871.43,1398.73,871.43z"></path>
                                               <path class="st17" d="M1396.19,868.99c0,0-4.32-8.69,9.99-17.36c14.31-8.68,28.09-10.7,33.37-3.87c5.27,6.82,2.36,14.36-1.73,18.8
                                                               C1433.72,870.99,1409.24,884.29,1396.19,868.99z"></path>
                                               <path class="st17" d="M1393.39,845.93c0,0-0.58,8.47,8.52,11.19c9.09,2.72,15.57-0.71,9.12-12.86
                                                               C1404.56,832.11,1392.37,840.95,1393.39,845.93z"></path>
                                </g>
                                <path class="st17 elvn-layer" d="M1167.5,625.78c0,0-26.42,2.9-58.08-33.37c-26.11-29.92-33.48-54.98-33.48-54.98s-25.48-11.64-19.01,9.34
                                               c6.46,20.98,55.1,116.63,109.73,118.33C1221.29,666.79,1167.5,625.78,1167.5,625.78z" data-elvn="maskSlideBR, in, 4550, 650, backin"></path>
                                <path class="st25 elvn-layer" d="M1087.88,523.43c0,0-32.48-5.13-36.2,6.04c-3.72,11.17,0.64,20.02,5.39,24.64
                                               c4.75,4.62,16.05,7.83,25.16-7.19C1091.34,531.9,1087.88,523.43,1087.88,523.43z" data-elvn="maskSlideB, in, 4800, 650, backin"></path>
                                <g data-elvn="maskSlideB, in, 5000, 650, backin" class="elvn-layer">
                                               <g>
                                                               <path class="st10" d="M1082.33,604.96c0,1.65-1.35,3-3,3h-19.68c-1.65,0-3-1.35-3-3V491.8c0-1.65,1.35-3,3-3h19.68
                                                                               c1.65,0,3,1.35,3,3V604.96z"></path>
                                               </g>
                                               <rect x="1056.65" y="488.8" class="st26" width="25.68" height="22.82"></rect>
                                               <g>
                                                               <path class="st10" d="M1225.14,500.22c0,2.75-2.25,5-5,5h-301.3c-2.75,0-5-2.25-5-5V296.4c0-2.75,2.25-5,5-5h301.3
                                                                               c2.75,0,5,2.25,5,5V500.22z"></path>
                                               </g>
                                               <g>
                                                               <path class="st27" d="M1204.48,486.03c0,2.75-2.25,5-5,5H939.49c-2.75,0-5-2.25-5-5V310.59c0-2.75,2.25-5,5-5h259.99
                                                                               c2.75,0,5,2.25,5,5V486.03z"></path>
                                               </g>
                                               <g>
                                                               <g>
                                                                               <path class="st28" d="M993.49,404.78v-18.41h-31.41v-6.03l30.17-43.17h9.88v42.02h9.47v7.18h-9.47v18.41H993.49z M993.49,379.2
                                                                                               v-22.57c0-3.54,0.1-7.07,0.31-10.61h-0.31c-2.08,3.95-3.74,6.87-5.62,9.99l-16.54,22.99v0.21H993.49z"></path>
                                                                               <path class="st28" d="M1063.49,370.25c0,22.99-8.53,35.68-23.51,35.68c-13.21,0-22.16-12.38-22.36-34.74
                                                                                               c0-22.68,9.78-35.16,23.51-35.16C1055.38,336.03,1063.49,348.72,1063.49,370.25z M1026.77,371.29
                                                                                               c0,17.58,5.41,27.56,13.73,27.56c9.36,0,13.83-10.92,13.83-28.19c0-16.64-4.27-27.57-13.73-27.57
                                                                                               C1032.59,343.1,1026.77,352.88,1026.77,371.29z"></path>
                                                                               <path class="st28" d="M1100.2,404.78v-18.41h-31.41v-6.03l30.17-43.17h9.88v42.02h9.47v7.18h-9.47v18.41H1100.2z M1100.2,379.2
                                                                                               v-22.57c0-3.54,0.1-7.07,0.31-10.61h-0.31c-2.08,3.95-3.74,6.87-5.62,9.99l-16.54,22.99v0.21H1100.2z"></path>
                                                               </g>
                                               </g>
                                               <g>
                                                               <path class="st11" d="M993.45,470.88h-27.03v-46.93h27.03v8.15h-17.08v10.3h15.89v8.15h-15.89v12.1h17.08V470.88z"></path>
                                                               <path class="st11" d="M1013.19,452.87v18.01h-9.95v-46.93h13.67c6.38,0,11.1,1.16,14.16,3.48c3.06,2.32,4.59,5.85,4.59,10.58
                                                                               c0,2.76-0.76,5.22-2.28,7.37c-1.52,2.15-3.67,3.84-6.45,5.06c7.06,10.55,11.66,17.37,13.8,20.45h-11.04l-11.2-18.01H1013.19z
                                                                               M1013.19,444.78h3.21c3.15,0,5.47-0.52,6.97-1.57c1.5-1.05,2.25-2.7,2.25-4.94c0-2.23-0.77-3.81-2.29-4.75
                                                                               c-1.53-0.94-3.9-1.41-7.11-1.41h-3.02V444.78z"></path>
                                                               <path class="st11" d="M1056.59,452.87v18.01h-9.95v-46.93h13.67c6.38,0,11.1,1.16,14.16,3.48c3.06,2.32,4.59,5.85,4.59,10.58
                                                                               c0,2.76-0.76,5.22-2.28,7.37c-1.52,2.15-3.67,3.84-6.45,5.06c7.06,10.55,11.66,17.37,13.8,20.45h-11.04l-11.2-18.01H1056.59z
                                                                               M1056.59,444.78h3.21c3.15,0,5.47-0.52,6.97-1.57c1.5-1.05,2.25-2.7,2.25-4.94c0-2.23-0.77-3.81-2.29-4.75
                                                                               c-1.53-0.94-3.9-1.41-7.11-1.41h-3.02V444.78z"></path>
                                                               <path class="st11" d="M1132.64,447.35c0,7.77-1.93,13.74-5.78,17.91c-3.85,4.17-9.37,6.26-16.56,6.26s-12.71-2.09-16.56-6.26
                                                                               c-3.85-4.17-5.78-10.16-5.78-17.98c0-7.81,1.93-13.78,5.79-17.9c3.86-4.12,9.4-6.18,16.61-6.18s12.73,2.08,16.55,6.23
                                                                               C1130.73,433.59,1132.64,439.56,1132.64,447.35z M1098.39,447.35c0,5.24,1,9.19,2.99,11.84c1.99,2.65,4.96,3.98,8.92,3.98
                                                                               c7.94,0,11.91-5.27,11.91-15.83c0-10.57-3.95-15.86-11.84-15.86c-3.96,0-6.94,1.33-8.96,4
                                                                               C1099.39,438.15,1098.39,442.11,1098.39,447.35z"></path>
                                                               <path class="st11" d="M1152.31,452.87v18.01h-9.95v-46.93h13.67c6.38,0,11.1,1.16,14.16,3.48c3.06,2.32,4.59,5.85,4.59,10.58
                                                                               c0,2.76-0.76,5.22-2.28,7.37c-1.52,2.15-3.67,3.84-6.45,5.06c7.06,10.55,11.66,17.37,13.8,20.45h-11.04l-11.2-18.01H1152.31z
                                                                               M1152.31,444.78h3.21c3.15,0,5.47-0.52,6.97-1.57c1.5-1.05,2.25-2.7,2.25-4.94c0-2.23-0.77-3.81-2.29-4.75
                                                                               c-1.53-0.94-3.9-1.41-7.11-1.41h-3.02V444.78z"></path>
                                               </g>
                                </g>
                                <g data-elvn="maskSlideB, in, 4800, 650, backin" class="elvn-layer">
                                               <path class="st17" d="M1059.25,518.94c0,0,9.26,1.03,10.15,10.91c0.89,9.89-5.14,16.69-10.15,17.72
                                                               c-5.01,1.03-7.68,0.39-8.98-2.95C1048.98,541.28,1047.7,521.89,1059.25,518.94z"></path>
                                               <path class="st17" d="M1084.79,520.09c0,0-6.29-1.28-9.24,5.26c-2.95,6.54-1.02,11.74,8.73,8.12
                                                               C1094.03,529.85,1088.62,519.83,1084.79,520.09z"></path>
                                </g>
                                <g data-elvn="fade, in, 5000, 500, easeinout" class="elvn-layer">
                                               <path class="st28" d="M1374.51,601.94c-0.32,0.69-0.64,1.41-0.97,2.09l-160.94-72.62c0.3-0.7,0.62-1.42,0.93-2.11
                                                               c11.83-26.21,34.86-43.86,60.87-49.78c0.23-0.06,0.48-0.12,0.73-0.17c0.81,0.62,1.71,1.13,2.66,1.56l0.47,0.21
                                                               c5.99,2.7,12.84,0.98,16.88-3.77c0.01-0.02,0.03-0.03,0.05-0.05c5.89,0.06,11.78,0.73,17.69,2.02c5.88,1.29,11.72,3.21,17.45,5.8
                                                               c5.12,2.31,9.96,5.06,14.4,8.19c5.06,3.55,9.66,7.57,13.78,11.98c0.06,0.05,0.14,0.13,0.2,0.2c-1.15,6.35,2.12,12.89,8.26,15.66
                                                               l0.47,0.21c1.04,0.47,2.1,0.81,3.17,1c0.14,0.04,0.28,0.06,0.43,0.08C1384.25,546.03,1386.48,575.4,1374.51,601.94z"></path>
                                               <path class="st29" d="M1344.74,493.32l-33.34,67.13l-26.66-12.03l28.15-69.09c5.88,1.29,11.72,3.21,17.45,5.8
                                                               C1335.46,487.44,1340.29,490.19,1344.74,493.32z"></path>
                                               <path class="st28" d="M1313.67,555.84c-3.28,7.26-7.62,1.19-14.88-2.08l-0.47-0.21c-7.26-3.27-14.68-2.52-11.4-9.78l26.22-58.1
                                                               c3.28-7.26,11.81-10.49,19.07-7.21l0.47,0.21c7.26,3.27,10.49,11.81,7.21,19.07L1313.67,555.84z"></path>
                                               <path class="st29" d="M1295.15,477.36l-19.02,42.15c-2.05,4.54-7.38,6.54-11.92,4.49c-2.25-1.02-3.9-2.87-4.72-5.02
                                                               c-0.81-2.16-0.81-4.61,0.21-6.89l14.69-32.57c0.23-0.06,0.48-0.12,0.73-0.17c0.81,0.62,1.71,1.13,2.66,1.56l0.47,0.21
                                                               C1284.26,483.83,1291.11,482.11,1295.15,477.36z"></path>
                                               <path class="st29" d="M1370.61,522.37l-14.91,33.04c-2.05,4.55-7.38,6.54-11.92,4.49c-2.27-1.03-3.9-2.87-4.74-5.03
                                                               c-0.81-2.16-0.81-4.61,0.22-6.89l19.26-42.69c0.06,0.05,0.14,0.13,0.2,0.2c-1.15,6.35,2.12,12.89,8.26,15.66l0.47,0.21
                                                               C1368.47,521.83,1369.54,522.18,1370.61,522.37z"></path>
                                               <path class="st28" d="M1373.58,604.05c-4.75,10.53-44.62,2.81-89.06-17.24c-44.43-20.05-76.6-44.84-71.85-55.37
                                                               c4.75-10.53,38.6,10.54,83.03,30.59C1340.13,582.09,1378.33,593.52,1373.58,604.05z"></path>
                                               <path class="st26" d="M1372.33,598.44c-2.8,6.09-13.59,6.07-19.77,5.82c-24.07-0.98-74.56-21.78-115.34-43.56
                                                               c12.46,8.61,28.81,17.77,47.3,26.11c44.44,20.05,84.31,27.76,89.06,17.24C1374.4,602.22,1373.91,600.37,1372.33,598.44z"></path>
                                               <path class="st1" d="M1374.51,601.94c-0.32,0.69-0.64,1.41-0.97,2.09l-160.94-72.62c0.3-0.7,0.62-1.42,0.93-2.11
                                                               c2.29-5.09,5-9.83,8.08-14.25l158.23,71.4C1378.57,591.69,1376.8,596.86,1374.51,601.94z"></path>
                                </g>
                </g>
                <g id="cono_3" data-elvn="maskSlideB, in, 3200, 1000, easeinout" class="elvn-layer" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                               <g>
                                                               <path class="st28" d="M1657.86,699.04H1642l-7.5-29.5c-0.97-3.81-1.95-7.67-2.18-8.58c-0.23-0.92-1.21-4.78-2.18-8.59
                                                                               l-5.01-19.72c-0.97-3.81-2.55-10.03-3.51-13.84l-1.86-7.33c-0.97-3.81-2.01-7.94-2.33-9.19c-0.32-1.25-1.37-5.37-2.34-9.18
                                                                               l-1.57-6.15c-0.97-3.81-2.55-10.04-3.51-13.84l-10.08-39.71c-0.97-3.81-4.97-6.92-8.9-6.92h-27.82c-3.93,0-7.93,3.11-8.89,6.92
                                                                               l-10.07,39.71c-0.96,3.81-2.54,10.04-3.51,13.84l-1.56,6.15c-0.97,3.81-2.01,7.94-2.32,9.18c-0.32,1.25-1.36,5.38-2.33,9.19
                                                                               l-1.86,7.33c-0.97,3.81-2.55,10.04-3.51,13.85l-5,19.72c-0.97,3.81-1.95,7.67-2.18,8.59c-0.23,0.91-1.21,4.77-2.17,8.59
                                                                               l-7.48,29.5h-15.85c-3.93,0-7.15,3.21-7.15,7.14v10.95c0,3.93,3.21,7.14,7.15,7.14h161.39c3.93,0,7.14-3.21,7.14-7.14v-10.95
                                                                               C1665,702.25,1661.79,699.04,1657.86,699.04z"></path>
                                                               <path class="st1" d="M1611.76,580.01l6.24,24.51h-81.72l6.21-24.51H1611.76z"></path>
                                                               <path class="st1" d="M1626,633.2l8.8,34.59h-115.33l8.76-34.59H1626z"></path>
                                               </g>
                                </g>
                </g>
                <g id="cono_1" data-elvn="maskSlideB, in, 2800, 1000, easeinout" class="elvn-layer" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                               <g>
                                                               <path class="st28 elvn-layer" d="M1580.92,828.84h-22.39l-10.58-41.65c-1.37-5.38-2.75-10.83-3.08-12.12c-0.32-1.3-1.7-6.75-3.08-12.13
                                                                               l-7.07-27.84c-1.37-5.38-3.6-14.16-4.96-19.54l-2.63-10.35c-1.36-5.38-2.84-11.21-3.29-12.97c-0.45-1.76-1.94-7.58-3.3-12.96
                                                                               l-2.21-8.69c-1.36-5.38-3.6-14.17-4.96-19.54L1499.13,595c-1.37-5.38-7.02-9.78-12.57-9.78h-39.28c-5.54,0-11.2,4.4-12.55,9.78
                                                                               l-14.21,56.06c-1.36,5.37-3.59,14.17-4.95,19.54l-2.2,8.69c-1.36,5.38-2.83,11.2-3.28,12.96c-0.45,1.76-1.92,7.59-3.28,12.97
                                                                               l-2.62,10.35c-1.37,5.38-3.59,14.17-4.96,19.55l-7.06,27.83c-1.36,5.38-2.75,10.83-3.07,12.13c-0.33,1.29-1.71,6.74-3.07,12.12
                                                                               l-10.56,41.64h-22.37c-5.55,0-10.09,4.54-10.09,10.08v15.46c0,5.54,4.53,10.08,10.09,10.08h227.82c5.55,0,10.08-4.54,10.08-10.08
                                                                               v-15.46C1591,833.38,1586.47,828.84,1580.92,828.84z" data-elvn="morph, out, 5000, 2000, elastic" data-elvnmorph="M1510.01,866.76v-22.39l41.65-10.58c5.38-1.37,10.83-2.75,12.12-3.08c1.3-0.32,6.75-1.7,12.13-3.08                                                  l27.84-7.07c5.38-1.37,14.16-3.6,19.54-4.96l10.35-2.63c5.38-1.36,11.21-2.84,12.97-3.29c1.76-0.45,7.58-1.94,12.96-3.3                                                 l8.69-2.21c5.38-1.36,14.17-3.6,19.54-4.96l56.06-14.24c5.38-1.37,9.78-7.02,9.78-12.57v-39.28c0-5.54-4.4-11.2-9.78-12.55                                                    l-56.06-14.21c-5.37-1.36-14.17-3.59-19.54-4.95l-8.69-2.2c-5.38-1.36-11.2-2.83-12.96-3.28c-1.76-0.45-7.59-1.92-12.97-3.28                                                     l-10.35-2.62c-5.38-1.37-14.17-3.59-19.55-4.96l-27.83-7.06c-5.38-1.36-10.83-2.75-12.13-3.08c-1.29-0.33-6.74-1.71-12.12-3.07                                                                l-41.64-10.56v-22.37c0-5.55-4.54-10.09-10.08-10.09h-15.46c-5.54,0-10.08,4.53-10.08,10.09v227.82                                                   c0,5.55,4.54,10.08,10.08,10.08h15.46C1505.47,876.84,1510.01,872.3,1510.01,866.76z"></path>
                                                               <path class="st1 elvn-layer" d="M1515.85,660.83l8.8,34.6h-115.36l8.77-34.6H1515.85z" data-elvn="morph, out, 5000, 2000, elastic" data-elvnmorph="M1678.02,801.68l-34.6,8.8V695.13l34.6,8.77V801.68z"></path>
                                                               <path class="st1 elvn-layer" d="M1535.95,735.9l12.42,48.83h-162.8l12.37-48.83H1535.95z" data-elvn="morph, out, 5000, 2000, elastic" data-elvnmorph="M1602.94,821.78l-48.83,12.42v-162.8l48.83,12.37V821.78z"></path>
                                               </g>
                                </g>
                </g>
                <g id="cono_4" data-elvn="maskSlideB, in, 2500, 1000, easeinout" class="elvn-layer" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                               <g>
                                                               <path class="st28 elvn-layer" d="M1069.4,712.36h-14.54l-6.87-27.05c-0.89-3.49-1.78-7.03-2-7.87c-0.21-0.84-1.11-4.38-2-7.88l-4.59-18.08
                                                                               c-0.89-3.49-2.34-9.2-3.22-12.69l-1.71-6.72c-0.89-3.5-1.84-7.28-2.14-8.43c-0.29-1.14-1.26-4.93-2.14-8.42l-1.44-5.64
                                                                               c-0.89-3.49-2.34-9.2-3.22-12.69l-9.25-36.41c-0.89-3.49-4.56-6.35-8.16-6.35h-25.51c-3.6,0-7.27,2.85-8.15,6.35l-9.23,36.41
                                                                               c-0.88,3.49-2.33,9.2-3.21,12.69l-1.43,5.64c-0.89,3.49-1.84,7.28-2.13,8.42c-0.29,1.15-1.25,4.93-2.13,8.43l-1.7,6.72
                                                                               c-0.89,3.5-2.33,9.2-3.22,12.69l-4.58,18.08c-0.89,3.49-1.78,7.03-2,7.88c-0.21,0.83-1.11,4.38-1.99,7.87l-6.86,27.05h-14.53
                                                                               c-3.61,0-6.55,2.95-6.55,6.55v10.04c0,3.6,2.94,6.55,6.55,6.55h147.96c3.6,0,6.55-2.95,6.55-6.55V718.9
                                                                               C1075.95,715.31,1073,712.36,1069.4,712.36z" data-elvn="morph, out, 5000, 2000, elastic" data-elvnmorph="M835.96,570.84v14.54l-27.05,6.87c-3.49,0.89-7.03,1.78-7.87,2c-0.84,0.21-4.38,1.11-7.88,2l-18.08,4.59                                                          c-3.49,0.89-9.2,2.34-12.69,3.22l-6.72,1.71c-3.5,0.89-7.28,1.84-8.43,2.14c-1.14,0.29-4.93,1.26-8.42,2.14l-5.64,1.44                                                       c-3.49,0.89-9.2,2.34-12.69,3.22l-36.41,9.25c-3.49,0.89-6.35,4.56-6.35,8.16v25.51c0,3.6,2.85,7.27,6.35,8.15L720.5,675                                                  c3.49,0.88,9.2,2.33,12.69,3.21l5.64,1.43c3.49,0.89,7.28,1.84,8.42,2.13c1.15,0.29,4.93,1.25,8.43,2.13l6.72,1.7                                                c3.5,0.89,9.2,2.33,12.69,3.22l18.08,4.58c3.49,0.89,7.03,1.78,7.88,2c0.83,0.21,4.38,1.11,7.87,1.99l27.05,6.86v14.53                                                                c0,3.61,2.95,6.55,6.55,6.55h10.04c3.6,0,6.55-2.94,6.55-6.55V570.84c0-3.6-2.95-6.55-6.55-6.55h-10.04                                                            C838.91,564.29,835.96,567.23,835.96,570.84z"></path>
                                                               <path class="st1 elvn-layer" d="M1027.14,603.24l5.72,22.47h-74.92l5.69-22.47H1027.14z" data-elvn="morph, out, 5000, 2000, elastic" data-elvnmorph="M726.84,613.1l22.47-5.72v74.92l-22.47-5.69V613.1z"></path>
                                                               <path class="st1 elvn-layer" d="M1040.19,652l8.07,31.71H942.53l8.03-31.71H1040.19z" data-elvn="morph, out, 5000, 2000, elastic" data-elvnmorph="M775.6,600.04l31.71-8.07v105.73l-31.71-8.03V600.04z"></path>
                                               </g>
                                </g>
                </g>
                <g id="cono_2" data-elvn="maskSlideB, in, 3400, 1000, easeinout" class="elvn-layer" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                               <g>
                                                               <path class="st28" d="M1031.5,840.53h-18.87l-8.92-35.1c-1.15-4.53-2.32-9.13-2.6-10.21c-0.27-1.09-1.44-5.69-2.59-10.22
                                                                               l-5.96-23.46c-1.15-4.53-3.03-11.93-4.18-16.47l-2.21-8.72c-1.15-4.54-2.39-9.45-2.77-10.93c-0.38-1.48-1.63-6.39-2.78-10.92
                                                                               l-1.86-7.32c-1.15-4.53-3.03-11.94-4.18-16.47l-12-47.24c-1.15-4.53-5.92-8.24-10.59-8.24h-33.1c-4.67,0-9.43,3.7-10.58,8.24
                                                                               l-11.98,47.24c-1.14,4.53-3.02,11.94-4.17,16.47l-1.86,7.32c-1.15,4.53-2.39,9.44-2.77,10.92c-0.38,1.49-1.62,6.4-2.77,10.93
                                                                               l-2.21,8.72c-1.15,4.54-3.03,11.94-4.18,16.47L872.44,785c-1.15,4.53-2.32,9.13-2.59,10.22c-0.27,1.08-1.44,5.68-2.59,10.21
                                                                               l-8.9,35.09h-18.85c-4.68,0-8.5,3.82-8.5,8.5v13.03c0,4.67,3.82,8.5,8.5,8.5h191.99c4.67,0,8.5-3.82,8.5-8.5v-13.03
                                                                               C1040,844.35,1036.18,840.53,1031.5,840.53z"></path>
                                                               <path class="st1" d="M976.67,698.94l7.42,29.16h-97.21l7.39-29.16H976.67z"></path>
                                                               <path class="st1" d="M993.61,762.21l10.47,41.15h-137.2l10.43-41.15H993.61z"></path>
                                               </g>
                                </g>
                </g>
                <g id="oops" data-elvn="pulse, in, 6000, 650, backin" class="elvn-layer" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                               <g>
                                                               <path class="st1" d="M1170.45,143.86c0,10.72-2.66,18.96-7.97,24.72c-5.32,5.76-12.94,8.64-22.86,8.64s-17.54-2.88-22.86-8.64
                                                                               c-5.32-5.76-7.97-14.03-7.97-24.81c0-10.78,2.67-19.01,8-24.7c5.33-5.69,12.97-8.53,22.93-8.53c9.95,0,17.57,2.87,22.84,8.59
                                                                               C1167.82,124.87,1170.45,133.11,1170.45,143.86z M1123.18,143.86c0,7.24,1.37,12.69,4.12,16.35c2.75,3.66,6.85,5.49,12.32,5.49
                                                                               c10.96,0,16.44-7.28,16.44-21.84c0-14.59-5.45-21.89-16.35-21.89c-5.46,0-9.58,1.84-12.36,5.52
                                                                               C1124.57,131.17,1123.18,136.63,1123.18,143.86z"></path>
                                                               <path class="st1" d="M1227.82,151.48c0,8.06-2.13,14.37-6.38,18.92c-4.25,4.55-10.17,6.82-17.76,6.82
                                                                               c-4.76,0-8.95-1.04-12.58-3.12c-3.63-2.08-6.42-5.07-8.37-8.97c-1.95-3.9-2.92-8.45-2.92-13.65c0-8.09,2.11-14.38,6.33-18.87
                                                                               c4.22-4.49,10.16-6.73,17.81-6.73c4.75,0,8.95,1.03,12.58,3.1c3.63,2.07,6.42,5.04,8.37,8.9
                                                                               C1226.85,141.75,1227.82,146.29,1227.82,151.48z M1193.58,151.48c0,4.9,0.8,8.61,2.41,11.12c1.61,2.51,4.23,3.77,7.86,3.77
                                                                               c3.6,0,6.19-1.25,7.77-3.74c1.58-2.5,2.37-6.21,2.37-11.14c0-4.9-0.8-8.58-2.39-11.03c-1.59-2.45-4.21-3.68-7.84-3.68
                                                                               c-3.6,0-6.2,1.22-7.8,3.65C1194.37,142.87,1193.58,146.55,1193.58,151.48z"></path>
                                                               <path class="st1" d="M1266.19,177.22c-5.82,0-10.38-2.11-13.69-6.34h-0.71c0.47,4.14,0.71,6.53,0.71,7.18v20.07h-13.51v-71.33
                                                                               h10.99l1.9,6.42h0.62c3.16-4.9,7.84-7.35,14.04-7.35c5.85,0,10.43,2.26,13.73,6.78s4.96,10.79,4.96,18.83
                                                                               c0,5.29-0.78,9.88-2.33,13.78c-1.55,3.9-3.76,6.87-6.62,8.9C1273.42,176.2,1270.06,177.22,1266.19,177.22z M1262.2,136.69
                                                                               c-3.34,0-5.77,1.03-7.31,3.08c-1.54,2.05-2.33,5.44-2.39,10.17v1.46c0,5.32,0.79,9.13,2.37,11.43c1.58,2.3,4.08,3.46,7.51,3.46
                                                                               c6.05,0,9.08-4.99,9.08-14.97c0-4.87-0.75-8.53-2.24-10.96C1267.73,137.91,1265.39,136.69,1262.2,136.69z"></path>
                                                               <path class="st1" d="M1330.96,161.63c0,5.08-1.77,8.95-5.29,11.61c-3.53,2.66-8.81,3.99-15.84,3.99c-3.6,0-6.67-0.24-9.21-0.73
                                                                               s-4.92-1.2-7.13-2.15v-11.16c2.51,1.18,5.34,2.17,8.48,2.97c3.14,0.8,5.91,1.2,8.31,1.2c4.9,0,7.35-1.42,7.35-4.25
                                                                               c0-1.06-0.33-1.93-0.97-2.59c-0.65-0.66-1.77-1.42-3.37-2.26s-3.72-1.82-6.38-2.95c-3.81-1.6-6.61-3.07-8.4-4.43
                                                                               c-1.79-1.36-3.09-2.92-3.9-4.67c-0.81-1.76-1.22-3.92-1.22-6.49c0-4.4,1.71-7.8,5.12-10.21c3.41-2.41,8.25-3.61,14.51-3.61
                                                                               c5.97,0,11.77,1.3,17.41,3.9l-4.08,9.75c-2.48-1.06-4.8-1.93-6.96-2.61c-2.16-0.68-4.36-1.02-6.6-1.02
                                                                               c-3.99,0-5.98,1.08-5.98,3.23c0,1.21,0.64,2.26,1.93,3.15c1.29,0.89,4.1,2.2,8.44,3.94c3.87,1.57,6.7,3.03,8.51,4.39
                                                                               c1.8,1.36,3.13,2.92,3.99,4.7C1330.53,157.07,1330.96,159.18,1330.96,161.63z"></path>
                                                               <path class="st1" d="M1339.6,170c0-2.48,0.66-4.36,1.99-5.63c1.33-1.27,3.26-1.9,5.8-1.9c2.45,0,4.35,0.65,5.69,1.95
                                                                               c1.34,1.3,2.02,3.16,2.02,5.58c0,2.33-0.68,4.17-2.04,5.52c-1.36,1.34-3.25,2.02-5.67,2.02c-2.48,0-4.4-0.66-5.76-1.97
                                                                               C1340.27,174.25,1339.6,172.39,1339.6,170z M1352.84,154.85h-10.81l-2.26-43.28h15.33L1352.84,154.85z"></path>
                                               </g>
                                </g>
                </g>
                <g id="text" data-elvn="fadeShortB, in, 6000, 650, backin" class="elvn-layer" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                               <g>
                                                               <path class="st1" d="M781.95,973.75h-14.96l-3.39,9.31h-5.92l13.4-35.51h6.89l13.4,35.51h-6.08L781.95,973.75z M780.22,969.07
                                                                               l-4.09-11.41l-1.61-5.11h-0.16l-1.51,5l-4.2,11.51H780.22z"></path>
                                                               <path class="st1" d="M802.44,975.21c0,2.64,0.65,3.55,2.8,3.55c1.35,0,2.15-0.16,3.55-0.7l-0.65,4.63
                                                                               c-1.18,0.59-2.91,0.92-4.46,0.92c-4.79,0-7-2.37-7-7.59v-31.96h5.76V975.21z"></path>
                                                               <path class="st1" d="M837.9,985.75c0,5.59-6.03,8.88-13.83,8.88c-8.88,0-12.8-2.91-12.8-6.89c0-2.42,1.72-4.36,4.79-5.17
                                                                               c-2.1-0.97-3.77-2.64-3.77-5.11c0-2.8,1.67-4.52,4.2-5.38c-2.69-1.56-4.14-4.14-4.14-7.37c0-5.49,4.68-9.09,11.67-9.09
                                                                               c2.04,0,3.87,0.32,5.43,0.86c0.97-3.82,3.5-6.08,7.91-5.97l0.97,4.47c-3.5-0.11-5.54,0.92-6.35,2.74
                                                                               c2.37,1.61,3.71,4.04,3.71,6.99c0,5.49-4.63,9.09-11.67,9.09c-1.67,0-3.12-0.16-4.47-0.48c-1.83,0.43-2.58,1.4-2.58,2.96
                                                                               c0,1.67,1.29,2.58,3.33,2.58h8.99C834.72,978.86,837.9,981.45,837.9,985.75z M832.95,986.4c0-1.72-1.13-2.74-4.68-2.74H821
                                                                               c-3.28,0-4.84,1.08-4.84,2.85c0,2.37,2.74,3.82,8.45,3.82C830.09,990.32,832.95,988.49,832.95,986.4z M818.26,964.71
                                                                               c0,3.07,2.47,5.11,5.76,5.11c3.34,0,5.81-2.04,5.81-5.11c0-3.01-2.47-5.06-5.81-5.06C820.73,959.66,818.26,961.7,818.26,964.71z"></path>
                                                               <path class="st1" d="M868.67,969.61c0,8.77-5,13.99-13.4,13.99c-8.39,0-13.4-5.22-13.4-13.99c0-8.77,5-13.99,13.4-13.99
                                                                               C863.67,955.62,868.67,960.84,868.67,969.61z M847.69,969.61c0,6.08,2.74,9.47,7.59,9.47c4.84,0,7.64-3.39,7.64-9.47
                                                                               c0-6.08-2.8-9.47-7.64-9.47C850.43,960.14,847.69,963.53,847.69,969.61z"></path>
                                                               <path class="st1" d="M891.26,977.95l3.87-3.34c1.4,3.23,4.57,4.47,8.34,4.47c3.82,0,5.76-1.4,5.76-3.23
                                                                               c0-1.67-1.56-2.64-6.24-3.55c-8.02-1.51-10.81-3.82-10.81-8.34c0-4.36,4.09-8.34,11.3-8.34c5.97,0,9.36,1.94,11.3,6.08
                                                                               l-4.09,3.01c-1.35-3.23-3.66-4.57-7.16-4.57c-3.34,0-5.6,1.61-5.6,3.28c0,1.94,1.56,2.96,6.62,3.87
                                                                               c7.69,1.4,10.44,3.71,10.44,8.18c0,4.73-4.3,8.12-11.73,8.12C898.04,983.6,893.52,981.88,891.26,977.95z"></path>
                                                               <path class="st1" d="M947.22,979.08l-0.38,3.87c-0.86,0.38-1.99,0.65-3.34,0.65c-2.91,0-4.9-1.08-5.49-4.04
                                                                               c-1.67,2.58-5.11,4.04-9.09,4.04c-5.06,0-8.77-2.37-8.77-7.05c0-4.14,3.44-7.21,10.49-8.45l6.83-1.24v-1.4
                                                                               c0-3.23-1.99-5.33-5.49-5.33c-3.34,0-5.81,1.45-6.94,4.84l-4.47-2.37c1.29-4.25,5.43-6.99,11.3-6.99
                                                                                c6.73,0,11.35,3.07,11.35,9.52v12.16c0,1.34,0.54,1.99,1.99,1.99C945.92,979.29,946.62,979.24,947.22,979.08z M937.48,974.13
                                                                               v-3.23l-5.86,1.18c-3.66,0.7-5.49,1.94-5.49,4.09c0,1.99,1.51,3.07,4.04,3.07C933.76,979.24,937.48,977.36,937.48,974.13z"></path>
                                                                <path class="st1" d="M958.24,975.21c0,2.64,0.65,3.55,2.8,3.55c1.35,0,2.15-0.16,3.55-0.7l-0.65,4.63
                                                                               c-1.18,0.59-2.91,0.92-4.46,0.92c-4.79,0-7-2.37-7-7.59v-31.96h5.76V975.21z"></path>
                                                               <path class="st1" d="M968.46,948.04c0-2.26,1.4-3.55,3.87-3.55s3.82,1.29,3.82,3.55s-1.34,3.6-3.82,3.6
                                                                               S968.46,950.3,968.46,948.04z M975.19,983.06h-5.76v-26.9h5.76V983.06z"></path>
                                                               <path class="st1" d="M1009.4,969.61c0,8.77-5,13.99-13.4,13.99c-8.39,0-13.4-5.22-13.4-13.99c0-8.77,5-13.99,13.4-13.99
                                                                               C1004.4,955.62,1009.4,960.84,1009.4,969.61z M988.42,969.61c0,6.08,2.74,9.47,7.59,9.47c4.84,0,7.64-3.39,7.64-9.47
                                                                               c0-6.08-2.8-9.47-7.64-9.47C991.17,960.14,988.42,963.53,988.42,969.61z"></path>
                                                               <path class="st1" d="M1076.6,965.63v17.43h-5.76v-16.52c0-4.41-2.37-6.19-5.54-6.19c-3.77,0-6.99,2.85-6.99,8.07v14.63h-5.76
                                                                               v-16.52c0-4.41-2.37-6.19-5.54-6.19c-3.23,0-6.99,2.37-6.99,8.39v14.31h-5.76v-26.9h5.11l0.27,4.47c1.88-3.39,5.22-5,8.72-5
                                                                               c3.82,0,7.42,1.72,9.04,5.38c1.78-3.66,5.7-5.38,9.31-5.38C1071.86,955.62,1076.65,958.8,1076.6,965.63z"></path>
                                                               <path class="st1" d="M1110.55,979.08l-0.38,3.87c-0.86,0.38-1.99,0.65-3.34,0.65c-2.91,0-4.9-1.08-5.49-4.04
                                                                               c-1.67,2.58-5.11,4.04-9.09,4.04c-5.06,0-8.77-2.37-8.77-7.05c0-4.14,3.44-7.21,10.49-8.45l6.83-1.24v-1.4
                                                                               c0-3.23-1.99-5.33-5.49-5.33c-3.34,0-5.81,1.45-6.94,4.84l-4.47-2.37c1.29-4.25,5.43-6.99,11.3-6.99
                                                                               c6.73,0,11.35,3.07,11.35,9.52v12.16c0,1.34,0.54,1.99,1.99,1.99C1109.25,979.29,1109.95,979.24,1110.55,979.08z M1100.81,974.13
                                                                               v-3.23l-5.86,1.18c-3.66,0.7-5.49,1.94-5.49,4.09c0,1.99,1.51,3.07,4.04,3.07C1097.09,979.24,1100.81,977.36,1100.81,974.13z"></path>
                                                               <path class="st1" d="M1121.57,975.21c0,2.64,0.65,3.55,2.8,3.55c1.35,0,2.15-0.16,3.55-0.7l-0.65,4.63
                                                                               c-1.18,0.59-2.91,0.92-4.46,0.92c-4.79,0-7-2.37-7-7.59v-31.96h5.76V975.21z"></path>
                                                               <path class="st1" d="M1140.51,981.5c0,3.87-1.99,7.64-6.35,9.63l-2.8-2.37c3.01-1.02,5.06-3.17,5.7-5.54l-0.27-0.11
                                                                               c-0.27,0.27-0.7,0.43-1.51,0.43c-1.56,0-3.44-1.18-3.44-3.77c0-2.31,1.78-3.98,4.09-3.98
                                                                               C1138.9,975.8,1140.51,978.22,1140.51,981.5z"></path>
                                                               <path class="st1" d="M1170.85,975.21c0,2.64,0.65,3.55,2.8,3.55c1.35,0,2.15-0.16,3.55-0.7l-0.65,4.63
                                                                               c-1.18,0.59-2.91,0.92-4.46,0.92c-4.79,0-7-2.37-7-7.59v-31.96h5.76V975.21z"></path>
                                                               <path class="st1" d="M1207.33,979.08l-0.38,3.87c-0.86,0.38-1.99,0.65-3.34,0.65c-2.91,0-4.9-1.08-5.49-4.04
                                                                               c-1.67,2.58-5.11,4.04-9.09,4.04c-5.06,0-8.77-2.37-8.77-7.05c0-4.14,3.44-7.21,10.49-8.45l6.83-1.24v-1.4
                                                                               c0-3.23-1.99-5.33-5.49-5.33c-3.34,0-5.81,1.45-6.94,4.84l-4.47-2.37c1.29-4.25,5.43-6.99,11.3-6.99
                                                                               c6.73,0,11.35,3.07,11.35,9.52v12.16c0,1.34,0.54,1.99,1.99,1.99C1206.04,979.29,1206.74,979.24,1207.33,979.08z M1197.59,974.13
                                                                               v-3.23l-5.86,1.18c-3.66,0.7-5.49,1.94-5.49,4.09c0,1.99,1.51,3.07,4.04,3.07C1193.88,979.24,1197.59,977.36,1197.59,974.13z"></path>
                                                               <path class="st1" d="M1246.06,956.16l-0.91,5.27c-0.75-0.43-1.94-0.7-3.39-0.7c-3.23,0-6.78,2.58-6.78,8.07v14.26h-5.76v-26.9
                                                                               h4.9l0.54,4.79c1.45-3.5,4.3-5.33,8.45-5.33C1244.18,955.62,1245.31,955.78,1246.06,956.16z"></path>
                                                               <path class="st1" d="M1275.87,956.16v26.9h-5.11l-0.27-4.36c-1.83,3.39-5.17,4.9-9.52,4.9c-5.38,0-9.68-2.74-9.68-9.74v-17.7
                                                                               h5.76v16.19c0,5,2.15,6.51,5.54,6.51c4.09,0,7.53-2.48,7.53-8.18v-14.53H1275.87z"></path>
                                                               <path class="st1" d="M1302.23,981.29c-1.72,1.45-4.25,2.31-7.16,2.31c-4.63,0-8.18-2.1-8.23-7.64v-15.28h-5.11v-4.52h5.11v-6.29
                                                                               l5.76-1.61v7.91h9.42v4.52h-9.42v14.31c0,2.64,1.56,3.82,3.93,3.82c1.83,0,3.17-0.65,4.36-1.72L1302.23,981.29z"></path>
                                                               <path class="st1" d="M1333,979.08l-0.38,3.87c-0.86,0.38-1.99,0.65-3.34,0.65c-2.91,0-4.9-1.08-5.49-4.04
                                                                               c-1.67,2.58-5.11,4.04-9.09,4.04c-5.06,0-8.77-2.37-8.77-7.05c0-4.14,3.44-7.21,10.49-8.45l6.83-1.24v-1.4
                                                                               c0-3.23-1.99-5.33-5.49-5.33c-3.34,0-5.81,1.45-6.94,4.84l-4.47-2.37c1.29-4.25,5.43-6.99,11.3-6.99
                                                                               c6.73,0,11.35,3.07,11.35,9.52v12.16c0,1.34,0.54,1.99,1.99,1.99C1331.71,979.29,1332.41,979.24,1333,979.08z M1323.26,974.13
                                                                               v-3.23l-5.86,1.18c-3.66,0.7-5.49,1.94-5.49,4.09c0,1.99,1.51,3.07,4.04,3.07C1319.55,979.24,1323.26,977.36,1323.26,974.13z"></path>
                                                               <path class="st1" d="M1379.48,965.63v17.43h-5.76v-15.6c0-5.33-2.48-7.1-5.76-7.1c-3.55,0-7.32,2.26-7.32,8.45v14.26h-5.76v-26.9
                                                                               h5.11l0.27,4.52c1.83-3.55,5.22-5.06,9.09-5.06C1375.07,955.62,1379.48,958.9,1379.48,965.63z"></path>
                                                               <path class="st1" d="M1413.43,969.61c0,8.77-5,13.99-13.4,13.99c-8.39,0-13.4-5.22-13.4-13.99c0-8.77,5-13.99,13.4-13.99
                                                                               C1408.42,955.62,1413.43,960.84,1413.43,969.61z M1392.44,969.61c0,6.08,2.74,9.47,7.59,9.47c4.84,0,7.64-3.39,7.64-9.47
                                                                               c0-6.08-2.8-9.47-7.64-9.47C1395.19,960.14,1392.44,963.53,1392.44,969.61z"></path>
                                                               <path class="st1" d="M1462.11,971.76h-19.69c0.65,4.74,3.44,7.26,7.8,7.26c3.55,0,5.92-1.29,6.78-3.66l4.79,1.99
                                                                               c-1.94,3.98-5.65,6.24-11.57,6.24c-8.5,0-13.5-5.22-13.5-13.99c0-8.77,4.73-13.99,13.13-13.99c8.07,0,12.48,5.38,12.48,13.18
                                                                               C1462.33,969.83,1462.22,971.06,1462.11,971.76z M1442.48,967.35h14.47c-0.54-4.52-3.07-7.16-7.1-7.16
                                                                               C1445.6,960.19,1443.07,962.72,1442.48,967.35z"></path>
                                                               <path class="st1" d="M1485.41,983.06l-6.78-10.12l-7,10.12h-6.51l10.17-13.56l-9.63-13.34h6.89l6.46,9.74l6.4-9.74h6.62
                                                                               l-9.68,13.24l9.85,13.67H1485.41z"></path>
                                                               <path class="st1" d="M1497.03,948.04c0-2.26,1.4-3.55,3.87-3.55s3.82,1.29,3.82,3.55s-1.34,3.6-3.82,3.6
                                                                               S1497.03,950.3,1497.03,948.04z M1503.75,983.06H1498v-26.9h5.76V983.06z"></path>
                                                               <path class="st1" d="M1510.48,977.95l3.87-3.34c1.4,3.23,4.57,4.47,8.34,4.47c3.82,0,5.76-1.4,5.76-3.23
                                                                               c0-1.67-1.56-2.64-6.24-3.55c-8.02-1.51-10.81-3.82-10.81-8.34c0-4.36,4.09-8.34,11.3-8.34c5.97,0,9.36,1.94,11.3,6.08
                                                                               l-4.09,3.01c-1.35-3.23-3.66-4.57-7.16-4.57c-3.34,0-5.6,1.61-5.6,3.28c0,1.94,1.56,2.96,6.62,3.87
                                                                               c7.69,1.4,10.44,3.71,10.44,8.18c0,4.73-4.3,8.12-11.73,8.12C1517.26,983.6,1512.74,981.88,1510.48,977.95z"></path>
                                                               <path class="st1" d="M1558.63,981.29c-1.72,1.45-4.25,2.31-7.16,2.31c-4.63,0-8.18-2.1-8.23-7.64v-15.28h-5.11v-4.52h5.11v-6.29
                                                                               l5.76-1.61v7.91h9.42v4.52H1549v14.31c0,2.64,1.56,3.82,3.93,3.82c1.83,0,3.17-0.65,4.36-1.72L1558.63,981.29z"></path>
                                                               <path class="st1" d="M1587.84,971.76h-19.69c0.65,4.74,3.44,7.26,7.8,7.26c3.55,0,5.92-1.29,6.78-3.66l4.79,1.99
                                                                               c-1.94,3.98-5.65,6.24-11.57,6.24c-8.5,0-13.5-5.22-13.5-13.99c0-8.77,4.73-13.99,13.13-13.99c8.07,0,12.48,5.38,12.48,13.18
                                                                               C1588.06,969.83,1587.95,971.06,1587.84,971.76z M1568.2,967.35h14.47c-0.54-4.52-3.07-7.16-7.1-7.16
                                                                               C1571.32,960.19,1568.79,962.72,1568.2,967.35z"></path>
                                                               <path class="st1" d="M1602.2,979.67c0,2.37-1.56,3.93-4.04,3.93s-4.09-1.56-4.09-3.93s1.61-3.87,4.09-3.87
                                                                               S1602.2,977.3,1602.2,979.67z"></path>
                                               </g>
                                </g>
                </g>
                </svg>
                
        </div>
    </div>
</div>