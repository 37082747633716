<div (click)="onItemClick(searchItem.id)" class=" bg-card">
    <div class="card mt-0">
        <div class="d-flex flex-row">
            <div class="p-10 bg-info">
                <h3 class="text-white box m-b-0">
                    <i class="fas fa-map-marker-alt" style="font-size: 23px !important; line-height: 32px;"></i>
                </h3>
            </div>
            <div class="m-l-10 align-self-center textCard">
                <h4 class="mt-2 m-b-1">{{searchItem.title}}</h4>
                <h5 *ngIf="searchItem.subtitle" class="m-b-1">{{searchItem.subtitle}}</h5>
                <h5 *ngIf="searchItem.subtitle2" class="m-b-1">{{searchItem.subtitle2}}</h5>
                <h5 *ngIf="searchItem.subtitle3" class="m-b-1">{{searchItem.subtitle3}}</h5>
                <h5 *ngIf="searchItem.status" class="text-success m-b-1">
                    <div *ngIf="searchItem.status=='A'">
                        <div class="Item_TextOn">
                            <i class="fas fa-info-circle"></i>
                            <span class="Item_Text">{{searchItem.status_name}}</span>
                        </div>
                    </div>
                    <div *ngIf="searchItem.status!='A'">
                        <div class="Item_TextOff">
                            <i class="fas fa-info-circle"></i>
                            <span class="Item_Text">{{searchItem.status_name}}</span>
                        </div>
                    </div>
                </h5>
            </div>

        </div>
    </div>
</div>

