import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Profile } from 'src/app/data/models/profile.model';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-detail-profile',
  templateUrl: './detail-profile.component.html',
  styleUrls: ['./detail-profile.component.css']
})
export class DetailProfileComponent implements OnInit {

  @Output() goBack = new EventEmitter();
  @Input() idProfileDetail: number;

  viewProfile = true;
  newProfile = false;
  isNewEdit = false;
  profileSelected = new Profile();
  notStorage = true;

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
   }

  ngOnInit(): void {
    if (this.idProfileDetail === 0) {
      this.newProfile = true;
    } else {
      this.profileById();
    }
  }

  newEditProfile() {
    this.isNewEdit = true;
    this.viewProfile = false;
  }

  cancelEdit() {
    this.isNewEdit = false;
    this.viewProfile = true;
    this.profileById();
  }

  cancelNewProfile() {
    this.viewProfile = false;
    this.isNewEdit = false;
    if (this.idProfileDetail === 0) {
      this.goBack.emit();
    }
  }

  profileTitle(title) {
    this.profileSelected.ProfileDescription = title
  }

  onSelectApp(e) {
    this.profileSelected.Id_Application = e;
  }
  
  onDeselectApp() {
    this.profileSelected.Id_Application = 0;
  }

  onSelectTag(e) {
    this.profileSelected.Id_Tag = e;
  }

  onDeselectTag() {
    this.profileSelected.Id_Tag = 0;
  }

  onSubmit() {
    if (this.idProfileDetail === 0) {
      this.createProfile(this.profileSelected);
      this.goBack.emit();
    } else {
      this.editProfile(this.profileSelected);
      this.viewProfile = true;
      this.isNewEdit = false;
      this.newProfile = false;
    }
  }

  profileById() {
    this.services.post({
      entidad: 'Profile',
      funcion: 2,
      data: {
        Id_Profile: this.idProfileDetail
      }
    }).subscribe(result => {
      if (result.data) {
        this.profileSelected = result.data;
      }
    }, error => {
      console.log('Error', error);
    });
  }

  editProfile(profile: Profile) {
    this.services.post({
      entidad: 'Profile',
      funcion: 4,
      data: profile
    }).subscribe(result => {
      if (result.data) {
        this.profileById();
        this.toastr.success('Menú modificado correctamente', 'Menus');
      }
    }, error => {
      console.log('Error', error);
    });
  }

  createProfile(profile: Profile) {
    this.services.post({
      entidad: 'Profile',
      funcion: 3,
      data: profile
    }).subscribe(result => {
      if (result.data) {
        this.toastr.success('Menu creado correctamente', 'Menus');
      }
    }, error => {
      console.log('Error', error);
    });
  }

}
