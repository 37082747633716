import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomerList } from 'src/app/data/models/customer-list.model';
import { ServicesService } from 'src/app/data/services/services.service';
import { Customer, CustomerApp, CustomerFolio } from './customer';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {


  customerLst = new CustomerList();

  public channelSelected = 0;
  public customerSelected = new Customer();
  public searchKey = '';
  public CustomerModuleList: any;
  public isNewFolio = false;
  public listCustomersApp: CustomerApp[];
  public StatusList: boolean = false;
  public StatusListMFA = false;

  public listCustomers = [];
  public listAllChannel = [];
  public CustomerfolioSelected = new CustomerFolio();

  public results = true;
  public viewCustomer = false;
  public isNewEdit = false;
  public isEdit = false;
  public isView = false;
  public isFree = false;
  isReturn = false;
  public viewMoreItems = true;
  public page = 1;
  public titleNewEditCustomer = '';
  public idCustomer: Number;
  idCust = 0;
  newEd = true;
  isRest = false; 
  
  Title = 'Organizaciones';
  isAccess = false;
  EditCustomer = false;
  idx: any;
  filterType = '';
  Page: Number = 1;
  Pagination = 3;
  selectedPage: any = 1;
  collectionSize = 0;
  totalPages = 0;
  AZcus = false;
  ZAcus = false;
  AZema = false;
  ZAema = false;
  AZcha = false;
  ZAcha = false;
  AZdat = false;
  ZAdat = false;

  constructor(
    private services: ServicesService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
    if (localStorage.getItem('chaStoId') !== null) {
      this.customerLst.Id_Channel = +localStorage.getItem('chaStoId');
    }
    if (localStorage.getItem('cusStoId') !== null) {
      this.customerLst.Id_Customer = +localStorage.getItem('cusStoId');
    }
    if (localStorage.getItem('paginationStorage') !== null) {
      this.customerLst.Pagination = +localStorage.getItem('paginationStorage');
    }

  }

  ngOnInit(): void {

    //this.User_AccessService('MPX_CMBCHNL');

    const myOffcanvas = document.querySelector('#offcanvasRight') as any;
    if (myOffcanvas !== null) {
      myOffcanvas.addEventListener('hidden.bs.offcanvas', () => {
        this.filterType = '';
      });
    }

    this.LoadCustomers();
  }

  newEditCustomer(edit: boolean) {
    this.isReturn = true;
    this.EditCustomer = edit;
    this.results = false;
    this.isNewEdit = true;
    this.isView = true;
  }


  changeChannelUp(Id_Channel) {
    this.channelSelected = Id_Channel;
    this.LoadCustomers();
    this.returnToList();
  }

  returnToList() {
    // this.customerSelected = new Customer();
    this.isEdit = false;
    this.isView = false;
    this.results = true;
    this.isNewEdit = false;
    this.isReturn = false;
    this.idCust = 0;
    this.LoadCustomers();
  }

  onSearchChange(searchText) {
    this.searchKey = searchText;
    this.LoadCustomers();
    this.returnToList();
  }

  changeStatus(status: boolean) {
    this.customerSelected.Status = status ? 'A' : 'I';
  }

  changeStatusMFA(statusMFA: boolean) {
    this.customerSelected.MFA = statusMFA ? 'A' : 'I';
  }

  filterChannel(e) {
    if (e === 'O') {
      this.filterType = e;
    } else if (e === 'C') {
      this.filterType = e;
    } else if (e === 'E') {
      this.filterType = e;
    } else if (e === 'M') {
      this.filterType = e;
    } 
  }

  filterByChannel(e) {
    this.customerLst.Id_Channel = e;
  }

  filterByCustomer(e) {
    this.customerLst.Id_Customer = e;
  }

  updateStatus(e) {
    if (!e.target.checked) {
      this.customerLst.Status = 'I';
    } else {
      this.customerLst.Status = 'A';
    }
    this.LoadCustomers();
  }

  updateStatusMFA(e) {
    if (!e.target.checked) {
      this.customerLst.MFA = 'I';
    } else {
      this.customerLst.MFA = 'A';
    }
    this.LoadCustomers();
  }

  filterData() {
    this.LoadCustomers();
  }

  onSortedC(e) {
    this.customerLst.Order_Column = e.sortColumn;
  }

  onSortedA(e) {
    this.customerLst.Order_Dir = 'A';
    if (this.customerLst.Order_Column === 'cus' && this.customerLst.Order_Dir === 'A'){
      this.AZcus = true;
      this.isRest = true;
      this.ZAcus = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZcha = false;
      this.ZAcha = false;
      this.AZdat = false;
      this.ZAdat = false;
    }else if (this.customerLst.Order_Column === 'ema' && this.customerLst.Order_Dir === 'A'){
      this.AZcus = false;
      this.ZAcus = false;
      this.AZema = true;
      this.isRest = true;
      this.ZAema = false;
      this.AZcha = false;
      this.ZAcha = false;
      this.AZdat = false;
      this.ZAdat = false;
    }else if (this.customerLst.Order_Column === 'cha' && this.customerLst.Order_Dir === 'A'){
      this.AZcus = false;
      this.ZAcus = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZcha = true;
      this.isRest = true;
      this.ZAcha = false;
      this.AZdat = false;
      this.ZAdat = false;
    }else if (this.customerLst.Order_Column === 'dat' && this.customerLst.Order_Dir === 'A'){
      this.AZcus = false;
      this.ZAcus = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZcha = false;
      this.ZAcha = false;
      this.AZdat = true;
      this.isRest = true;
      this.ZAdat = false;
    }
    this.LoadCustomers();
  }

  onSortedD(e) {
    this.customerLst.Order_Dir = 'D';
    if (this.customerLst.Order_Column === 'cus' && this.customerLst.Order_Dir === 'D'){
      this.AZcus = false;
      this.ZAcus = true;
      this.isRest = true;
      this.AZema = false;
      this.ZAema = false;
      this.AZcha = false;
      this.ZAcha = false;
      this.AZdat = false;
      this.ZAdat = false;
    }else if (this.customerLst.Order_Column === 'ema' && this.customerLst.Order_Dir === 'D'){
      this.AZcus = false;
      this.ZAcus = false;
      this.AZema = false;
      this.ZAema = true;
      this.isRest = true;
      this.AZcha = false;
      this.ZAcha = false;
      this.AZdat = false;
      this.ZAdat = false;
    }else if (this.customerLst.Order_Column === 'cha' && this.customerLst.Order_Dir === 'D'){
      this.AZcus = false;
      this.ZAcus = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZcha = false;
      this.ZAcha = true;
      this.isRest = true;
      this.AZdat = false;
      this.ZAdat = false;
    }else if (this.customerLst.Order_Column === 'dat' && this.customerLst.Order_Dir === 'D'){
      this.AZcus = false;
      this.ZAcus = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZcha = false;
      this.ZAcha = false;
      this.AZdat = false;
      this.ZAdat = true;
      this.isRest = true;
    }
    this.LoadCustomers();
  }

  resetTable() {
      localStorage.removeItem('chaStoId');
      localStorage.removeItem('chaStoNa');
      localStorage.removeItem('cusStoId');
      localStorage.removeItem('paginationStorage');
      this.customerLst.Id_Channel = 0;
      this.customerLst.Id_Customer = 0;
      this.customerLst.Order_Column = '';
      this.customerLst.Order_Dir = '';
      this.customerLst.Status = '';
      this.customerLst.MFA = '';
      this.customerLst.Page = 1;
      this.customerLst.Pagination = 10;
      this.AZcus = false;
      this.ZAcus = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZcha = false;
      this.ZAcha = false;
      this.AZdat = false;
      this.ZAdat = false;
      this.StatusList = false;
      this.StatusListMFA = false;
      this.isRest = false;
    return this.LoadCustomers();
  }

  filterSel(e) {
    const ftro = Number(e);
    if (ftro === 1) {
      this.filterType = 'O';
    } else if (ftro === 2) {
      this.filterType = 'C';
    } else if (ftro === 3) {
      this.filterType = 'E';
    } else if (ftro === 4) {
      this.filterType = 'M';
    } 
  }

  changeAmountView(e) {
    this.customerLst.Pagination = e.target.value;
    this.LoadCustomers();
  }

  changePage(e) {
    this.customerLst.Page = e;
    this.LoadCustomers();
  }


  // ::::::::::::::::::::::::::::Metodos::::::::::::::::::::::::::::



  selectCustomerId(Id_Customer) {
    this.isReturn = true;
    this.viewCustomer = true;
    this.isView = true;
    this.results = false;
    this.idCust = Id_Customer;
  }


  // ::::::::::::::::::::::::::::Servicios::::::::::::::::::::::::::::

  LoadAllChannel() {
    this.spinner.show();
    this.services.Channel_All().subscribe(resp => {
      this.spinner.hide();

      if (resp.result) {
        this.listAllChannel = resp.data;
      }

    });
  }

  LoadCustomers() {
    this.spinner.show();
    this.services.Customer_List(this.customerLst).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.listCustomers = resp.data;
      }
      for (let i = 0; i < resp.data.length; i++) {
        this.totalPages = resp.data[i].Pages;
        this.collectionSize = resp.data[i].Pages * 10;
      }
      if (resp.data.length === 0) {
        this.totalPages = 1;
        this.collectionSize = 1;
        this.selectedPage = 1;
      }
    });
  }


}
