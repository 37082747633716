import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first, repeat } from 'rxjs/operators';
import { ServicesService } from 'src/app/data/services/services.service';
import { GlobalService } from 'src/app/global/global.service';
import { ToastrService } from 'ngx-toastr';
import { NewPass } from './new-security';

@Component({
  selector: 'app-new-security',
  templateUrl: './new-security.component.html',
  styleUrls: ['./new-security.component.css']
})
export class NewSecurityComponent implements OnInit {

  @Output() backLogin = new EventEmitter();

  // tslint:disable-next-line: new-parens
  newPass = new NewPass;

  newPassForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  fieldTextType: boolean;

  fieldTextTypeConfirm: boolean;

  email = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private allService: ServicesService,
    private globalService: GlobalService,
    private toastr: ToastrService
  ) {
    // redirect to home if already logged in
    // if (this.authenticationService.userValue) {
    //   this.router.navigate(['/']);
    // }
  }

  ngOnInit(): void {

    this.email = localStorage.getItem('email');
    this.newPass.Code = localStorage.getItem('Code');
    this.newPassForm = this.formBuilder.group({
      password: ['', Validators.required],
      passwordConfirm: ['', Validators.required]
    });

  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleFieldTextType1() {
    this.fieldTextTypeConfirm = !this.fieldTextTypeConfirm;
  }

  // convenience getter for easy access to form fields
  get f() { return this.newPassForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.newPassForm.invalid) {
      return;
    }

    this.loading = true;

    if (this.newPassForm.value.password !== this.newPassForm.value.passwordConfirm){
      this.toastr.warning('¡Las contraseñas no coinciden, intentalo nuevamente!', 'Alerta');
    } else {
      this.Password_Reset_NewService();
    }

    this.loading = false;
  }

  backToLogin() {
    this.backLogin.emit();
    localStorage.removeItem('email');
  }

  Password_Reset_NewService() {
    this.newPass.Password = this.newPassForm.value.password;
    this.newPass.PasswordConfirm = this.newPassForm.value.passwordConfirm;
    
    this.allService.Password_Reset_New(this.newPass).subscribe(resp => {
      if (this.globalService.verifyResponse(resp)) {
        if (resp.result === false){
          this.toastr.warning(resp.message, 'Alerta');
        } else {
          this.backToLogin();
          this.toastr.success('¡Contraseña cambiada correctamente!', 'Éxito');
          localStorage.removeItem('Code');
        }
      }
    });
  }

}
