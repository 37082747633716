export class User {
  Id_User: Number = 0;
  CustomerName: String = '';
  Crew: String = '';
  Status_Name: String = '';
  Id_Customer: Number = 0;
  Id_Company: Number = null;
  Id_Language: Number = 1;
  Id_Hierarchy: Number = 0;
  Hierarchy: string = '';
  Id_CreditorType: Number = 0;
  CreditorType: String = '';
  Status: String = 'I';
  Name: String = '';
  Img_Path: String = '';
  Email: String = '';
  CompanyName: String = '';
  Date_Add: String = '';
  Password: String = '';
  CellPhone: String = '';
  Multicompany: boolean = false;
  Id_Boss_User: Number = 0;
  Boss_User : string = '';
}

export class Application {
  AppToken: String = '';
  Id_Project: Number = 0;
  Id_Application: Number = 0;
  Maintenance_Percentage: Number = 0;
  Name: String = '';
  Profiles: any[] = [];
}

export class CompanyAccess {
  Id_Company: Number = 0;
  Id_Customer: Number = 0;
  IsSelected: Number = 0;

  Status: String = '';
  Name: String = '';
  Alter_Name: String = '';
  Logo_Path: String = '';
  RFC: String = '';
  StatusName: String = '';
  CardCode: String = '';

  edit: boolean = false;
}

export class Hierarchy {
  Id_Hierarchy: Number = 0;
  Description: string = '';
}

export class TypeEmployee {
  Id_CreditorType: Number = 0;
  Description: string = '';
}
