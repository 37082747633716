import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-tag-multiselect',
  templateUrl: './tag-multiselect.component.html',
  styleUrls: ['./tag-multiselect.component.css']
})
export class TagMultiselectComponent implements OnInit {

  @Output() idTagC = new EventEmitter<Number>();
  @Output() noSave = new EventEmitter();
  @Input() storage: boolean;
  @Input() idTagF: number;
  @Input() nameTagF: string;

  dropdownSettings: IDropdownSettings = {};
  listTags: any [];
  selectedItems: any = [];
  idTag = 0;

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
  ) { 
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'Id',
      textField: 'Value',
      searchPlaceholderText: 'Buscar:',
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
      enableCheckAll: true,
      defaultOpen: true
    };
  }

  ngOnInit(): void {
    if (localStorage.getItem('tagStoId') == null || localStorage.getItem('tagStoId') == undefined) {
      this.selectedItems = [];
    }else{
      this.selectedItems = [
        { Id: localStorage.getItem('tagStoId'), Value: localStorage.getItem('tagStoNa') },
      ];
    }
    if (this.idTagF && this.nameTagF) {
      this.selectedItems = [
        { Id: this.idTagF, Value: this.nameTagF },
      ];
    } 
    
    this.getTags();
  }

  onItemSelect(e) {
    this.idTag = e.Id;
    if (!this.storage) {
      localStorage.setItem('tagStoId', String(this.idTag));
      localStorage.setItem('tagStoNa', String(e.Value));
    }
    this.idTagC.emit(this.idTag);
  }

  onItemDeselect(e) {
    this.selectedItems = [];
    this.idTag = 0;
    localStorage.removeItem('tagStoId');
    localStorage.removeItem('tagStoNa');
    this.idTagC.emit(this.idTag);
    this.noSave.emit(true);
  }

  getTags() {
    this.services
      .post({
        entidad: 'Catalogs',
        funcion: 8,
        data: {
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.listTags = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

}
