import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FileUploader } from 'ng2-file-upload';
import { ServicesService } from 'src/app/data/services/services.service';
import { v4 as uuidv4 } from 'uuid';
import { Channel } from '../channels';
import { SelectedValue } from 'src/app/validators/selectedValue';

@Component({
  selector: 'app-detail-channel',
  templateUrl: './detail-channel.component.html',
  styleUrls: ['./detail-channel.component.css']
})
export class DetailChannelComponent implements OnInit {
  @Output() goBack = new EventEmitter();
  @Input() idChannel: number;
  @Input() newEdit: string;

  public editChannelSelected = new Channel();
  public List_Contacts = [];
  public listState = [];
  public listCountry = [];
  public List_Users_Ximplify = [];
  public form: UntypedFormGroup;

  isDetail = true;
  isEdit = false;

  uploaderImage: FileUploader = new FileUploader({
    isHTML5: true,
    queueLimit: 1
  });

  file: File;
  filesChange: FileList;
  fileNameChange = '';
  uploadFile: any;
  typeUploadUnicFile = '';
  fileInputDLV: any;
  imageUrl = '';
  idStore = '';
  imageHeader = '';
  imageFooter = '';
  idChan = '';
  isdetailsChannel = true;
  canva = 0;

  imagetoUpload = '';
  fileLogo: File;
  filesChangeLogo: FileList;
  fileNameChangeLo: string;
  @ViewChild('fileInputLogoUnic') fileInputLogoUnic;
  @ViewChild('imageInputUnic') imageInputUnic;
  @ViewChild('pdfInputUnic') pdfInputUnic;

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private services: ServicesService,
    private formBuilder: UntypedFormBuilder,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else
      if (localStorage.getItem('languaje') === 'en') {
        translate.use('en');
      } else {
        translate.setDefaultLang('es');
        localStorage.setItem('languaje', String('es'));
      }
  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      ChannelName: ['', { validators: [Validators.required] }],
      ContactPerson: ['', { validators: [Validators.required] }],
      Email: ['', { validators: [Validators.email, Validators.required] }],
      Phone: ['', { validators: [Validators.required] }],
      BusinessName: ['', { validators: [Validators.required] }],
      RFC: ['', { validators: [Validators.required] }],
      IdCountry: ['', { validators: [Validators.required, SelectedValue()] }],
      IdState: ['', { validators: [Validators.required, SelectedValue()] }],
      Id_User_Ximplify: ['', { validators: [Validators.required, SelectedValue()] }],
    });

    if (this.newEdit === 'N') {
      this.isdetailsChannel = true;
      this.isDetail = false;
      this.isEdit = true;
      this.fillContent();
    } else {
      this.isDetail = true;
      this.isdetailsChannel = true;
      this.isEdit = false;
      this.channelInfo();
    }

  }

  changeState(Id_State){
    this.editChannelSelected.Id_State = Id_State;
  }

  changeName(Name){
    this.editChannelSelected.Name = Name;
  }

  changeContact_Person(Contact_Person){
    this.editChannelSelected.Contact_Person = Contact_Person;
  }

  changePhone(Phone){
    this.editChannelSelected.Phone = Phone;
  }

  changeBusiness_Name(Business_Name){
    this.editChannelSelected.Business_Name = Business_Name;
  }

  changeEmail(Email){
    this.editChannelSelected.Email = Email;
  }

  changeRFC(RFC){
    this.editChannelSelected.RFC = RFC;
  }

  selectContractFile(event: any) {
    // tslint:disable-next-line: prefer-const
    let selectedFiles = event.target.files;
    this.editChannelSelected.ContractFile = selectedFiles.item(0);
  }

  selectLogoFile(event: any) {
    // tslint:disable-next-line: prefer-const
    let selectedFiles = event.target.files;
    this.editChannelSelected.LogoFile = selectedFiles.item(0);
  }

  fillContent() {
    this.loadCountries();
    this.loadXimplifyUsers();
    this.getChannelContacts();
  }

  fillForm() {
    this.form.get('ChannelName')?.setValue(this.editChannelSelected.Name);
    this.form.get('ContactPerson')?.setValue(this.editChannelSelected.Contact_Person);
    this.form.get('Email')?.setValue(this.editChannelSelected.Email);
    this.form.get('Phone')?.setValue(this.editChannelSelected.Phone);
    this.form.get('BusinessName')?.setValue(this.editChannelSelected.Business_Name);
    this.form.get('RFC')?.setValue(this.editChannelSelected.RFC);
    this.form.get('IdCountry')?.setValue(this.editChannelSelected.Id_Country);
    this.form.get('IdState')?.setValue(this.editChannelSelected.Id_State);
    this.form.get('Id_User_Ximplify')?.setValue(this.editChannelSelected.Id_User_Ximplify);
  }

  getErrorMessage(formulario: number) {

    if (formulario === 2) {

      // tslint:disable-next-line: prefer-const
      let ChannelName = this.form.get('ChannelName');
      if (ChannelName.hasError('required')) {
        return this.translate.instant('FORM.VALIDATORS.ChannelName');
      }

      // tslint:disable-next-line: prefer-const
      let ContactPerson = this.form.get('ContactPerson');
      if (ContactPerson.hasError('required')) {
        return this.translate.instant('FORM.VALIDATORS.BusinessContact');
      }

      // tslint:disable-next-line: prefer-const
      let Email = this.form.get('Email');
      if (Email.hasError('required')) {
        return this.translate.instant('FORM.VALIDATORS.Email');
      }

      // tslint:disable-next-line: prefer-const
      let Phone = this.form.get('Phone');
      if (Phone.hasError('required')) {
        return this.translate.instant('FORM.VALIDATORS.Name');
      }

      // tslint:disable-next-line: prefer-const
      let BusinessName = this.form.get('BusinessName');
      if (BusinessName.hasError('required')) {
        return this.translate.instant('FORM.VALIDATORS.BusinessName');
      }

      // tslint:disable-next-line: prefer-const
      let RFC = this.form.get('RFC');
      if (RFC.hasError('required')) {
        return this.translate.instant('FORM.VALIDATORS.RFC');
      }

      // tslint:disable-next-line: prefer-const
      let IdCountry = this.form.get('IdCountry');
      if (IdCountry.hasError('required') || IdCountry.hasError('selectedValue')) {
        return this.translate.instant('FORM.VALIDATORS.Country');
      }

      // tslint:disable-next-line: prefer-const
      let IdState = this.form.get('IdState');
      if (IdState.hasError('required') || IdState.hasError('selectedValue')) {
        return this.translate.instant('FORM.VALIDATORS.State');
      }

      // tslint:disable-next-line: prefer-const
      let Id_User_Ximplify = this.form.get('Id_User_Ximplify');
      if (Id_User_Ximplify.hasError('required') || Id_User_Ximplify.hasError('selectedValue')) {
        return this.translate.instant('FORM.VALIDATORS.Vendor');
      }

    }

    return '';
  }

  generateNewChannel(): void {

    this.isdetailsChannel = false;
    this.isEdit = false;
    this.isDetail = true;
    this.editChannelSelected = new Channel();
    this.getChannelContacts();
    this.loadCountries();
    this.loadXimplifyUsers();
    this.channelInfo();

  }

  saveImg(){
    this.canva = 1;
  }

  saveDoc(){
    this.canva = 2;
  }

  showDetails(Id_Channel) {
    if (this.newEdit === 'N'){
      this.goBack.emit();
    }else{
    this.isDetail = true;
    this.isEdit = false;
    this.idChan = Id_Channel;
    }
  }

  editChannel() {
    this.isEdit = true;
    this.isDetail = false;
    this.fillForm();
    this.fillContent();
    this.loadStates(this.editChannelSelected.Id_Country);
  }

  cancelEdit() {
      this.isEdit = false;
      this.isDetail = true;
      this.channelInfo();
  }

  cancelChannel() {
    if (this.newEdit === 'N'){
      this.goBack.emit();
    }else{
      this.isEdit = false;
      this.isDetail = true;
      this.channelInfo();
    }
  }

  return() {
    this.goBack.emit();
    this.channelInfo();
  }

  canceledit() {
    this.showDetails(this.editChannelSelected.Id_Channel);
  }

  changeCountry(Id_Country) {
    this.editChannelSelected.Id_Country = Id_Country;
    this.loadStates(Id_Country);
  }



  // Servicios

  getChannelContacts() {

    this.spinner.show();
    this.services.Channel_Contacts(this.editChannelSelected.Id_Channel).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        // tslint:disable-next-line: prefer-const
        let data = resp.data;

        if (Array.isArray(data) && data.length > 0) {
          this.editChannelSelected.Contacts = data;
        }
      }

    });

  }

  channelInfo() {
    this.spinner.show();
    this.services.Channel_Detail(this.idChannel).subscribe(resp => {

      this.spinner.hide();
      if (resp.result) {
        // tslint:disable-next-line: prefer-const
        let data = resp.data;

        if (Array.isArray(data) && data.length > 0) {

          this.editChannelSelected = data[0];
           this.getChannelContacts();
        }
      }
    });
  }

  loadCountries() {

    this.spinner.show();

    this.services.Catalog_Country().subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        // tslint:disable-next-line: prefer-const
        let data = resp.data;

        if (Array.isArray(data) && data.length > 0) {
          this.listCountry = data;
        }
      }
    });

  }

  loadStates(Id_Country) {
    this.spinner.show();

    this.services.Catalog_State(Id_Country).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        // tslint:disable-next-line: prefer-const
        let data = resp.data;

        if (Array.isArray(data) && data.length > 0) {
          this.listState = data;
        }
      }
    });

  }


  changeStatusChannelInt(activo, Id_Channel) {

    this.spinner.show();

    this.services.Channel_Update_Status(Id_Channel, (activo === true ? 'A' : 'I')).subscribe(resp => {
      this.spinner.hide();

      if (resp.result) {
        this.toastr.success('Canal ' + (activo === true ? '"Activado"' : '"Desactivado"') + ' correctamente');
      }

    });

  }
  saveNew() {
    // TO DO Validate new channel
    this.channel_New_Edit();

  }

  saveChanges(event) {
    this.fileLogo = event.target.files[0];
    this.filesChangeLogo = event.target.files;
    this.fileNameChangeLo = this.filesChangeLogo[0].name;
    let Logo_File = this.imageInputUnic;
    // tslint:disable-next-line: max-line-length
    if (this.fileInputLogoUnic !== undefined && this.fileInputLogoUnic.nativeElement !== undefined && this.fileInputLogoUnic.nativeElement.files[0] !== undefined) {
      Logo_File = this.fileInputLogoUnic.nativeElement;
    }

    let selectedFiles = event.target.files;
    let file = selectedFiles.item(0);
    // Revisar si se han cargado archivos para subirlos al S3
    if (this.editChannelSelected.LogoFile !== undefined || this.editChannelSelected.ContractFile !== undefined) {
      if (this.editChannelSelected.LogoFile !== undefined) {
        this.spinner.show();
        // tslint:disable-next-line: prefer-const
        let uuid = uuidv4();

        this.services.FileUpload_ImagesResources(this.editChannelSelected.LogoFile).subscribe(resp => {
          this.spinner.hide();
          if (resp.result) {
            this.editChannelSelected.Url_Logo = resp.data;
            this.channel_New_Edit();
          }
        });
      }

      if (this.editChannelSelected.ContractFile !== undefined) {
        this.spinner.show();
        // tslint:disable-next-line: prefer-const
        let uuid = uuidv4();
        this.services.FileUpload_ImagesResources(this.editChannelSelected.ContractFile).subscribe(resp => {
          this.spinner.hide();
          if (resp.result) {
            this.editChannelSelected.Url_Contract = resp.data;
            this.channel_New_Edit();
          }
        });
      }
    }
    else {
      this.channel_New_Edit();
    }
    this.channel_New_Edit();
  }

  saveChangesForm() {
    this.channel_New_Edit();
  }


  channel_New_Edit() {
    this.spinner.show();
    this.services.Channel_New_Edit(this.editChannelSelected).subscribe(resp => {

      this.spinner.hide();
      if (resp.result) {
        if (this.editChannelSelected.Id_Channel !== 0) {
          this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));
          this.channelInfo();
        }
        else {
          this.toastr.success('Guardado correctamente');
        }

        this.showDetails(this.editChannelSelected.Id_Channel);
      }
    });

  }

  loadXimplifyUsers() {

    this.spinner.show();
    this.services.Catalog_Ximplify_Users().subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        // tslint:disable-next-line: prefer-const
        let data = resp.data;

        if (Array.isArray(data) && data.length > 0) {
          this.List_Users_Ximplify = data;
        }
      }
    });

  }

  changeSeller(e) {

    this.editChannelSelected.Id_User_Ximplify = e;
    // tslint:disable-next-line: prefer-const
    let ximplifyUser: any = this.List_Users_Ximplify.filter(x => x.Id_User === e);

    if (ximplifyUser !== undefined) {
      this.editChannelSelected.Seller = ximplifyUser[0].Name;
    }

  }

}
