import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { crewData } from 'src/app/data/models/crew-data.model';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-users-groups',
  templateUrl: './users-groups.component.html',
  styleUrls: ['./users-groups.component.css']
})
export class UsersGroupsComponent implements OnInit {
  @Input() id;
  crew: crewData = new crewData();

  isNew = false;
  SelectDrop = '';
  UsersGroup: any[] = [];
  searchUser = '';
  Page = 1;
  Pagination = 10;
  selectedPage: any = 1;
  collectionSize = 0;
  totalPages = 0;
  isStatusUser = false;
  statusUser = '';
  idCustomer = 0;
  statusFilter = false;
  usersActive = false;

  constructor(
    private services: ServicesService,
    private toastr: ToastrService,
    private translate: TranslateService) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
    if (localStorage.getItem('destiny') === 'N') {
      this.isNew = true;
    } else {
      this.isNew = false;
    }
    translate.get('GENERAL.selectcompany').subscribe((res: string) => {
      this.SelectDrop = res;
    });
  }

  ngOnInit(): void {
    this.crewGet();
  }

  updateStatus(e) {
    if (!e.target.checked) {
      this.statusUser = 'I';
    } else {
      this.statusUser = 'A';
    }
    this.crewGet();
  }

  clearStatus() {
    this.statusUser = '';
    this.isStatusUser = false;
    this.crewGet();
  }

  onSearchUser(e: any) {
    if (e.target.value !== '') {
      this.searchUser = e.target.value;
      this.crewGet();
    }
    else {
      this.crewGet();
    }
  }

  changeAmountView(e) {
    this.Pagination = e.target.value;
    this.crewGet();
  }

  changePage(e) {
    this.Page = e;
    this.crewGet();
  }

  compare(a: Number | String | Date, b: Number | String | Date, isAsc: Boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onSorted(sort) {

    let data = this.UsersGroup.slice();

    let sortedData = data.sort((a, b) => {
      const isAsc = sort.sortDirection === 'asc';
      switch (sort.sortColumn) {
        case 'User_Name': return this.compare(a.UserName, b.UserName, isAsc);
        case 'Company_Name': return this.compare(a.CompanyName, b.CompanyName, isAsc);
        default: return 0;
      }
    });

    this.UsersGroup = sortedData;
  }

  // Servicios

  crewGet() {
    this.services.post({
      entidad: 'Crew',
      funcion: 2,
      data: {
        Id_Crew: this.id
      }
    }).subscribe(result => {
      this.crew = result.data;
      this.idCustomer = this.crew.Id_Customer;
      this.getUsersToGroup(this.crew.Id_Customer);
    }, error => console.log(error), () => { });
  }

  getUsersToGroup(Id_CustomerG) {
    this.services.post({
      entidad: 'Crew',
      funcion: 20,
      data: {
        SearchKey: this.searchUser,
        Id_Crew: this.id,
        Id_Customer: Id_CustomerG,
        Status_User_Crew: this.statusUser,
        Page: this.Page,
        Pagination: this.Pagination
      }
    }).subscribe(result => {
      this.UsersGroup = result.data;
      this.UsersGroup.length > 0 ? this.usersActive = true : this.usersActive = false;

      for (let i = 0; i < result.data.length; i++) {
        this.totalPages = result.data[i].Pages;
        this.collectionSize = result.data[i].Pages * 10;
      }
      if (result.data.length === 0) {
        this.totalPages = 1;
        this.collectionSize = 1;
        this.selectedPage = 1;
      }
    });
  }

  activeUser(active: boolean, Id_User: Number, Id_Crew: Number, Id_User_Crew: number) {
    this.services.post({
      entidad: 'Crew',
      funcion: 21,
      data: {
        Id_User_Crew: Id_User_Crew,
        Id_User: Id_User,
        Id_Crew: Id_Crew,
        Status: active ? 'A' : 'I'
      }
    }).subscribe(result => {
      this.toastr.success('Actualizado Correctamente', 'Grupos');
    });
  }

}
