import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CompanyList } from 'src/app/data/models/company-list.model';
import { CustomerList } from 'src/app/data/models/customer-list.model';
import { ServicesService } from 'src/app/data/services/services.service';
import { Customer } from '../../customer/customer';
import { CompanyEdit, TaxRegime } from '../companies';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-general-company',
  templateUrl: './general-company.component.html',
  styleUrls: ['./general-company.component.css']
})
export class GeneralCompanyComponent implements OnInit {

  @Output() goBack = new EventEmitter();
  @Output() updateName = new EventEmitter();
  @Input() newEdit: boolean;
  @Input() idCompany;

  // dropdownSettingsCus: IDropdownSettings = {};
  customerLst = new CustomerList();
  companyLst = new CompanyList();
  selectedItems: any = [];
  public companieSelected = new CompanyEdit();
  public listCustomer: Customer[] = [];
  public listTaxReg: TaxRegime[] = [];
  public listCompanies = [];
  // lstCustomerGet: any[] = [];

  viewCompanie = true;
  isNewEdit = false;
  isEdit = false;
  results = true;
  isView = false;
  titleNewEditCompanie = '';
  public UserInfo: any;

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private services: ServicesService
  ) {

    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
    // this.dropdownSettingsCus = {
    //   singleSelection: true,
    //   idField: 'Id_Customer',
    //   textField: 'Name',
    //   searchPlaceholderText: 'Buscar:',
    //   allowSearchFilter: true,
    //   closeDropDownOnSelection: true,
    //   clearSearchFilter: true,
    //   enableCheckAll: true,
    //   defaultOpen: true
    // };
  }
  ngOnInit(): void {
    // if (this.companieSelected.Id_Customer === 0) {
    //   this.selectedItems = [];
    // }
    if (this.newEdit === false && this.idCompany === 0) {
      this.viewCompanie = false;
      this.isNewEdit = true;
      this.results = false;
      this.isView = true;
      this.List_Tax_Regime();
    } else {
      this.isEdit = true;
      this.titleNewEditCompanie = 'GENERAL.edit';
      this.companyById();
    }
  }

  companyById() {
    this.spinner.show();
    this.services.Company_ById(this.idCompany).subscribe(resp => {

      if (resp.result) {
        this.companieSelected = resp.data;
      }
      this.spinner.hide();
    });
  }

  List_Tax_Regime() {
    this.spinner.show();
    this.services.Catalog_Tax_Regime().subscribe(resp => {
      this.spinner.hide();
      if (resp.result){
        this.listTaxReg = resp.data;
      }
    });
  }

  newEditCompanie(edit) {
    this.viewCompanie = false;
    this.isNewEdit = true;
    this.results = false;
    this.isView = true;
    this.List_Tax_Regime();

    if (edit) {
      this.isEdit = true;
      this.titleNewEditCompanie = 'GENERAL.edit';
    } else {
      this.isEdit = false;
      this.titleNewEditCompanie = 'GENERAL.new2';
      this.companieSelected = new CompanyEdit();
      this.companieSelected.Id_Customer = this.UserInfo.Id_Customer;
    }
  }

  // onItemSelect(e) {
  //   this.companieSelected.Id_Customer = e.Id_Customer;
  // }

  // onItemDeselect(e) {
  //   this.selectedItems = [];
  //   this.companieSelected.Id_Customer = 0;
  //   this.companieSelected.NameCustomer = '';
  // }

  cancelCompanie() {
    this.returnToView();
  }

  sendCustomer(e) {
    this.companieSelected.Id_Customer = e;
  }

  returnToView() {
    this.isView = false;
    this.isNewEdit = false;
    this.isView = false;
    this.results = true;
    this.viewCompanie = true;
    if (this.idCompany === 0) {
      this.goBack.emit();
    }
  }

  LoadAllCustomers() {
    this.spinner.show();
    this.services.Customer_List(this.customerLst).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.listCustomer = resp.data;
      }
    });
  }

  changeCompanyMFA(status: boolean) {
    if (status){
      this.companieSelected.MFA = 'A';
    }else{
      this.companieSelected.MFA = 'I';
    }
  }

  changeCompanyStatus(status: boolean) {
    if (status){
      this.companieSelected.Status = 'A';
    }else{
      this.companieSelected.Status = 'I';
    }
  }

  cancelEdit() {
    this.viewCompanie = true;
    this.isView = true;
    this.isNewEdit = false;
    this.isEdit = false;
    this.companyById();
  }

  Save_New_Company() {

    if (this.companieSelected.Id_Customer === 0) {
      this.toastr.warning(this.translate.instant('GENERAL.selectorganization'));
      return;
    }
    if (this.companieSelected.Id_Tax_Regime === '0') {
      this.toastr.warning(this.translate.instant('GENERAL.selectTaxReg'));
      return;
    }
    this.spinner.show();
    this.services.Company_New_Edit(this.companieSelected).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.viewCompanie = true;
        this.isNewEdit = false;
        this.toastr.success(this.translate.instant('CODEALERTS.coda12'));
        this.updateName.emit();
        this.cancelCompanie();
      }
    });
    // this.companyById();
  }

  // loadCustomers() {
  //   this.services
  //     .post({
  //       entidad: 'Customer',
  //       funcion: 3,
  //       data: {
  //         Page: -1
  //       },
  //     })
  //     .subscribe(
  //       (result) => {
  //         if (result.code === 1) {
  //           this.lstCustomerGet = result.data;
  //         }
  //       },
  //       (error) => {
  //         console.log('Error', error);
  //       }
  //     );
  // }

}
