<form [formGroup]="codePassRecovery" (ngSubmit)="onSubmit()" class="form-signin">
    <h1 class="h3 mb-4 font-weight-normal mt-3" style="text-align: center"> Recupera tu contraseña
    </h1>

    <p class="h5 mb-4 font-weight-normal mt-3" style="text-align: center"> Ingresa el código que se envió a tu email
    </p>

    <div class="form-group">
        <label for="code">Código</label>
        <input type="text" formControlName="code" placeholder="Ingresa código" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.code.errors }" />

            
        <div *ngIf="submitted && f.code.errors" class="invalid-feedback">
            <div *ngIf="f.code.errors.required">El código es requerido</div>
        </div>
    </div>

    <div class="d-flex mt-4 btn-group mb-2" role="group" aria-label="Botones">
        <button type="button" class="btn btn-primary btnLogin" (click)="backToLogin()" data-bs-toggle="tooltip" data-bs-placement="top" title="Regresar">
            <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
            <i class="fa-solid fa-arrow-left"></i> Regresar
        </button>
       
        <button [disabled]="loading" class="btn btn-success mt-0 flex-grow-1 btnLogin" type="submit">
                <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
                <i class="far fa-arrow-alt-circle-right"></i>
                Comprobar código
        </button>
    </div>
    <!-- <button [disabled]="loading" class="btn btn-success btn-block mt-3 btnLogin" type="submit">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        <i class="far fa-arrow-alt-circle-right"></i>
        Comprobar código
    </button>

    <hr>

    <div class="row justify-content-between">
        <div class="col- ml-2">
            <a (click)="backToLogin()" href="javascript:void(0)" id="cancel_reset"><i class="fas fa-angle-left"></i>
                Regresar</a>
        </div>
    </div> -->
</form>