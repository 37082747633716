import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first, repeat } from 'rxjs/operators';
import { ServicesService } from 'src/app/data/services/services.service';
import { GlobalService } from 'src/app/global/global.service';
import { ToastrService } from 'ngx-toastr';
import { NewPass } from './new-user-security';

@Component({
  selector: 'app-new-user-security',
  templateUrl: './new-user-security.component.html',
  styleUrls: ['./new-user-security.component.css']
})
export class NewUserSecurityComponent implements OnInit {

  @Output() backLogin = new EventEmitter();

  // tslint:disable-next-line: new-parens
  newPass = new NewPass;

  newPassForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  fieldTextType: boolean;

  fieldTextTypeConfirm: boolean;

  email = '';

  loginToken = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private allService: ServicesService,
    private globalService: GlobalService,
    private toastr: ToastrService
  ) {
    // redirect to home if already logged in
    // if (this.authenticationService.userValue) {
    //   this.router.navigate(['/']);
    // }
  }

  ngOnInit(): void {

    this.loginToken = localStorage.getItem('loginToken');

    this.email = localStorage.getItem('email');
    this.newPassForm = this.formBuilder.group({
      password: ['', Validators.required],
      passwordConfirm: ['', Validators.required]
    });

  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  toggleFieldTextType1() {
    this.fieldTextTypeConfirm = !this.fieldTextTypeConfirm;
  }

  // convenience getter for easy access to form fields
  get f() { return this.newPassForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.newPassForm.invalid) {
      return;
    }

    this.loading = true;

    if (this.newPassForm.value.password !== this.newPassForm.value.passwordConfirm) {
      this.toastr.warning('¡Las contraseñas no coinciden, intentalo nuevamente!', 'Alerta');
    } else {
      this.Password_First_Reset_New_ByTokenService();
    }

    this.loading = false;
  }

  backToLogin() {
    this.backLogin.emit();
    localStorage.removeItem('email');
  }

  Password_First_Reset_New_ByTokenService() {
    this.newPass.password = this.newPassForm.value.password;
    this.newPass.passwordConfirm = this.newPassForm.value.passwordConfirm;
    let JWT = localStorage.getItem('bearerToken');

    this.allService.Password_First_Reset_New_ByToken(this.newPass, JWT).subscribe(resp => {
      if (this.globalService.verifyResponse(resp)) {
        if (resp.result === false) {
          this.toastr.warning(resp.message, 'Alerta');
        } else {
          this.backToLogin();
          this.toastr.success('¡Contraseña cambiada correctamente!', 'Éxito');
          localStorage.removeItem('loginToken');
        }
      }
    });
  }

}
