<div class="row animate__animated animate__flip">
    <div class="col">
        <div class="row mt-1 justify-content-between">
            <div class="col-auto">
                <div class="row">
                    <div class="col-auto tittleKPICard">
                        <h2 class="mb-0">{{infoNewKPI.Title}}</h2>
                        <h2 class="mb-0">{{Titulo}}</h2>
                        <small>{{proyecto}}</small>
                    </div>

                    <div class="col-auto">
                        <h5>
                            <span *ngIf="infoNewKPI.Status === 'A' || Status === 'A'" class="badge badge-success">Status: Activo</span>
                            <span *ngIf="infoNewKPI.Status === 'I' || Status === 'I'" class="badge badge-warning">Status: Inactivo</span>
                        </h5>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-auto closeBtn">
                <div class="col-auto mt-4">
                    <button class="close">
            <i class="far fa-times-circle" (click)="closeCb()"></i>
          </button>
                </div>
            </div>
        </div>
        <hr class="mt-2 mb-1" />
        <div class="row mt-0">
            <div class="col-auto">
                <div class="form-group">
                    <label for="typeFilters" class="subTittleCard">Filtros</label>
                </div>
            </div>
        </div>
        <div class="row">
            <ng-container *ngFor="let f of filtersData">
                <div class="col-auto">
                    <label for="{{f.NameFilter}}" class="mb-0 labelText">{{f.NameFilter}}<span *ngIf="f.Validators === 'V'"  class="asteri"><i class="fa fa-asterisk" ></i></span></label>
                    <ng-container *ngIf="f.Type === 'CMB'">
                        <select [(ngModel)]="f.Value" (change)="selectOption($event, f)" [required]="f.Validators === 'V'" class="form-control" id="{{f.NameFilter}}">
              <option value="">Selecciona una opción</option>
              <option *ngFor="let opc of f.Data" value="{{opc.Id}}">
                {{ opc.Value }}
              </option>
            </select>
                    </ng-container>
                    <ng-container *ngIf="f.Type === 'DAT'">
                        <input type="date" (change)="selectDate($event, f)" class="form-control" [(ngModel)]="f.Value" [required]="f.Validators === 'V'" id="date">
                    </ng-container>
                    <ng-container *ngIf="f.Type === 'TXT'">
                        <input type="text" (change)="changeData($event, f)" class="form-control" [(ngModel)]="f.Value" [required]="f.Validators === 'V'" id="text">
                    </ng-container>
                    <ng-container *ngIf="f.Type === 'NUM'">
                        <input type="number" (change)="changeData($event, f)" class="form-control" [(ngModel)]="f.Value" [required]="f.Validators === 'V'" id="number">
                    </ng-container>
                    <ng-container *ngIf="f.Type === 'CHK'">
                        <label class="switch mx-2">
              <input (change)="checkOption($event, f)" [checked]="f.Value === 'true'" name="status" type="checkbox" [required]="f.Validators === 'V'" id="status" />
              <span class="slider round-in"></span>
            </label>
                    </ng-container>


                </div>
            </ng-container>

        </div>
        <div class="row mt-3">
            <div class="col-auto">
                <button class="btn btn-primary succesBtn" (click)="addNewFilters()"><i class="fas fa-filter"></i> Filtrar</button>
            </div>
        </div>
    </div>
</div>