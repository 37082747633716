import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css'],
})
export class GraphComponent implements OnInit {
  @Output() viewL = new EventEmitter();
  @Output() configKPI = new EventEmitter();
  @Output() addNewF = new EventEmitter();
  @Output() delKPI = new EventEmitter();
  @Output() dupKPI = new EventEmitter();
  @Input() dtlofKPI: any;
  @Input() indexKpi: any;

  idKpi = 0;
  infoKpi = '';
  infoData = '';

  ltsKpiExist = [];
  barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
  };

  barChartLabels: Label[] = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];
  barChartData: ChartDataSets [];

  // chartData: ChartDataSets[] = [
  //   { data: [2500, 5900, 6000, 8100, 8600, 8050, 1200], label: 'Company A' },
  //   { data: [2800, 4800, 4000, 7900, 9600, 8870, 1400], label: 'Company B' }
  // ];


  public chartData: any = [
     { data: 0, label: 'prueba 1' }
    //   { data: [2500], label: 'Company A' },
    // { data: [2800], label: 'Company B' }
  ];

  public ChartColors = [
    {
      backgroundColor: ['#B13D80', '#3DB1A4', '#1CACED', '#775e98', '#B13D80', '#3DB1A4', '#1CACED', '#775e98'],
    },
  ];

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    this.barChartType = this.dtlofKPI.TipoChart;
    this.serviceGraph();
    console.log(this.chartData);
  }

  dupItem(e) {
    this.dupliKpiGraph();
  }
  delItem(id) {
    this.idKpi = id;
    this.delKPIsel();
  }

  viewLogH(e) {
    this.viewL.emit({ e, position: this.indexKpi });
  }

  addNewConfig(e) {
    this.configKPI.emit({ e, position: this.indexKpi });
  }

  addNewCf(e) {
    this.addNewF.emit({ e, position: this.indexKpi });
  }

  delConfirmSel(dt: any) {
    this.idKpi = dt.Id_KPI;
    Swal.fire({
      title: '¿Estás seguro de querer eliminar este KPI?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#746AB0',
      cancelButtonColor: '#E83845',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.delKPIsel();
      }
    });
  }

  // Servicios
  serviceGraph() {
    this.services
      .post({
        entidad: 'KPIs',
        funcion: 10,
        data: {
          id_kpi: this.dtlofKPI.Id_KPI,
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.chartData = [];
            this.barChartLabels = [];
            let values = [];
            for (let i = 0; i < result.data[0].Detail?.length; i++){
              if(result.data === 'bar' || 'line'){
                values.push(+result.data[0].DataChart[i].datasets);
              this.barChartLabels.push(result.data[0].DataChart[i].labels);
              }
            }
            this.chartData.push({data: values, label: this.dtlofKPI.Descripcion});
            console.log(this.chartData);
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

  dupliKpiGraph() {
    this.services
      .post({
        entidad: 'KPIs',
        funcion: 13,
        data: {
          id_kpi: this.dtlofKPI.Id_KPI,
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.toastr.success('Se duplico correctamente', 'KPIs');
            this.dupKPI.emit();
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

  delKPIsel() {
    this.services
      .post({
        entidad: 'KPIs',
        funcion: 14,
        data: {
          id_kpi: this.dtlofKPI.Id_KPI,
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.toastr.success('Se borro correctamente', 'KPIs');
            this.delKPI.emit();
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }
}
