import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyAccess, User } from '../users';

@Component({
  selector: 'app-my-tab-user',
  templateUrl: './my-tab-user.component.html',
  styleUrls: ['./my-tab-user.component.css']
})
export class MyTabUserComponent implements OnInit {

  @Output() goBack = new EventEmitter();
  @Input() id_User: Number;
  isGeneralView = true;
  isProfileView = false;
  isCompanyView = false;
  isGroupView = false;

  public isNew: boolean = false;
  
  constructor(
  ) { }

  ngOnInit(): void {
    if (this.id_User === 0) 
    { this.isNew = false;
    } else { 
      this.isNew = true; }
  }

  ViewGeneral(){
    this.isGeneralView = true;
    this.isProfileView = false;
    this.isCompanyView = false;
    this.isGroupView = false;
  }

  ViewProfile(){
    this.isGeneralView = false;
    this.isProfileView = true;
    this.isCompanyView = false;
    this.isGroupView = false;
  }

  ViewCompany(){
    this.isGeneralView = false;
    this.isProfileView = false;
    this.isCompanyView = true;
    this.isGroupView = false;
  }

  ViewGroup(){
    this.isGeneralView = false;
    this.isProfileView = false;
    this.isCompanyView = false;
    this.isGroupView = true;
  }

  returnToList() {
    this.goBack.emit();
  }

}
