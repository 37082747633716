<select [(ngModel)]="typeFilCom" (change)="selectFilter($event)" class="form-select form-select-sm" aria-label=".form-select-sm example">
    <option value="">Selecciona filtro:</option>
    <option value="1">
        Compañía
    </option>
    <option value="2">
        Organización
    </option>
    <option value="3">
        Estatus
    </option>
</select>