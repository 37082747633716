import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { SortService } from './sort.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[sortable-column]',
    templateUrl: './sortable-column.component.html'
})
export class SortableColumnComponent implements OnInit, OnDestroy {

    constructor(private sortService: SortService) { }

    // tslint:disable-next-line:no-input-rename
    @Input('sortable-table')
    tableName: String;

    // tslint:disable-next-line:no-input-rename
    @Input('sortable-column')
    columnName: String;

    // tslint:disable-next-line:no-input-rename
    @Input('sort-direction')
    sortDirection: String = '';

    private columnSortedSubscription: Subscription;

    @HostListener('click')
    sort() {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
        this.sortService.columnSorted({ sortTable: this.tableName, sortColumn: this.columnName, sortDirection: this.sortDirection });
    }

    ngOnInit() {
        // subscribe to sort changes so we can react when other columns are sorted
        this.columnSortedSubscription = this.sortService.columnSorted$.subscribe(event => {
            // reset this column's sort direction to hide the sort icons
            if (this.tableName === event.sortTable) {
                if (this.columnName !== event.sortColumn) {
                    this.sortDirection = '';
                }
            }
        });
    }

    ngOnDestroy() {
        this.columnSortedSubscription.unsubscribe();
    }



}
