
<!-- <div class="form-menu">
    
    <h1 class="h3 mb-4 font-weight-normal mt-3 text-black" style="text-align: center"> Selecciona una applicación</h1>

    <div class="row menu-cont pb-4">
        <div *ngFor="let app of lstApp" class="col-auto px-1 mt-2 btnMnu text-center">
            <a href="javascript:void(0)" (click)="selApp(app.id)">
                <div class="card p-2">
                    <div class="row justify-content-center align-items-center h-100">
                        <div class="col-">
                            <div class="iconColor">
                                <img src="{{app.image}}" />
                            </div>
                            <p class="mb-0 mt-2">{{app.text}}</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>

    <button class="btn btn-success btn-block btnLogin mt-3 backButton" type="button" (click)="backstep()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        <i class="far fa-arrow-alt-circle-left"></i> Regresar
    </button>
  
</div> -->

<div class="form-menu d-flex flex-column align-items-center justify-content-center mt-5">
    <span class="version" >Versión {{appVersion}}</span>
    <img class="logo"  src="https://xam-app.s3.us-west-2.amazonaws.com/images/simplesuit/ss-sinslogan-simple-suite.png" />
    <h1 class="h5 mb-4 font-weight-normal mt-3 text-gray" style="text-align: center">Selecciona una aplicación</h1>

    <div class="row menu-cont pb-4">
        <div *ngFor="let app of lstApp" class="col-md-4 px-1 mt-2 btnMnu text-center">
            <a href="javascript:void(0)" (click)="selApp(app.id)">
                <div class="card p-3">
                    <div class="row justify-content-center align-items-center h-100">
                        <div class="col-">
                            <div class="iconColor">
                                <img src="{{app.image}}" />
                            </div>
                            <p class="mb-0 mt-2">{{app.text}}</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>

    <button class="btn btn-success btn-block btnLogin mt-3 backButton" type="button" (click)="backstep()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        <i class="fas fa-share-alt mr-3"></i>Ambientes
    </button>
</div>
