import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from '../data/services/services.service';

@Component({
  selector: 'app-account',
  templateUrl: './app-account.component.html',
  styleUrls: ['./app-account.component.css']
})
export class AppAccountComponent implements OnInit {

  loginToken: string = "";

  constructor(
    private route: ActivatedRoute,
    private services: ServicesService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {

      if (params.has("ut") && params.has("amb") && params.has("at")) {

        let userToken = params.get("ut");
        let enviroment = parseInt(params.get("amb"));
        let appToken = params.get("at");

        localStorage.setItem("amb", enviroment.toString());

        this.services.Get_JWT(userToken, appToken).subscribe(result => {
          if (result.result) {
            localStorage.setItem("loginToken", result.message);
            this.router.navigateByUrl('/account');
          }
          else {
            this.toastr.error(result.message);
            this.router.navigateByUrl('/');
          }
        },
          (error) => {
            this.router.navigateByUrl('/');
          }
        );
      }

    });

  }

}
