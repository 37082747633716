import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FileUploader } from 'ng2-file-upload';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import { CompanyEdit } from '../companies';

@Component({
  selector: 'app-logo-company',
  templateUrl: './logo-company.component.html',
  styleUrls: ['./logo-company.component.css']
})
export class LogoCompanyComponent implements OnInit {

  @Input() idCompany;

  public companieSelected = new CompanyEdit();

  imagetoUpload = '';
  fileLogo: File;
  filesChangeLogo: FileList;
  fileNameChangeLogo: string;
  @ViewChild('fileInputLogoUnic') fileInputLogoUnic;
  @ViewChild('logoinput') logoinput;
  @ViewChild('fileInput') fileInput;
  @ViewChild('fileInput2') fileInput2;
  @ViewChild('fileInput3') fileInput3;
  @ViewChild('fileInput4') fileInput4;

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private services: ServicesService
  ) {

    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }

  }

  ngOnInit(): void {
    this.companyById();
  }

  getNumber(){}

  companyById() {
    this.spinner.show();
    this.services.Company_ById(this.idCompany).subscribe(resp => {
      if (resp.result) {
        this.companieSelected = resp.data;
      }
      this.spinner.hide();
    });
  }

  LoadCompanyLogo(event) {
    this.fileLogo = event.target.files[0];
    this.filesChangeLogo = event.target.files;
    this.fileNameChangeLogo = this.filesChangeLogo[0].name;
    let Logo_File = this.logoinput;
    // tslint:disable-next-line: max-line-length
    if (this.fileInputLogoUnic !== undefined && this.fileInputLogoUnic.nativeElement !== undefined && this.fileInputLogoUnic.nativeElement.files[0] !== undefined) {
      Logo_File = this.fileInputLogoUnic.nativeElement;
    }

    let selectedFiles = event.target.files;
    let file = selectedFiles.item(0);
    if (file != undefined) {
      this.spinner.show();
      this.services.FileUpload_ImagesResources(file).subscribe(resp => {
        this.spinner.hide();
        if (resp.result) {
          let urlLogo = resp.data;
          this.services.Company_Logo_Edit(this.idCompany, urlLogo).subscribe(resp => {
            if (resp.result) {
              this.toastr.success(this.translate.instant('CODEALERTS.coda14'));
              this.companieSelected.Logo_Path = urlLogo;
            }
          });
        }
      });
    }
  }

}
