import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  isTitle = 'Menus';
  showPrincipal = true;
  isReturn = false;
  isEdit = false;
  isView = false;
  idMenu = 0;
  searchTerm = '';
  listMenus = [];
  listMenusFather = [];
  nameColumn = '';
  orderColumn = '';
  orderDir = '';
  selectedPage: any = 1;
  collectionSize = 0;
  totalPages = 0;
  idApp = 0;
  idTag = 0;
  idMenuFather = null;
  page = 1;
  pagination = 10;
  statusMenu = true;
  labels = [];
  titleMenu = '';
  language = 0;

  azIdx = false;
  zaIdx = false;
  azMen = false;
  zaMen = false;
  azApp = false;
  zaApp = false;
  azTag = false;
  zaTag = false;
  azFat = false;
  zaFat = false;
  azAct = false;
  zaAct = false;
  isRest = false;

  constructor(
    private services: ServicesService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
    if (localStorage.getItem('appStoId') !== null) {
      this.idApp = +localStorage.getItem('appStoId');
    }
    if (localStorage.getItem('tagStoId') !== null) {
      this.idTag = +localStorage.getItem('tagStoId');
    }
    if (localStorage.getItem('menuStoId') !== null) {
      this.idMenuFather = +localStorage.getItem('menuStoId');
    }
    if (localStorage.getItem('paginationStorage') !== null) {
      this.pagination = +localStorage.getItem('paginationStorage');
    }
   }

  ngOnInit(): void {
    const myOffcanvas = document.querySelector('#offcanvasRight') as any;
    if (myOffcanvas !== null) {
      myOffcanvas.addEventListener('hidden.bs.offcanvas', () => {
        this.nameColumn = '';
      });
    }

    this.getMenus();
  }

  returnToList() {
    this.showPrincipal = true;
    this.isEdit = false;
    this.isView = false;
    this.isReturn = false;
    this.idMenu = 0;
    return this.getMenus();
  }

  searchInput(e: any) {
    if (e.target.value !== '') {
      this.searchTerm = e.target.value;
      this.getMenus();
    }
    else {
      this.getMenus();
    }
  }

  createNewMenu() {
    this.isReturn = true;
    this.isEdit = true;
    this.isView = true;
    this.showPrincipal = false;
  }

  selectMenu(Id_Menu) {
    this.isReturn = true;
    this.isView = true;
    this.showPrincipal = false;
    this.idMenu = Id_Menu;
  }

  filterByApp(e) {
    this.idApp = e;
  }

  filterByTag(e) {
    this.idTag = e;
  }

  filterByFather(e) {
    this.idMenuFather = e;
  }

  filterData() {
    this.getMenus();
  }

  selectColumn(column) {
    if (column === 'A') {
      this.nameColumn = column;
    } else if (column === 'T') {
      this.nameColumn = column;
    } else if (column === 'F') {
      this.nameColumn = column;
    } else if (column === 'S') {
      this.nameColumn = column;
    } 
  }

  selectFilter(e) {
    const ftro = Number(e);
    if (ftro === 1) {
      this.nameColumn = 'A';
    } else if (ftro === 2) {
      this.nameColumn = 'T';
    } else if (ftro === 3) {
      this.nameColumn = 'F';
    } else if (ftro === 4) {
      this.nameColumn = 'S';
    }
  }

  // selectLanguage(e) { 
  //   this.language = e;
  //   this.getMenus();
  // }

  updateStatus(e) {
    if (!e.target.checked) {
      this.statusMenu = false;
    } else {
      this.statusMenu = true;
    }
    this.getMenus();
  }

  changeAmountView(e) {
    this.pagination = e.target.value;
    this.getMenus();
  }

  changePage(e) {
    this.page = e;
    this.getMenus();
  }

  onSorted(e) {
    this.orderColumn = e.sortColumn;
  }

  onSortedA(e) {
    this.orderDir = 'A';
    if (this.orderColumn === 'mnu' && this.orderDir === 'A'){
      this.azIdx = false;
      this.zaIdx = false;
      this.azMen = true;
      this.zaMen = false;
      this.azApp = false;
      this.zaApp = false;
      this.azTag = false;
      this.zaTag = false;
      this.azFat = false;
      this.zaFat = false;
      this.azAct = false;
      this.zaAct = false;
      this.isRest = true;
    } else if (this.orderColumn === 'app' && this.orderDir === 'A'){
      this.azIdx = false;
      this.zaIdx = false;
      this.azMen = false;
      this.zaMen = false;
      this.azApp = true;
      this.zaApp = false;
      this.azTag = false;
      this.zaTag = false;
      this.azFat = false;
      this.zaFat = false;
      this.azAct = false;
      this.zaAct = false;
      this.isRest = true;
    } else if (this.orderColumn === 'tag' && this.orderDir === 'A'){
      this.azIdx = false;
      this.zaIdx = false;
      this.azMen = false;
      this.zaMen = false;
      this.azApp = false;
      this.zaApp = false;
      this.azTag = true;
      this.zaTag = false;
      this.azFat = false;
      this.zaFat = false;
      this.azAct = false;
      this.zaAct = false;
      this.isRest = true;
    } else if (this.orderColumn === 'action' && this.orderDir === 'A'){
      this.azIdx = false;
      this.zaIdx = false;
      this.azMen = false;
      this.zaMen = false;
      this.azApp = false;
      this.zaApp = false;
      this.azTag = false;
      this.zaTag = false;
      this.azFat = false;
      this.zaFat = false;
      this.azAct = true;
      this.zaAct = false;
      this.isRest = true;
    } else if (this.orderColumn === 'idx' && this.orderDir === 'A'){
      this.azIdx = true;
      this.zaIdx = false;
      this.azMen = false;
      this.zaMen = false;
      this.azApp = false;
      this.zaApp = false;
      this.azTag = false;
      this.zaTag = false;
      this.azFat = false;
      this.zaFat = false;
      this.azAct = false;
      this.zaAct = false;
      this.isRest = true;
    } else if (this.orderColumn === 'mnuf' && this.orderDir === 'A'){
      this.azIdx = false;
      this.zaIdx = false;
      this.azMen = false;
      this.zaMen = false;
      this.azApp = false;
      this.zaApp = false;
      this.azTag = false;
      this.zaTag = false;
      this.azFat = true;
      this.zaFat = false;
      this.azAct = false;
      this.zaAct = false;
      this.isRest = true;
    }
    this.getMenus();
  }

  onSortedD(e) {
    this.orderDir = 'D';
    if (this.orderColumn === 'mnu' && this.orderDir === 'D'){
      this.azIdx = false;
      this.zaIdx = false;
      this.azMen = false;
      this.zaMen = true;
      this.azApp = false;
      this.zaApp = false;
      this.azTag = false;
      this.zaTag = false;
      this.azFat = false;
      this.zaFat = false;
      this.azAct = false;
      this.zaAct = false;
      this.isRest = true;
    } else if (this.orderColumn === 'app' && this.orderDir === 'D'){
      this.azIdx = false;
      this.zaIdx = false;
      this.azMen = false;
      this.zaMen = false;
      this.azApp = false;
      this.zaApp = true;
      this.azTag = false;
      this.zaTag = false;
      this.azFat = false;
      this.zaFat = false;
      this.azAct = false;
      this.zaAct = false;
      this.isRest = true;
    } else if (this.orderColumn === 'tag' && this.orderDir === 'D'){
      this.azIdx = false;
      this.zaIdx = false;
      this.azMen = false;
      this.zaMen = false;
      this.azApp = false;
      this.zaApp = false;
      this.azTag = false;
      this.zaTag = true;
      this.azFat = false;
      this.zaFat = false;
      this.azAct = false;
      this.zaAct = false;
      this.isRest = true;
    } else if (this.orderColumn === 'action' && this.orderDir === 'D'){
      this.azIdx = false;
      this.zaIdx = false;
      this.azMen = false;
      this.zaMen = false;
      this.azApp = false;
      this.zaApp = false;
      this.azTag = false;
      this.zaTag = false;
      this.azFat = false;
      this.zaFat = false;
      this.azAct = false;
      this.zaAct = true;
      this.isRest = true;
    } else if (this.orderColumn === 'idx' && this.orderDir === 'D'){
      this.azIdx = false;
      this.zaIdx = true;
      this.azMen = false;
      this.zaMen = false;
      this.azApp = false;
      this.zaApp = false;
      this.azTag = false;
      this.zaTag = false;
      this.azFat = false;
      this.zaFat = false;
      this.azAct = false;
      this.zaAct = false;
      this.isRest = true;
    } else if (this.orderColumn === 'mnuf' && this.orderDir === 'D'){
      this.azIdx = false;
      this.zaIdx = false;
      this.azMen = false;
      this.zaMen = false;
      this.azApp = false;
      this.zaApp = false;
      this.azTag = false;
      this.zaTag = false;
      this.azFat = false;
      this.zaFat = true;
      this.azAct = false;
      this.zaAct = false;
      this.isRest = true;
    }
    this.getMenus();
  }

  resetTable() {
    localStorage.removeItem('appStoId');
    localStorage.removeItem('appStoNa');
    localStorage.removeItem('tagStoId');
    localStorage.removeItem('tagStoNa');
    localStorage.removeItem('menuStoId');
    localStorage.removeItem('menuStoNa');
    localStorage.removeItem('paginationStorage');
    this.idApp = 0;
    this.idTag = 0;
    this.idMenuFather = null;
    this.orderColumn = '';
    this.orderDir = '';
    this.page = 1;
    this.pagination = 10;
    this.statusMenu = true;
    this.azIdx = false;
    this.zaIdx = false;
    this.azMen = false;
    this.zaMen = false;
    this.azApp = false;
    this.zaApp = false;
    this.azTag = false;
    this.zaTag = false;
    this.azFat = false;
    this.zaFat = false;
    this.azAct = false;
    this.zaAct = false;
    this.isRest = false;
    return this.getMenus();
}

  getMenus() {
    this.services.post({
      entidad: 'Menu',
      funcion: 1,
      data: {
        SearchKey: this.searchTerm,
        Id_Application: this.idApp,
        Id_Tag: this.idTag,
        Id_MenuFather: this.idMenuFather,
        Status: this.statusMenu,
        Order_Column: this.orderColumn,
        Order_Dir: this.orderDir,
        Page: this.page,
        Pagination: this.pagination
      }
    }).subscribe(result => {
      if (result.data) {
        this.listMenus = result.data;
      } 
      for (let i = 0; i < result.data.length; i++) {
        this.totalPages = result.data[i].Pages;
        this.collectionSize = result.data[i].Pages * 10;
        this.labels = result.data[i].Labels;
        // this.titleMenu = this.labels[this.language] ? this.labels[this.language].Text : this.labels[0].Text;
        // result.data[i].titleIn = this.titleMenu;
      }
      if (result.data.length === 0) {
        this.totalPages = 1;
        this.collectionSize = 1;
        this.selectedPage = 1;
      }
    }, error => {
      console.log('Error', error);
    });
  }

  deleteMenu(Id_Menu: Number, Description: String) {
    Swal.fire({
      title: `¿Estás seguro de querer eliminar el menú ${Description}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#746AB0',
      cancelButtonColor: '#E83845',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.services.post({
          entidad: 'Menu',
          funcion: 5,
          data: {
            Id_Menu: Id_Menu
          }
        }).subscribe(result => {
          if (result.code === 1) {
            this.toastr.success('Menú eliminado correctamente', 'Menús');
            this.getMenus();
          }
          else {
            this.toastr.error(result.message, 'Menús');
          }
        }, error => {
          console.log(error),
            this.toastr.error('Error al eliminar el menú', 'Menús');
        }, () => { });
      }
    });
  }

}
