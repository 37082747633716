import { Component } from '@angular/core';
import { SecurityService } from '../data/services/security.service';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent {

  constructor(
    private SecurityService: SecurityService
  ) {

  }

  logOff()
  {
    this.SecurityService.loggOff();
  }

}
