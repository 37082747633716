<!-- Comienza encabezado -->
<ng-container>
    <app-title-payment [titleP]="Title"></app-title-payment>
</ng-container>

<ng-container>
    <div class="row justify-content-between">
        <div class="col-auto">
            <div class="row ">
                <div class="col-auto">
                    <form class="app-search pull-right">
                        <input [(ngModel)]="channelLst.SearchKey" name="search" type="text" (input)="onSearchChange()" (click)="backtoListChannels()" class="input-search form-control" placeholder="{{ 'GENERAL.searchby' | translate }}..." />
                        <a class="ico-btn-search">
                            <i class="ti-search"></i>
                        </a>
                    </form>
                </div>
            </div>
        </div>
        <ng-container *ngIf="isButtonsView">
            <div class="col-auto mt-3 mt-sm-0">
                <button (click)="downloadCSV();" class="tooltipp btnSta" placement="top" ngbTooltip="Descargar .CSV" style="margin-left: 10px;">
                    <i class="fas fa-file-download"></i>
                </button>
                <button (click)="generateNewChannel('N')" class="tooltipp btnSta" placement="top" ngbTooltip="Nuevo" style="margin-left: 5px;">
                    <i class="fas fa-plus-square"></i>
                </button>
                <button (click)="requestInfo();" class="tooltipp btnSta" placement="top" ngbTooltip="Restablecer" href="javascript:void(0)" style="margin-left: 5px;"><i
                    class="fas fa-sync-alt"></i></button>
            </div>
        </ng-container>
    </div>
    <!-- Termina encabezado -->
    <!--Canvas-->
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
            <button type="button" class="btnArrow" data-bs-dismiss="offcanvas">
            <span><i class="fa fa-arrow-left"></i></span>
        </button>
            <div class="col-auto">
                <div>
                    <app-filters-channel (filSel)="filterSel($event)"></app-filters-channel>
                </div>
            </div>
        </div>
        <div class="offcanvas-body">
            <div class="col-auto px-0">
                <ng-container *ngIf="filterType === 'C'">
                    <app-channel-payment (channelSel)="filterByChannel($event)"></app-channel-payment>
                    <div class="d-grid gap-2 mt-3">
                        <button (click)="filterData()" type="button" data-bs-dismiss="offcanvas" data-bs-target="#typePayment" class="btn btn-success btnFilter">Filtrar</button>
                    </div>
                </ng-container>
                <ng-container *ngIf="filterType === 'S'">
                    <div class="row text-center">
                        <div class="col-12">
                            <p class="mb-1">{{ "GENERAL.showActives" | translate }}</p>
                            <label class="switch hideShow mb-3">
                                <input #status [(ngModel)]="StatusList" (change)="updateStatus($event)" name="status"
                                    type="checkbox" id="status"/>
                                <span class="slider round-in"></span>
                            </label>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <!--termina canvas-->

    <!-- Comienza contenido -->
    <div class="row mt-2 pb-2">
        <!-- Comienza Listado de canales -->
        <ng-container *ngIf="isChannelList">
            <div class="col-md-12 col-lg-12 col-sm-12" style="margin-left: auto; margin-right: auto;">
                <div class="card">
                    <div class="card-body pb-0">
                        <div class="row">
                            <div class="table-responsive tablePayments" style="min-height: 250px;">
                                <table sortable-table (sorted)="onSorted($event)" class="table table-striped">
                                    <thead>
                                        <tr class="purple">
                                            <th style="width: 50px;">
                                                <button *ngIf="isRestart || channelLst.Id_Channel != 0 || channelLst.Status != '' || channelLst.Pagination != 10" class="tooltipp btnRes" (click)="resetTable()" placement="top" ngbTooltip="Restablecer Filtros"><i
                                                    class="fas fa-trash-restore-alt p-1"></i></button>
                                            </th>
                                            <th class="th-sort" sortable-table="padre" sortable-column="cha" scope="col">
                                                <div class="dropdown ps-1">
                                                    <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                                    <span *ngIf="AZcha"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="ZAcha"><i
                                                            class="fas fa-sort-alpha-down-alt"></i></span>
                                                    <span *ngIf="channelLst.Id_Channel != 0" class="icon"><i
                                                            class="fas fa-filter"></i></span>
                                                    Canal
                                                </button>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">De la A a la Z</a></li>
                                                        <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">De la Z a la A</a></li>
                                                        <li><a (click)="filterChannel('C')" class="dropdown-item" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Filtrar canales</a></li>
                                                    </ul>
                                                </div>
                                            </th>
                                            <th sortable-table="padre" sortable-column="sta" scope="col">
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span *ngIf="channelLst.Status != ''" class="icon"><i class="fas fa-filter"></i></span>
                                                    Estatus
                                                </button>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a (click)="filterChannel('S')" class="dropdown-item" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" id="A" value="FO">Filtrar
                                                            Estatus </a></li>
                                                    </ul>
                                                </div>
                                            </th>
                                            <th sortable-table="padre" sortable-column="con" scope="col">
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span *ngIf="AZcon"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="ZAcon"><i
                                                            class="fas fa-sort-alpha-down-alt"></i></span>
                                                    Persona de contacto
                                                </button>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">De la A a la Z</a></li>
                                                        <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">De la Z a la A</a></li>
                                                    </ul>
                                                </div>
                                            </th>
                                            <th sortable-table="padre" sortable-column="pho" scope="col">
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span *ngIf="AZpho"><i
                                                            class="fas fa-sort-numeric-up-alt"></i></span>
                                                    <span *ngIf="ZApho"><i
                                                            class="fas fa-sort-numeric-down-alt"></i></span>
                                                    Teléfono
                                                </button>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">Ascendente</a></li>
                                                        <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">Descendente</a></li>
                                                    </ul>
                                                </div>
                                            </th>
                                            <th sortable-table="padre" sortable-column="ema" scope="col">
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span *ngIf="ZAema"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="AZema"><i
                                                            class="fas fa-sort-alpha-down-alt"></i></span>
                                                    E-mail
                                                </button>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">De la A a la Z</a></li>
                                                        <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">De la Z a la A</a></li>
                                                    </ul>
                                                </div>
                                            </th>
                                            <th scope="col" sortable-column="rfc">
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <!-- <span *ngIf="annuityId != ''" class="icon" ><i class="fas fa-funnel-dollar"></i></span> -->
                                                    <span *ngIf="AZrfc"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="ZArfc"><i
                                                            class="fas fa-sort-alpha-down-alt"></i></span>
                                                    RFC
                                                </button>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">De la A a la Z</a></li>
                                                        <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">De la Z a la A</a></li>
                                                    </ul>
                                                </div>
                                            </th>
                                            <th scope="col" sortable-column="dat">
                                                <div class="dropdown">
                                                    <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span *ngIf="AZdat"><i class="fas fa-sort-numeric-up-alt"></i></span>
                                                    <span *ngIf="ZAdat"><i class="fas fa-sort-numeric-down-alt"></i></span>
                                                    Fecha
                                                </button>
                                                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">Ascendente</a></li>
                                                        <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">Descendente</a></li>
                                                    </ul>
                                                </div>
                                            </th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let channel of lstChannel; let i = index">
                                            <td style="width: 50px;">
                                                <ng-container *ngIf="i != idx">
                                                    <button (click)="showDetails(channel.Id_Channel, 'E')" class="btnEdit ps-3"><i class="fas fa-file-invoice"></i></button>
                                                </ng-container>
                                            </td>
                                            <td scope="row">&#160;{{channel.Channel_Name}}</td>
                                            <td class="center">
                                                <div class="col-auto mb-1">
                                                    <ng-container *ngIf="i != idx">
                                                        <label class="switch hideShow">
                                                        <input #status [checked]="channel.Status =='A' ? true:false"
                                                            (change)="changeStatusChannelInt(status.checked, channel.Id_Channel)"
                                                            name="status" type="checkbox" id="status" />
                                                        <span class="slider round-in"></span>
                                                    </label>
                                                    </ng-container>
                                                </div>
                                            </td>
                                            <td>{{channel.Contact_Person}}</td>
                                            <td>{{channel.Phone}}</td>
                                            <td>{{channel.Email}}</td>
                                            <td>{{channel.RFC}}</td>
                                            <td>{{channel.Date_Add | date:"dd-MMM-YY"}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row gx-1 mt-2 justify-content-between">
                        <div class="col-auto ps-5 ">
                            <select class="select-css" id="dropAmounts" aria-label="Default select example" (change)="changeAmountView($event)" [(ngModel)]="channelLst.Pagination" name="dropAmounts">
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        </div>
                        <div class="col-auto pe-5  mt-sm-0">
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="channelLst.Page" [maxSize]="4" (pageChange)="changePage($event)" [rotate]="true"></ngb-pagination>
                        </div>
                    </div> -->
                    <app-pagination-global [callbackFunction]="loadChannels" [actualPagination]="channelLst.Pagination" [collectionDinamic]="collectionSize" (changePagination)="changeAmountView($event)" (changePageEmi)="changePage($event)"></app-pagination-global>
                </div>
            </div>
        </ng-container>
        <!-- Termina Listado de canales -->

       

        

</div>
<ng-container *ngIf="isdetailsChannel">
    <app-detail-channel [idChannel]="idChan" (goBack)="viewdetailChann()" [newEdit]="newEdit"></app-detail-channel>
</ng-container>
<app-back-to-top></app-back-to-top>
</ng-container>
<!-- Termina contenido -->
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white;">Loading...</p>
</ngx-spinner>