import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelService } from 'src/app/data/services/excel.service';
import { ServicesService } from 'src/app/data/services/services.service';
import { User } from './users';
import { UserList } from '../../data/models/user-list.model';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  userLst = new UserList();

  public Users: User[] = [];
  public UserSelected: User = new User();
  public isNew: boolean = false;
  public isEdit: boolean = false;
  public isView: boolean = false;
  public isResults: boolean = true;

  isAccess = false;
  isDown = true;
  idUser: Number = 0;
  Title = 'Usuarios';
  filterType = '';
  selectedPage: any = 1;
  collectionSize = 0;
  totalPages = 0;
  AZnam = false;
  ZAnam = false;
  AZema = false;
  ZAema = false;
  AZcus = false;
  ZAcus = false;
  AZcom = false;
  ZAcom = false;
  orderColumn = '';
  orderDir = '';
  isRest = false;
  isReturn = false;

  constructor(
    private services: ServicesService,
    private spinner: NgxSpinnerService,
    private excelService: ExcelService,
    private translate: TranslateService,

    private router: Router,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
    if (localStorage.getItem('cusStoId') !== null) {
      this.userLst.Id_Customer = +localStorage.getItem('cusStoId');
    }
    if (localStorage.getItem('comStoId') !== null) {
      this.userLst.Id_Company = +localStorage.getItem('comStoId');
    }
    if (localStorage.getItem('staSto') !== null) {
      this.userLst.Status = localStorage.getItem('staSto');
    }
    if (localStorage.getItem('paginationStorage') !== null) {
      this.userLst.Pagination = +localStorage.getItem('paginationStorage');
    }
  }

  ngOnInit(): void {

    const myOffcanvas = document.querySelector('#offcanvasRight') as any;
    if (myOffcanvas !== null) {
      myOffcanvas.addEventListener('hidden.bs.offcanvas', () => {
        this.filterType = '';
      });
    }
    
    this.GetUsers();
  }

  /* Metodos */

  viewDetailUser() {
    this.isResults = true;
    this.isReturn = false;
    this.isView = false;
    this.GetUsers();
  }

  returnToList() {
    this.UserSelected = new User();
    this.isEdit = false;
    this.isView = false;
    this.isReturn = false;
    this.idUser = 0;
    this.isNew = false;
    this.isView = false;
    this.isResults = true;
    this.GetUsers();
  }

  returnToView() {
    this.isView = false;
    this.isNew = false;
    this.isResults = true;
    this.isReturn = false;
    this.GetUsers();
  }

  SelectUser(user: User) {
    this.isView = true;
    this.isNew = false;
    this.isEdit = false;
    this.isResults = false;
    this.isReturn = true;

    this.UserSelected = user;
    this.idUser = user.Id_User;
  }

  onSearchChange() {
    this.GetUsers();
  }

  NewUser() {
    this.UserSelected = new User();
    this.idUser = 0;
    this.isReturn = true;
    this.isNew = true;
    this.isView = true;
    this.isResults = false;
  }

  /* Servicios*/

  filterByCustomer(e) {
    this.userLst.Id_Customer = e;
  }

  filterByCompany(e) {
    this.userLst.Id_Company = e;
  }

  filterByStatus(e) {
    this.userLst.Status = e;
  }

  filterChannel(e) {
    if (e === 'O') {
      this.filterType = e;
    } else if (e === 'C') {
      this.filterType = e;
    } else if (e === 'E') {
      this.filterType = e;
    }
  }

  filterSel(e) {
    const ftro = Number(e);
    if (ftro === 1) {
      this.filterType = 'O';
    } else if (ftro === 2) {
      this.filterType = 'C';
    } else if (ftro === 3) {
      this.filterType = 'E';
    } 
  }

  filterData() {
    this.GetUsers();
  }

  changeAmountView(e) {
    this.userLst.Pagination = e.target.value;
    this.GetUsers();
  }

  changePage(e) {
    this.userLst.Page = e;
    this.GetUsers();
  }

  onSorted1(e) {
    this.userLst.Order_Column = e.sortColumn;
  }

  onSortedA(e) {
    this.userLst.Order_Dir = 'A';
    if (this.userLst.Order_Column === 'nam' && this.userLst.Order_Dir === 'A'){
      this.isRest= true;
      this.AZnam = true;
      this.ZAnam = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZcus = false;
      this.ZAcus = false;
      this.AZcom = false;
      this.ZAcom = false;
    } else if (this.userLst.Order_Column === 'ema' && this.userLst.Order_Dir === 'A'){
      this.isRest= true;
      this.AZnam = false;
      this.ZAnam = false;
      this.AZema = true;
      this.ZAema = false;
      this.AZcus = false;
      this.ZAcus = false;
      this.AZcom = false;
      this.ZAcom = false;
    } else if (this.userLst.Order_Column === 'cus' && this.userLst.Order_Dir === 'A'){
      this.isRest= true;
      this.AZnam = false;
      this.ZAnam = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZcus = true;
      this.ZAcus = false;
      this.AZcom = false;
      this.ZAcom = false;
    } else if (this.userLst.Order_Column === 'com' && this.userLst.Order_Dir === 'A'){
      this.isRest= true;
      this.AZnam = false;
      this.ZAnam = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZcus = false;
      this.ZAcus = false;
      this.AZcom = true;
      this.ZAcom = false;
    }
    this.GetUsers();
  }

  onSortedD(e) {
    this.userLst.Order_Dir = 'D';
    if (this.userLst.Order_Column === 'nam' && this.userLst.Order_Dir === 'D'){
      this.isRest= true;
      this.AZnam = false;
      this.ZAnam = true;
      this.AZema = false;
      this.ZAema = false;
      this.AZcus = false;
      this.ZAcus = false;
      this.AZcom = false;
      this.ZAcom = false;
    } else if (this.userLst.Order_Column === 'ema' && this.userLst.Order_Dir === 'D'){
      this.isRest= true;
      this.AZnam = false;
      this.ZAnam = false;
      this.AZema = false;
      this.ZAema = true;
      this.AZcus = false;
      this.ZAcus = false;
      this.AZcom = false;
      this.ZAcom = false;
    } else if (this.userLst.Order_Column === 'cus' && this.userLst.Order_Dir === 'D'){
      this.isRest= true;
      this.AZnam = false;
      this.ZAnam = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZcus = false;
      this.ZAcus = true;
      this.AZcom = false;
      this.ZAcom = false;
    } else if (this.userLst.Order_Column === 'com' && this.userLst.Order_Dir === 'D'){
      this.isRest= true;
      this.AZnam = false;
      this.ZAnam = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZcus = false;
      this.ZAcus = false;
      this.AZcom = false;
      this.ZAcom = true;
    }
    this.GetUsers();
  }

  resetTable() {
    localStorage.removeItem('cusStoId');
    localStorage.removeItem('chaStoNa');
    localStorage.removeItem('comStoId');
    localStorage.removeItem('comStoNa');
    localStorage.removeItem('staSto');
    localStorage.removeItem('paginationStorage');
    this.userLst.Id_Customer = 0;
    this.userLst.Id_Company = 0;
    this.userLst.Order_Column = '';
    this.userLst.Order_Dir = '';
    this.userLst.Status = '';
    this.userLst.Page = 1;
    this.userLst.Pagination = 10;
    this.AZnam = false;
    this.ZAnam = false;
    this.AZema = false;
    this.ZAema = false;
    this.AZcus = false;
    this.ZAcus = false;
    this.AZcom = false;
    this.ZAcom = false;
    this.isRest = false;
    return this.GetUsers();
  }

  GetUsers() {
    this.spinner.show();
    this.services.User_List(this.userLst).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.Users = resp.data;
      }
      for (let i = 0; i < resp.data.length; i++) {
        this.totalPages = resp.data[i].Pages;
        this.collectionSize = resp.data[i].Pages * 10;
      }
      if (resp.data.length === 0) {
        this.totalPages = 1;
        this.collectionSize = 1;
        this.selectedPage = 1;
      }
    });

  }

  downloadCSV() {
    const struct = this.Users.map(s => ({ Estatus: s.Status_Name, Nombre: s.Name, Correo: s.Email, Organizacion: s.CustomerName, Compania: s.CompanyName }));
    this.excelService.exportAsExcelFile(struct, 'Users_Report');
  }

  // async User_AccessService(KeyAccess) {
  //   const resp = await this.services.getAccessPermit(KeyAccess);
  //   this.isAccess = resp.granted;
  // }

}
