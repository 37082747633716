import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filters-company',
  templateUrl: './filters-company.component.html',
  styleUrls: ['./filters-company.component.css']
})
export class FiltersCompanyComponent implements OnInit {

  @Output() filSelCom = new EventEmitter<string>();

  typeFilCom = '';

  constructor(
    private translate: TranslateService,
  ) { 
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
  }

  selectFilter(e) {
    this.typeFilCom = e.target.value;
    this.filSelCom.emit(this.typeFilCom);
  }

}
