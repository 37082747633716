import { Component, EventEmitter, Input, OnInit, Output, Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServicesService } from 'src/app/data/services/services.service';


@Component({
  selector: 'app-log-kpis',
  templateUrl: './log-kpis.component.html',
  styleUrls: ['./log-kpis.component.css']
})


export class LogKpisComponent implements OnInit {
  
  @Output() closeC = new EventEmitter();
  @Output() viewLog = new EventEmitter();
  @Input() dtlofKPI: any;
  lstLogGenerate = [];
  filtersData = [];
  typeEvent = '';

  constructor(private services: ServicesService, private translate: TranslateService) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    console.log(this.dtlofKPI);
    this.typeLogGet();
  }

  closeCb(){
    console.log('Entra aqui');
    this.closeC.emit();
  }

  typeLogGet() {

    this.services.post({
      entidad: 'KPIs',
      funcion: 15,
      data: {
        id_kpi: this.dtlofKPI.Id_KPI
      }
    }).subscribe(result => {
      if (result.code === 1) {
        this.lstLogGenerate = result.data;
      }

    }, error => {
      console.log('Error', error);
    });

  }


}
