<div class="row gx-1 justify-content-between">
    <div class="col-auto ps-5">
        <select class="select-css pointer" id="dropAmounts" aria-label="Default select example" (change)="changeAmountView($event)" [(ngModel)]="actualPagination" name="dropAmounts">
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
        </select>
    </div>
    <div class="col-auto pe-5 mt-2 mt-sm-0">
        <ngb-pagination [collectionSize]="collectionDinamic" [(page)]="Page" [maxSize]="4" (pageChange)="changePage($event)" [rotate]="true"></ngb-pagination>
    </div>
</div>