export class Companie {
  NameCustomer: String = '';
  Id_Company: Number;
  Id_Customer: Number;
  Status: String = 'I';
  Name: String = '';
  CompanyName: String = '';
  Alter_Name: String = '';
  Logo_Path: String = '';
  RFC: String = '';
  Certificate_Path: String = '';
  Key_Path: String = '';
  PrivatePsw: String = '';
  StatusName: String = '';
  MFA: String = 'I';
  MFAName: String = '';
  PriorityOrder: String = 'A';
  PriorityOrderType: String = 'P';
  Id_Tax_Regime: Number = 601;
  Street: String;
  Colony: String;
  Num_Ext: String;
  Num_Int: String;
  Postal_Code: String;
  Location: String;
  Municipality: String;
  Estate: String;
  CURP: String;
  Reference: String;
  E_Mail: String;
  Phone_Number: String;
  Admin_Contact: String;
  Branch_Office: String;
  Expedition_Place_Postal_Code: String;
}

export class PAC {
  Id_Company_PAC: Number;
  Id_Company: Number;
  Id_PAC: any = 0;
  OrderNo: Number = 0;
  STATUS: String = 'A';
  Logo_Path: String;
  PAC_User: String = '';
  PAC_Password: String = '';
  PAC_Password2: String = '';
  PAC_Name: String = '';
  Require_Password: String;
}

export class Company_SMTP {
  Id_Company: number = 0;
  Id_Company_SMTP: number = 0;
  EmailTo: string = '';

  Port: number;
  Server: string = '';
  Sender: string = '';
  User_smtp: string = '';
  Password_smtp: string = '';
  Status: string = 'A';
  StatusName: string = '';
  SSL: string = 'I';
  SSLName: string = '';
}

export class Company {
  Id_Company: number = 0;
  Id_Customer: number = 0;
  Name: string = '';
  CompanyName: String = '';
  CustomerName: String = '';
  RFC: string = '';
  CURP: string = '';
  Num_Ext: string = '';
  Colony: string = '';
  Location: string = '';
  Postal_Code: string = '';
  E_Mail: string = '';
  Admin_Contact: string = '';
  Expedition_Place_Postal_Code: string = '';
  MFA: string = '';
  Alter_Name: string = '';
  Id_Tax_Regime: string = '0';
  Name_Tax_Regime: string = '';
  Street: string = '';
  Num_Int: string = '';
  Municipality: string = '';
  Estate: string = '';
  Reference: string = '';
  Phone_Number: string = '';
  Branch_Office: string = '';
  Status: string = '';

  Certificate_Path: string = '';
  Key_Path: string = '';
  Pfx_Path: string = '';
  EFirma_Cer: string = '';
  EFirma_Key: string = '';
  EFirma_Pfx: string = '';
  CSD_Valid_From?: Date;
  CSD_Valid_Until?: Date;
  Efirma_Valid_From?: Date;
  Efirma_Valid_Until?: Date;
  QR_Fiscal: string = '';
}
export class CompanyEdit extends Company {
  PriorityOrder: string = '';
  NameCustomer: string = '';
  StatusName: string = '';
  MFAName: string = '';
  Logo_Path: string = '';
}

export class TaxRegime {

  Id_Tax_Regime: number = 0;
  Name: string = '';
}
export class Dimension {
  Id_Dimension: number = 0;
  Description: string = '';
}

