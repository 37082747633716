<ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item text-center colorTab">
        <a (click)="viewGeneral()" class="nav-link active" id="general-tab" data-toggle="tab" href="#General" role="tab"
            aria-controls="General" aria-selected="true"><i class="fas fa-tasks fa-2x"></i><br>General</a>
    </li>
    <li *ngIf="!isNew && id != 0" class="nav-item text-center  colorTab">
        <a (click)="viewUsers()" class="nav-link" id="users-tab" data-toggle="tab" href="#users" role="tab"
            aria-controls="users" aria-selected="false"><i class="fas fa-users fa-2x"></i><br>Usuarios</a>
    </li>
</ul>

<div class="tab-content generalTab " id="myTabContent">
    <div class="tab-pane fade show active " id="General" role="tabpanel" aria-labelledby="general-tab">
        <ng-container *ngIf="generalView">
            <div class="card pb-3" style="background-color:#dcdcdc;">
                <app-general-groups [idG]="id" [operation]="operation" [lstCustomer]="Customers"
                    (backCrewList)="backToCrewList()"></app-general-groups>
            </div>
        </ng-container>
    </div>

    <div class="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
        <ng-container *ngIf="usersView ">
            <div class="card pb-3" style="background-color:#dcdcdc; ">
                <app-users-groups [id]="id" [operation]="operation" [lstCustomer]="Customers"></app-users-groups>
            </div>
        </ng-container>
    </div>
</div>