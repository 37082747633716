export default class SearchItem {
    id: number;
    logo_path: string;
    logo_icon: string;
    Active_Users: string;
    title: string;
    subtitle: string;
    subtitle2: string;
    subtitle3: string;
    status: string;
    status_name: string;
}
