<div class="row mt-1 justify-content-between">
    <div class="col-auto">
        <div class="row">
            <div class="col-auto tittleKPICard">
                <h2 class="mb-0">Titulo de KPI</h2>
                <small>Aplicación</small>
            </div>

            <div class="col-auto">
                <h5>
                    <span class="badge badge-success">Status: Activo</span>
                </h5>
            </div>
        </div>
    </div>
    <div class="col-auto">
        <div class="col-auto mt-4">
            <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle btnDrop" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
          <i class="fas fa-ellipsis-v"></i>
        </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#">Filtros</a>
                    <a class="dropdown-item" href="#">Log de Actualización</a>
                    <a class="dropdown-item" href="#">Configuración</a>
                    <a class="dropdown-item" href="#">Duplicar</a>
                    <a class="dropdown-item" href="#" (click)="closeCb()">Eliminar</a>
                </div>
            </div>
        </div>
    </div>
</div>
<hr class="mt-2 mb-1" />
<div class="row mt-0">
    <div class="col-auto">
        <div class="form-group">
            <p for="typeFilters" class="subTittleCard mb-0">Filtros</p>
            <p class="descriptionText">
                Descripcion del kpi ingresada por el creador.
            </p>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-auto">
        <div class="card cardTable">
            <div class="card-header text-center">Conta-e</div>
            <div class="card-body p-1">
                <div class="row justify-content-around text-center">
                    <div class="col-6">
                        <h5 class="mb-0 titleP">Tipo de Pago</h5>
                        <h5>
                            <span class="badge badge-success">Prepago</span>
                        </h5>

                        <p class="mb-0 titleP">Estatus de Pago</p>
                        <p class="mb-0 text-success"><i class="far fa-check-circle"></i></p>
                    </div>

                    <div class="col-6">
                        <h5 class="mb-0 titleP">Fecha de Aniversario</h5>
                        <p class="mb-0 textM">28 de Agosto</p>
                    </div>
                </div>

                <hr />
                <div class="row justify-content-between">
                    <div class="col-auto">
                        <h5 class="mb-0 titleP">Módulos</h5>
                        <p class="mb-0 textM">Descargas del SAT</p>
                        <p class="mb-0 textM">Conta-e Premium</p>
                        <p class="mb-0 textM">Fiscal</p>
                    </div>
                    <div class="col-auto text-center mr-5">
                        <p class="mb-0 titleP">Estatus</p>
                        <p class="text-success"><i class="fas fa-check"></i></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<hr class="mt-2 mb-1" />
<div class="row justify-content-around filtersBottom">
    <div class="col-auto">
        <small>Ximpli</small>
    </div>
    <div class="col-auto">
        <small>Validación de EFOS & EDOS</small>
    </div>
    <div class="col-auto">
        <small>Validación de EFOS & EDOS</small>
    </div>
    <div class="col-auto">
        <small>Abril</small>
    </div>
    <div class="col-auto">
        <small>2022</small>
    </div>
</div>