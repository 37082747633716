<h4 class="card-title textP"><b>{{companieSelected.Alter_Name}} </b></h4>

<ul class="nav nav-tabs " id="myTab" role="tablist">
    <li class="nav-item text-center colorTab">
        <a (click)="viewGeneral()" class="nav-link active" id="general-tab" data-toggle="tab" href="#general" role="tab" aria-controls="general" aria-selected="true"><i class="fas fa-tasks fa-2x"></i><br>General </a>
    </li>
    <ng-container *ngIf="isNewEdit">
        <li class="nav-item text-center colorTab">
            <a (click)="viewLogo()" class="nav-link" id="logo-tab" data-toggle="tab" href="#logo" role="tab" aria-controls="logo" aria-selected="false"><i class="far fa-image fa-2x"></i> <br> Logo</a>
        </li>
        <li class="nav-item text-center colorTab"> 
            <a (click)="viewQR()" class="nav-link" id="QR-tab" data-toggle="tab" href="#QR" role="tab" aria-controls="QR" aria-selected="false"><i class="fas fa-qrcode fa-2x"></i><br>QR Fiscal</a>
        </li>
        <li class="nav-item text-center colorTab">
            <a (click)="viewPac()" class="nav-link" id="pacSimple-tab" data-toggle="tab" href="#pacSimple" role="tab" aria-controls="pacSimple" aria-selected="false"><i class="fas fa-file-invoice-dollar fa-2x"></i><br>Pac
                Simple</a>
        </li>
        <li class="nav-item text-center colorTab">
            <a (click)="viewSat()" class="nav-link" id="sat-tab" data-toggle="tab" href="#sat" role="tab" aria-controls="sat" aria-selected="false"><i class="fas fa-wallet fa-2x"></i><br>SAT</a>
        </li>
        <li class="nav-item text-center colorTab">
            <a (click)="viewSmtp()" class="nav-link" id="smtp-tab" data-toggle="tab" href="#smtp" role="tab" aria-controls="smtp" aria-selected="false"><i class="fas fa-mail-bulk fa-2x"></i> <br> SMTP</a>
        </li>
        <li class="nav-item text-center colorTab">
            <a (click)="viewCost()" class="nav-link" id="costCenter-tab" data-toggle="tab" href="#costCenter" role="tab" aria-controls="costCenter" aria-selected="false"><i class="fas fa-donate fa-2x"></i><br>C. Costos</a>
        </li>
        <li class="nav-item text-center colorTab">
            <a (click)="viewProject()" class="nav-link" id="projects-tab" data-toggle="tab" href="#projects" role="tab" aria-controls="projects" aria-selected="false"><i class="fas fa-project-diagram fa-2x "></i><br>Proyectos</a>
        </li>
        <li class="nav-item text-center colorTab">
            <a (click)="viewBranch()" class="nav-link" id="branchOffices-tab" data-toggle="tab" href="#branchOffices" role="tab" aria-controls="branchOffices" aria-selected="false"><i class="fas fa-warehouse fa-2x "></i><br>Sucursales</a>
        </li>
    </ng-container>
</ul>

<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
        <ng-container *ngIf="generalView ">
            <div class="card pb-3" style="background-color:#dcdcdc; ">
                <app-general-company (updateName)="nameUpdate()" (goBack)="returnToList()" [newEdit]="newEdit" [idCompany]="idCompany">
                </app-general-company>
            </div>
        </ng-container>
    </div>

    <div class="tab-pane fade" id="logo" role="tabpanel" aria-labelledby="logo-tab">
        <ng-container *ngIf="logoView ">
            <div class="card pb-3" style="background-color:#dcdcdc; ">
                <app-logo-company [idCompany]="idCompany"></app-logo-company>
            </div>
        </ng-container>
    </div>

    <div class="tab-pane fade" id="QR" role="tabpanel" aria-labelledby="QR-tab">
        <ng-container *ngIf="qrView">
            <div class="card pb-3" style="background-color:#dcdcdc; ">
                <app-qr-company [idCompany]="idCompany"></app-qr-company>
            </div>
        </ng-container>
    </div>

    <div class="tab-pane fade" id="pacSimple" role="tabpanel" aria-labelledby="pacSimple-tab">
        <ng-container *ngIf="pacView">
            <div class="card pb-3" style="background-color:#dcdcdc; ">
                <app-pacsimple-company [idCompany]="idCompany"></app-pacsimple-company>
            </div>
        </ng-container>
    </div>
    
    <div class="tab-pane fade" id="sat" role="tabpanel" aria-labelledby="sat-tab">
        <ng-container *ngIf="satView">
            <div class="card pb-3" style="background-color:#dcdcdc; ">
                <app-sat-company [idCompany]="idCompany"></app-sat-company>
            </div>
        </ng-container>
    </div>

    <div class="tab-pane fade" id="smtp" role="tabpanel" aria-labelledby="smtp-tab">
        <ng-container *ngIf="smtpView">
            <div class="card pb-3" style="background-color:#dcdcdc; ">
                <app-smtp-company [idCompany]="idCompany"></app-smtp-company>
            </div>
        </ng-container>
    </div>
   
    <div class="tab-pane fade" id="costCenter" role="tabpanel" aria-labelledby="costCenter-tab">
        <ng-container *ngIf="centerView">
            <div class="card pb-3" style="background-color:#dcdcdc; ">
                <app-cost-center-company [idCompany]="idCompany"></app-cost-center-company>
            </div>
        </ng-container>
    </div>
     
    <div class="tab-pane fade" id="projects" role="tabpanel" aria-labelledby="projects-tab">
        <ng-container *ngIf="projectView">
            <div class="card pb-3" style="background-color:#dcdcdc; ">
                <app-project-company [idCompany]="idCompany"></app-project-company>
            </div>
        </ng-container>
    </div>
    
    <div class="tab-pane fade" id="branchOffices" role="tabpanel" aria-labelledby="branchOffices-tab">
        <ng-container *ngIf="branchView">
            <div class="card pb-3" style="background-color:#dcdcdc; ">
                <app-branch-offices-company [idCompany]="idCompany"></app-branch-offices-company>
            </div>
        </ng-container>
    </div> 
</div>