import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-top-new-indicator',
  templateUrl: './top-new-indicator.component.html',
  styleUrls: ['./top-new-indicator.component.css']
})
export class TopNewIndicatorComponent implements OnInit {

  @Output() addNewKpi = new EventEmitter();
  @Output() closeC = new EventEmitter();
  @Input() dtlofKPI: any;


  lstKpisGenerate = [];
  lstProyectGenerate = [];
  typeKpi = '';
  typeProyect = '';

  constructor(private services: ServicesService, private translate: TranslateService) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }


  ngOnInit(): void {
    this.typeKpiGet();
  }

  closeNI(){
    this.closeC.emit();
  }

  addNewConfig(e){
    this.addNewKpi.emit({e, type: this.typeKpi, app: this.typeProyect});
  }

  selectTypeKpi(e){
    this.typeKpi = e.target.value;
    this.typeProyectGet();
  }

  selectProject(e){
    this.typeProyect = e.target.value;
  }

  // Servicios

  typeKpiGet() {

    this.services.post({
      entidad: 'KPIs',
      funcion: 2,
      data: {
      }
    }).subscribe(result => {
      if (result.code === 1) {
        this.lstKpisGenerate = result.data;
      }

    }, error => {
      console.log('Error', error);
    });

  }

  typeProyectGet() {

    this.services.post({
      entidad: 'KPIs',
      funcion: 3,
      data: {
        id_kpi_type: this.typeKpi,
      }
    }).subscribe(result => {
      if (result.code === 1) {
        this.lstProyectGenerate = result.data;
      }

    }, error => {
      console.log('Error', error);
    });

  }


}
