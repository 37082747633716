import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/data/services/security.service';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.css']
})
export class SideMenuComponent implements OnInit {

    @HostBinding('class.is-open') click = false;

    scroll = 0;

    isExpanded = false;
    IsLogued = false;
    lt: string;
    menus: any[];

    urlActual = '';

    constructor(
        private router: Router,
        private services: ServicesService,
        private SecurityService: SecurityService
    ) {

        this.urlActual = this.router.url;
    }

    ngOnInit(): void {
        if (this.SecurityService.isLogged())
            this.getMenus();
    }


    @HostListener('window:scroll', []) onWindowScroll() {
        // do some stuff here when the window is scrolled
        const verticalOffset = window.pageYOffset
            || document.documentElement.scrollTop
            || document.body.scrollTop || 0;
        this.scroll = verticalOffset;
    }

    logOff() {
        this.SecurityService.loggOff();
    }

    async getMenus() {

        this.services.Get_Menu_Adm().subscribe(result => {

            if (Array.isArray(result.data)) {
                this.menus = result.data;
            }

        }, error => console.log(error));

    }

    texttoObjetc(str: string): string {
        return JSON.parse(str).class;
    }

}
