import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router
  ) { }

  headers = new HttpHeaders().set('Content-Type', 'application/json');

  getToken() {
    return localStorage.getItem('key');
  }

  public UrlSSO() {
    //return 'https://sso.ximplify.mx/';   ES MUY LENTA LA LAMBDA
    return document.getElementsByTagName('base')[0].href;
  }

  public verifyResponse(response) {
    if (response.Result) {
      return true;
    } else {
      if (response.Codigo === 0) {
        this.redirectWeb();
      } else if (response.Codigo < 0) {
        if (response.Codigo === -1 || response.Codigo === -2) {
          // this.toastr.error(response.Message, 'Error!', { toastLife: 10000, showCloseButton: true });
        }
        // else {
        //   this.translate.get('ERROR.' + response.Message).subscribe((res: string) => {
        //     this.toastr.error(res, 'Error!', { toastLife: 10000, showCloseButton: true });
        //   });
        // }
        return false;
      } else {
        return true;
      }
    }
    return 0;
  }

  private redirectWeb() {
    this.toastr.info('Su sesión ha expirado, sera redireccionado.');
    localStorage.setItem('key', '');
    const redirect = '/authentication/login';
    this.router.navigate([redirect]);
  }


}
