<!-- <div class="form-menu d-flex flex-column align-items-center justify-content-center mt-5">
    <span class="version">Versión {{appVersion}}</span>
    <img class="logo" src="https://xam-app.s3.us-west-2.amazonaws.com/images/simplesuit/ss-sinslogan-simple-suite.png" />
    
    <h1 class="h5 mb-4 font-weight-normal mt-3" style="text-align: center; color: #6a6a6a;">Selecciona un ambiente</h1>

    <div class="row pt-0 px-3 cardsMenu pb-5">
        <div *ngFor="let mnu of lstMenu" class="col-12 col-md-auto px-1 mt-2 btnMnu text-center">
            <a href="javascript:void(0)" (click)="selAmb(mnu.id)">
                <div class="card p-2">
                    <div class="row justify-content-center align-items-center h-100">
                        <div class="col-">
                            <div class="iconColor">
                                <i class="{{mnu.image}}" style="font-size: 30px !important;" aria-hidden="true"></i>
                            </div>
                            <p class="mb-0 text-black">{{mnu.text}}</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div> -->

<div class="form-menu d-flex flex-column align-items-center justify-content-center mt-5">
    <span class="version">Versión {{appVersion}}</span>
    <img class="logo" src="https://xam-app.s3.us-west-2.amazonaws.com/images/simplesuit/ss-sinslogan-simple-suite.png" />
    
    <h1 class="h5 mb-4 font-weight-normal mt-3" style="text-align: center; color: #6a6a6a;">Selecciona un ambiente</h1>

    <div class="row pt-0 px-3 cardsMenu pb-5">
        <div *ngFor="let mnu of lstMenu" class="col-12 col-md-6 col-lg-4 px-1 mt-2 btnMnu text-center">
            <a href="javascript:void(0)" (click)="selAmb(mnu.id)">
                <div class="card p-2">
                    <div class="row justify-content-center align-items-center h-100">
                        <div class="col-">
                            <div class="iconColor">
                                <i class="{{mnu.image}}" style="font-size: 30px !important;" aria-hidden="true"></i>
                            </div>
                            <p class="mb-0 text-black">{{mnu.text}}</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>
