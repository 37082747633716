export class CustomerList {
  Page = 1;
  Id_Customer = 0;
  Id_Channel = 0;
  SearchKey = '';
  Status = '';
  Order_Column = '';
  Order_Dir = '';
  Pagination = 10;
  MFA = '';
}
