import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filters-user',
  templateUrl: './filters-user.component.html',
  styleUrls: ['./filters-user.component.css']
})
export class FiltersUserComponent implements OnInit {

  @Output() filSelU = new EventEmitter<string>();

  typeFilU = '';

  constructor(
    private translate: TranslateService,
  ) { 
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
  }

  selectFilter(e) {
    this.typeFilU = e.target.value;
    this.filSelU.emit(this.typeFilU);
  }

}
