import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../../global/global.service';
import { environment } from 'src/environments/environment';
import { Channel, ChannelContact } from 'src/app/account/channel/channels';
import { Customer, CustomerApp, CustomerFolio } from 'src/app/account/customer/customer';
import { CompanyEdit, Company_SMTP, PAC } from 'src/app/account/company/companies';
import { NotificationFoliosPurchase, Response } from 'src/app/data/models/models';
import { User } from 'src/app/account/user/users';
import Result, { UserAccess } from '../models/result.model';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  private enviroment = localStorage.getItem('amb');
  private UrlSSO = this.globalService.UrlSSO();
  private UrlStorageService = environment.UrlStorageService;

  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) { }


  public Get_Apps(): Observable<any> {

    this.enviroment = localStorage.getItem('amb');
    const json = JSON.stringify({
      enviroment: this.enviroment
    });

    return this.http.post<any>(
      this.UrlSSO + 'Authenticate/Get_Apps', json);
  }

  public Validate_Email(data): Observable<any> {

    this.enviroment = localStorage.getItem('amb');

    const obj = {
      enviroment: this.enviroment,
      data
    };
    const json = JSON.stringify(obj);
    return this.http.post<any>(
      this.UrlSSO + 'Authenticate/Validate_Email', json);
  }

  public Login(data) {
    this.enviroment = localStorage.getItem('amb');
    const obj = {
      enviroment: this.enviroment,
      data
    };

    const json = JSON.stringify(obj);
    return this.http.post<any>(
      this.UrlSSO + 'Authenticate/Login', json);
  }

  public Password_Reset(data) {
    this.enviroment = localStorage.getItem('amb');
    const obj = {
      enviroment: this.enviroment,
      data
    };
    const json = JSON.stringify(obj);
    return this.http.post<any>(
      this.UrlSSO + 'Authenticate/Password_Reset', json);
  }

  public Password_Reset_Code(code) {
    this.enviroment = localStorage.getItem('amb');
    const obj = {
      enviroment: this.enviroment,
      data: { Code: code }
    };
    const json = JSON.stringify(obj);
    return this.http.post<any>(
      this.UrlSSO + 'Authenticate/Password_Reset_Code', json);
  }

  public Password_Reset_New(data): Observable<any> {
    this.enviroment = localStorage.getItem('amb');
    const obj = {
      enviroment: this.enviroment,
      data
    };
    const json = JSON.stringify(obj);
    return this.http.post<any>(this.UrlSSO + 'Authenticate/Password_Reset_New', json);
  }

  public Password_Change(OldPassword: string, Password: string, PasswordConfirm: string): Observable<any> {
    const obj = {
      OldPassword,
      Password,
      PasswordConfirm
    };
    const json = JSON.stringify(obj);
    return this.http.post<any>(this.UrlSSO + 'Authenticate/Password_Reset_New_ByToken', json);
  }

  public AcceptTermsAndConditions(appToken) {
    this.enviroment = localStorage.getItem('amb');
    const obj = {
      enviroment: this.enviroment,
      data: {
        appToken
      }
    };
    const json = JSON.stringify(obj);

    return this.http.post<any>(
      this.UrlSSO + 'Authenticate/AcceptTermsAndConditions', json);
  }

  public Password_First_Reset_New_ByToken(data, loginToken): Observable<any> {
    const obj = {
      data
    };

    const json = JSON.stringify(obj);
    return this.http.post<any>(this.UrlSSO + 'Authenticate/Password_First_Reset_New_ByToken', json);
  }

  public TermsAndConditions(data, enviroment) {
    const obj = {
      data,
      enviroment
    };
    const json = JSON.stringify(obj);
    return this.http.post<any>(this.UrlSSO + 'Authenticate/TermsAndConditions', json);
  }

  public PrivacyPolicy(data, enviroment): Observable<any> {
    const obj = {
      data,
      enviroment
    };
    const json = JSON.stringify(obj);
    return this.http.post<any>(this.UrlSSO + 'Authenticate/PrivacyPolicy', json);
  }

  public Get_Preferences_Notification_List_By_Token(loginToken): Observable<any> {
    const obj = {
      loginToken
    };

    const json = JSON.stringify(obj);
    return this.http.post<any>(this.UrlSSO + 'Authenticate/Get_Preferences_Notification_List', json);
  }

  public Update_Preferences_Notification_By_Token(data): Observable<any> {
    const obj = {
      data
    };
    const json = JSON.stringify(obj);
    return this.http.post<any>(this.UrlSSO + 'Authenticate/Update_Preferences_Notification', json);
  }

  public Save_Preferences_Notification(): Observable<any> {
    const obj = {
    };
    const json = JSON.stringify(obj);
    return this.http.post<any>(this.UrlSSO + 'Authenticate/Save_Preferences_Notification', json);
  }

  public appTokenInfo(data) {
    const json = JSON.stringify(data);

    return this.http.post<any>(this.UrlSSO + 'Authenticate/Application_Info', json);
  }

  public Get_JWT(lt: string, at: string): Observable<any> {

    const amb = localStorage.getItem('amb');

    const json = JSON.stringify({
      enviroment: amb,
      LoginToken: lt,
      appToken: at
    });

    return this.http.post<any>(this.UrlSSO + 'Authenticate/GetLoginToken', json);
  }

  /*---------------------------------------------------------------Email----------------------------------------------*/


  public Validate_SMTP(company_SMTP: Company_SMTP): Observable<any> {

    const json = JSON.stringify(company_SMTP);

    return this.http.post<Response<any>>(this.UrlSSO + 'Authenticate/Validate_SMTP', json);
  }

  public SendNotificationFoliosPurchase(notificationFoliosPurchase: NotificationFoliosPurchase): Observable<any> {

    const json = JSON.stringify(notificationFoliosPurchase);

    return this.http.post<Response<any>>(this.UrlSSO + 'Authenticate/SendNotificationFoliosPurchase', json);
  }

  public SendNotificationNewUser(user: User): Observable<any> {

    const json = JSON.stringify(user);

    return this.http.post<Response<any>>(this.UrlSSO + 'Authenticate/SendNotificationNewUser', json);
  }

  /*---------------------------------------------------------------User----------------------------------------------*/

  public User_Info() {
    const json = JSON.stringify({
      funcion: 2,
      entidad: 'User',
      data: {}
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=User_Info', json);
  }

  public User_List(userLst) {
    const json = JSON.stringify({
      funcion: 3,
      entidad: 'User',
      data: {
        Page: userLst.Page,
        Id_Customer: userLst.Id_Customer,
        Id_Company: userLst.Id_Company,
        Status: userLst.Status,
        Order_Dir: userLst.Order_Dir,
        Order_Column: userLst.Order_Column,
        SearchKey: userLst.SearchKey,
        Pagination: userLst.Pagination
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=User_List', json);
  }

  public User_New_Edit(user: User) {

    if (user.Date_Add === '') {
      user.Date_Add = null;
    }

    const json = JSON.stringify(user);

    return this.http.post<Response<any>>(this.UrlSSO + 'Authenticate/User_NewEdit', json);
  }

  public User_By_Id(Id_User) {
    const json = JSON.stringify({
      funcion: 6,
      entidad: 'User',
      data: {
        Id_User
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=User_By_Id', json);
  }

  public User_Lenguaje_Update(Id_User, Id_Language) {
    const json = JSON.stringify({
      funcion: 7,
      entidad: 'User',
      data: {
        Id_User,
        Id_Language
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=User_Lenguaje_Update', json);
  }

  public User_By_Token() {
    const json = JSON.stringify({
      funcion: 8,
      entidad: 'User',
      data: {
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=User_By_Token', json);
  }

  public Users_By_Customer(Id_Customer) {
    const json = JSON.stringify({
      funcion: 9,
      entidad: 'User',
      data: {
        Id_Customer
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Users_By_Customer', json);
  }

  public UserProfile_List(Id_User) {
    const json = JSON.stringify({
      funcion: 10,
      entidad: 'User',
      data: {
        Id_User,
        
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=UserProfile_List', json);
  }

  public UserProfile_Change(Id_User, Id_Profile, Active: boolean) {
    const json = JSON.stringify({
      funcion: Active ? 11 : 12,
      entidad: 'User',
      data: {
        Id_User,
        Id_Profile
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=UserProfile_Change', json);
  }

  public User_Img_Edit(Id_User, ImgPath: string) {
    const json = JSON.stringify({
      funcion: 13,
      entidad: 'User',
      data: {
        Id_User,
        Img_Path: ImgPath
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=UserProfile_Change', json);
  }

  public Get_Menu_Adm() {
    
    const json = JSON.stringify({
      funcion: 32,
      entidad: 'User',
      data: {
        AppToken: environment.AdminToken,
        Id_Module: environment.Id_Module,
        Id_Tag: environment.Id_TagAdmin
      }
    });

    return this.http.post<any>(this.UrlSSO + 'app/model?d=Get_Menu_Adm', json);
  }

  public User_Multicompany_Change_Status(Id_User, IsActive: boolean) {
    const json = JSON.stringify({
      funcion: 42,
      entidad: 'User',
      data: {
        Id_User,
        Multicompany: IsActive ? 1 : 0
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=User_Multicompany_Change_Status', json);
  }

  /*---------------------------------------------------------------Canales----------------------------------------------*/

  public Channel_List(channelLst) {

    const json = JSON.stringify({
      funcion: 1,
      entidad: 'Channel',
      data: {
        SearchKey: channelLst.SearchKey,
        Page: channelLst.Page,
        Status: channelLst.Status,
        Id_Channel: channelLst.Id_Channel,
        Order_Column: channelLst.Order_Column,
        Order_Dir: channelLst.Order_Dir,
        Pagination: channelLst.Pagination
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Channel_List', json);
  }

  public Channel_Detail(Id_Channel) {

    const json = JSON.stringify({
      funcion: 2,
      entidad: 'Channel',
      data: {
        Id_Channel
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Channel_Detail', json);
  }

  public Channel_New_Edit(channel: Channel) {

    const json = JSON.stringify({
      funcion: 3,
      entidad: 'Channel',
      data: channel
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Channel_New_Edit', json);
  }

  public Channel_Update_Status(Id_Channel, Status: string) {

    const json = JSON.stringify({
      funcion: 5,
      entidad: 'Channel',
      data: {
        Id_Channel,
        Status
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Channel_Update_Status', json);
  }

  public Channel_All() {

    const json = JSON.stringify({
      funcion: 6,
      entidad: 'Channel',
      data: {
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Channel_All', json);
  }

  public Channel_List_DownLoad() {

    const json = JSON.stringify({
      funcion: 12,
      entidad: 'Channel',
      data: {
        Access_Key_DownLoad: 'MPX_SLDLCH'
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Channel_List_DownLoad', json);
  }

  public Channel_Contacts(Id_Channel) {

    const json = JSON.stringify({
      funcion: 9,
      entidad: 'Channel',
      data: {
        Id_Channel
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Channel_Contacts', json);
  }

  public Channel_Contact_New_Edit(channelContact: ChannelContact) {

    const json = JSON.stringify({
      funcion: 11,
      entidad: 'Channel',
      data: channelContact
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Channel_Contact_New_Edit', json);
  }

  /*---------------------------------------------------------------CATALOGOS----------------------------------------------*/

  public Catalog_Country() {

    const json = JSON.stringify({
      funcion: 1,
      entidad: 'Catalogs',
      data: {
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Catalog_Country', json);
  }

  public Catalog_State(Id_Country) {

    const json = JSON.stringify({
      funcion: 2,
      entidad: 'Catalogs',
      data: {
        Id_Country
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Catalog_State', json);
  }

  public Catalog_Ximplify_Users() {

    const json = JSON.stringify({
      funcion: 3,
      entidad: 'Catalogs',
      data: {
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Catalog_Ximplify_Users', json);
  }

  public Catalog_Tax_Regime(Type_Tax_Regime: string = '') {

    const json = JSON.stringify({
      funcion: 4,
      entidad: 'Catalogs',
      data: {
        Type_Tax_Regime
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Catalog_Tax_Regime', json);
  }

  public Catalog_Hierarchy() {

    const json = JSON.stringify({
      funcion: 5,
      entidad: 'Catalogs',
      data: {}
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Catalog_Hierarchy', json);
  }

  public Catalog_Type_Employee() {

    const json = JSON.stringify({
      funcion: 6,
      entidad: 'Catalogs',
      data: {}
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Catalog_Type_Employee', json);
  }

  public Catalog_Dimensions() {

    const json = JSON.stringify({
      funcion: 7,
      entidad: 'Catalogs',
      data: {}
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Catalog_Dimensions', json);
  }

  /*---------------------------------------------------------------Storage----------------------------------------------*/

  public FileUpload_ImagesResources(file: File) {

    const input = new FormData();
    const headers_ = new HttpHeaders({ xtype: 'file', 'Access-Control-Allow-Origin': '*' });

    input.append('file', file);
    input.append('KeyFile', 'Recursos');

    return this.http.post<any>(this.UrlStorageService, input, { headers: headers_ });
  }

  /*---------------------------------------------------------------Customer----------------------------------------------*/

  public Customer_List(customerLst) {

    const json = JSON.stringify({
      funcion: 3,
      entidad: 'Customer',
      data: {
        Page: customerLst.Page,
        Id_Customer: customerLst.Id_Customer,
        Id_Channel: customerLst.Id_Channel,
        SearchKey: customerLst.SearchKey,
        Status: customerLst.Status,
        MFA: customerLst.MFA,
        Order_Column: customerLst.Order_Column,
        Order_Dir: customerLst.Order_Dir,
        Pagination: customerLst.Pagination
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Customer_List', json);
  }

  public Customer_By_Id(Id_Customer) {

    const json = JSON.stringify({
      funcion: 4,
      entidad: 'Customer',
      data: {
        Id_Customer
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Customer_By_Id', json);
  }

  public Customer_New_Edit(customer: Customer) {
    if (customer.Id_Customer === 0) {
      customer = {
        Id_Customer: 0,
        Id_Channel: customer.Id_Channel,
        ChannelName: '',
        Status: 'I',
        StatusName: '',
        Name: customer.Name,
        Email: customer.Email,
        MFAName: '',
        MFA: 'I',
        Date_Add: null
      };
    }
    const json = JSON.stringify({
      funcion: (customer.Id_Customer === 0 ? 5 : 6),
      entidad: 'Customer',
      data: customer
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Customer_New_Edit', json);
  }

  public Customer_Application_NewEdit(customerApp: CustomerApp) {
    const json = JSON.stringify({
      funcion: (customerApp.Id_Customer_Application === 0 ? 30 : 31),
      entidad: 'Customer',
      data: customerApp
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Customer_Application_NewEdit', json);
  }

  public Customer_Application_List(Id_Customer) {

    const json = JSON.stringify({
      funcion: 32,
      entidad: 'Customer',
      data: {
        Id_Customer
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Customer_Application_List', json);
  }

  public Customer_Type_Pay_List(Id_Project) {

    const json = JSON.stringify({
      funcion: 33,
      entidad: 'Customer',
      data: {
        Id_Project
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Customer_Type_Pay_List', json);
  }

  public Customer_Application_Folio_New(customerFolio: CustomerFolio) {

    const json = JSON.stringify({
      funcion: 40,
      entidad: 'Customer',
      data: customerFolio
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Customer_Application_Folio_NewEdit', json);
  }

  public Customer_Application_Folio_Delete(Id_Customer_Application_Folio) {

    const json = JSON.stringify({
      funcion: 41,
      entidad: 'Customer',
      data: {
        Id_Customer_Application_Folio
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Customer_Application_Folio_Delete', json);
  }


  public Customer_Application_Folio_List(Id_Customer) {

    const json = JSON.stringify({
      funcion: 42,
      entidad: 'Customer',
      data: {
        Id_Customer
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Customer_Application_Folio_List', json);
  }

  public Customer_Module_Add_Edit(Id_Customer, Id_Module, Status: string) {

    const json = JSON.stringify({
      funcion: 113,
      entidad: 'Customer',
      data: {
        Id_Customer,
        Id_Module,
        Status
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Customer_Module_Add_Edit', json);
  }

  public Customer_Module_List_By_CustomerId(Id_Customer, Id_Project) {

    const json = JSON.stringify({
      funcion: 114,
      entidad: 'Customer',
      data: {
        Id_Customer,
        Id_Project
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Customer_Module_List_By_CustomerId', json);
  }

  /*---------------------------------------------------------------Application----------------------------------------------*/

  public Application_Combo(Use_Folios: boolean) {

    const json = JSON.stringify({
      funcion: 3,
      entidad: 'Application',
      data: {
        Use_Folios: Use_Folios ? 'V' : 'F'
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Application_Combo', json);
  }

  public Application_List() {

    const json = JSON.stringify({
      funcion: 7,
      entidad: 'Application',
      data: {
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Application_List', json);
  }

  

  public Application_Plan_Folio_List(Id_Application) {

    const json = JSON.stringify({
      funcion: 43,
      entidad: 'Application',
      data: {
        Id_Application
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Application_Plan_Folio_List', json);
  }

  /*---------------------------------------------------------------Company----------------------------------------------*/

  public Company_List(companyLst) {

    const json = JSON.stringify({
      funcion: 1,
      entidad: 'Company',
      data: {
        Id_Customer: companyLst.Id_Customer,
        Page: companyLst.Page,
        SearchKey: companyLst.SearchKey,
        Id_Company: companyLst.Id_Company,
        Status: companyLst.Status,
        Order_Column: companyLst.Order_Column,
        Order_Dir: companyLst.Order_Dir,
        Pagination: companyLst.Pagination
      }
    });

    return this.http.post<Response<any[]>>(this.UrlSSO + 'data/xiam?d=Company_List', json);
  }

  public Company_ById(Id_Company) {

    const json = JSON.stringify({
      funcion: 2,
      entidad: 'Company',
      data: {
        Id_Company
      }
    });

    return this.http.post<Response<CompanyEdit>>(this.UrlSSO + 'data/xiam?d=Company_ById', json);
  }

  public Company_New_Edit(Company: CompanyEdit) {

    const json = JSON.stringify({
      funcion: Company.Id_Company === 0 ? 3 : 4,
      entidad: 'Company',
      data: Company
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_New_Edit', json);
  }

  public Company_PAC_List(Id_Company) {

    const json = JSON.stringify({
      funcion: 10,
      entidad: 'Company',
      data: {
        Id_Company
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Company_PAC_List', json);
  }

  public PAC_Available_List(Id_Company) {

    const json = JSON.stringify({
      funcion: 6,
      entidad: 'Company',
      data: {
        Id_Company
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=PAC_Available_List', json);
  }

  public Company_NewPAC(pac: PAC) {

    const json = JSON.stringify(pac);

    return this.http.post<Response<any>>(this.UrlSSO + 'Authenticate/Company_NewPAC', json);
  }

  public Company_DeletePAC(Id_Company, Id_Pac) {

    const json = JSON.stringify({
      funcion: 8,
      entidad: 'Company',
      data: {
        Id_Company,
        Id_PAC: Id_Pac
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_DeletePAC', json);
  }

  public Company_PAC_Microservices(Id_PAC) {

    const json = JSON.stringify({
      funcion: 9,
      entidad: 'Company',
      data: {
        Id_PAC
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_PAC_Microservices', json);
  }

  public Company_PAC_ChangesStatus(Id_Company, Id_Pac, status: boolean) {

    const json = JSON.stringify({
      funcion: 11,
      entidad: 'Company',
      data: {
        Id_Company,
        Id_PAC: Id_Pac,
        Status: status ? 'A' : 'I'
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_PAC_ChangesStatus', json);
  }

  public Company_Logo_Edit(Id_Company, Logo_Path: string) {

    const json = JSON.stringify({
      funcion: 5,
      entidad: 'Company',
      data: {
        Id_Company,
        Logo_Path
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_Logo_Edit', json);
  }

  public Company_QR_Fiscal_Edit(Id_Company, QR_Fiscal: string) {

    const json = JSON.stringify({
      funcion: 13,
      entidad: 'Company',
      data: {
        Id_Company,
        QR_Fiscal
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_QR_Fiscal_Edit', json);
  }

  public Company_SMTP_By_Id(Id_Company) {

    const json = JSON.stringify({
      funcion: 21,
      entidad: 'Company',
      data: {
        Id_Company
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_SMTP_By_Id', json);
  }

  public Company_SMTP_New_Edit(company_SMTP: Company_SMTP) {

    const json = JSON.stringify({
      funcion: 22,
      entidad: 'Company',
      data: company_SMTP
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_SMTP_New_Edit', json);
  }

  public Company_SMTP_Reset(Id_Company: number) {

    const json = JSON.stringify({
      funcion: 23,
      entidad: 'Company',
      data: {
        Id_Company
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_SMTP_Reset', json);
  }

  public Multicompany_List(Id_User, SearchKey: string = '') {

    const json = JSON.stringify({
      funcion: 53,
      entidad: 'Company',
      data: {
        Id_User,
        SearchKey
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Multicompany_List', json);
  }

  public Multicompany_ChangeStatus(Id_Company, Id_User, active: boolean) {

    const json = JSON.stringify({
      funcion: active ? 54 : 55,
      entidad: 'Company',
      data: {
        Id_Company,
        Id_User
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Multicompany_ChangeStatus', json);
  }

  public Company_Center_Cost_New(Id_Company, Dimension, Description: string, ERP_Code: string) {

    const json = JSON.stringify({
      funcion: 60,
      entidad: 'Company',
      data: {
        Id_Company,
        Dimension,
        Description,
        ERP_Code
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_Center_Cost_New', json);
  }

  public Company_Center_Cost_Delete(Id_CostCentre) {

    const json = JSON.stringify({
      funcion: 61,
      entidad: 'Company',
      data: {
        Id_CostCentre
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_Center_Cost_Delete', json);
  }

  public Company_Center_Cost_List(Id_Company) {

    const json = JSON.stringify({
      funcion: 62,
      entidad: 'Company',
      data: {
        Id_Company
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_Center_Cost_List', json);
  }

  public Company_Center_Cost_AllCreditors(Id_CostCentre, All_Creditors: boolean) {

    const json = JSON.stringify({
      funcion: 63,
      entidad: 'Company',
      data: {
        Id_CostCentre,
        All_Creditors: All_Creditors ? 'V' : 'F'
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_Center_Cost_AllCreditors', json);
  }

  public Company_Center_Cost_User_List(Id_CostCentre) {

    const json = JSON.stringify({
      funcion: 64,
      entidad: 'Company',
      data: {
        Id_CostCentre
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_Center_Cost_User_List', json);
  }

  public Company_Center_Cost_Add_User(Id_CostCentre, Id_User, Status: boolean, Id_UserCostCenter) {

    const json = JSON.stringify({
      funcion: 65,
      entidad: 'Company',
      data: {
        Id_CostCentre,
        Id_UserCostCenter,
        Id_User,
        Status: Status ? 'A' : 'I'
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_Center_Cost_Add_User', json);
  }

  public Company_Project_New(Description: string, ERP_Code: string, Id_Company) {

    const json = JSON.stringify({
      funcion: 73,
      entidad: 'Company',
      data: {
        Description,
        ERP_Code,
        Id_Company
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_Project_New', json);
  }

  public Company_Project_Delete(Id_ERP_Project) {

    const json = JSON.stringify({
      funcion: 74,
      entidad: 'Company',
      data: {
        Id_ERP_Project
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_Project_Delete', json);
  }

  public Company_Project_List(Id_Company) {

    const json = JSON.stringify({
      funcion: 75,
      entidad: 'Company',
      data: {
        Id_Company
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_Project_List', json);
  }



  public Company_Branch_Office_New(Description: string, ERP_Id, Id_Company) {

    const json = JSON.stringify({
      funcion: 76,
      entidad: 'Company',
      data: {
        Id_Company,
        Description,
        ERP_Id
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_Branch_Office_New', json);
  }

  public Company_Branch_Office_Delete(Id_BusinessPlace) {

    const json = JSON.stringify({
      funcion: 77,
      entidad: 'Company',
      data: {
        Id_BusinessPlace
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_Branch_Office_Delete', json);
  }

  public Company_Branch_Office_List(Id_Company) {

    const json = JSON.stringify({
      funcion: 78,
      entidad: 'Company',
      data: {
        Id_Company
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Company_Branch_Office_List', json);
  }




  public Company_PriorityOrder(Id_Company, Automatic: boolean) {

    const json = JSON.stringify({
      funcion: 12,
      entidad: 'Company',
      data: {
        Id_Company,
        PriorityOrder: Automatic ? 'A' : 'M'
      }
    });

    return this.http.post<any>(this.UrlSSO + 'data/xiam?d=Company_PriorityOrder', json);
  }

  public Multicompany_ChangeCardCode(Id_Company, Id_User, CardCode) {

    const json = JSON.stringify({
      funcion: 72,
      entidad: 'Company',
      data: {
        Id_Company,
        Id_User,
        CardCode
      }
    });

    return this.http.post<Response<any>>(this.UrlSSO + 'data/xiam?d=Multicompany_ChangeCardCode', json);
  }

  public post(body: any): Observable<any> {
    const json = JSON.stringify(body);
    return this.http.post<Result>(this.UrlSSO + 'data/xiam', json);
  }

  public getAccessPermit(KeyAccess): Promise<UserAccess> {

    const obj = {
      data: {
        UserToken: localStorage.getItem('loginToken'),
        KeyAccess
      }
    };

    const json = JSON.stringify(obj);

    return this.http.post<any>(this.UrlSSO + 'Authenticate/User_Access', json, { headers: this.headers }).toPromise();
  }

  public get(pathUrl: string): Observable<any> {
    return this.http.get<Result>(this.UrlSSO + pathUrl);
  }

  public News_Get(amb): Observable<any> {
    const json = JSON.stringify({
      enviroment: amb,
      AppToken: localStorage.getItem('appToken'),
    });

    return this.http
      .post<Result>(this.UrlSSO + 'Authenticate/News_Get', json, { headers: this.headers });
  }

  public Video_Get(amb): Observable<any> {
    const json = JSON.stringify({
      enviroment: amb,
      AppToken: localStorage.getItem('appToken'),
    });

    return this.http
      .post<Result>(this.UrlSSO + 'Authenticate/Video_Get', json, { headers: this.headers });
  }

}
