import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomerList } from 'src/app/data/models/customer-list.model';
import { NotificationFoliosPurchase } from 'src/app/data/models/models';
import { ServicesService } from 'src/app/data/services/services.service';
import { SelectedValue } from 'src/app/validators/selectedValue';
import { Customer, CustomerApp, CustomerFolio } from '../customer';

@Component({
  selector: 'app-folios',
  templateUrl: './folios.component.html',
  styleUrls: ['./folios.component.css']
})
export class FoliosComponent implements OnInit {

  @Input() idCustomer: number;

  customerLst = new CustomerList();

  public customerSelected = new Customer();
  public CustomerModuleList: any;
  public isNewFolio = false;
  public listCustomersApp: CustomerApp[];
  public form: UntypedFormGroup;
  public form2: UntypedFormGroup;
  public listApp = [];
  public listAppBack = [];
  public listFolio = [];
  public listPlanFolio = [];
  public CustomerfolioSelected = new CustomerFolio();

  public viewCustomer = false;
  public isEdit = false;
  public isFree = false;
  filterType = '';

  constructor(
    private services: ServicesService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private formBuilder2: UntypedFormBuilder,

    private translate: TranslateService) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }


  }

  ngOnInit(): void {
    const myOffcanvas = document.querySelector('#offcanvasRight') as any;

    if (myOffcanvas !== null) {
      myOffcanvas.addEventListener('hidden.bs.offcanvas', () => {
        this.filterType = '';
      });
    }
    if (localStorage.getItem('chaStoId') !== null) {
      this.customerLst.Id_Channel = +localStorage.getItem('chaStoId');
    }
    if (localStorage.getItem('cusStoId') !== null) {
      this.customerLst.Id_Customer = +localStorage.getItem('cusStoId');
    }


    this.form = this.formBuilder.group({
      Name: ['', { validators: [Validators.required] }],
      Email: ['', { validators: [Validators.email, Validators.required] }],
      Id_Channel: ['0', { validators: [Validators.required, SelectedValue()] }],
    });

    this.form2 = this.formBuilder2.group({
      Id_Application: ['0', { validators: [Validators.required, SelectedValue()] }],
      Id_Folio_Selected: ['0', { validators: [Validators.required, SelectedValue()] }],
      Folios: ['0'],
      Description: [''],
      Folios2: [{ value: '0', disabled: true }]
    });

    this.Customer_Detail();
    this.Customer_Application_Folio_List(this.idCustomer);

  }

  GetErrorMessage(formulario: number) {


    if (formulario === 2) {

      let Id_Application = this.form2.get('Id_Application');

      if (Id_Application.hasError('required') || Id_Application.hasError('selectedValue')) {
        return '*' + this.translate.instant('FORM.VALIDATORS.Application');
      }

      let Id_Folio_Selected = this.form2.get('Id_Folio_Selected');

      if (Id_Folio_Selected.hasError('required') || Id_Folio_Selected.hasError('selectedValue')) {
        return '*' + this.translate.instant('FORM.VALIDATORS.Folio');
      }

      let Description = this.form2.get('Description');

        if (Description.hasError('required') || Description.hasError('selectedValue')) {
          return '*' + ('Escriba una descripción del concepto.');
        }
      

    }

    return '';
  }

  returnToList() {
    this.customerSelected = new Customer();
    this.isEdit = false;
  }


  Customer_Detail() {
    this.spinner.show();
    this.services.Customer_By_Id(this.idCustomer).subscribe(resp => {
      this.spinner.hide();

      if (resp.result)
        this.customerSelected = resp.data;

    });
  }

  onSorted2(sort) {
    let data = this.listFolio.slice();
    let sortedData = data.sort((a, b) => {
      const isAsc = sort.sortDirection === 'asc';
      switch (sort.sortColumn) {
        case 'Comments': return this.compare(a.Comments, b.Comments, isAsc);
        case 'Application_Name': return this.compare(a.Application_Name, b.Application_Name, isAsc);
        case 'Company_Name': return this.compare(a.Company_Name, b.Company_Name, isAsc);
        case 'Estatus_Name': return this.compare(a.Status_Name, b.Status_Name, isAsc);
        case 'Folios': return this.compare(a.Folios, b.Folios, isAsc);
        case 'Registration_Date': return this.compare(a.Registration_Date, b.Registration_Date, isAsc);
        case 'User_Name': return this.compare(a.UserName, b.UserName, isAsc);
        default: return 0;
      }
    });

    this.listFolio = sortedData;
  }
  compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }


  addFolio() {
    this.Application_Combo(true);
    this.isNewFolio = true;
    this.CustomerfolioSelected = new CustomerFolio();
  }


  ifViewTrash(folio, date) {
    const dat = date.split(' ');
    const da = dat[0].split('-');
    // tslint:disable-next-line:radix
    const x = parseInt(da[1]) - 1;
    // tslint:disable-next-line:radix
    const d = new Date(parseInt(da[0]), x, parseInt(da[2]));
    const dd = d.setMonth(d.getMonth());
    const today = new Date();
    const todayd = today.setMonth(today.getMonth());
    const dplus = d;
    const dplusd = dplus.setMonth(dplus.getMonth() + 1);
    if (todayd > dd && todayd < dplusd && folio === 'A') {
      return true;
    } else {
      return false;
    }
  }

  deleteFolio(Id_Customer_Application_Folio: number) {

    this.spinner.show();
    this.services.Customer_Application_Folio_Delete(Id_Customer_Application_Folio).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.toastr.success(this.translate.instant('CONSULTANTS.msg64'));
        this.CustomerfolioSelected = new CustomerFolio();
        this.isNewFolio = false;

        this.Customer_Application_Folio_List(this.idCustomer);
      }
    });

  }



  // ::::::::::::::::::::::::::::Metodos::::::::::::::::::::::::::::
  onSubmitFolios() {

    this.spinner.show();

    this.services.Customer_Application_Folio_New(this.CustomerfolioSelected).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {

        this.toastr.success(this.translate.instant('CONSULTANTS.msg63'));

        this.services.Customer_By_Id(this.CustomerfolioSelected.Id_Customer).subscribe(resp => {

          if (resp.result) {
            let infoCustomer: Customer = resp.data;

            //Enviamos el correo con la compra de los folios
            let notificationFoliosPurchase: NotificationFoliosPurchase = new NotificationFoliosPurchase();

            let app = this.listApp.filter(x => x.Id_Application == this.CustomerfolioSelected.Id_Application);

            notificationFoliosPurchase.customerName = infoCustomer.Name;
            notificationFoliosPurchase.customerEmail = infoCustomer.Email;

            /* Se omite la informacion de la compañia debido a que por el momento no se pueden generer mas paquetes a nivel company */
            notificationFoliosPurchase.companyRFC = "";
            notificationFoliosPurchase.companyName = "";
            notificationFoliosPurchase.Id_Company = 0;
            notificationFoliosPurchase.applicationName = app[0].Name;

            notificationFoliosPurchase.folios = this.CustomerfolioSelected.Folios.toString();
            notificationFoliosPurchase.Id_Application = this.CustomerfolioSelected.Id_Application;

            this.services.SendNotificationFoliosPurchase(notificationFoliosPurchase).subscribe(resp => { });
          }

          this.CustomerfolioSelected = new CustomerFolio();
          this.isNewFolio = false;

          this.Customer_Application_Folio_List(this.idCustomer);
        });
      }
    });

  }

  descriptionFolios(description) {
    this.CustomerfolioSelected.Description = description;
  }

  setFoliosPackage(folios: number) {
    this.CustomerfolioSelected.Folios = folios;
  }

  cancelFolio() {
    this.CustomerfolioSelected = new CustomerFolio();
    this.isNewFolio = false;
    this.form2.reset();
  }

  changeFolio(Id_Application_Plan_Folio) {

    let pack = this.listPlanFolio.filter(x => x.Id_Application_Plan_Folio == Id_Application_Plan_Folio);

    this.CustomerfolioSelected.Id_Application_Plan_Folio = Id_Application_Plan_Folio;

    if (Id_Application_Plan_Folio === 'free' || Id_Application_Plan_Folio === 0) {

      this.isFree = true;
      this.CustomerfolioSelected.Folios = 0;
      this.CustomerfolioSelected.Comments = 'Libre';
      this.form2.controls['Folios'].setValue(0);
    }
    else {

      this.isFree = false;
      this.CustomerfolioSelected.Folios = pack[0].Folios;
      this.CustomerfolioSelected.Comments = pack[0].Description;
      this.form2.controls['Folios2'].setValue(pack[0].Folios);

    }

  }

  changeAppFolio(Id_Application) {
    this.CustomerfolioSelected.Id_Application = Id_Application;
    this.CustomerfolioSelected.Id_Application_Plan_Folio = 0;
    this.CustomerfolioSelected.Folios = 0;
    this.CustomerfolioSelected.Id_Customer = this.idCustomer;
    this.Application_Plan_Folio_List(Id_Application);
  }




  // ::::::::::::::::::::::::::::Servicios::::::::::::::::::::::::::::

  Customer_Application_Folio_List(idCustomer) {
    this.spinner.show();
    this.services.Customer_Application_Folio_List(idCustomer).subscribe(resp => {
      this.spinner.hide();

      if (resp.result) {
        this.listFolio = resp.data;
      }

    });
  }



  Application_Combo(Use_Folios: boolean) {

    this.spinner.show();

    this.services.Application_Combo(Use_Folios).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.listApp = resp.data;
        this.listAppBack = resp.data;
      }
    }
    );
  }

  Application_Plan_Folio_List(Id_Application: Number) {

    this.listPlanFolio = [];
    this.spinner.show();

    this.services.Application_Plan_Folio_List(Id_Application).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.listPlanFolio = resp.data;
      }
    }
    );
  }

}
