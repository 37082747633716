import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from './callback/callback.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from '../guards/auth.guard';
import { AppsComponent } from './apps/apps.component';
import { StoreComponent } from './store.component';

const routes: Routes = [
  { path: 'store/callback/:amb/:ut', component: CallbackComponent , pathMatch: 'full' },
  { path: 'store', component: StoreComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'home', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'apps', component: AppsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StoreRoutingModule { }
