import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Customer } from '../../customer/customer';

@Component({
  selector: 'app-mytab-groups',
  templateUrl: './mytab-groups.component.html',
  styleUrls: ['./mytab-groups.component.css']
})
export class MytabGroupsComponent implements OnInit {

  @Input() operation = '';
  @Input() id ;
  @Output() backCrewList = new EventEmitter();
  
  Customers: Customer[] = [];
  isNew = false;
  generalView = true;
  usersView = false;

  constructor() { }

  ngOnInit(): void {
  }

  viewGeneral(){
    this.generalView = true;
    this.usersView = false;
  }

  viewUsers(){
    this.generalView = false;
    this.usersView = true;
  }
  backToCrewList(){
    this.backCrewList.emit();
  }

}
