<div class="container-fluid backgroundAnima">
    <!-- <div class="container-fluid  formLogin"> -->

        <div class="row justify-content-md-left" style="position: absolute;">
            <div class="col-">
                <img alt="Ximplify" class="logo ml-4 mt-4 mb-4"  src="https://xam-app.s3.us-west-2.amazonaws.com/images/simplesuit/logoXim_Horizontal-white1.svg">
            </div>
        </div>           
        
    <!-- </div> -->

    <div class="row" *ngIf="ScreenStep == 3" >
        <div class="smallDis col col-sm-12 col-md-5 d-flex flex-column align-items-center justify-content-center" style="height: 100vh; " >

            <!-- Ingreso de Usuario -->
             <div *ngIf="ScreenStep == 3" id="logreg-forms" [@slideInOut]>
                <app-user (userValid)="validUser($event)" (backApp)="backApps()" (backUS)="backUS()">
                </app-user>
            </div>
        </div>

        <div class="col-7 d-none d-sm-block d-md-block" style="background: #fff; ">
            <div class="container d-flex flex-column justify-content-center" style=" min-height: 100vh; overflow-y: auto;">
                <div class="mt-5">
                    <app-videos></app-videos>
                </div>
        
                <div  class="mt-5 mb-5" >
                    <app-news></app-news>
                </div>
            </div>
        </div>

    </div>

    <div class="row" *ngIf="ScreenStep == 4 || ScreenStep == 10 || ScreenStep == 8 || ScreenStep == 9 || ScreenStep == 11 || ScreenStep == 12" >
        
        <div class="smallDis col col-sm-12 col-md-5 d-flex flex-column align-items-center justify-content-center" style="height: 100vh; " >

            <!-- Ingreso de Usuario -->
             <!-- <div *ngIf="ScreenStep == 3" id="logreg-forms" [@slideInOut]>
                <app-user (userValid)="validUser($event)" (backApp)="backAmb(2)">
                </app-user>
            </div> -->

            <!-- Login Ingreso de contraseña -->
            <div *ngIf="ScreenStep == 4" id="logreg-forms" [@slideInOut]>
                <app-security (backApp)="backAmb()" (recoverPass)="passRecovery($event)" (passValid)="validPass($event)" (backLogin)="backToLogin($event)" (recoverPass)="passRecovery($event)" (codePass)="passCode($event)" (recoverPass)="newPassUser()" (preferences)='viewPreferences()'></app-security>
            </div>

            <!-- Ingreso de nueva contraseña -->
            <div *ngIf="ScreenStep == 8" id="logreg-forms" [@slideInOut]>
                <app-new-security (backLogin)="backToLogin($event)"></app-new-security>
            </div>

            <!-- Ingreso de nueva contraseña, nuevo usuarioapp-new-user-security -->
            <div *ngIf="ScreenStep == 9" id="logreg-forms" [@slideInOut]>
                <app-new-user-security (backLogin)="backToLogin($event)"></app-new-user-security>
            </div>

            <!-- Ingreso de codigo de recuperacion -->
            <div *ngIf="ScreenStep == 10" id="logreg-forms" [@slideInOut]>
                <app-code-recovery-pass (backLogin)="backToLogin($event)" (newPass)="newPass($event)">
                </app-code-recovery-pass>
            </div>

            <div *ngIf="ScreenStep == 11" id="logreg-forms" [@slideInOut]>
                <app-preferences></app-preferences>
            </div>
            <div *ngIf="ScreenStep == 12" id="logreg-forms" [@slideInOut]>
                <app-user-sessions (userValid)="validUser($event)"  (backLogin)="backToLogin($event)" ></app-user-sessions>
            </div>

        </div>

        <div class="col-7 d-none d-sm-block d-md-block" style="background: #fff; ">
            <div class="container d-flex flex-column justify-content-center" style=" min-height: 100vh; overflow-y: auto;">
                <div class="mt-5">
                    <app-videos></app-videos>
                </div>
        
                <div  class="mt-5 mb-5" >
                    <app-news></app-news>
                </div>
            </div>
        </div>

    </div>


    <div class="row" *ngIf="ScreenStep == 1 || ScreenStep == 2">
        <div class="d-flex flex-column align-items-center mt-5">
            <div class="col-auto mt-4 mt-md-0 loginItems">
                <div class="row justify-content-md-left pl-0 ml-0 pl-md-0 ml-md-0 mt-0 pl-sm-0 ml-sm-0">
                    <div class="col- animated flip infinite">
    
                        <!-- Selección de Ambiente -->
                        <div *ngIf="ScreenStep == 1" id="logreg-forms" [@slideInOut]>
                            <app-sel-enviroment (environmentValid)="validEnvironmet($event)">
                            </app-sel-enviroment>
                        </div>
    
                        <!-- Selección de Aplicación -->
                        <div *ngIf="ScreenStep == 2" id="logreg-forms" [@slideInOut]>
                            <app-sel-app (appValid)="validApp($event)" (backStep)="backAmb()">
                            </app-sel-app>
                        </div>
                    </div>
                </div>
                
                <div class="row justify-content-md-center pl-0 ml-0 pl-md-2 ml-md-0 mt-3 mt-sm-1 pl-sm-0 ml-sm-0">
                    <div class="pl-md-0 ml-md-0 pl-0 ml-0 text-center">
                        <!-- <a href="javascript:void(0)">Versión {{appVersion}}</a> -->
    
                        <!-- <ng-container *ngIf="terms">
                            <a (click)="viewTerms()" href="javascript:void(0)" data-toggle="modal" data-target="#termsModal" class="">  Términos y condiciones</a> |
                            <a (click)="viewAdvice()" href="javascript:void(0)" data-toggle="modal" data-target="#termsModal" class="">  Aviso de privacidad</a>
                        </ng-container> -->
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>

<!-- <div class="container-fluid  formLogin">

    <div class="row justify-content-md-left mt-3">
        <div class="col-">
            <img class="logo ml-4 mt-4" src="https://xam-app.s3.us-west-2.amazonaws.com/images/simplesuit/logoXim_Horizontal-white1.svg">
        </div>
    </div>

    
    
</div> -->


<!-- Modal -->
<div class="modal fade" id="termsModal" tabindex="-1" role="dialog" aria-labelledby="termsModalLabel" aria-hidden="true">
    <div (click)="destroyTerms()" class="modal-dialog  modal-lg modalTerms" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div [innerHTML]="contract"></div>
            </div>

        </div>
    </div>
</div>


<ngx-spinner bdColor="rgba(111,111,111,0.15)" size="medium" color="#379ff0" type="ball-atom"></ngx-spinner>