import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-month',
  templateUrl: './month.component.html',
  styleUrls: ['./month.component.css']
})
export class MonthComponent implements OnInit {

  @Output() monthSel = new EventEmitter<string>();

  ltsMonthGet: any [];
  typeMon = '';

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    if(localStorage.getItem('monthSto') !== null) {
      this.typeMon = localStorage.getItem('monthSto');
    }

    this.typeMonth();
  }

  selectMonth(e) {
    this.typeMon = e.target.value;
    localStorage.setItem('monthSto', String(this.typeMon));
    this.monthSel.emit(this.typeMon);
  }

  //Services
  typeMonth() {
    this.services
      .post({
        entidad: 'Payments',
        funcion: 3,
        data: {
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.ltsMonthGet = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

}
