import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {

  public isIfo = true;
  public UserInfo: any = {};

  constructor(
    private toastr: ToastrService,
    private services: ServicesService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    this.LoadData();
  }

  LoadData() {
    this.services.User_By_Token().subscribe(resp => {
      this.UserInfo = resp.data;
    });
  }

  editProfile() {
    this.isIfo = false;
  }

  cancelEdit() {
    this.isIfo = true;
  }

  SaveChanges() {
    this.isIfo = true;
  }

  uploaded(event: any) {

    let selectedFiles = event.target.files;
    let img = selectedFiles.item(0);

    if (img) {
      this.spinner.show();

      this.services.FileUpload_ImagesResources(img).subscribe(resp => {
        this.spinner.hide();

        if (resp.result) {

          let url = resp.data;
          if (url != undefined && url.length > 0) {

            this.spinner.show();
            this.services.User_Img_Edit(+this.UserInfo.Id_User, url).subscribe(resp2 => {


              this.spinner.hide();
              if (resp.result) {
                this.toastr.success('Imagen actualizada correctamente');
                this.LoadData();
              }
            });
          }
        }
      });
    }
  }
}
