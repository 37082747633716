import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.css'],
})
export class IndicatorsComponent implements OnInit {

  isNewIndicator = false;
  isConfig = false;
  isFilters = false;
  isGraph = false;
  isLog = false;
  isList = true;
  isNewKpi = false;
  isKpi = true;

  isNewEdit = '';
  position = 0;

  lstKpis = [];


  isforNew = {};
  infoNewKPI = {};

  constructor(
    private services: ServicesService,
    private translate: TranslateService
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    this.listKpisGet();
  }

  // Servicios

  addNewChart() {
    this.isList = false;
    this.isNewIndicator = true;
  }

  configKPI(e, kpi) {
      this.isNewEdit = e.e;
      this.position = e.position;
      kpi.isConfig = true;
      kpi.isKpi = false;
  }

  configKPINew(e) {
    if (e.e === 'N') {
      this.isNewKpi = true;
      this.isNewEdit = e;
      this.isConfig = true;
      this.isNewIndicator = false;
      this.isGraph = false;
      this.isforNew = e;
    }
  }

  closeTopNew(){
    this.isNewKpi = false;
    this.isList = true;
    this.isNewIndicator = false;
  }

  closeFilters(kpi) {
    kpi.isFilters = false;
    kpi.isKpi = true;
    this.listKpisGet();
  }

  closeCUpdate(kpi){
    kpi.isConfig = false;
    kpi.isKpi = true;
  }

  closeFiltersNew(){
    this.isNewKpi = false;
    this.isConfig = false;
    this.isFilters = false;
    this.isList = true;
    this.listKpisGet();
  }

  closeLog(kpi){
    kpi.isLog = false;
    kpi.isKpi = true;
  }

  delItem(id){
    this.listKpisGet();
  }

  addKpisDup(){
    this.listKpisGet();
  }

  editKPI(e, kpi) {
    this.isNewEdit = e;
    kpi.isConfig = false;
    kpi.isKpi = true;
    this.listKpisGet();
  }

  configFiltersNew(e) {
    this.infoNewKPI = e;
    this.isFilters = true;
    this.isConfig = false;
    this.isGraph = false;
  }

  configFilters(e, kpi) {
      kpi.isFilters = true;
      kpi.isKpi = false;
  }

  viewGraph() {
    this.isGraph = true;
    this.isFilters = false;
  }

  viewGraphF(kpi) {
    kpi.isKpi = true;
    kpi.isFilters = false;
    this.listKpisGet();
  }

  viewLogHistory(e, kpi) {
    kpi.isLog = true;
    kpi.isKpi = false;
  }

  closeNewCofig(){
    this.isNewKpi = false;
    this.isConfig = false;
    this.isList = true;
  }

  // Servicios

  listKpisGet() {
    this.services
      .post({
        entidad: 'KPIs',
        funcion: 1,
        data: {
          estatus: 'I',
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.lstKpis = result.data;
            this.lstKpis.map(x => x.isKpi = true);
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }
}
