import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import { CompanyEdit} from '../companies';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-project-company',
  templateUrl: './project-company.component.html',
  styleUrls: ['./project-company.component.css']
})
export class ProjectCompanyComponent implements OnInit {

  @Input() idCompany;
  public ERP_Projects: any[] = [];
  public Project: any = {};
  public companieSelected = new CompanyEdit();

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private services: ServicesService
  ) {

    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }

  }

  ngOnInit(): void {
    this.Company_Project_List(this.companieSelected.Id_Company);
  }

  Company_Project_List(Id_Company) {
    this.spinner.show();
    this.services.Company_Project_List(this.idCompany).subscribe(resp => {
      this.spinner.hide();
      if (resp.result){
        this.ERP_Projects = resp.data;
      }
    });

  }

  deleteProyect(Id_ERP_Project) {
    Swal.fire({
      title: '¿Estás seguro de querer eliminar este Proyecto?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#746AB0',
      cancelButtonColor: '#E83845',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.services.Company_Project_Delete(Id_ERP_Project).subscribe(resp => {
          if (resp.result) {
            this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));
            this.Company_Project_List(this.idCompany);
          }
        });
      }
    });
  }

  Company_Project_New() {

    this.services.Company_Project_New(this.Project.Description, this.Project.ERP_Code, this.idCompany).subscribe(resp => {
      if (resp.result) {
        this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));
        this.Company_Project_List(this.idCompany);
      }
    });

    this.Project.Description = '';
    this.Project.ERP_Code = '';
  }

}
