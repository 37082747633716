<div class="card dataCompany ms-3 me-4 mt-4">
    <div class="card-header bg-transparent border-0">
        <h5 class="card-title" style="margin-top: 20px;">{{ "GENERAL.logoCompany" | translate }}
        </h5>
        <div style="display: flex;">
            <div class="u-img image-upload text-center">
                <label for="logo-input" class="ejemplo-1">
                    <ng-container *ngIf="companieSelected.Logo_Path">
                        <a style="cursor: pointer;" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightCompany"
                        aria-controls="offcanvasRight"><img style="max-width: 200px;" 
                            src="{{companieSelected.Logo_Path}}?r={{getNumber()}}" alt="user"><br><u>Cambiar Logo
                        </u></a>
                    </ng-container> 
                    <ng-container *ngIf="!companieSelected.Logo_Path">
                        <a style="cursor: pointer;" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightCompany"
                        aria-controls="offcanvasRight"><img style="max-width: 200px;" 
                            src="../../../assets/images/starbucks-logo.png" alt="user"><br> <u>Subir Logo </u></a> 
                    </ng-container>
                    
                </label>
            </div>
        </div>
    </div>
</div>

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRightCompany" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <button type="button" class="btnArrow" data-bs-dismiss="offcanvas">
            <span><i class="fa fa-arrow-left"></i></span>
        </button>
        <h5 class="ps-4 mt-1" id="offcanvasRightLabel">Arrastra tu Imagen</h5>
    </div>
    <div class="offcanvas-body">
        <div class="col-auto px-0">
            <div class="row gx-0 mt-3 px-3">
                <div class="col- d-flex justify-content-center">
                    <ng-container>
                        <div class="csvPolitic form-group dragg">
                            <div class="form-group dragAndDrop text-center" style="width: 100%">
                                <input #logoinput (change)="LoadCompanyLogo($event)" name="fielName" id="fielName" type="file" ng2FileSelect [ngModel]="imagetoUpload" class="custom-file-input form-control-file text-primary font-weight-bold" accept="image/jpeg,image/jpg,image/png">
                                <span id="span-click" class="custom-file-control span-click" *ngIf="!fileNameChangeLogo">
                                    <div class="row justify-content-center">
                                        <div class="col-12">
                                            <p class="h4">
                                                <i class="fa fa-upload" aria-hidden="true"></i>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-">
                                            <p>Arrastra o da click para subir tu Logo</p>
                                        </div>
                                    </div>
                                </span>
                                <span id="span-click" class="custom-file-control span-click"  *ngIf="fileNameChangeLogo">
                                    <div class="row justify-content-center">
                                        <div class="col-12">
                                            <p class="h4 archivoSubido">
                                                <i class="far fa-check-circle"></i>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-">
                                            <p>{{ fileNameChangeLogo }}</p>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>