<ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item text-center colorTab">
        <a (click)="viewDetail()" class="nav-link active" id="detail-tab" data-toggle="tab" href="#detail" role="tab" aria-controls="detail" aria-selected="true"><i class="fas fa-tasks fa-2x"></i><br>Detalle</a>
    </li>
    <li *ngIf="!isNew" class="nav-item text-center colorTab">
        <a (click)="viewLanguages()" class="nav-link" id="language-tab" data-toggle="tab" href="#languages" role="tab" aria-controls="languages" aria-selected="false"><i class="fas fa-language fa-2x"></i> <br> Idiomas</a>
    </li>
</ul>

<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="detail" role="tabpanel" aria-labelledby="general-tab">
        <div *ngIf="detailView" class="card pb-3" style="background-color:#dcdcdc; ">
            <app-detail-menu [idMenuDetail]="idMenuDetail" (goBack)="returnToList()"></app-detail-menu>
        </div>
    </div>

    <div class="tab-pane fade" id="languages" role="tabpanel" aria-labelledby="aplication-tab">
        <div *ngIf="languageView" class="card pb-3" style="background-color:#dcdcdc; ">
            <app-languages-menu [idMenuDetail]="idMenuDetail"></app-languages-menu>
        </div>
    </div>
</div>