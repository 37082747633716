import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';


import { LoginComponent } from './login';
import { SecurityComponent } from './login/security/security.component';
import { UserComponent } from './login/user/user.component';
import { ToastrModule } from 'ngx-toastr';

import { FormsModule } from '@angular/forms';
import { ServicesService } from './data/services/services.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CodeRecoveryPassComponent } from './login/code-recovery-pass/code-recovery-pass.component';
import { NewSecurityComponent } from './login/new-security/new-security.component';
import { NewUserSecurityComponent } from './login/new-user-security/new-user-security.component';
import { PreferencesComponent } from './login/preferences/preferences.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AccountModule } from "./account/account.module";
import { StoreModule } from "./store/store.module";
import { SecurityInterceptorService } from './interceptors/security-interceptor.service';
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelEnviromentComponent } from './login/sel-enviroment/sel-enviroment.component';
import { SelAppComponent } from './login/sel-app/sel-app.component';
import { NewsComponent } from './news/news.component';
import { SecureurlPipe } from './news/secureurl.pipe';
import { VideosComponent } from './videos/videos.component';
import { ChartsModule } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { UserSessionsComponent } from './login/user-sessions/user-sessions.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SecurityComponent,
    UserComponent,
    CodeRecoveryPassComponent,
    NewSecurityComponent,
    NewUserSecurityComponent,
    PreferencesComponent,
    NotFoundComponent,
    SelEnviromentComponent,
    SelAppComponent,
    NewsComponent,
    SecureurlPipe,
    VideosComponent,
    UserSessionsComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AccountModule,
    StoreModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({preventDuplicates : true}),
    NgbModule,
    ChartsModule,
    ReactiveFormsModule,
    NgbCarouselModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecurityInterceptorService,
      multi: true
    },
    ServicesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
