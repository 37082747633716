<div class="card mt-3 ms-3 me-4">
    <div class="card-header bg-transparent border-0 generalTab">
        <div>
            <h4 class="card-title"><b>Perfil:</b> {{profileSelected.ProfileDescription}}</h4>
        </div>
        <div>
            <div class="row gx-0">
                <div class="col-auto">
                    <h5 class="card-title pull-left mt-1">Filtrar por status</h5>
                </div>
                <div class="col-auto">
                    <label class="switch">
                        <input name="status" [(ngModel)]="isActiveStatus" (change)="updateStatus($event)"
                            type="checkbox" id="status" />
                        <span class="slider round-in"></span>
                    </label>
                </div>
                <ng-container *ngIf="statusByProfile !== -1">
                    <div class="col-auto">
                        <h5 class="card-title pull-left mt-1">Limpiar filtro por status</h5>
                    </div>
                    <div class="col-auto">
                        <button class="noP ps-1" (click)="clearStatus()"><i class="fas fa-eraser"></i></button>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="row gx-0 mt-1">
            <div class="col-lg-12 col-md-9">
                <div *ngFor="let app of listApps; let i = index" class="table-responsive">
                    <table *ngIf="app.Menus != null" class="table table-striped">
                        <thead>
                            <tr>
                                <th style="width: 180px;">{{app.Application}}</th>
                                <th>Menús</th>
                                <th class="text-nowrap">{{ "GENERAL.status" | translate }}</th>
                                <th></th> <!-- Submenus primer nivel -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let menu of app.Menus; let j = index">
                                <td class="ps-5">
                                    <i *ngIf="menu.SubMenus != null && (i != idxApp || j != idxMenu)"
                                        (click)="showSubmenu(i, j)" class="far fa-plus-square btnApli"
                                        style="font-size: 22px; cursor: pointer;"></i>
                                    <i *ngIf="i == idxApp && j == idxMenu" (click)="cancel()"
                                        class="far fa-minus-square btnApli"
                                        style="font-size: 22px; cursor: pointer;"></i>
                                </td>
                                <td style="width: 220px;">{{menu.Menu}}</td>
                                <td>
                                    <label class="switch">
                                        <input [checked]="menu.Active == true ? true : false"
                                            (change)="changeStatus(menu.Id_Menu, $event.target.checked, menu.Menu)"
                                            name="status{{menu.Id_Menu}}" type="checkbox" id="status{{menu.Id_Menu}}" />
                                        <span class="slider round-in"></span>
                                    </label>
                                </td>
                                <td>
                                    <table *ngIf="showSubmenus && i == idxApp && j == idxMenu"
                                        class="table table-striped mt-1">
                                        <thead>
                                            <tr>
                                                <th>Submenú</th>
                                                <th>{{ "GENERAL.status" |translate }}</th>
                                                <th></th>
                                                <th style="width: 346px;"></th> <!-- Submenus segundo nivel -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let submenu of menu.SubMenus; let k = index">
                                                <td>{{submenu.Menu}}</td>
                                                <td>
                                                    <label class="switch">
                                                        <input [checked]="(submenu.Active == true ? true : false)"
                                                            (change)="changeStatus(submenu.Id_Menu, $event.target.checked, submenu.Menu)"
                                                            name="status{{submenu.Id_Menu}}" type="checkbox"
                                                            id="status{{submenu.Id_Menu}}">
                                                        <span class="slider round-in"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <i *ngIf="submenu.SubMenus != null && (i != idxApp || j != idxMenu || k != idxSubmenu)"
                                                        (click)="showSubmenu2(i, j, k)"
                                                        class="far fa-plus-square btnApli ps-4"
                                                        style="font-size: 22px; cursor: pointer;"></i>
                                                    <i *ngIf="k == idxSubmenu" (click)="cancel2()"
                                                        class="far fa-minus-square btnApli ps-4"
                                                        style="font-size: 22px; cursor: pointer;"></i>
                                                </td>
                                                <td>
                                                    <table
                                                        *ngIf="showSubmenus2 && i == idxApp && j == idxMenu && k == idxSubmenu"
                                                        class="table table-striped mt-1">
                                                        <thead>
                                                            <tr>
                                                                <th>Submenú (Segundo nivel)</th>
                                                                <th>{{ "GENERAL.status" |translate }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let subsubmenu of submenu.SubMenus; let l = index">
                                                                <td>{{subsubmenu.Menu}}</td>
                                                                <td>
                                                                    <label class="switch">
                                                                        <input
                                                                            [checked]="(subsubmenu.Active == true ? true : false)"
                                                                            (change)="changeStatus(subsubmenu.Id_Menu, $event.target.checked, subsubmenu.Menu)"
                                                                            name="status{{subsubmenu.Id_Menu}}"
                                                                            type="checkbox"
                                                                            id="status{{subsubmenu.Id_Menu}}">
                                                                        <span class="slider round-in"></span>
                                                                    </label>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>