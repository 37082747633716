import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first, repeat } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";

import { ServicesService } from 'src/app/data/services/services.service';
import { GlobalService } from 'src/app/global/global.service';
import { LoginData, PassRecoveryData } from './security';
import { environment } from 'src/environments/environment';
import { Session } from 'protractor';


@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.css']
})
export class SecurityComponent implements OnInit {
  @Output() passValid = new EventEmitter();
  @Output() backLogin = new EventEmitter();
  @Output() recoverPass = new EventEmitter();
  @Output() codePass = new EventEmitter();
  @Output() newUser = new EventEmitter();
  @Output() preferences = new EventEmitter();
  @Output() backApp = new EventEmitter();

  // tslint:disable-next-line: new-parens
  logindata = new LoginData;

  // tslint:disable-next-line: new-parens
  passRecoveryData = new PassRecoveryData;
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  lt: string;

  isLoginUser = true;
  isLoginPass = false;
  isMFA = false;
  isForgetPass = false;
  isForgetPassTwo = false;
  isnewPass = false;

  fieldTextType: boolean;

  email = '';
  sesUsers = [];

  codeError: any = '';

  loginToken = '';
  bearer = '';
  appVersion: string = environment.appVersion;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private allService: ServicesService,
    private globalService: GlobalService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    
    this.email = localStorage.getItem('email');
    this.sesUsers = JSON.parse(localStorage.getItem('sesUsers'));
    if (this.sesUsers == null) this.sesUsers = [];

    this.loginForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]]
    });

  }

  backApps(){
    this.backApp.emit();
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  
  onSubmit() {
    this.submitted = true;
   
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.logindata.username = this.email;
    this.logindata.password = this.f.password.value;
    this.logindata.appToken = localStorage.getItem('appToken');
    localStorage.removeItem('bearerToken');

    let amb = localStorage.getItem('amb');

    console.log('login Data:',  this.logindata);


    this.allService.Login(this.logindata)
      .subscribe((resp) => {

        console.log('resp', resp);

        this.loginToken = resp.loginToken;
        //resp.url = localStorage.getItem('callBackUrl') + amb +'/'+ this.loginToken;
        this.returnUrl = localStorage.getItem('callBackUrl');

        if (this.logindata.appToken.toUpperCase() == '65E22B2C-C250-4E0B-987A-BAD078412F05')
          resp.url = this.returnUrl + amb + '/' + this.loginToken + '/' + this.logindata.appToken;
        else
          resp.url = this.returnUrl + this.loginToken;


        // 'El usuario o la contraseña son incorrectas.'
        if (resp.code == -1) {
          this.codeError = resp.code;
        }
        else if (resp.code == -2) {
          this.toastr.warning('El usuario o la contraseña son incorrectas.', 'Alerta');
        }
        // 'Usuario Inactivo.'
        else if (resp.code == -3) {
          this.codeError = resp.code;
        }
        // 'La organización a la que pertenece el usuario esta inactiva.'
        else if (resp.code == -4) {
          this.toastr.warning('¡La organización a la que pertenece el usuario esta inactiva.', 'Alerta');
        }
        else if (resp.code == -5) {
          this.toastr.error('No cuenta con acceso a la aplicación.', 'Alerta');
        }
        // 'Usuario Valido'
        else if (resp.resultado === 1) {
          localStorage.setItem('loginToken', this.loginToken);

          // if (!resp.notificationsAccepted) {
            this.loading = true;
            this.spinner.show();
            this.toastr.success('¡Usuario Válido.', 'Bienvenido');

            // Guardar Sesion de usuario
            if (this.sesUsers.every((email) => email.toLowerCase() != this.email.toLowerCase()))
            {
              this.sesUsers.push(this.email);
              localStorage.setItem('sesUsers', JSON.stringify(this.sesUsers))
            }

            // Limpiar Variables
            localStorage.removeItem('email');
            localStorage.removeItem('appToken');
            localStorage.removeItem('amb');

            this.redirectApp(resp.url);

          // }
          // else {
          //   Herko : agregar lectura de Token largo para aceptar preferencias de notificaciones
          //   this.preferences.emit();
          //   this.toastr.warning('¡Actualiza tus preferencias', 'Bienvenido');
          // }
        }
        // No se han aceptado los términos y condiciones.'
        else if (resp.resultado === 3 || resp.resultado === 2) {

          this.allService.Get_JWT(this.loginToken, this.logindata.appToken.toUpperCase())
            .subscribe((result) => {
              localStorage.setItem('loginToken', result.message);

              if (result.result) {

                if (resp.resultado === 3) {
                  Swal.fire({
                    title: 'Aceptas nuestros terminos y condiciones',
                    text: 'Terminos y condiciones',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Acepto',
                    cancelButtonText: 'No Acepto'
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.AcceptTermsAndConditionsService(resp.url);
                      Swal.fire(
                        'Gracias!',
                        'Lorem Ipsum',
                        'success'
                      );
                    }
                  });
                  // this.toastr.warning('¡No se han aceptado los términos y condiciones.', 'Alerta');
                }
                // El usuario requiere cambio de conntraseña.'
                else if (resp.resultado === 2) {
                  Swal.fire({
                    title: 'ATENCIÓN',
                    text: 'El usuario ' + this.email + ', requiere cambio de contraseña',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Cambiar Contraseña'
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.recoverPass.emit();
                      //localStorage.setItem('loginToken', this.loginToken);
                    }
                  });
                }
              }
              else
                this.toastr.error(result.message, 'Alerta', { closeButton: true });
            });
        }
        else {
          this.toastr.error(`${resp.descripcion} (codigo: ${resp.resultado}) .`, 'Alerta', { closeButton: true });
        }

      },
        (error) => {

          this.loading = true;

          this.error = error.error.message;

          console.log('catch', error);

          if (this.error !== '') {
            this.toastr.warning(this.error, 'Alerta', {
              closeButton: true,
            });
          }

          this.loading = false;
        }
      );

  }

  redirectApp(url: string) {
    this.loading = true;
    this.spinner.show();
    window.open(url, '_self');
  }

  post(path, lt) {
    const form = document.createElement('form');
    form.method = 'post';
    form.action = path;

    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = 'lt';
    hiddenField.value = lt;

    form.appendChild(hiddenField);
    document.body.appendChild(form);
    form.submit();
  }

  passRecovery() {
    this.Password_ResetService();
    this.codePass.emit();
  }

  backToLogin() {
    this.backLogin.emit();
    localStorage.removeItem('email');
  }

  AcceptTermsAndConditionsService(url: string) {

    this.allService.AcceptTermsAndConditions(this.logindata.appToken).subscribe(resp => {
      if (this.globalService.verifyResponse(resp)) {
        this.loading = true;
        this.spinner.show();
        this.redirectApp(url);
      }
    });
  }

  Password_ResetService() {
    this.passRecoveryData.email = this.email;
    this.loading = true;
    this.spinner.show();
    this.allService.Password_Reset(this.passRecoveryData).subscribe(resp => {
      if (this.globalService.verifyResponse(resp)) {
        Swal.fire({
          title: 'Código enviado!',
          text: 'Ingresa el código enviado a ' + this.passRecoveryData.email,
        });
        this.loading = false;
        this.spinner.hide();
      }
    });
  }

}
