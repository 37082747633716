<div class="row mt-1 animate__animated animate__backInLeft mt-5">
  <div class="col-auto">
    <div class="card mt-2">
      <div class="card-body pt-2 px-2">
        <div class="row mt-1 justify-content-between align-items-center">
          <div class="col-auto">
            <h4 class="tittleCards pl-2">Nuevo Indicador</h4>
          </div>
          <div class="col-auto pr-4 mt-2">
            <button class="close">
              <i class="far fa-times-circle" (click)="closeNI()"></i>
            </button>
          </div>
        </div>
        <hr class="mt-0" />
        <div class="row mt-0">
          <div class="col-auto">
            <div class="form-group">
              <label for="typeKPI" class="mb-0 labelText">Tipo</label>
              <select (change)="selectTypeKpi($event)" class="form-control" id="typeKPI">
                <option value="">Selecciona una opción</option>
                <option *ngFor="let tKpi of lstKpisGenerate" value="{{tKpi.Id}}">
                  {{ tKpi.Value }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <label for="typeProject" class="mb-0 labelText">Proyecto</label>
              <select (change)="selectProject($event)" class="form-control" id="typeProject">
                <option value="">Selecciona una opción</option>
                <option *ngFor="let tProject of lstProyectGenerate" value="{{tProject.Id}}">
                  {{ tProject.Value }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-auto mt-4">
            <button [disabled]="typeKpi === '' || typeProyect === ''" class="btn btn-primary btnAdd "
              (click)="addNewConfig('N')"><i class="fas fa-plus-circle"></i> Agregar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>