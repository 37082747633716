import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Menu } from 'src/app/data/models/menu.model';
import { newLabel } from 'src/app/data/models/newLabel.model';
import { ServicesService } from 'src/app/data/services/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-languages-menu',
  templateUrl: './languages-menu.component.html',
  styleUrls: ['./languages-menu.component.css']
})
export class LanguagesMenuComponent implements OnInit {

  @Input() idMenuDetail: number;

  menuSelected = new Menu();
  newLabel = new newLabel();
  labels = [];
  lstLabels = [];
  languagesLst = [];
  title = '';
  showButton = false;

  constructor(
    private services: ServicesService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.menuById();
    this.labelsByMenu();
    this.listLanguages();
  }

  changeLanguage(Id_Language) {
    this.newLabel.Id_Language = Id_Language;
  }

  menuById() {
    this.services.post({
      entidad: 'Menu',
      funcion: 2,
      data: {
        Id_Menu: this.idMenuDetail
      }
    }).subscribe(result => {
      if (result.data) {
        this.menuSelected = result.data;
        this.labels = this.menuSelected.Labels;
        this.title = this.labels[0].Text;
      }
    }, error => {
      console.log('Error', error);
    });
  }

  labelsByMenu() {
    this.services.post({
      entidad: 'Menu',
      funcion: 8,
      data: {
        Id_Menu: this.idMenuDetail
      }
    }).subscribe(result => {
      if (result.data) {
        this.lstLabels = result.data;
        this.lstLabels.length == this.languagesLst.length ? this.showButton = false : this.showButton = true
      }
    }, error => {
      console.log('Error', error);
    });
  }

  listLanguages() {
    this.services.post({
      entidad: 'Catalogs',
      funcion: 11,
      data: {
      }
    }).subscribe(result => {
      if (result.data) {
        this.languagesLst = result.data;
        this.lstLabels.length == this.languagesLst.length ? this.showButton = false : this.showButton = true
      }
    }, error => {
      console.log('Error', error);
    });
  }

  createLabel(label: newLabel) {
    this.newLabel.Id_Label = this.menuSelected.Id_Label;
    this.services.post({
      entidad: 'Menu',
      funcion: 6,
      data: label
    }).subscribe(result => {
      if (result.data) {
        this.labelsByMenu();
        this.listLanguages();
        this.toastr.success('Etiqueta creada correctamente', 'Menus');
        this.newLabel.Id_Language = 0;
        this.newLabel.MenuText = '';
      }
    }, error => {
      console.log('Error', error);
    });
  }

  deleteLabel(language, label, menu) {
    Swal.fire({
      title: `¿Estás seguro de querer eliminar la etiqueta en ${label} del menú '${menu}'?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#746AB0',
      cancelButtonColor: '#E83845',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.newLabel.Id_Label = this.menuSelected.Id_Label;
        this.services.post({
          entidad: 'Menu',
          funcion: 7,
          data: {
            Id_Label: this.menuSelected.Id_Label,
            Id_Language: language
          }
        }).subscribe(result => {
          if (result.data) {
            this.labelsByMenu();
            this.listLanguages();
            this.toastr.success('Etiqueta eliminada correctamente', 'Menus');
            this.newLabel.Id_Language = 0;
          }
        }, error => {
          console.log('Error', error);
        });
      }
    });
  }

}
