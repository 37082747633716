import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-sel-enviroment',
  templateUrl: './sel-enviroment.component.html',
  styleUrls: ['./sel-enviroment.component.css']
})
export class SelEnviromentComponent implements OnInit {
  @Output() environmentValid = new EventEmitter<Number>();

  lstMenu = [
    { id: 1, text: 'Producción', image: 'fas fa-tablet-alt' },
    { id: 2, text: 'Pruebas', image: 'fas fa-vial' },
    { id: 3, text: 'Desarrollo', image: 'fas fa-code' }
  ];
  appVersion: string = environment.appVersion;
  constructor(
    ) { }

  ngOnInit(): void {
    
  }

  selAmb(e){
    this.environmentValid.emit(e);
  }

}
