import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'XIMSSO';

  fieldTextType: boolean;

  isLoginUser = true;
  isLoginPass = false;
  isMFA = false;
  isForgetPass = false;
  isForgetPassTwo = false;
  isnewPass = false;

  constructor() {}

  passRecovery() {
    this.isLoginUser = false;
    this.isLoginPass = false;
    this.isMFA = false;
    this.isForgetPass = true;
    this.isForgetPassTwo = false;
    this.isnewPass = false;
  }
  backToLogin() {
    this.isLoginUser = true;
    this.isLoginPass = false;
    this.isMFA = false;
    this.isForgetPass = false;
    this.isForgetPassTwo = false;
    this.isnewPass = false;
  }

  enterPass() {
    this.isLoginUser = false;
    this.isLoginPass = true;
    this.isMFA = false;
    this.isForgetPass = false;
    this.isForgetPassTwo = false;
    this.isnewPass = false;
  }

  enterSiteMFA() {
    this.isLoginUser = false;
    this.isLoginPass = false;
    this.isMFA = true;
    this.isForgetPass = false;
    this.isForgetPassTwo = false;
    this.isnewPass = false;
  }

  sendRecoveryPass() {
    this.isLoginUser = false;
    this.isLoginPass = false;
    this.isMFA = false;
    this.isForgetPass = false;
    this.isForgetPassTwo = true;
    this.isnewPass = false;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  
}
