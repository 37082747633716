import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChannelList } from 'src/app/data/models/channel-list.model';
import { CompanyList } from 'src/app/data/models/company-list.model';
import { CustomerList } from 'src/app/data/models/customer-list.model';
import { UserList } from 'src/app/data/models/user-list.model';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-pagination-global',
  templateUrl: './pagination-global.component.html',
  styleUrls: ['./pagination-global.component.css']
})
export class PaginationGlobalComponent implements OnInit {

  @Output() changePagination = new EventEmitter();
  @Output() changePageEmi = new EventEmitter();
  @Input() callbackFunction: () => void;
  @Input() collectionDinamic: number;
  @Input() actualPagination: number;

  channelLst = new ChannelList();
  customerLst = new CustomerList();
  companyLst = new CompanyList();
  // Accesos no tiene modelo
  userLst = new UserList();
  // Grupos no tiene modelo
  // Pagos no tiene modelo
  
  // selectedPage: any = 1;
  // collectionSize = 0;
  // totalPages = 0;
  Page = 1;
  Pagination = 10;

  constructor(
    private spinner: NgxSpinnerService,
    private services: ServicesService
  ) { 
    if (localStorage.getItem('paginationStorage') !== null) {
      this.Pagination = +localStorage.getItem('paginationStorage');
    } else {
      this.Pagination = 10;
    }
  }

  ngOnInit(): void {
    this.callbackFunction();
  }

  changeAmountView(e) {
    localStorage.setItem('paginationStorage', (e.target.value));
    this.changePagination.emit(e);
  }

  changePage(e) {
    this.changePageEmi.emit(e);
  }

}
