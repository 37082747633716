<!-- <div class="col mt-0 px-md-3 px-0"></div> -->
<!-- <div class="d-flex pb-6" style="background-color: red;"> 
  <div class="row">
    <div class="card px-0 pb-3 pt-0 cardSup">
      <div class="row">
        <div class="col px-0">
        </div>         
      </div>   
    </div>

  </div>
</div> -->
<!-- 
<div class="d-flex flex-row">
  <ng-container *ngFor="let n of videoLst">
    <div class="card px-2 py-1 notice me-2" style="width: 270px;">
      <div class="row">
        <div class="col-12">
          <h1>{{n.Title}}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12" class="textArticle" [innerHTML]="n.Text"></div>
      </div>
      <div *ngIf="n.Url_Video != ''" class="row mt-1">
          <div class="col" [innerHTML]="getVideo(n.Url_Video) | secureurl"></div>
      </div>
    </div>
  </ng-container>
</div> -->

<ngb-carousel>
  <ng-container *ngFor="let videos of videosGrouped">
    <ng-template ngbSlide>
      <div class="d-flex flex-row">
        <ng-container *ngFor="let n of videos">
          <div class="card px-2 py-1 notice me-2" style="width: 270px;">
            <div class="row">
              <div class="col-12">
                <h1>{{n.Title}}</h1>
              </div>
            </div>
            <div class="row">
              <div class="col-12" class="textArticle" [innerHTML]="n.Text"></div>
            </div>
            <div *ngIf="n.Url_Video != ''" class="row mt-1">
              <div class="col" [innerHTML]="getVideo(n.Url_Video) | secureurl"></div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>
</ngb-carousel>



<!-- <div class="d-flex flex-row">
  <ng-container *ngFor="let n of videoLst; let i = index">
    <div class="card px-2 py-1 notice me-2" style="width: 270px;" *ngIf="i < 3">
      <div class="row">
        <div class="col-12">
          <h1>{{n.Title}}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 textArticle" [innerHTML]="n.Text"></div>
      </div>
      <div *ngIf="n.Url_Video != ''" class="row mt-1">
        <div class="col" [innerHTML]="getVideo(n.Url_Video) | secureurl"></div>
      </div>
    </div>
  </ng-container>

</div> -->

