import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessage } from 'ng-bootstrap-form-validation';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  loading = false;

  newPassFormFirst = new UntypedFormGroup({});

  errorpasswordold: ErrorMessage[] = [
    {
      error: 'required',
      format: (label, error) => `La contraseña es requerida!`
    }, {
      error: 'minlength',
      format: (label, error) => `La contraseña debe tener al menos 8 caracteres!`
    }
  ];

  errorpassword1: ErrorMessage[] = [
    {
      error: 'required',
      format: (label, error) => `La contraseña es requerida!`
    }, {
      error: 'minlength',
      format: (label, error) => `La contraseña debe tener al menos 8 caracteres!`
    }, {
      error: 'pattern',
      format: (label, error) => `La contraseña debe tener al menos una letra minúscula, 1 letra mayúscula debe incluir al menos 1 número y debe tener por lo menos 8 caracteres!`
    }
  ];

  errorpassword2: ErrorMessage[] = [
    {
      error: 'required',
      format: (label, error) => `Confirmar la contraseña es requerido!`
    },
    {
      error: 'minlength',
      format: (label, error) => `La contraseña debe tener al menos 8 caracteres!`
    }, {
      error: 'pattern',
      format: (label, error) => `La contraseña debe tener al menos una letra minúscula, 1 letra mayúscula debe incluir al menos 1 número y debe tener por lo menos 8 caracteres!`
    }
  ];

  isInvalidPass = false;

  constructor(
    private toastr: ToastrService,
    private services: ServicesService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    this.newPassFormFirst = new UntypedFormGroup({
      PasswordOld: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20)
      ]),
      Password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)
      ]),
      Password2: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)
      ])
    });
  }

  onSubmit() {

    if (this.newPassFormFirst.value.PasswordOld === this.newPassFormFirst.value.Password) {
      this.toastr.warning('La nueva contraseña no puede ser igual a la anterior', 'alert');
    } else if (this.newPassFormFirst.value.Password !== this.newPassFormFirst.value.Password2) {
      this.isInvalidPass = true;
    } else {
      this.isInvalidPass = false;

      this.Password_Change(this.newPassFormFirst.value.PasswordOld, this.newPassFormFirst.value.Password, this.newPassFormFirst.value.Password2);
    }
  }

  matchPass(e: any) {

    if (e.target.value !== this.newPassFormFirst.value.Password) {
      this.isInvalidPass = true;
    } else {
      this.isInvalidPass = false;
    }
  }

  cancel() { }

  Password_Change(OldPassword: string, Password: string, PasswordConfirm: string) {

    this.spinner.show();
    this.services.Password_Change(OldPassword, Password, PasswordConfirm).subscribe(resp => {
      this.spinner.hide();

      if (resp.result && resp.code == 1) {
        this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));

        this.newPassFormFirst.patchValue({
          PasswordOld: '',
          Password: '',
          Password2: ''
        });

      }
    });

  }

}
