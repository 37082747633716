import { Directive, HostListener, HostBinding } from '@angular/core';

@Directive({
    selector: '[appSidebar]',
    exportAs: 'appSidebar'
})
export class SidebarDirective {

    targetElement = null;

    @HostBinding('class.is-open') click = false;
    screenHeight = 0;
    screenWidth = 0;

    constructor() {
        this.onResize();
        if (localStorage.getItem('menuState') === 'true' && this.screenWidth > 768) {
            this.click = true;
        } else {
            this.click = false;
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event?: undefined) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
        if (localStorage.getItem('menuState') === 'true' && this.screenWidth > 768) {
            this.click = true;
        } else {
            this.click = false;
        }
        if (this.screenWidth > 1569) {
            this.click = true;
        } else {
            this.click = false;
        }
    }
    @HostListener('click') onClic() {
        this.click = !this.click;
        if (this.click) {
            localStorage.setItem('menuState', String(this.click));
        } else {
            localStorage.setItem('menuState', String(this.click));
        }        
    }
}
