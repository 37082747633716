import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import { CompanyEdit } from '../companies';

@Component({
  selector: 'app-qr-company',
  templateUrl: './qr-company.component.html',
  styleUrls: ['./qr-company.component.css']
})
export class QrCompanyComponent implements OnInit {

  @Input() idCompany;

  public companieSelected = new CompanyEdit();

  imagetoUpload = '';
  fileQr: File;
  filesChangeQr: FileList;
  fileNameChangeQr: string;
  @ViewChild('qrinput') qrinput;
  @ViewChild('fileInputQrUnic') fileInputQrUnic;

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private services: ServicesService
  ) {

    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    this.companyById();
  }

  getNumber(){}

  companyById() {
    this.spinner.show();
    this.services.Company_ById(this.idCompany).subscribe(resp => {
      if (resp.result) {
        this.companieSelected = resp.data;
      }
      this.spinner.hide();
    });
  }

  UploadQRFiscal(event: any) {
    this.fileQr = event.target.files[0];
    this.filesChangeQr = event.target.files;
    this.fileNameChangeQr = this.filesChangeQr[0].name;
    let Qr_File = this.qrinput;
    // tslint:disable-next-line: max-line-length
    if (this.fileInputQrUnic !== undefined && this.fileInputQrUnic.nativeElement !== undefined && this.fileInputQrUnic.nativeElement.files[0] !== undefined) {
      Qr_File = this.fileInputQrUnic.nativeElement;
    }
    let selectedFiles = event.target.files;
    let file = selectedFiles.item(0);
    if (file !== undefined) {
      this.spinner.show();
      this.services.FileUpload_ImagesResources(file).subscribe(resp => {
        this.spinner.hide();
        if (resp.result) {
          let urlQr = resp.data;
          this.services.Company_QR_Fiscal_Edit(this.idCompany, urlQr).subscribe(resp => {
            if (resp.result) {
              this.toastr.success('Se ha Actualizado el QR');
              this.companieSelected.QR_Fiscal = urlQr;
            }
          });
        }
      });
    }
  }
  
}
