
export interface Response<T> {
  code?: number;
  result?: boolean;
  message?: string;
  data?: T;
}

export class NotificationFoliosPurchase {
  customerName: string = '';
  customerEmail: string = '';
  applicationName: string = '';
  companyRFC: string = '';
  companyName: string = '';
  folios: string = '';
  Id_Company: Number = 0;
  Id_Application: Number = 0;
}
