 <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item text-center colorTab">
        <a (click)="viewGeneral()" class="nav-link active" id="general-tab" data-toggle="tab" href="#General" role="tab" aria-controls="General" aria-selected="true"><i class="fas fa-tasks fa-2x"></i><br>General</a>
    </li>
    <li *ngIf="isNew" class="nav-item text-center colorTab">
        <a (click)="viewApss()" class="nav-link" id="aplication-tab" data-toggle="tab" href="#Aplicación" role="tab" aria-controls="Aplicación" aria-selected="false"><i class="fas fa-desktop fa-2x"></i> <br> Aplicación</a>
    </li>
    <li *ngIf="isNew" class="nav-item text-center colorTab">
        <a (click)="viewFolios()" class="nav-link" id="folios-tab" data-toggle="tab" href="#Folios" role="tab" aria-controls="Folios" aria-selected="false"><i class="far fa-file-alt fa-2x"></i> <br> Folios</a>
    </li>
</ul>

<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="General" role="tabpanel" aria-labelledby="general-tab">
        <ng-container *ngIf="generalView">
            <div class="card pb-3" style="background-color:#dcdcdc; ">
            <app-general (goBack)="returnToList()" [newEdit]="newEdit" [idCustomer]="idCustomer"></app-general>
            </div>
        </ng-container>
    </div>

    <div class="tab-pane fade" id="Aplicación" role="tabpanel" aria-labelledby="aplication-tab">
        <ng-container>
            <ng-container *ngIf="isAppView">
                <div class="card pb-3" style="background-color:#dcdcdc; ">
                <app-aplication [idCustomer]="idCustomer"></app-aplication>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <div class="tab-pane fade" id="Folios" role="tabpanel" aria-labelledby="folios-tab">
        <ng-container>
            <ng-container *ngIf="isFoliosView">
                <div class="card pb-3" style="background-color:#dcdcdc; ">
                <app-folios [idCustomer]="idCustomer"></app-folios>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>