<!-- Comienza encabezado -->
<div class="row page-titles mt-5">
    <div class="col-md-3 col-sm-12 col-lg-3 align-self-center">
        <h3 class="text-primary m-b-0 m-t-0">{{ "GENERAL.changePass" | translate }}</h3>
    </div>
</div>

<div class="row mt-3">
    <ng-container>
        <div class="col-md-12 col-lg-12 col-sm-12" style="margin-left: auto; margin-right: auto;">
            <div class="card">
                <div class="card-body generalTab ">

                    <div class="row justify-content-center">

                        <div class="col-lg-6 col-md-7">
                            <div class="row">
                                <div class="col-12 text-center mt-3">
                                    <h1>Ingresa nueva contraseña</h1>
                                </div>

                                <div class="col-12 text-center p-3 px-5">
                                    <h2>Ingresa tu antigua contraseña, una nueva contraseña y confirmala.</h2>
                                </div>

                                <div class="col-12 text-center p-3 px-5">
                                    <div class="alert alert-primary" role="alert">
                                        La contraseña nueva debe llevar 8 caracteres, por lo menos 1 letra mayúscula, 1 letra minúscula y un número.
                                      </div>
                                </div>

                            <div class="col-12">
                                <form [formGroup]="newPassFormFirst" (validSubmit)="onSubmit()">
                                    <div class="col">
                                        <div class="row gx-0 justify-content-center">
                                            <div class="col-auto changePass">

                                                <div class="mb-3 form-group loginForm" [customErrorMessages]="errorpasswordold">
                                                    <label for="pass" class="form-label">Antigua contraseña</label>
                                                    <show-hide-password size="lg" [btnOutline]="false">
                                                        <input type="password" formControlName="PasswordOld" class="form-control" id="oldpass" placeholder="Ingresa contraseña">
                                                    </show-hide-password>

                                                </div>

                                                <div class="mb-3 form-group loginForm" [customErrorMessages]="errorpassword1">
                                                    <label for="pass" class="form-label">Nueva contraseña</label>
                                                    <show-hide-password size="lg" [btnOutline]="false">
                                                        <input type="password" formControlName="Password" class="form-control" id="pass" placeholder="Ingresa contraseña">
                                                    </show-hide-password>
                                                </div>

                                                <div class="mb-3 form-group loginForm" [customErrorMessages]="errorpassword2">
                                                    <label for="pass" class="form-label">Repetir contraseña</label>
                                                    <show-hide-password size="lg" [btnOutline]="false">
                                                        <input (keyup)="matchPass($event)" type="password" formControlName="Password2" class="form-control" id="pass2" placeholder="Repetir contraseña">
                                                    </show-hide-password>
                                                    <ng-container *ngIf="isInvalidPass">
                                                        <span  ng-reflect-ng-class="invalid-feedback" class="invalid">Las contraseñas deben ser iguales!</span>
                                                    </ng-container>


                                                </div>

                                            </div>
                                        </div>

                                        <div class="row gx-0 justify-content-center loginbutton mt-2">
                                            <div class="col-auto">
                                                <button [disabled]="loading"class="btn btn-primary pull-right" type="submit">
                                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Confirmar</button>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </ng-container>



</div>
