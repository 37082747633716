<!-- <div class="row mt-0 px-md-3 px-0">
  <div class="col">
    <div class="card px-0 pb-3 pt-0 cardSup">
      
      <div class="row">
        <div class="col px-0">
        </div>
        
      </div>
      
    </div>
  </div>
</div> -->
<div class="d-flex flex-row flex-wrap">
  <ngb-carousel *ngIf="newsGrouped.length > 0">
    <ng-container *ngFor="let group of newsGrouped">
      <ng-template ngbSlide>
        <div class="card-group">
          <div class="card px-4 py-1 notice me-2 mb-2" *ngFor="let n of group">
            <div class="row">
              <div class="col-12">
                <h1>{{n.Title}}</h1>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-auto pr-1">
                <img class="rounded-circle" [src]="n.Img_User" alt="100x100" data-holder-rendered="true" />
              </div>
              <div class="col">
                <p class="mb-1 editor">{{n.Editor}}</p>
                <p class="mb-0 date">{{n.Date}}</p>
              </div>
              <div class="col-auto">
                <span class="badge badge-info">{{n.App_Name}}</span>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12" class="textArticle" [innerHTML]="n.Text"></div>
            </div>
            <div *ngIf="n.Text.length > 260" class="row pl-3">
              <div class="col-12 text-right">
                <a (click)="viewNotice(n)" href="javascript:void(0)" data-toggle="modal" data-target="#fullNotice" class="btnread">...Ver más </a>
              </div>
            </div>
            <div class="row mt-2">
              <ng-container *ngFor="let img of n.Imgs">
                <div class="images" [ngClass]="{
                                                    'col-4': n.Imgs.length > 2,
                                                    'col-6': n.Imgs.length == 2, 
                                                    'col-12': n.Imgs.length == 1
                                                  }">
                  <a (click)="viewImageBig(img.URL)" href="javascript:void(0)" data-toggle="modal" data-target="#exampleModal">
                    <img src="{{img.URL}}">
                  </a>
                </div>
              </ng-container>
            </div>
            <div *ngIf="n.Url_Video != ''" class="row mt-3">
              <div class="col" [innerHTML]="getVideo(n.Url_Video) | secureurl"></div>
            </div>
            <div *ngIf="n.URL_Encuesta != ''" class="row mt-3">
              <div class="col" [innerHTML]="getEncuesta(n.URL_Encuesta) | secureurl"></div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ngb-carousel>
</div>

  <!-- <div class="d-flex flex-row flex-wrap">
      <ng-container *ngFor="let n of newsLst; let i = index">
        <div class="card px-4 py-1 notice me-2 mb-2" style="width: 350px;" *ngIf="i < 2">
          <div class="row">
            <div class="col-12">
              <h1>{{n.Title}}</h1>
            </div>
          </div>
          <div class="row align-items-center">
              <div class="col-auto pr-1">
                  <img
                    class="rounded-circle"
                    alt="100x100"
                    src="{{n.Img_User}}"
                    data-holder-rendered="true"
                  />
                </div>
                <div class="col">
                  <p class="mb-1 editor">{{n.Editor}}</p>
                  <p class="mb-0 date">{{n.Date}}</p>
                </div>
            <div class="col-auto">
              <span class="badge badge-info">{{n.App_Name}}</span>
            </div>

          </div>
          <div class="row mt-2">
            <div class="col-12" class="textArticle" [innerHTML]="n.Text"></div>
          </div>
          <div *ngIf="n.Text.length > 260" class="row pl-3">
              <div class="col-12 text-right">
                  <a (click)="viewNotice(n)" href="javascript:void(0)" data-toggle="modal" data-target="#fullNotice" class="btnread">...Ver más </a>
              </div>
          </div>
          <div class="row mt-2">
              <ng-container *ngFor="let img of n.Imgs">
                  <div class="images" [ngClass]="{
                                                    'col-4': n.Imgs.length > 2,
                                                    'col-6': n.Imgs.length == 2, 
                                                    'col-12': n.Imgs.length == 1
                                                  }">
                      <a (click)="viewImageBig(img.URL)" href="javascript:void(0)" data-toggle="modal" data-target="#exampleModal">
                          <img src="{{img.URL}}">
                      </a>
                                          
                  </div>
              </ng-container>
            
          </div>
          <div *ngIf="n.Url_Video != ''" class="row mt-3">
              <div class="col" [innerHTML]="getVideo(n.Url_Video) | secureurl"></div>
          </div>
          <div *ngIf="n.URL_Encuesta != ''" class="row mt-3">
            <div class="col" [innerHTML]="getEncuesta(n.URL_Encuesta) | secureurl"></div>
        </div>                
        </div>
      </ng-container>

  </div> -->
  
  <!-- Modal -->
  <div class="modal fade" id="fullNotice" tabindex="-1" role="dialog" aria-labelledby="fullNoticeLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="fullNoticeLabel">{{noticeSel.Title}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body py-3 px-5">
              <div class="row align-items-center">
                  <div class="col-auto pr-1">
                      <img
                        class="rounded-circle"
                        alt="100x100"
                        src="{{noticeSel.Img_User}}"
                        data-holder-rendered="true"
                      />
                    </div>
                    <div class="col">
                      <p class="mb-1 editor">{{noticeSel.Editor}}</p>
                      <p class="mb-0 date">{{noticeSel.Date}}</p>
                    </div>
                <div class="col-auto">
                  <span class="badge badge-info">{{noticeSel.App_Name}}</span>
                </div>
  
              </div>
              <div class="row mt-2">
                <div class="col-12" class="textArticleC" [innerHTML]="noticeSel.Text"></div>
              </div>
  
              <div class="row mt-2">
                  <ng-container *ngFor="let img of noticeSel.Imgs">
                      <div class="images" [ngClass]="{'col-6': noticeSel.Imgs.length > 1, 'col-12': noticeSel.Imgs.length == 1}">
                          <a (click)="viewImageBig(img.URL)" href="javascript:void(0)" data-toggle="modal" data-target="#exampleModal">
                              <img src="{{img.URL}}">
                          </a>
                                              
                      </div>
                  </ng-container>
                
              </div>
              <div *ngIf="noticeSel.Url_Video != ''" class="row mt-3">
                  <div class="col" [innerHTML]="getVideo(noticeSel.Url_Video) | secureurl">

          
                    </div>
              </div>
          </div>
        </div>
      </div>
  </div>
  
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body imageM text-center">
          <img src="{{selImg}}" alt="">
        </div>

      </div>
    </div>
  </div>
