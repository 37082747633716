import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { crewData } from 'src/app/data/models/crew-data.model';
import { CustomerList } from 'src/app/data/models/customer-list.model';
import SearchItem from 'src/app/data/models/search-item.model';
import { ServicesService } from 'src/app/data/services/services.service';
import { Customer } from '../customer/customer';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-grupos',
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.css']
})
export class GruposComponent implements OnInit {

  customerLst = new CustomerList();

  id_Customer_Selected = 0;
  Customers: Customer[] = [];

  lstCrew: crewData[] = [];
  lstSearchItems: SearchItem[] = [];

  Title = 'Grupos';
  operationCrew = '';
  filterType = '';
  orderColumn = '';
  orderDir = '';
  searchTerm = '';
  statusCrew = '';
  idCrew = 0;
  Page = 1;
  Pagination = 10;
  selectedPage: any = 1;
  collectionSize = 0;
  totalPages = 0;
  AZdes = false;
  ZAdes = false;
  AZsub = false;
  ZAsub = false;
  AZcus = false;
  ZAcus = false;
  isCrewCatalog = true;
  isFormCrew = false;
  isTable = true;
  StatusComp = true;
  isRest = false;
  isStatusCrew = false;

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
    if (localStorage.getItem('cusStoId') !== null) {
      this.id_Customer_Selected = +localStorage.getItem('cusStoId');
    }
    if (localStorage.getItem('paginationStorage') !== null) {
      this.Pagination = +localStorage.getItem('paginationStorage');
    }
  }

  ngOnInit(): void {
    const myOffcanvas = document.querySelector('#offcanvasRight') as any;
    if (myOffcanvas !== null) {
      myOffcanvas.addEventListener('hidden.bs.offcanvas', () => {
        this.filterType = '';
      });
    }
    
    this.crewGet();
  }

  loadData() {
    this.crewGet();
  }

  viewStatus(event) {
    if (event.target.checked === true) {
      this.statusCrew = 'A';
    } else {
      this.statusCrew = 'I';
    }
    this.crewGet();
  }

  filterCrew(e) {
    if (e === 'O') {
      this.filterType = e;
    } else if (e === 'S') {
      this.filterType = e;
    }
  }

  filterByCustomer(e) {
    this.id_Customer_Selected = e;
  }

  changeAmountView(e) {
    this.Pagination = e.target.value;
    this.crewGet();
  }

  changePage(e) {
    this.Page = e;
    this.crewGet();
  }

  onSorted(e) {
    this.orderColumn = e.sortColumn;
  }

  searchCrew(e: any) {
    if (e.target.value !== '') {
      this.searchTerm = e.target.value;
      this.crewGet();
    }
    else {
      this.crewGet();
    }
  }

  newCrew() {
    this.idCrew = 0;
    this.isCrewCatalog = false;
    this.isFormCrew = true;
    this.operationCrew = 'N';
    this.isTable = false;
    localStorage.setItem('destiny', this.operationCrew);
  }

  detailCrew(id: number) {
    this.idCrew = id;
    this.isCrewCatalog = false;
    this.isFormCrew = true;
    this.operationCrew = 'D';
    this.isTable = false;
    localStorage.setItem('destiny', this.operationCrew);
  }

  backToCrewList() {
    this.isCrewCatalog = true;
    this.isFormCrew = false;
    this.operationCrew = '';
    this.lstSearchItems = [];
    this.isTable = true;
    localStorage.removeItem('cusStoId');
    localStorage.removeItem('cusStoNa');
    this.crewGet();
  }

  onSortedA(e) {
    this.orderDir = 'A';
    if (this.orderColumn === 'des' && this.orderDir === 'A'){
      this.isRest = true;
      this.AZdes = true;
      this.ZAdes = false;
      this.AZsub = false;
      this.ZAsub = false;
      this.AZcus = false;
      this.ZAcus = false;
    } else if (this.orderColumn === 'sub' && this.orderDir === 'A'){
      this.isRest = true;
      this.AZdes = false;
      this.ZAdes = false;
      this.AZsub = true;
      this.ZAsub = false;
      this.AZcus = false;
      this.ZAcus = false;
    }else if (this.orderColumn === 'cus' && this.orderDir === 'A'){
      this.isRest = true;
      this.AZdes = false;
      this.ZAdes = false;
      this.AZsub = false;
      this.ZAsub = false;
      this.AZcus = true;
      this.ZAcus = false;
    }
    this.crewGet();
  }

  onSortedD(e) {
    this.orderDir = 'D';
    if (this.orderColumn === 'des' && this.orderDir === 'D'){
      this.isRest = true;
      this.AZdes = false;
      this.ZAdes = true;
      this.AZsub = false;
      this.ZAsub = false;
      this.AZcus = false;
      this.ZAcus = false;
    } else if (this.orderColumn === 'sub' && this.orderDir === 'D'){
      this.isRest = true;
      this.AZdes = false;
      this.ZAdes = false;
      this.AZsub = false;
      this.ZAsub = true;
      this.AZcus = false;
      this.ZAcus = false;
    }else if (this.orderColumn === 'cus' && this.orderDir === 'D'){
      this.isRest = true;
      this.AZdes = false;
      this.ZAdes = false;
      this.AZsub = false;
      this.ZAsub = false;
      this.AZcus = false;
      this.ZAcus = true;
    }
    this.crewGet();
  }

  resetTable() {
    localStorage.removeItem('cusStoId');
    localStorage.removeItem('cusStoNa');
    localStorage.removeItem('paginationStorage');
    this.statusCrew = '';
    this.isStatusCrew = false;
    this.id_Customer_Selected = 0;
    this.orderDir = '';
    this.orderColumn = '';
    this.Page = 1;
    this.Pagination = 10;
    this.AZdes = false;
    this.ZAdes = false;
    this.AZsub = false;
    this.ZAsub = false;
    this.AZcus = false;
    this.ZAcus = false;
    this.isRest = false;
    return this.crewGet();
  }

  //Servicios

  crewGet() {
    this.services.post({
      entidad: 'Crew',
      funcion: 1,
      data: {
        SearchKey: this.searchTerm,
        Status: this.statusCrew,
        Id_Customer: this.id_Customer_Selected,
        Order_Column: this.orderColumn,
        Order_Dir: this.orderDir,
        Page: this.Page,
        Pagination: this.Pagination
      }
    }).subscribe(result => {
      if (result.data) {
        this.lstSearchItems = result.data;
      }
      else {
        this.lstCrew = [];
      }
      for (let i = 0; i < result.data.length; i++) {
        this.totalPages = result.data[i].Pages;
        this.collectionSize = result.data[i].Pages * 10;
      }
      if (result.data.length === 0) {
        this.totalPages = 1;
        this.collectionSize = 1;
        this.selectedPage = 1;
      }
    }, error => {
      console.log('Error', error);
    });

  }

  deleteCrew(Id_Crew1: Number, Title: String) {
    Swal.fire({
      title: `¿Estás seguro de querer eliminar el grupo ${Title}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#746AB0',
      cancelButtonColor: '#E83845',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.services.post({
          entidad: 'Crew',
          funcion: 4,
          data: {
            Id_Crew: Id_Crew1,
            Status: 'D'
          }
        }).subscribe(result => {
          if (result.code === 1) {
            this.toastr.success('Grupo eliminado correctamente', 'Grupos');
            this.crewGet();
          }
          else {
            this.toastr.error(result.message, 'Grupos');
          }
        }, error => {
          console.log(error),
            this.toastr.error('Error al eliminar el grupo', 'Grupos');
        }, () => { });
      }
    });
  }
}
