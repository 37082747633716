<div class="row me-2">
    <div class="text-end mt-1 mb-1">
        <button *ngIf="showButton" class="btnEd" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightCPac"
            aria-controls="offcanvasRightCPac"> <i class="far fa-plus-square"></i> Agregar Pac
        </button>
    </div>
</div>

<div class="card dataCompany ms-3 me-4">
    <div class="card-header bg-transparent border-0">
        <h5 class="card-title" style="margin-top: 10px; float: left;">{{ "GENERAL.priorityATM" | translate }}
        </h5>
        <label class="switch" style="float: left; margin-top: 5px; margin-left: 13px;">
            <input [disabled]="listPacAssigned.length == 0"
                [checked]="companieSelected.PriorityOrder == 'A'? true:false"
                (change)="changePriority($event.target.checked)" name="priority" type="checkbox" id="priority">
            <span class="slider round-in"></span>
        </label>
        <div class="table-responsive">
            <h5 class="card-subtitle sub" style="margin-top: 1px;"> {{ "COMPANIES.subtitlePAC" | translate }} </h5>
            <!-- <button *ngIf="inPAC" (click)="saveOrderPAC()" class="btn pull-right btn-success col-md-3 "
                style="max-width: 88px; margin-top: -42px;">
                {{ "GENERAL.save" | translate }}
            </button> -->
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th class="text-center">{{ "GENERAL.priority" | translate }}</th>
                        <th style="min-width: 180px;">PAC</th>
                        <th style="min-width: 250px;">{{ "GENERAL.name" | translate }}</th>
                        <th style="padding-left: 16px;">{{ "GENERAL.status" | translate }}</th>
                        <th style="text-align: left;">MicroServicios</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody *ngIf="companieSelected.PriorityOrder === 'A'">
                    <tr *ngFor="let pac of listPacAssigned; let i = index">
                        <td *ngIf="pac.OrderNo === 0" class="text-center"></td>
                        <td *ngIf="pac.OrderNo !== 0" class="text-center">{{pac.OrderNo}}</td>
                        <td>{{pac.PAC_Name}}</td>
                        <td>{{pac.PAC_User}}</td>
                        <td class="text-nowrap">
                            <div class="col-md-10">
                                <label class="switch">
                                    <input (click)="changeStatusPac($event.target.checked,pac)"
                                        [checked]="pac.STATUS == 'A'? true:false" name="status" type="checkbox"
                                        id="status">
                                    <span class="slider round-in"></span>
                                </label>
                            </div>
                        </td>
                        <td>
                            <button (click)="viewDetailPAC(pac)" class="btn pull-right btn-success col-md-3 mb-1 mt-1"
                                style="max-width: 88px; min-width: 88px; margin-left: auto; margin-right: auto;">
                                {{ "GENERAL.detail" | translate }}
                            </button>
                        </td>
                        <td>
                            <i (click)="deletePac(pac, pac.PAC_Name)" style="color:crimson; cursor: pointer;" class="fas fa-trash"></i>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="companieSelected.PriorityOrder === 'M'" dragula="VAMPIRES" [(dragulaModel)]="listPacAssigned">
                    <tr *ngFor="let pac of listPacAssigned; let i = index" class="grabbable">
                        <td *ngIf="pac.OrderNo === 0" class="text-center"></td>
                        <td *ngIf="pac.OrderNo !== 0" class="text-center">{{pac.OrderNo}}</td>
                        <td>{{pac.PAC_Name}}</td>
                        <td>{{pac.PAC_User}}</td>
                        <td class="text-nowrap">
                            <div class="col-md-10">
                                <label class="switch">
                                    <input (click)="changeStatusPac($event.target.checked,pac)"
                                        [checked]="pac.STATUS == 'A'? true:false" name="status" type="checkbox"
                                        id="status">
                                    <span class="slider round-in"></span>
                                </label>
                            </div>
                        </td>
                        <td>
                            <button (click)="viewDetailPAC(pac)" class="btn pull-right btn-success col-md-3 mb-1 mt-1"
                                style="max-width: 88px; min-width: 88px; margin-left: auto; margin-right: auto;">
                                {{ "GENERAL.detail" | translate }}
                            </button>
                        </td>
                        <td>
                            <!-- <i  class="fa fa-trash"
                                style="font-size: 28px; cursor: pointer;"></i> -->
                            <i (click)="deletePac(pac, pac.PAC_Name)" style="color:crimson; cursor: pointer;"
                                class="fas fa-trash"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
            <!-- <p>{{listPacAssigned | json}}</p> -->
        </div>
    </div>
</div>
<button *ngIf="inPAC" (click)="saveOrderPAC()" class="btn btnSave waves-effect waves-light m-r-10 mt-2 mr-4" style="float: right">{{ "GENERAL.save" | translate }}</button>
<button *ngIf="inPAC" (click)="cancelOrderPAC()" class="btn btnCancel waves-effect waves-light m-r-10 mt-2 mr-2" style="float: right;"> {{"GENERAL.cancel" | translate }} </button>

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRightCPac" aria-labelledby="offcanvasRightCPacLabel">
    <div class="offcanvas-header">
        <button type="button" class="btnArrow" data-bs-dismiss="offcanvas">
            <span><i class="fa fa-arrow-left"></i></span>
        </button>
        <h5 class="ps-4 mt-1" id="offcanvasRightCPacLabel">Agregar Pac</h5>
    </div>
    <div class="offcanvas-body">
        <div class="col-auto px-0">

            <div class="row gx-0 mt-3 px-3">
                <div class="col-auto">
                    <!-- <h4 class="card-title" style="margin-top: 20px;"><b>{{ "GENERAL.company" | translate }}:</b>
                        {{companieSelected.Name}}</h4> -->
                    <h5 class="card-title">Seleccionar PAC</h5>
                    <!-- <h6 class="card-subtitle"> {{ "COMPANIES.headerUser" | translate }} </h6> -->
                    <div *ngIf="isAddPac" class="row">

                        <div class="row">
                            <div *ngIf="true" class="col-">

                                <select (change)="selectPac($event.target.value)" class="custom-select col-12"
                                    id="inlineFormCustomSelect" style="width: 295px;">
                                    <option value="0" selected="">{{ "GENERAL.selectPAC" | translate }}...
                                    </option>
                                    <option *ngFor="let pac of listFreePacs"
                                        value="{{pac.Id_PAC}},{{pac.PAC_Name}},{{pac.Require_Password}}"
                                        id="{{pac.PAC_Name}}">
                                        {{pac.PAC_Name}}
                                    </option>
                                </select>


                            </div>
                            <div class="row">
                                <div class="col-md-2 mt-0 mb-2">
                                    <a class="tooltipp" placement="top" ngbTooltip="Detalle">
                                        <!-- <button *ngIf="newPacFre.Id_PAC !== '0' && newPacFre.Id_PAC !== 0"
                                            (click)="viewDetailPAC(newPacFre)" class="btn col-md-3">
                                            <i class="fas fa-info"></i>
                                        </button> -->
                                        <span *ngIf="newPacFre.Id_PAC !== '0' && newPacFre.Id_PAC !== 0"
                                            (click)="viewDetailPAC(newPacFre)" class="badge badge-pill badge-secondary"
                                            style="cursor: pointer; background-color: #5b2e7e">Ver Detalle</span>
                                    </a>
                                </div>
                            </div>


                        </div>
                        <div class="row hidePass">
                            <div class="col-auto mt-2" *ngIf="newPacFre.Require_Password === 'V'">
                                <span>Usuario Pac</span>
                                <show-hide-password size="sm" icon="fontawesome">
                                    <input [(ngModel)]="newPacFre.PAC_User" style="width: 250px;" required name="uPac"
                                        class="form-control " type="text"
                                        placeholder="{{ 'GENERAL.user' | translate }} {{ 'GENERAL.pac' | translate }}..."
                                        id="uPac">
                                </show-hide-password>
                                <!-- <a class="tooltipp" placement="top"
                                    ngbTooltip="{{ 'GENERAL.user' | translate }} {{ 'GENERAL.pac' | translate }}">
                                    <i class="fa fa-question-circle" style="font-size: 19px; cursor: pointer;"></i>
                                </a> -->
                            </div>
                            <div class="col-auto mt-2" *ngIf="newPacFre.Require_Password === 'V'">
                                <span>Contraseña Pac</span>
                                <show-hide-password size="sm" icon="fontawesome">
                                    <input [(ngModel)]="newPacFre.PAC_Password" style="width: 250px;" required
                                        name="pPac" class="form-control" type="password"
                                        placeholder="{{ 'GENERAL.password' | translate }} {{ 'GENERAL.pac' | translate }}..."
                                        id="pPac">
                                </show-hide-password>
                                <!-- <a class="tooltipp" placement="top"
                                    ngbTooltip="{{ 'GENERAL.password' | translate }} {{ 'GENERAL.pac' | translate }}">
                                    <i class="fa fa-question-circle" style="font-size: 19px; cursor: pointer;"></i>
                                </a> -->
                            </div>
                            <div class="col-auto mt-2" *ngIf="newPacFre.Require_Password === 'V'">

                                <show-hide-password *ngIf="isAddPassPac" size="sm" icon="fontawesome">
                                    <span>Segunda Contraseña Pac</span>
                                    <input [(ngModel)]="newPacFre.PAC_Password2"
                                        style="width: 180px; border-radius: 4px;" required name="pPac"
                                        class="form-control " type="password"
                                        placeholder="{{ 'GENERAL.password' | translate }}..." id="pPac">
                                </show-hide-password>

                                <!-- <a *ngIf="isAddPassPac" class="tooltipp" placement="top"
                                    ngbTooltip="{{ 'GENERAL.password' | translate }}">
                                    <i class="fa fa-question-circle" style="font-size: 19px; cursor: pointer;"></i>
                                </a> -->

                                <!-- <div class="checkbox checkbox-success" style="margin-top: 7px; margin-left: 25px;">
                                    <input (change)="isAddPassPac = !isAddPassPac;" id="checkbox33" type="checkbox"
                                        [checked]="isAddPassPac">
                                    <label for="checkbox33">{{
                                        "GENERAL.addSecondPass" | translate }}</label>
                                </div> -->
                                <div class="row mt-2">
                                    <div class="col-auto"><label>Agregar {{ "GENERAL.addSecondPass" | translate
                                            }}</label>
                                    </div>
                                    <div class="col-auto">
                                        <label class="switch">
                                            <input (change)="isAddPassPac = !isAddPassPac;" id="checkbox33"
                                                type="checkbox" [checked]="isAddPassPac">
                                            <span class="slider round-in mb-1"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <button *ngIf="newPacFre.Id_PAC !== '0' && newPacFre.Id_PAC !== 0" (click)="addPac()"
                                class="btn pull-right btnSave col-md-3 ms-2 mt-2"
                                style="min-width: 310px;" data-bs-dismiss="offcanvas">
                                {{ "GENERAL.add" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>