import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import { CompanyEdit} from '../companies';
import Swal from 'sweetalert2';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';
import { throwError } from 'rxjs';


@Component({
  selector: 'app-sat-company',
  templateUrl: './sat-company.component.html',
  styleUrls: ['./sat-company.component.css']
})
export class SatCompanyComponent implements OnInit {

  @Input() idCompany;

  SAT = {
    passwordCertificate: '',
    user: '',
    pass: ''
  };

  SATNEW = {
    Password: '',
    Certificate: '',
    Key: ''
  };

  @ViewChild('fileInputCerCSD') fileInputCerCSD;
  @ViewChild('fileInputCerUnicCSD') fileInputCerUnicCSD;
  @ViewChild('fileInputCerCSD2') fileInputCerCSD2;
  @ViewChild('fileInputCerUnicCSD2') fileInputCerUnicCSD2;
  @ViewChild('fileInputKey') fileInputKey;
  @ViewChild('fileInputUnicKey') fileInputUnicKey;
  @ViewChild('fileInputKey2') fileInputKey2;
  @ViewChild('fileInputUnicKey2') fileInputUnicKey2;

  @ViewChild('fileInputCerUnicCSD') clearInputVariable: ElementRef;
  @ViewChild('fileInputUnicKey') clearInput2Variable: ElementRef;
  @ViewChild('fileInputCerUnicCSD2') clearInput3Variable: ElementRef;
  @ViewChild('fileInputUnicKey2') clearInput4Variable: ElementRef;

  public companieSelected = new CompanyEdit();
  fileNameChangeCerCSD: String;
  clearFile : any;
  typeUploadUnicFileCerCSD = '';
  typeUploadUnicFileCerCSD2 = '';
  typeUploadUnicFileKey = '';
  typeUploadUnicFileKey2 = '';
  fileNameChangeCerCSD2 = '';
  fileNameChangeKey = '';
  fileNameChangeKey2 = '';

  fileCerCSD: File;
  fileCerCSD2: File;
  fileKey: File;
  fileKey2: File;
  filesChangeCerCSD: FileList;
  filesChangeCerCSD2: FileList;
  filesChangeKey: FileList;
  filesChangeKey2: FileList;
  
  isEditFiles = false;
  isEditSAT = false;

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private services: ServicesService,
    private http: HttpClient,
  ) {

    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }

  }

  ngOnInit(): void {
    this.companyById();
  }

  updateCer(event) {
    this.fileCerCSD = event.target.files[0];
    this.filesChangeCerCSD = event.target.files;
    this.fileNameChangeCerCSD = this.filesChangeCerCSD[0].name;
    let CER_File = this.fileInputCerCSD;
    if (this.fileInputCerUnicCSD !== undefined && this.fileInputCerUnicCSD.nativeElement !== undefined && this.fileInputCerUnicCSD.nativeElement.files[0] !== undefined) {
      CER_File = this.fileInputCerUnicCSD.nativeElement;
    }
    if (this.typeUploadUnicFileCerCSD === '') {
      this.fileCerCSD = event.target.files[0];
    }
  }

  updateKey(event) {
    this.fileKey = event.target.files[0];
    this.filesChangeKey = event.target.files;
    this.fileNameChangeKey = this.filesChangeKey[0].name;
    let KEY_File = this.fileInputKey;
    if (this.fileInputUnicKey !== undefined && this.fileInputUnicKey.nativeElement !== undefined && this.fileInputUnicKey.nativeElement.files[0] !== undefined) {
      KEY_File = this.fileInputUnicKey.nativeElement;
    }
    if (this.typeUploadUnicFileKey === '') {
      this.fileKey = event.target.files[0];
    }
  }

  updateCer2(event) {
    this.fileCerCSD2 = event.target.files[0];
    this.filesChangeCerCSD2 = event.target.files;
    this.fileNameChangeCerCSD2 = this.filesChangeCerCSD2[0].name;
    let CER_File = this.fileInputCerCSD2;
    if (this.fileInputCerUnicCSD2 !== undefined && this.fileInputCerUnicCSD2.nativeElement !== undefined && this.fileInputCerUnicCSD2.nativeElement.files[0] !== undefined) {
      CER_File = this.fileInputCerUnicCSD2.nativeElement;
    }
    if (this.typeUploadUnicFileCerCSD2 === '') {
      this.fileCerCSD2 = event.target.files[0];
    }
  }

  updateKey2(event) {
    this.fileKey2 = event.target.files[0];
    this.filesChangeKey2 = event.target.files;
    this.fileNameChangeKey2 = this.filesChangeKey2[0].name;
    let CER_File = this.fileInputKey2;
    if (this.fileInputUnicKey2 !== undefined && this.fileInputUnicKey2.nativeElement !== undefined && this.fileInputUnicKey2.nativeElement.files[0] !== undefined) {
      CER_File = this.fileInputUnicKey2.nativeElement;
    }
    if (this.typeUploadUnicFileKey2 === '') {
      this.fileKey2 = event.target.files[0];
    }
  }

  cancelFiles() {
    this.isEditFiles = false;
    this.fileNameChangeCerCSD = '';
    this.clearInputVariable.nativeElement.value = "";
    this.clearInput2Variable.nativeElement.value = "";
    this.typeUploadUnicFileCerCSD = '';
    this.fileNameChangeKey = '';
    this.typeUploadUnicFileKey = '';
    this.SAT.passwordCertificate = '';
  }

  cancelSAT() {
    this.isEditSAT = false;
    this.fileNameChangeCerCSD2 = '';
    this.fileNameChangeKey2 = '';
    this.clearInput3Variable.nativeElement.value = "";
    this.clearInput4Variable.nativeElement.value = "";
    this.SATNEW.Password = '';
  }

  // SERVICIOS

  companyById() {
    this.spinner.show();
    this.services.Company_ById(this.idCompany).subscribe(resp => {

      if (resp.result) {
        this.companieSelected = resp.data;
      }
      this.spinner.hide();
    });
  }

  public uploadDocsCSD(): Observable<any> {
    const url = "https://apps.ximplify.mx/";
    // const url = "https://localhost:44340/"; //Pruebas en local
    const fileBrowser = this.fileInputCerUnicCSD.nativeElement;
    const fileBrowser2 = this.fileInputUnicKey.nativeElement;
    if (fileBrowser.files && fileBrowser.files[0] && fileBrowser2.files[0]) {
      const formData = new FormData();
      formData.append('Certificate', fileBrowser.files[0]);
      formData.append('Key', fileBrowser2.files[0]);
      formData.append('Id_Company', this.idCompany);
      formData.append('Password', this.SAT.passwordCertificate);

      const xhr = new XMLHttpRequest();

      let headers_ = new HttpHeaders().set('xtype', 'file').set('Access-Control-Allow-Origin', '*').set('JWT', localStorage.getItem('loginToken'));

      return this.http.post<any>(
        url + 'Signature/Signature_ESign', formData, { headers: headers_ })
        .catch(error => {
          return throwError('An error occurred:', error.error.message);
        });
    } else {
      this.toastr.warning('Favor agregar ambos archivos y contraseña.', 'Alerta!');
    }
    return null;
  }

  uploadCSD() { 
    const fileBrowser = this.fileInputCerUnicCSD.nativeElement;
    const fileBrowser2 = this.fileInputUnicKey.nativeElement;

    if (fileBrowser.files[0] === undefined || fileBrowser2.files[0] === undefined) {
      const formData = new FormData();
      formData.append('Certificate', fileBrowser.files[0]);
      formData.append('Key', fileBrowser2.files[0]);
      const xhr = new XMLHttpRequest();
      this.toastr.warning('Favor agregar ambos archivos y contraseña.', 'Alerta!');
    } else if (this.SAT.passwordCertificate === '') {
      this.toastr.warning('Favor de ingresar la contraseña.', 'Alerta!');
    } else if (fileBrowser.files[0] && fileBrowser2.files[0]) {
      Swal.fire({
        title: 'Aceptas nuestros terminos y condiciones',
        text: 'Terminos y condiciones',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Acepto',
        cancelButtonText: 'No Acepto'
      })
        .then((result) => {
          if (result.value) {
            this.uploadDocsCSD().subscribe(resp => {
              if (resp.result) {
                this.companyById();
                this.isEditSAT = false;
                this.fileInputCerCSD = '';
                this.fileNameChangeCerCSD = '';
                this.fileInputUnicKey = '';
                this.fileNameChangeKey = '';
                this.SAT.passwordCertificate = '';
                this.toastr.success('Se ha actualizado su información.', 'Éxito!');
              } else {
                this.toastr.error(resp.message, 'Error!');
              }
            });
          } else {
            // this.toastr.error(resp.Message, 'Error!', { toastLife: 10000, showCloseButton: true });
          }
        });
    }
  }

  public uploadDocsEF(): Observable<any> {

    const url = "https://apps.ximplify.mx/";
    // const url = "https://localhost:44340/";
    const fileBrowser3 = this.fileInputCerUnicCSD2.nativeElement;
    const fileBrowser4 = this.fileInputUnicKey2.nativeElement;
    if (fileBrowser3.files && fileBrowser3.files[0] && fileBrowser4.files[0]) {
      const formData = new FormData();
      formData.append('Certificate', fileBrowser3.files[0]);
      formData.append('Key', fileBrowser4.files[0]);
      formData.append('Id_Company', this.idCompany);
      formData.append('Password', this.SATNEW.Password);

      const xhr = new XMLHttpRequest();

      let headers_ = new HttpHeaders().set('xtype', 'file').set('Access-Control-Allow-Origin', '*').set('JWT', localStorage.getItem('loginToken'));

      return this.http.post<any>(
        url + 'Signature/Signature_EFSign', formData, { headers: headers_ })
        .catch(error => {
          return throwError('An error occurred:', error.error.message);
        });
    } else {
      this.toastr.warning('Favor agregar ambos archivos y contraseña.', 'Alerta!');
    }
    return null;
  }

  uploadEF() { 
    const fileBrowser3 = this.fileInputCerUnicCSD2.nativeElement;
    const fileBrowser4 = this.fileInputUnicKey2.nativeElement;

    if (fileBrowser3.files[0] === undefined || fileBrowser4.files[0] === undefined) {
      const formData = new FormData();
      formData.append('Certificate', fileBrowser3.files[0]);
      formData.append('Key', fileBrowser4.files[0]);
      const xhr = new XMLHttpRequest();
      this.toastr.warning('Favor agregar ambos archivos y contraseña.', 'Alerta!');
    } else if (this.SATNEW.Password === '') {
      this.toastr.warning('Favor de ingresar la contraseña.', 'Alerta!');
    } else if (fileBrowser3.files[0] && fileBrowser4.files[0]) {
      Swal.fire({
        title: 'Aceptas nuestros terminos y condiciones',
        text: 'Terminos y condiciones',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Acepto',
        cancelButtonText: 'No Acepto'
      })
        .then((result) => {
          if (result.value) {
            this.uploadDocsEF().subscribe(resp => {
              if (resp.result) {
                this.companyById();
                this.isEditSAT = false;
                this.toastr.success('Se ha actualizado su información.', 'Éxito!');
                this.fileInputCerCSD2 = '';
                this.fileNameChangeCerCSD2 = '';
                this.fileInputUnicKey2 = '';
                this.fileNameChangeKey2 = '';
                this.SATNEW.Password = '';
              } else {
                this.toastr.error(resp.message, 'Error!');
              }
            });
          } else {
            // this.toastr.error(resp.Message, 'Error!', { toastLife: 10000, showCloseButton: true });
          }
        });
    }
  }
}
