import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Menu } from '../../../data/models/menu.model';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-detail-menu',
  templateUrl: './detail-menu.component.html',
  styleUrls: ['./detail-menu.component.css']
})
export class DetailMenuComponent implements OnInit {

  @Output() goBack = new EventEmitter();
  @Input() idMenuDetail: number;
  @Input() noSave: boolean;

  viewMenu = true;
  newMenu = false;
  isNewEdit = false;
  menuSelected = new Menu();
  listActions = [];
  listModules = [];
  listMenusByApp = [];
  labels = [];
  title = '';
  objectTemplate = `{"":""}`;
  nullModule = null;
  notStorage = true;

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) { 
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    this.getActions();
    this.getModules();
    if (this.idMenuDetail === 0) {
      this.newMenu = true;
    } else {
      this.menuById();
    }
  }

  newEditMenu() {
    this.isNewEdit = true;
    this.viewMenu = false;
  }

  // ponleFocus(){
  //   document.getElementById("image").focus();
  // }

  cancelEdit() {
    this.objectTemplate = `{"":""}`;
    this.isNewEdit = false;
    this.viewMenu = true;
    this.menuById();
  }

  cancelNewMenu() {
    this.objectTemplate = `{"":""}`;
    this.viewMenu = false;
    this.isNewEdit = false;
    if (this.idMenuDetail === 0) {
      this.goBack.emit();
    }
  }

  onSubmit() {
    if (this.idMenuDetail === 0) {
      this.menuSelected.Active = true;
      this.createMenu(this.menuSelected);
      this.goBack.emit();
    } else {
      this.menuSelected.MenuText = this.title;
      this.editMenu(this.menuSelected);
      this.viewMenu = true;
      this.isNewEdit = false;
      this.newMenu = false;
    }
  }

  changeApplication(Id_Application) {
    this.menuSelected.Id_Application = Id_Application;
  }

  changeAction(Id_Action) {
    this.menuSelected.ActionType = Id_Action;
    this.menuSelected.Id_MenuFather = 0;
  }

  changeModule(Id_Module) {
    this.menuSelected.Id_Module = Id_Module;
  }

  changeMenuFather(Id_MenuFather) {
    this.menuSelected.Id_MenuFather = Id_MenuFather;
  }

  changeStatus(status: boolean) {
    if (status){
      this.menuSelected.Active = true;
    }else{
      this.menuSelected.Active = false;
    }
  }

  onSelectApp(e) {
    this.menuSelected.Id_Application = e;
    this.getMenusByApp(e);
  }
  
  onDeselectApp() {
    this.menuSelected.Id_Application = 0;
    this.getMenusByApp(0);
  }

  onSelectTag(e) {
    this.menuSelected.Id_Tag = e;
  }

  onDeselectTag() {
    this.menuSelected.Id_Tag = 0;
  }

  enterSettings(settings) {
    this.menuSelected.Settings = settings;
  }

  newMenuTitle(title) {
    this.menuSelected.MenuText = title
  }

  menuById() {
    this.services.post({
      entidad: 'Menu',
      funcion: 2,
      data: {
        Id_Menu: this.idMenuDetail
      }
    }).subscribe(result => {
      if (result.data) {
        this.menuSelected = result.data;
        this.labels = this.menuSelected.Labels;
        this.title = this.labels[0].Text;
        this.getMenusByApp(this.menuSelected.Id_Application);
      }
    }, error => {
      console.log('Error', error);
    });
  }

  editMenu(menu: Menu) {
    this.services.post({
      entidad: 'Menu',
      funcion: 4,
      data: menu
    }).subscribe(result => {
      if (result.data) {
        this.menuById();
        this.toastr.success('Menú modificado correctamente', 'Menus');
      }
    }, error => {
      console.log('Error', error);
    });
  }

  createMenu(menu: Menu) {
    this.services.post({
      entidad: 'Menu',
      funcion: 3,
      data: menu
    }).subscribe(result => {
      if (result.data) {
        this.toastr.success('Menu creado correctamente', 'Menus');
      }
    }, error => {
      console.log('Error', error);
    });
  }

  getActions() {
    this.services
      .post({
        entidad: 'Catalogs',
        funcion: 9,
        data: {
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.listActions = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

  getModules() {
    this.services
      .post({
        entidad: 'Catalogs',
        funcion: 10,
        data: {
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.listModules = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

  getMenusByApp(idApplication: number) {
    this.services.post({
      entidad: 'Menu',
      funcion: 9,
      data: {
        Id_Application: idApplication
      }
    }).subscribe(result => {
      if (result.data) {
        this.listMenusByApp = result.data;
      } 
    }, error => {
      console.log('Error', error);
    });
  }

}
