import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter } from 'rxjs-compat/operator/filter';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-filters-kpi',
  templateUrl: './filters-kpi.component.html',
  styleUrls: ['./filters-kpi.component.css'],
})
export class FiltersKpiComponent implements OnInit {
  @Output() addNewF = new EventEmitter();
  @Output() closeC = new EventEmitter();
  @Input() infoNewKPI: any = {};
  @Input() dtlofKPI: any;

  lstOrganizationGenerate = [];
  lstCompanyGenerate = [];
  lstValidationGenerate = [];
  lstCFDIGenerate = [];
  lstYears = [];
  lstKpis = [];
  filtersData = [];
  typeOrganization = '';
  typeCompany = '';
  typeValidation = '';
  typeCFDI = '';
  typeYear = '';
  typeMonth = '';

  filters = {
    Id_KPI_Filter: 0,
    Value: '',
    NameFilter: '',
    Type: '',
    Validators: '',
    Data: '[]',
  };

  Titulo = '';
  Status = '';
  typeGraph = '';
  proyecto = '';
  angular = '';
  

  constructor(
    private toastr: ToastrService,
    private services: ServicesService,
    private translate: TranslateService
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    this.filtersDynamicsService();
    if (this.dtlofKPI !== undefined) {
      this.Titulo = this.dtlofKPI.Titulo;
      this.Status = this.dtlofKPI.Status;
      this.typeGraph = this.dtlofKPI.Id_KPI_Chart_Type;
      this.proyecto = this.dtlofKPI.Name;
    }
  }

  closeCb() {
    this.closeC.emit();
  }

  selectOrganization(e) {
    this.typeOrganization = e.target.value;
  }

  selectOption(e, f) {
    console.log(e);
    console.log(f);
    f.Value = e.target.value;
    this.updateFiltersDynamicsService();
  }

  checkOption(e, f) {
    f.Value = e.target.checked;
    this.updateFiltersDynamicsService();
  }

  selectDate(e, f) {
    f.Value = e.target.value;
    this.updateFiltersDynamicsService();
  }

  changeData(e, f) {
    f.Value = e.target.value;
    this.updateFiltersDynamicsService();
  }

  selectCompany(e) {
    this.typeCompany = e.target.value;
  }

  selectValidation(e) {
    this.typeValidation = e.target.value;
  }

  selectCFDI(e) {
    this.typeCFDI = e.target.value;
  }

  selectYear(e) {
    this.typeYear = e.target.value;
  }

  selectMonth(e) {
    this.typeMonth = e.target.value;
  }
  // Servicios

  addNewFilters() {
    let valido = true;
    this.filtersData.forEach(item => {
      if (item.Validators === 'V' && item.Value === '') {
        this.toastr.warning('Verificá que los campos requeridos estén completos.', 'Filtros');
        valido = false;
        return;
      } else if (item.Validators === 'V') {
          valido = valido && true;
        }
    });
    if (valido) {
      this.toastr.success('Se filtro correctamente.', 'Filtros');
      this.addNewF.emit();
    }
  }

  filtersDynamicsService() {
    if (this.infoNewKPI.Id_KPI === undefined) {
      this.infoNewKPI.Id_KPI = this.dtlofKPI.Id_KPI;
    }
    this.services
      .post({
        entidad: 'KPIs',
        funcion: 5,
        data: {
          id_kpi: this.infoNewKPI.Id_KPI,
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.filtersData = result.data;
            console.log(this.filtersData);
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

  updateFiltersDynamicsService() {
    if (this.infoNewKPI.Id_KPI === undefined) {
      this.infoNewKPI.Id_KPI = this.dtlofKPI.Id_KPI;
    }
    this.services
      .post({
        entidad: 'KPIs',
        funcion: 5,
        data: {
          id_kpi: this.infoNewKPI.Id_KPI,
          filters: this.filtersData,
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.filtersData = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }
}
