export class crewData {
  Id_Crew: number = 0;
  Description: string = '';
  Id_Customer: number = 0;
  Status: string = '';
  Comments: string = '';
  Id_User: number = 0;
  Date_Add: string = '';
  Id_User_Upd: number = 0;
  Date_Upd: string = '';
  StatusName: string = '';
  Customer: string = '';
}
