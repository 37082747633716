import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payments-control',
  templateUrl: './payments-control.component.html',
  styleUrls: ['./payments-control.component.css'],
})
export class PaymentsControlComponent implements OnInit {

  Title = 'Administración de Pagos';
  filterType = '';

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
  }
}
