<div class="row me-2">
    <div class="text-end mt-1 mb-1">
        <button class=" btnEd" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightBranch"
            aria-controls="offcanvasRightBranch">
            <i class="fas fa-plus"></i> {{ "GENERAL.add" | translate }}
        </button>

    </div>
</div>
<form ngNativeValidate #frmProject="ngForm" (ngSubmit)="Company_Branch_Office_New()">
<div class="card ms-3 me-4">
    <div class="card-header bg-transparent border-0">
        <!-- <h4 class="card-title" style="margin-top: 20px;"><b>{{ "GENERAL.company" | translate
                }}:</b> {{companieSelected.Name}}
        </h4> -->
        <!-- <div class="row">
            
                <div class="card content-add-prog">
                    <div class="row">

                        <div class="col-auto mt-2">
                            <input name="code" class="form-control" type="number" [(ngModel)]="BranchOffice.ERP_Id"
                                placeholder="{{ 'GENERAL.code' | translate }}..." id="code" required>
                        </div>

                        <div class="col-auto mt-2">
                            <input name="description" class="form-control" type="text"
                                [(ngModel)]="BranchOffice.Description"
                                placeholder="{{ 'GENERAL.description' | translate }}..." id="description" required>
                        </div>

                        <div class="col-auto mt-2">
                            <button type="submit" class="btn btn-info"
                                style="width: 110px; margin-left: auto; margin-right: auto;">
                                <i class="fas fa-plus"></i> {{ "GENERAL.add" | translate }}
                            </button>
                        </div>

                    </div>

                </div>
            
        </div> -->
        
            <div class="col-">
                <div class="table-responsive">

                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th class="text-center" style="min-width: 80px;">{{ "GENERAL.code" | translate }}</th>
                                <th class="text-center" style="min-width: 250px;">{{ "GENERAL.description" | translate
                                    }}</th>
                                <th class="text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let office of Branch_Offices">

                                <td class="text-center">{{office.ERP_Id}}</td>
                                <td class="text-center">{{office.Description}}</td>

                                <td class="text-center">
                                        <i (click)="deleteBranchOffice(office.Id_BusinessPlace)"
                                        class="fas fa-trash text-danger"
                                        style="font-size: 22px; cursor: pointer;"></i>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>


<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRightBranch" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <button type="button" class="btnArrow" data-bs-dismiss="offcanvas">
            <span><i class="fa fa-arrow-left"></i></span>
        </button>
        <h5 class="ps-4 mt-1" id="offcanvasRightLabel">Agregar Sucursales</h5>
    </div>
    <div class="offcanvas-body">
        <div class="row">
            
            <div class="col-auto ">
                <span>Ingresa Código</span>
                <input name="code" class="form-control" type="number" [(ngModel)]="BranchOffice.ERP_Id"
                style="width: 345px;" placeholder="{{ 'GENERAL.code' | translate }}..." id="code" required>
            </div>

            <div class="col-auto mt-2">
                <span>Ingresa Descripción</span>
                <input name="description" class="form-control" type="text"
                    [(ngModel)]="BranchOffice.Description" style="width: 345px;"
                    placeholder="{{ 'GENERAL.description' | translate }}..." id="description" required>
            </div>

            <div class="col-auto mt-2">
                <button type="submit" class="btn btnSave" 
                    style="width: 345px;">
                    <i class="fas fa-plus"></i> {{ "GENERAL.add" | translate }}
                </button>
            </div>

        </div>
    </div>
</div>
</form>