import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { crewData } from 'src/app/data/models/crew-data.model';
import { ServicesService } from 'src/app/data/services/services.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-general-groups',
  templateUrl: './general-groups.component.html',
  styleUrls: ['./general-groups.component.css']
})
export class GeneralGroupsComponent implements OnInit {
  @Input() operation = '';
  @Input() idG ;
  @Output() backCrewList = new EventEmitter();

  // dropdownSettingsCus: IDropdownSettings = {};
  crew: crewData = new crewData();
  formaCrew: UntypedFormGroup;

  isNew = false;

  SelectDrop = '';

  lstCustomerGet: any[] = [];
  selectedItems: any = [];

  constructor(private fc: UntypedFormBuilder,
    private services: ServicesService,
    private toastr: ToastrService,
    private translate: TranslateService) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
    if (localStorage.getItem('destiny') === 'N') {
      this.isNew = true;
    } else {
      this.isNew = false;
    }

    translate.get('GENERAL.selectcompany').subscribe((res: string) => {
      this.SelectDrop = res;
    });
    // this.dropdownSettingsCus = {
    //   singleSelection: true,
    //   idField: 'Id_Customer',
    //   textField: 'Name',
    //   searchPlaceholderText: 'Buscar:',
    //   allowSearchFilter: true,
    //   closeDropDownOnSelection: true,
    //   clearSearchFilter: true,
    //   enableCheckAll: true,
    //   defaultOpen: true
    // };
  }

  ngOnInit(): void {
    if (this.crew.Id_Customer === 0) {
      this.selectedItems = [];
    }
    this.createForm();
    this.loadData();
    this.crew.Id_Customer = 0;
  }

  loadData() {
    if (this.idG > 0) {
      this.crewGet();
    }
    else {
      this.crew = new crewData();
      // this.loadCustomers();
    }
  }

  createForm() {
    this.formaCrew = this.fc.group({
      Description: ['', Validators.required],
      Status: ['', Validators.required],
      Comments: '',
      StatusName: '',
      Customer: '0'
    });
  }

  fillForm() {
    this.formaCrew.get('Description')?.setValue(this.crew.Description);
    this.formaCrew.get('Status')?.setValue(this.crew.Status);
    this.formaCrew.get('Comments')?.setValue(this.crew.Comments);
    this.formaCrew.get('StatusName')?.setValue(this.crew.StatusName);
    if (this.isNew)
      this.formaCrew.get('Customer')?.setValue('0');
    else {
      this.formaCrew.get('Customer')?.setValue(this.crew.Customer);
    }
  }

  get descriptionNoValido() {
    return this.formaCrew.get('Description')?.invalid && this.formaCrew.get('Description')?.touched;
  }

  get statusNoValido() {
    return this.formaCrew.get('Status')?.invalid && this.formaCrew.get('Status')?.touched;
  }

  // onItemSelect(e) {
  //   this.crew.Id_Customer = e.Id_Customer;
  // }

  // onItemDeselect(e) {
  //   this.selectedItems = [];
  //   this.crew.Id_Customer = 0;
  //   this.crew.Customer = '';
  // }

  sendCustomer(e) {
    this.crew.Id_Customer = e;
  }

  editCrew() {
    this.fillForm();
    this.operation = 'E';
  }

  backToCrewList() {
    if(this.idG === 0){
      this.backCrewList.emit();
    }else{
      this.operation = 'D';
      this.loadData();
      this.isNew = false;
    }
  }

  // Services

  crewGet() {
    this.services.post({
      entidad: 'Crew',
      funcion: 2,
      data: {
        Id_Crew: this.idG
      }
    }).subscribe(result => {
      this.crew = result.data;
      this.selectedItems = [
        { Id_Customer: this.crew.Id_Customer, Name: this.crew.Customer }
      ];
    }, error => console.log(error),
      () => { });
  }

  // loadCustomers() {
  //   this.services
  //     .post({
  //       entidad: 'Customer',
  //       funcion: 3,
  //       data: {
  //         Page: -1
  //       },
  //     })
  //     .subscribe(
  //       (result) => {
  //         if (result.code === 1) {
  //           this.lstCustomerGet = result.data;
  //         }
  //       },
  //       (error) => {
  //         console.log('Error', error);
  //       }
  //     );
  // }

  saveCrew() {
    if (this.formaCrew.invalid) {
      for (const key in this.formaCrew.controls) {
        if (this.formaCrew.controls[key].status === 'INVALID') {
          this.toastr.error('Revisa el campo ' + key, 'Conceptos');
          break;
        }
      }
      return Object.values(this.formaCrew.controls).forEach(control => {
        control.markAsTouched();
      });
    }
    else if (this.formaCrew.valid) {
      if (this.operation === 'N') {
        // New
        this.services.post({
          entidad: 'Crew',
          funcion: 3,
          data: {
            Description: this.formaCrew.get('Description')?.value,
            Status: this.formaCrew.get('Status')?.value,
            Comments: this.formaCrew.get('Comments')?.value,
            Id_Customer: this.crew.Id_Customer
          }
        }).subscribe(result => {
          // console.log(result);
          if (result.code === 1) {
            this.toastr.success('Grupo guardado correctamente', 'Grupos');
            this.backCrewList.emit();
          }
          else {
            this.toastr.error(result.message, 'Grupos');
          }
        }, error => {
          console.log(error),
            this.toastr.error('Error al guardar el grupo', 'Grupos');
        }, () => { });
      }
      else if (this.operation === 'E') {
        // Edit
        this.services.post({
          entidad: 'Crew',
          funcion: 4,
          data: {
            Id_Crew: this.idG,
            Status: this.formaCrew.get('Status')?.value,
            Comments: this.formaCrew.get('Comments')?.value
          }
        }).subscribe(result => {
          // console.log(result);
          if (result.code === 1) {
            this.toastr.success('Grupo editado correctamente', 'Grupos');
            // this.backCrewList.emit();
            this.operation = 'D';
            this.isNew = false;
            this.loadData();
          }
          else {
            this.toastr.error(result.message, 'Grupos');
          }
        }, error => {
          console.log(error),
            this.toastr.error('Error al guardar el grupo', 'Grupos');
        }, () => { });
      }
      else { }
    }
  }

}
