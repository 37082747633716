import { Component, OnInit, Output, EventEmitter } from '@angular/core';
//import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

import { ServicesService } from 'src/app/data/services/services.service';
import { GlobalService } from 'src/app/global/global.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-sel-app',
  templateUrl: './sel-app.component.html',
  styleUrls: ['./sel-app.component.css']
})
export class SelAppComponent implements OnInit {
  @Output() appValid = new EventEmitter<string>();
  @Output() backStep = new EventEmitter();
  
  loading = false;
  lstApp: any [] = [];
  appVersion: string = environment.appVersion;
  constructor(
    private allService: ServicesService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService
  ) 
  { }

  ngOnInit(): void {
    this.Get_Apps();
  }

  Get_Apps() {
    this.loading = true;
    this.spinner.show();

    this.allService.Get_Apps().subscribe(resp => {

      if (this.globalService.verifyResponse(resp)) {
        if (resp.result) {
          this.lstApp = resp.data;
        }
        this.loading = false;
        this.spinner.hide();
      }
    });
  }
  
  selApp(id){
    localStorage.setItem('appToken', id);
    this.appValid.emit(id);
  }  

  backstep()
  {
    this.backStep.emit();
  }

}
