import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Result from 'src/app/data/models/result.model';
import { ServicesService } from 'src/app/data/services/services.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-table-payments',
  templateUrl: './table-payments.component.html',
  styleUrls: ['./table-payments.component.css']
})
export class TablePaymentsComponent implements OnInit {

  Services: any = [];
  editSel: any = {};
  idx: any;

  isAccess = false;
  filterType = '';
  chanelId = '';
  customerId = '';
  monthId = '';
  yearId = '';
  projectId = '';
  annuityId = '';
  moduleId = '';
  annActive = '';
  idPay = '';
  moduleActive = '';
  idActiveM = '';
  idNewPay = '';
  idNewPayModu = '';
  typePayme = '';
  yearG = '';
  monthG = '';
  AZorg = false;
  AZpro = false;
  AZyear = false;
  AZmonth = false;
  AZcan = false;
  Page = 1;
  Pagination = 10;
  selectedPage: any = 1;
  collectionSize = 0;
  totalPages = 0;

  ZAorg = false;
  ZApro = false;
  ZAyear = false;
  ZAmonth = false;
  ZAcan = false;
  orderColumn = '';
  orderDir = '';
  isRest = false;


  constructor(
    private services: ServicesService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {

    const myOffcanvas = document.querySelector('#offcanvasRight') as any;

    if (myOffcanvas !== null) {
      myOffcanvas.addEventListener('hidden.bs.offcanvas', () => {
        this.filterType = '';
        this.typePayment();
      });
    }
    if (localStorage.getItem('chaStoId') !== null) {
      this.chanelId = localStorage.getItem('chaStoId');
    }
    if (localStorage.getItem('cusStoId') !== null) {
      this.customerId = localStorage.getItem('cusStoId');
    }
    if (localStorage.getItem('projectSto') !== null) {
      this.projectId = localStorage.getItem('projectSto');
    }
    if (localStorage.getItem('yearSto') !== null) {
      this.yearId = localStorage.getItem('yearSto');
    }
    if (localStorage.getItem('monthSto') !== null) {
      this.monthId = localStorage.getItem('monthSto');
    }
    if (localStorage.getItem('annSto') !== null) {
      this.annuityId = localStorage.getItem('annSto');
    }
    if (localStorage.getItem('modSto') !== null) {
      this.moduleId = localStorage.getItem('modSto');
    }
    if (localStorage.getItem('paginationStorage') !== null) {
      this.Pagination = +localStorage.getItem('paginationStorage');
    }
    this.typePayment();

  }

  editService(e, position) {
    this.idx = position;
    this.editSel = e;
  }

  viewSwitch(e) {
    this.idx = - 1;
  }

  saveEdit(e) {
    this.idx = -1;
  }

  cancel() {
    this.idx = -1;
  }

  selectPayment(e) {
    this.typePayme = e.target.value;
  }

  onSorted(e) {
    this.orderColumn = e.sortColumn;
  }

  onSortedA(e) {
    this.orderDir = 'A';
    if (this.orderColumn === 'org' && this.orderDir === 'A'){
      this.isRest = true;
      this.AZorg = true;
      this.AZpro = false;
      this.AZyear = false;
      this.AZmonth = false;
      this.ZAorg = false;
      this.ZApro = false;
      this.ZAyear = false;
      this.ZAmonth = false;
    } else if (this.orderColumn === 'pro' && this.orderDir === 'A'){
      this.isRest = true;
      this.AZorg = false;
      this.AZpro = true;
      this.AZyear = false;
      this.AZmonth = false;
      this.ZAorg = false;
      this.ZApro = false;
      this.ZAyear = false;
      this.ZAmonth = false;
    }else if (this.orderColumn === 'year' && this.orderDir === 'A'){
      this.isRest = true;
      this.AZorg = false;
      this.AZpro = false;
      this.AZyear = true;
      this.AZmonth = false;
      this.ZAorg = false;
      this.ZApro = false;
      this.ZAyear = false;
      this.ZAmonth = false;
    }else if (this.orderColumn === 'month' && this.orderDir === 'A'){
      this.isRest = true;
      this.AZorg = false;
      this.AZpro = false;
      this.AZyear = false;
      this.AZmonth = true;
      this.ZAorg = false;
      this.ZApro = false;
      this.ZAyear = false;
      this.ZAmonth = false;
    }
    this.typePayment();
  }

  onSortedD(e) {
    this.orderDir = 'D';
    if (this.orderColumn === 'org' && this.orderDir === 'D'){
      this.isRest = true;
      this.AZorg = false;
      this.AZpro = false;
      this.AZyear = false;
      this.AZmonth = false;
      this.ZAorg = true;
      this.ZApro = false;
      this.ZAyear = false;
      this.ZAmonth = false;
    } else if (this.orderColumn === 'pro' && this.orderDir === 'D'){
      this.isRest = true;
      this.AZorg = false;
      this.AZpro = false;
      this.AZyear = false;
      this.AZmonth = false;
      this.ZAorg = false;
      this.ZApro = true;
      this.ZAyear = false;
      this.ZAmonth = false;
    }else if (this.orderColumn === 'year' && this.orderDir === 'D'){
      this.isRest = true;
      this.AZorg = false;
      this.AZpro = false;
      this.AZyear = false;
      this.AZmonth = false;
      this.ZAorg = false;
      this.ZApro = false;
      this.ZAyear = true;
      this.ZAmonth = false;
    }else if (this.orderColumn === 'month' && this.orderDir === 'D'){
      this.isRest = true;
      this.AZorg = false;
      this.AZpro = false;
      this.AZyear = false;
      this.AZmonth = false;
      this.ZAorg = false;
      this.ZApro = false;
      this.ZAyear = false;
      this.ZAmonth = true;
    }
    this.typePayment();
  }

  resetTable() {
    localStorage.removeItem('chaStoId');
    localStorage.removeItem('cusStoId');
    localStorage.removeItem('cusStoNa');
    localStorage.removeItem('projectSto');
    localStorage.removeItem('yearSto');
    localStorage.removeItem('monthSto');
    localStorage.removeItem('annSto');
    localStorage.removeItem('modSto');
    localStorage.removeItem('paginationStorage');
    this.chanelId = '';
    this.customerId = '';
    this.projectId = '';
    this.yearId = '';
    this.monthId = '';
    this.annuityId = '';
    this.moduleId = '';
    this.AZorg = false;
    this.AZpro = false;
    this.AZyear = false;
    this.AZmonth = false;
    this.ZAorg = false;
    this.ZApro = false;
    this.ZAyear = false;
    this.ZAmonth = false;
    this.isRest = false;
    this.orderDir = '';
    this.orderColumn = '';
    this.Page = 1;
    this.Pagination = 10;
    return this.typePayment();
  }

  filterChannel(e) {
    if (e === 'C') {
      this.filterType = e;
    } else if (e === 'O') {
      this.filterType = e;
    } else if (e === 'P') {
      this.filterType = e;
    } else if (e === 'A') {
      this.filterType = e;
    } else if (e === 'M') {
      this.filterType = e;
    } else if (e === 'AN') {
      this.filterType = e;
    } else if (e === 'MO') {
      this.filterType = e;
    }
  }


  filterByChannel(e) {
    this.chanelId = e;
  }

  filterByCustomer(e) {
    this.customerId = e;
  }

  filterByMonth(e) {
    this.monthId = e;
  }

  filterByYear(e) {
    this.yearId = e;
  }

  filterByProject(e) {
    this.projectId = e;
  }

  filterByAnnuity(e) {
    this.annuityId = e;
  }

  filterByModule(e) {
    this.moduleId = e;
  }

  filterData() {
    this.typePayment();
  }

  updateAnnuity(e, id1, year, month, id2) {
    this.idNewPay = id1;
    this.yearG = year;
    this.monthG = month;
    this.idPay = id2;
    if (!e.target.checked) {
      this.annActive = 'F';
    } else {
      this.annActive = 'V';
    }
    this.updateAnn();
  }

  updateG(g) {
    this.isAccess = this.isAccess;
    if (!g.target.checked) {
      this.annActive = 'F';
    } else {
      this.annActive = 'V';
    }
    this.updateAnn();
  }

  updateModule(e, id1, year, month, id2) {
    this.idNewPayModu = id1;
    this.yearG = year;
    this.monthG = month;
    this.idActiveM = id2;
    if (!e.target.checked) {
      this.moduleActive = 'F';
    } else {
      this.moduleActive = 'V';
    }
    this.updateMod();
  }

  filterSel(e) {

    const ftro = Number(e);

    if (ftro === 1) {
      this.filterType = 'C';
    } else if (ftro === 2) {
      this.filterType = 'O';
    } else if (ftro === 3) {
      this.filterType = 'P';
    } else if (ftro === 4) {
      this.filterType = 'A';
    } else if (ftro === 5) {
      this.filterType = 'M';
    } else if (ftro === 6) {
      this.filterType = 'AN';
    } else if (ftro === 7) {
      this.filterType = 'MO';
    }
  }

  changeAmountView(e) {
    this.Pagination = e.target.value;
    this.isRest = true;
    this.typePayment();
  }

  changePage(e) {
    this.Page = e;
    this.typePayment();
  }

  typePayment() {
    this.spinner.show();
    this.services
      .post({
        entidad: 'Payments',
        funcion: 1,
        data: {
          Id_Channel: this.chanelId,
          Id_Customer: this.customerId,
          Id_Project: this.projectId,
          Month: this.monthId,
          Year: this.yearId,
          Payment_Status: this.annuityId,
          OrderColumn: this.orderColumn,
          OrderDir: this.orderDir,
          Payment_Status_Module: this.moduleId,
          Page: this.Page,
          Pagination: this.Pagination
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.Services = result.data;
            this.spinner.hide();
          }
          this.spinner.hide();
          for (let i = 0; i < result.data.length; i++) {
            this.totalPages = result.data[i].Pages;
            this.collectionSize = result.data[i].Pages * 10;
          }
          if (result.data.length === 0) {
            this.totalPages = 1;
            this.collectionSize = 1;
            this.selectedPage = 1;
          }
        },
        (error) => {
          console.log('Error', error);
        }

      );
  }

  updateAnn() {
    this.spinner.show();
    this.services
      .post({
        entidad: 'Payments',
        funcion: 11,
        data: {
          Id_Customer_Project: this.idNewPay,
          Id_Payments_Customer_Project: this.idPay,
          Year: this.yearG,
          Month: this.monthG,
          Payment_Status: this.annActive
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.typePayment();
            this.spinner.hide();
            this.toastr.success(
              'Se ha Actualizado la Anualidad',
              'Exito'
            );
          }
          this.spinner.hide();
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

  updateMod() {
    this.spinner.show();
    this.services
      .post({
        entidad: 'Payments',
        funcion: 12,
        data: {
          Id_Customer_Module: this.idNewPayModu,
          Id_Payments_Customer_Module: this.idActiveM,
          Year: this.yearG,
          Month: this.monthG,
          Payment_Status: this.moduleActive
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.typePayment();
            this.spinner.hide();
            this.toastr.success(
              'Se ha Actualizado el Modulo',
              'Exito'
            );
          }
          this.spinner.hide();
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

  async User_AccessService(KeyAccess) {
    const resp = await this.services.getAccessPermit(KeyAccess);
    this.isAccess = resp.granted;
    if(!this.isAccess) {
      localStorage.removeItem("chaStoId")
      localStorage.removeItem("chaStoNa")
    }
  }

}
