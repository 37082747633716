import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-filters-channel',
  templateUrl: './filters-channel.component.html',
  styleUrls: ['./filters-channel.component.css']
})
export class FiltersChannelComponent implements OnInit {

  @Output() filSel = new EventEmitter<string>();

  typeFil = '';

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) { 
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
  }

  selectFilter(e) {
    this.typeFil = e.target.value;
    this.filSel.emit(this.typeFil);
  }

}
