import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import { CompanyAccess, User } from '../users';

@Component({
  selector: 'app-companies-user',
  templateUrl: './companies-user.component.html',
  styleUrls: ['./companies-user.component.css']
})
export class CompaniesUserComponent implements OnInit {

  @Input() id_User: Number;
  public UserSelected: User = new User();
  public listMultiCompany: CompanyAccess[] = [];
  public listMultiCompany_BACK: CompanyAccess[] = [];
  searchCompany = '';
  enterCardCode = false;
  public isNew = false;
  public isView = false;
  public isResults = true;

  constructor(
    private services: ServicesService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    if (this.id_User === 0) {
      this.isNew = true;
      this.isView = true;
      this.isResults = false;
    } else {
      this.User_By_Id();
    }
    this.Multicompany_List();
  }

  SelectUser(user: User) {
    this.UserSelected = user;
  }

  User_By_Id() {
    this.spinner.show();
    this.services.User_By_Id(this.id_User).subscribe(resp => {
      this.spinner.hide();

      if (resp.result) {
        this.UserSelected = resp.data[0];
        this.SelectUser(this.UserSelected);
      }
    });
  }

  compare(a: Number | String | Date, b: Number | String | Date, isAsc: Boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onSorted(sort) {

    let data = this.listMultiCompany.slice();

    let sortedData = data.sort((a, b) => {
      const isAsc = sort.sortDirection === 'asc';
      switch (sort.sortColumn) {
        case 'Company_Name': return this.compare(a.Name, b.Name, isAsc);
        default: return 0;
      }
    });

    this.listMultiCompany = sortedData;
  }

  editCC(multicompany: CompanyAccess) {
    if (multicompany.IsSelected === 0 && this.UserSelected.Multicompany === false) {
      this.toastr.warning('Activa primero la compañía, por favor.', 'Usuarios');
    } else {
      multicompany.edit = true;
      this.enterCardCode = true;
    }
  }

  saveCC(multicompany: CompanyAccess) {
    multicompany.edit = false;
    this.spinner.show();
    this.services.Multicompany_ChangeCardCode(multicompany.Id_Company, this.UserSelected.Id_User, multicompany.CardCode).subscribe(resp => {
      this.spinner.hide();
      if (resp.result)
        this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));
      this.enterCardCode = false;
    });
  }

  changeStatusMulticompany(Id_Company: Number, checked: boolean) {
    this.spinner.show();
    this.services.Multicompany_ChangeStatus(Id_Company, this.UserSelected.Id_User, checked).subscribe(resp => {
      this.spinner.hide();
      if (resp.result)
        this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));
        this.Multicompany_List();
    });
  }

  onSearchCompany(e: string) {
    if (e.length == 0)
      this.listMultiCompany = this.listMultiCompany_BACK;
    else {
      let data = this.listMultiCompany.filter(x => x.Name.toUpperCase().indexOf(e.toUpperCase()) >= 0);
      this.listMultiCompany = data;
    }
  }

  Multicompany_List() {
    this.spinner.show();
    this.services.Multicompany_List(this.id_User).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.listMultiCompany = resp.data;
        this.listMultiCompany_BACK = resp.data;
      }
    });
  }

  User_Multicompany_Change_Status(IsActive: boolean, Id_User: Number) {
    this.spinner.show();
    this.services.User_Multicompany_Change_Status(Id_User, IsActive).subscribe(resp => {
      this.spinner.hide();
      if (resp.result)
        this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));

      this.Multicompany_List();
    });
  }

}
