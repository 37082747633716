import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  isTitle = 'Perfiles';
  showPrincipal = true;
  isReturn = false;
  isEdit = false;
  isView = false;
  idProfile = 0;
  searchTerm = '';
  listProfiles = [];
  nameColumn = '';
  orderColumn = '';
  orderDir = '';
  selectedPage: any = 1;
  collectionSize = 0;
  totalPages = 0;
  idApp = 0;
  idTag = 0;
  page = 1;
  pagination = 10;

  azPro = false;
  zaPro = false;
  azApp = false;
  zaApp = false;
  azTag = false;
  zaTag = false;
  isRest = false;

  constructor(
    private services: ServicesService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
    if (localStorage.getItem('appStoId') !== null) {
      this.idApp = +localStorage.getItem('appStoId');
    }
    if (localStorage.getItem('tagStoId') !== null) {
      this.idTag = +localStorage.getItem('tagStoId');
    }
    if (localStorage.getItem('paginationStorage') !== null) {
      this.pagination = +localStorage.getItem('paginationStorage');
    }
   }

  ngOnInit(): void {
    const myOffcanvas = document.querySelector('#offcanvasRight') as any;
    if (myOffcanvas !== null) {
      myOffcanvas.addEventListener('hidden.bs.offcanvas', () => {
        this.nameColumn = '';
      });
    }

    this.getProfiles();
  }

  returnToList() {
    this.showPrincipal = true;
    this.isEdit = false;
    this.isView = false;
    this.isReturn = false;
    this.idProfile = 0;
    this.getProfiles();
  }

  searchInput(e: any) {
    if (e.target.value !== '') {
      this.searchTerm = e.target.value;
      this.getProfiles();
    }
    else {
      this.getProfiles();
    }
  }

  createNewProfile() {
    this.isReturn = true;
    this.isEdit = true;
    this.isView = true;
    this.showPrincipal = false;
  }

  selectProfile(Id_Profile) {
    this.isReturn = true;
    this.isView = true;
    this.showPrincipal = false;
    this.idProfile = Id_Profile;
  }

  filterByApp(e) {
    this.idApp = e;
  }

  filterByTag(e) {
    this.idTag = e;
  }

  filterData() {
    this.getProfiles();
  }

  selectColumn(column) {
    if (column === 'A') {
      this.nameColumn = column;
    } else if (column === 'T') {
      this.nameColumn = column;
    }
  }

  selectFilter(e) {
    const ftro = Number(e);
    if (ftro === 1) {
      this.nameColumn = 'A';
    } else if (ftro === 2) {
      this.nameColumn = 'T';
    }
  }

  changeAmountView(e) {
    this.pagination = e.target.value;
    this.getProfiles();
  }

  changePage(e) {
    this.page = e;
    this.getProfiles();
  }

  onSorted(e) {
    this.orderColumn = e.sortColumn;
  }

  onSortedA() {
    this.orderDir = 'A';
    if (this.orderColumn === 'pro' && this.orderDir === 'A'){
      this.azPro = true;
      this.zaPro = false;
      this.azApp = false;
      this.zaApp = false;
      this.azTag = false;
      this.zaTag = false;
      this.isRest = true;
    } else if (this.orderColumn === 'app' && this.orderDir === 'A'){
      this.azPro = false;
      this.zaPro = false;
      this.azApp = true;
      this.zaApp = false;
      this.azTag = false;
      this.zaTag = false;
      this.isRest = true;
    } else if (this.orderColumn === 'tag' && this.orderDir === 'A'){
      this.azPro = false;
      this.zaPro = false;
      this.azApp = false;
      this.zaApp = false;
      this.azTag = true;
      this.zaTag = false;
      this.isRest = true;
    } 
    this.getProfiles();
  }

  onSortedD() {
    this.orderDir = 'D';
    if (this.orderColumn === 'pro' && this.orderDir === 'D'){
      this.azPro = false;
      this.zaPro = true;
      this.azApp = false;
      this.zaApp = false;
      this.azTag = false;
      this.zaTag = false;
      this.isRest = true;
    } else if (this.orderColumn === 'app' && this.orderDir === 'D'){
      this.azPro = false;
      this.zaPro = false;
      this.azApp = false;
      this.zaApp = true;
      this.azTag = false;
      this.zaTag = false;
      this.isRest = true;
    } else if (this.orderColumn === 'tag' && this.orderDir === 'D'){
      this.azPro = false;
      this.zaPro = false;
      this.azApp = false;
      this.zaApp = true;
      this.azTag = false;
      this.zaTag = false;
      this.isRest = true;
    } 
    this.getProfiles();
  }

  resetTable() {
    localStorage.removeItem('appStoId');
    localStorage.removeItem('appStoNa');
    localStorage.removeItem('tagStoId');
    localStorage.removeItem('tagStoNa');
    localStorage.removeItem('paginationStorage');
    this.idApp = 0;
    this.idTag = 0;
    this.orderColumn = '';
    this.orderDir = '';
    this.page = 1;
    this.pagination = 10;
    this.azPro = false;
    this.zaPro = false;
    this.azApp = false;
    this.zaApp = false;
    this.azTag = false;
    this.zaTag = false;
    this.isRest = false;
    return this.getProfiles();
}

  getProfiles() {
    this.services.post({
      entidad: 'Profile',
      funcion: 1,
      data: {
        SearchKey: this.searchTerm,
        Id_Application: this.idApp,
        Id_Tag: this.idTag,
        Order_Column: this.orderColumn,
        Order_Dir: this.orderDir,
        Page: this.page,
        Pagination: this.pagination
      }
    }).subscribe(result => {
      if (result.data) {
        this.listProfiles = result.data;
      } 
      for (let i = 0; i < result.data.length; i++) {
        this.totalPages = result.data[i].Pages;
        this.collectionSize = result.data[i].Pages * 10;
      }
      if (result.data.length === 0) {
        this.totalPages = 1;
        this.collectionSize = 1;
        this.selectedPage = 1;
      }
    }, error => {
      console.log('Error', error);
    });
  }

  deleteProfile(Id_Profile: number, Description: string) {
    Swal.fire({
      title: `¿Estás seguro de querer eliminar el perfil '${Description}'?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#746AB0',
      cancelButtonColor: '#E83845',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.services.post({
          entidad: 'Profile',
          funcion: 5,
          data: {
            Id_Profile: Id_Profile
          }
        }).subscribe(result => {
          if (result.result === true) {
            this.toastr.success('Perfil eliminado correctamente', 'Perfiles');
            this.getProfiles();
          }
          else {
            this.toastr.error(result.message, 'Perfiles');
          }
        }, error => {
          console.log(error),
            this.toastr.error('Error al eliminar el menú', 'Perfiles');
        }, () => { });
      }
    });
  }

}
