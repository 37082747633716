<div class="row me-2">
    <div class="text-end mt-1 mb-1 ">
        <button *ngIf="viewAccess && !newAccess && !isNewEdit" (click)="editAccess()" class="btnEd">
            <i class="fas fa-edit"></i>
            {{ "GENERAL.edit" | translate }}
        </button>
    </div>
</div>

<form ngNativeValidate #f="ngForm" (ngSubmit)="onSubmitEdit()" id="formAccess" class="form">
    <div class="card ms-3 me-3">
        <div class="card-body">
            <h2 class="card-title mb-0">Detalle del Acceso</h2>
            <hr style="margin-top: 0;">
            <div class="row ps-3">
                <div class="col">
                    <div class="row">
                        <div class="col-auto ord mt-1">
                            <b class=" textP">{{ "GENERAL.name" | translate }} del Acceso</b>
                        </div>
                        <div class="col-auto">
                            <p *ngIf="viewAccess && !newAccess && !isNewEdit" class="pl-4 ml-3">
                                {{accessSelected.AccessDescription}}</p>
                            <p *ngIf="newAccess || isNewEdit"><input [(ngModel)]="accessSelected.AccessDescription"
                                    pattern="[A-Za-z0-9áéíóúüñÑ_., -]+" name="access" class="form-control input1"
                                    type="text" placeholder="{{ 'GENERAL.name' | translate }} del Acceso..." id="access"
                                    required>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto ord mt-1">
                            <b class="textP" for="example-text-input">KeyAccess</b>
                        </div>
                        <div class="col-auto">
                            <p *ngIf="viewAccess && !newAccess && !isNewEdit" class="pl-4 ml-3">
                                {{accessSelected.KeyAccess}}
                            </p>
                            <p *ngIf="newAccess || isNewEdit"><input [(ngModel)]="accessSelected.KeyAccess"
                                    pattern="[A-Za-z0-9áéíóúüñÑ_., -]+" name="keyAccess" class="form-control input1"
                                    type="text" placeholder="KeyAccess..." id="keyAccess" required></p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto ord mt-1">
                            <b class="textP" for="example-text-input">Aplicación</b>
                        </div>
                        <div class="col-auto">
                            <p *ngIf="viewAccess && !newAccess && !isNewEdit" class="pl-4 ml-3">
                                {{accessSelected.ApplicationName}}</p>
                            <div *ngIf="newAccess || isNewEdit" class="muul mi input1 mb-p">
                                <app-application-multiselect (idAppC)="onSelectApp($event)" [storage]="notStorage"
                                    [idApp]="accessSelected.Id_Application" [nameApp]="accessSelected.ApplicationName"
                                    (noSave)="onDeselectApp()">
                                </app-application-multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto ord mt-1">
                            <b class="textP" for="example-text-input">Tag</b>
                        </div>
                        <div class="col-auto">
                            <p *ngIf="viewAccess && !newAccess && !isNewEdit" class="pl-4 ml-3 ">
                                {{accessSelected.TagDescription}}</p>
                            <div *ngIf="newAccess || isNewEdit" class="muul mi input1 mb-p">
                                <app-tag-multiselect (idTagC)="onSelectTag($event)" [storage]="notStorage"
                                    [idTagF]="accessSelected.Id_Tag" [nameTagF]="accessSelected.TagDescription"
                                    (noSave)="onDeselectTag()">
                                </app-tag-multiselect>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="newAccess || isNewEdit" class="col-">
        <button type="submit" class="btn btnSave waves-effect waves-light m-r-10 mt-2 mr-3 buttonform"
            [disabled]="accessSelected.Id_Application === 0 || accessSelected.Id_Tag === 0">
            {{ "GENERAL.save" | translate }}
        </button>
        <button *ngIf="isNewEdit" (click)="cancelEdit()"
            class="btn btnCancel waves-effect waves-light m-r-10 mt-2 buttonform">
            Cancelar
        </button>
        <button *ngIf="!isNewEdit" (click)="cancelNewAccess()"
            class="btn btnCancel waves-effect waves-light m-r-10 mt-2 buttonform">
            Cancelar
        </button>
    </div>
</form>