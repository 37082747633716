import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ServicesService } from 'src/app/data/services/services.service';
import { GlobalService } from 'src/app/global/global.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.css']
})
export class PreferencesComponent implements OnInit {

  @Output() backLogin = new EventEmitter();


  loading = false;
  // tslint:disable-next-line: variable-name
  public Preferences_Notification_List: any = [];

  loginToken = '';
  data = {};

  constructor(private allService: ServicesService,
    private globalService: GlobalService,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.loginToken = this.loginToken = localStorage.getItem('loginToken');
    this.Get_Preferences_Notification_List_By_TokenService();
  }

  // tslint:disable-next-line: variable-name
  changeNotif(Id_Preferences_Notification, e) {
    let val = '';
    if (e) {
      val = 'V';
    } else {
      val = 'F';
    }
    this.Update_Preferences_Notification_By_TokenService(Id_Preferences_Notification, val);
  }

  enterSystem() {
    this.Save_Preferences_NotificationService();
    // const redirect = '/Indicators';
    // this.router.navigate([redirect]);
    // this.authService.notifSysGen().subscribe(response => { });
  }

  backToLogin() {
    this.backLogin.emit();
    localStorage.removeItem('email');
  }


  Get_Preferences_Notification_List_By_TokenService() {
    this.allService.Get_Preferences_Notification_List_By_Token(this.loginToken).subscribe(resp => {
      if (this.globalService.verifyResponse(resp)) {

        this.Preferences_Notification_List = [];

        resp.data.forEach(parameter => {
          parameter.oList.forEach(param => {
            if (param.value === 'V') {
              param.value = true;
            } else {
              param.value = false;
            }
          });
        });

        this.Preferences_Notification_List = resp.data;      
      }
    });
  }

  // tslint:disable-next-line: variable-name
  Update_Preferences_Notification_By_TokenService(Id_Preferences_Notification, Value) {
    this.data = {
      Id_Preferences_Notification,
      Value
    };
    this.allService.Update_Preferences_Notification_By_Token(this.data).subscribe(resp => {
      this.toastr.success('¡La preferencia se ha guardado', 'Exito');
    });
  }

  Save_Preferences_NotificationService() {
    this.allService.Save_Preferences_Notification().subscribe(resp => {
      this.toastr.success('Sus preferencias se han guardado', 'Exito');
    });
  }
}
