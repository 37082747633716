export class UserList {
  Page = 1;
  Id_Customer = 0;
  Id_Company = 0;
  Status = '';
  Order_Column = '';
  Order_Dir = '';
  SearchKey = '';
  Pagination = 10;
}
