import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Customer, CustomerApp } from '../customer';

@Component({
  selector: 'app-mytab',
  templateUrl: './mytab.component.html',
  styleUrls: ['./mytab.component.css']
})
export class MytabComponent implements OnInit {

  @Input() idCustomer: number;
  @Input() newEdit: boolean;
  @Output() goBack = new EventEmitter();

  titleNewEditCustomer = '';
  public form: UntypedFormGroup;
  public listCustomersApp: CustomerApp[];
  public customerSelected = new Customer();
  public listAppBack = [];
  public listApp = [];
  public isNewEdit = false;
  public isEdit = true;
  isNew = false;
  // EditCustomer = false;

  generalView = true;
  isAppView = false;
  isFoliosView = false;

  constructor() { }

  ngOnInit(): void {

    if (this.idCustomer === 0) {
       this.isNew = false;
     }else{
      this.isNew = true;
     }
  }

  viewGeneral() {
    this.generalView = true;
    this.isAppView = false;
    this.isFoliosView = false;

  }

  viewApss() {
    this.generalView = false;
    this.isAppView = true;
    this.isFoliosView = false;
  }

  viewFolios() {
    this.generalView = false;
    this.isAppView = false;
    this.isFoliosView = true;
  }

  returnToList(){
    this.goBack.emit();
  }
  

  newEditCustomer(edit: boolean) {
    if (edit) {
      this.isEdit = true;
      this.titleNewEditCustomer = 'GENERAL.edit';
    }
    else {
      this.isEdit = false;
      this.titleNewEditCustomer = 'GENERAL.new2';
      this.customerSelected = new Customer();
    }

    this.setFormInfo();

  }
  setFormInfo() {
    this.form.controls['Name'].setValue(this.customerSelected.Name);
    this.form.controls['Email'].setValue(this.customerSelected.Email);
    this.form.controls['Id_Channel'].setValue(this.customerSelected.Id_Channel);
  }
  
}
