<ng-container  *ngIf="isList">
  <app-top-title (addNew)="addNewChart()"></app-top-title>
</ng-container>
<ng-container *ngIf="isNewIndicator">
  <app-top-new-indicator
  (addNewKpi)="configKPINew($event)"
  (closeC)="closeTopNew()"
></app-top-new-indicator>
</ng-container>

<div class="row mt-3">
  <ng-container *ngIf="isNewKpi">
    <div class="col-auto">
      <div class="card" >
        <div class="card-body">
          <ng-container *ngIf="isConfig">
            <app-config-kpi
              (addNewKPI)="configFiltersNew($event)"
              [isforNew]="isforNew"
              [newEditKpi]="isNewEdit"
              (closeC)="closeNewCofig()"
            >
            </app-config-kpi>
          </ng-container>
          <ng-container *ngIf="isFilters">
            <app-filters-kpi
              (addNewF)="viewGraph()"
              (closeC)="closeFiltersNew()"
              (addNewF)="closeFiltersNew()"
              [infoNewKPI]="infoNewKPI"
            ></app-filters-kpi>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isList" >
    <ng-container *ngFor="let kpi of lstKpis; let i = index" >
      <div *ngIf="kpi.Status !== 'D'" class="mt-2 px-2" [ngClass]="{'col-auto': kpi.Tamanio === 'chico' ||  kpi.Tamanio === 'Mediano' || kpi.Tamanio === 'Grande' || kpi.Tamanio === 'Vertical', 'col-12': kpi.Tamanio === 'Horizontal'}">
        <div class="card">
          <div class="card-body" [ngClass]="{'KPIsmall': kpi.Tamanio === 'Chico', 'KPImedium' : kpi.Tamanio === 'Mediano','KPIbig' : kpi.Tamanio === 'Grande', 'KPIland': kpi.Tamanio === 'Horizontal', 'KPIvert': kpi.Tamanio === 'Vertical'}">
            <ng-container *ngIf="kpi.isKpi">
              <app-graph
                [dtlofKPI]="kpi"
                [indexKpi]="i"
                (viewL)="viewLogHistory($event, kpi)"
                (configKPI)="configKPI($event, kpi)"
                (addNewF)="configFilters($event, kpi)"
                (dupKPI)=" addKpisDup()"
                (delKPI)="delItem($event)"
              >
              </app-graph>
            </ng-container>
            <ng-container *ngIf="kpi.isConfig">
              <app-config-kpi
                [dtlofKPI]="kpi"
                (editKPI)="editKPI($event, kpi)"
                [newEditKpi]="isNewEdit"
                (closeC)="closeCUpdate(kpi)"
              >
              </app-config-kpi>
            </ng-container>
            <app-filters-kpi
              *ngIf="kpi.isFilters"
              [dtlofKPI]="kpi"
              (addNewF)="viewGraphF(kpi)"
              (closeC)="closeFilters(kpi)"
            ></app-filters-kpi>
            <app-log-kpis 
            *ngIf="kpi.isLog" 
            [dtlofKPI]="kpi" 
            (closeC)="closeLog(kpi)"
            ></app-log-kpis>
            <app-table-info *ngIf="isLog"></app-table-info>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
