import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import { CompanyEdit, Dimension, PAC } from '../companies';

@Component({
  selector: 'app-my-tab-company',
  templateUrl: './my-tab-company.component.html',
  styleUrls: ['./my-tab-company.component.css']
})
export class MyTabCompanyComponent implements OnInit {

  @Output() goBack = new EventEmitter();
  @Input() idCompany: number;
  @Input() newEdit: boolean;
  public companieSelected = new CompanyEdit();
  public Dimensions: Dimension[] = [];
  public listPacAssigned: PAC[] = [];
  public Branch_Offices: any[] = [];
  public CostCenters: any;
  public ERP_Projects: any[] = [];
  public listFreePacs: PAC[] = [];

  generalView = true;
  logoView = false;
  qrView = false;
  pacView = false;
  satView = false;
  smtpView = false;
  centerView = false;
  projectView = false;
  branchView = false;

  isNewEdit = false;
  isEdit = false;

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private services: ServicesService
  ) {

    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }

  }

  ngOnInit(): void {
    if (this.idCompany === 0){ 
      this.isNewEdit = false;
    } else {
      this.isNewEdit = true;
      this.companyById();
    }
  }

  viewGeneral(){
   this.generalView = true;
   this.logoView = false;
   this.qrView = false;
   this.pacView = false;
   this.satView = false;
   this.smtpView = false;
   this.centerView = false;
   this.projectView = false;
   this.branchView = false;
  }

  viewLogo(){
    this.generalView = false;
    this.logoView = true;
    this.qrView = false;
    this.pacView = false;
    this.satView = false;
    this.smtpView = false;
    this.centerView = false;
    this.projectView = false;
    this.branchView = false;
  }

  viewQR(){
    this.generalView = false;
    this.logoView = false;
    this.qrView = true;
    this.pacView = false;
    this.satView = false;
    this.smtpView = false;
    this.centerView = false;
    this.projectView = false;
    this.branchView = false;
  }
  viewPac(){
    this.generalView = false;
    this.logoView = false;
    this.qrView = false;
    this.pacView = true;
    this.satView = false;
    this.smtpView = false;
    this.centerView = false;
    this.projectView = false;
    this.branchView = false;
  }

  viewSat(){
    this.generalView = false;
    this.logoView = false;
    this.qrView = false;
    this.pacView = false;
    this.satView = true;
    this.smtpView = false;
    this.centerView = false;
    this.projectView = false;
    this.branchView = false;
  }

  viewSmtp(){
    this.generalView = false;
    this.logoView = false;
    this.qrView = false;
    this.pacView = false;
    this.satView = false;
    this.smtpView = true;
    this.centerView = false;
    this.projectView = false;
    this.branchView = false;
  }

  viewCost(){
    this.generalView = false;
    this.logoView = false;
    this.qrView = false;
    this.pacView = false;
    this.satView = false;
    this.smtpView = false;
    this.centerView = true;
    this.projectView = false;
    this.branchView = false;
  }

  viewProject(){
    this.generalView = false;
    this.logoView = false;
    this.qrView = false;
    this.pacView = false;
    this.satView = false;
    this.smtpView = false;
    this.centerView = false;
    this.projectView = false;
    this.branchView = false;
    this.projectView = true;
    this.branchView = false;
   }

   viewBranch(){
    this.generalView = false;
    this.logoView = false;
    this.qrView = false;
    this.pacView = false;
    this.satView = false;
    this.smtpView = false;
    this.centerView = false;
    this.projectView = false;
    this.branchView = false;
    this.projectView = false;
    this.branchView = true;
   }

   returnToList(){
    this.goBack.emit();
   }

   nameUpdate() {
    this.companyById();
   }

   companyById() {
    this.spinner.show();
    this.services.Company_ById(this.idCompany).subscribe(resp => {

      if (resp.result) {
        this.companieSelected = resp.data;
      }
      this.spinner.hide();
    });
  }

}
