<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form-signin">
    <div class="d-flex flex-column align-items-center justify-content-center">
        <span class="version" >Versión {{appVersion}}</span>
        <img class="logo" src="https://xam-app.s3.us-west-2.amazonaws.com/images/simplesuit/ss-sinslogan-simple-suite.png" /> 
        <h1 class="h5 mb-4 mt-3" style="text-align: center; color: #6a6a6a; font-weight:600;">Inicia Sesión</h1>

    </div>
    <div class="form-group">

        <div class="input-group">
            <label  for="email">{{email}}</label>
        </div>
        <!-- <input type="text" formControlName="email" placeholder="Ingresa tu email" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            
        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
            <div *ngIf="f.email.errors.required">El usuario es requerido</div>
        </div> -->

        <label class="mt-1" for="email">Contraseña</label>
        <div class="input-group">
            <!-- Change input type to be dynamic -->

            <input [type]="fieldTextType ? 'text' : 'password'" type="password" formControlName="password"
                class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <div class="input-group-append">
                <span class="input-group-text">
                    <i class="fa" [ngClass]="{
                      'fa-eye-slash': !fieldTextType,
                      'fa-eye': fieldTextType
                    }" (click)="toggleFieldTextType()"></i>
                </span>
            </div>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">La contraseña es obligatoria</div>
            </div>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.minlength">La longitud mínima es de 8 caracteres.</div>
            </div>
        </div>
    </div>

    <div class="d-flex mt-4 btn-group mb-2" role="group" aria-label="Botones">
        <button [disabled]="loading" class="btn btn-success mt-0 flex-grow-1 btnLogin" type="submit">
                <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
                <i class="fa-solid fa-arrow-right-to-bracket"></i> Entrar
        </button>
        <button type="button" class="btn btn-primary btnLogin" (click)="backToLogin()" data-bs-toggle="tooltip" data-bs-placement="top" title="No soy yo">
            <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
            <i class="fa-solid fa-user-lock"></i> Cambiar
        </button>
        <button type="button" class="btn btn-info btnLogin" (click)="passRecovery()" data-bs-toggle="tooltip" data-bs-placement="top" title="Recuperar acceso">
            <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
            <i class="fa-solid fa-user-shield"></i> Recuperar
        </button>
    </div>
    <!-- <hr> -->

    <!-- <div class="row justify-content-between">
        <div class="col- ml-2">
            <a (click)="backToLogin()" href="javascript:void(0)" id="cancel_reset" (click)="backApps()">
                <i class="fas fa-angle-left"></i>
                No soy yo
            </a>
        </div>
        <div class="col- mr-2">
            <a (click)="passRecovery()" href="javascript:void(0)" id="forgot_pswd">¿Olvidaste tu contraseña?</a>
        </div>
    </div> -->

</form>