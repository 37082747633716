export class Customer {
  Id_Customer: number = 0;
  Id_Channel: any = 0;
  ChannelName: string;
  Status: String = 'I';
  StatusName: string;
  Name: string;
  Email: string;
  MFAName: String = '';
  MFA: String = 'I';
  Date_Add: Date;
  static Id_Customer: any;
}

export class CustomerApp {
  isActive:boolean = false;
  Validity: string = 'I';
  Id_Customer_Application: Number = 0;
  Application_Name: string = '';
  Id_Application: number;
  Hide: boolean = false;
  Type_Pay: string = '';
  Id_Customer: number;
  ValiditySec: boolean = true;
  Date_Init: Date;
  Date_Expiration: Date;
  typePayList: any;
  Folio_Descount_Type: string;
}

export class CustomerFolio {
  Id_Customer_Application_Folio: Number = 0;
  Id_Application_Plan_Folio: Number = 0;
  Id_Application: Number = 0;
  Id_Customer: number;
  Id_Company: Number = 0;
  Folios: number;
  Description = '';
  Register_Type: String = 'F';
  Status: String = 'A';
  Id_Document_Apply: Number = 0;
  Comments: String = '';
}

export class UserSelected {
  Id_User: Number;
  Id_Customer: Number;
  Id_Company: Number;
  Id_Language: Number;
  Id_Channel: Number;
  ChannelName: String;
  Status: String;
  StatusName: String;
  Name: String;
  Img_Path: String;
  Email: String;
  Date_Add: String;
  Password: String;
  CustomerName: String;
  CompanyName: String;
};
