import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CompanyList } from 'src/app/data/models/company-list.model';
import { CustomerList } from 'src/app/data/models/customer-list.model';
import { ServicesService } from 'src/app/data/services/services.service';
import Swal from 'sweetalert2';
import { Customer } from '../../customer/customer';
import { CompanyEdit, Company_SMTP, Dimension, PAC, TaxRegime } from '../companies';
// Revisar import { NewDimension } from '../company.component';

@Component({
  selector: 'app-smtp-company',
  templateUrl: './smtp-company.component.html',
  styleUrls: ['./smtp-company.component.css']
})
export class SmtpCompanyComponent implements OnInit {

  @Input() idCompany;

  public companieSelected = new CompanyEdit();
  public selectedSMTP = new Company_SMTP();
  isEditSMTP = false;
  isNewEditSMTP = false;
  titleNewEditCompanie = '';


  constructor(
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private services: ServicesService
  ) {

    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }

  }

  ngOnInit(): void {
    this.selectCompanieId();
  }

  NewEditSMTP(ModeEdit: boolean) {
    this.isNewEditSMTP = true;
    this.isEditSMTP = true;
  }

  ChangeStatusSSL(statusSSL: boolean) {
    if (statusSSL){
      this.selectedSMTP.SSL = 'A';
  }else{
      this.selectedSMTP.SSL = 'I';
  }
  }

  ChangeStatusSMTP(status: boolean) {
    if (status){
      this.selectedSMTP.Status = 'A';
    }else{
      this.selectedSMTP.Status = 'I';
    }
  }

  selectCompanieId() {
    this.selectedSMTP = new Company_SMTP();
    this.services.Company_SMTP_By_Id(this.idCompany).subscribe(resp => {
      if (resp.result) {
        if (resp.data.length != 0){
          this.selectedSMTP = resp.data[0];
        }
      }
    });

  }

  onSubmitSMTP(e) {
    // console.log(this.UserInfo.Email);
    // this.selectedSMTP.EmailTo = this.UserInfo.Email;
    // this.selectedSMTP.Id_Company = this.companieSelected.Id_Company;
    this.selectedSMTP.Id_Company = this.idCompany;
    this.spinner.show();
    this.services.Validate_SMTP(this.selectedSMTP).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.spinner.show();
        this.services.Company_SMTP_New_Edit(this.selectedSMTP).subscribe(resp => {
          this.spinner.hide();
          if (resp.result) {
            this.spinner.show();
            this.services.Company_SMTP_By_Id(this.companieSelected.Id_Company).subscribe(resp => {
              this.spinner.hide();
              if (resp.result) {
                this.selectedSMTP = resp.data[0];
                this.isNewEditSMTP = false;
                this.isEditSMTP = false;
              }
            });
          }
        });
      }
    });

  }

  resetSMTP() {

    this.spinner.show();
    this.services.Company_SMTP_Reset(this.companieSelected.Id_Company).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.spinner.show();
        // tslint:disable-next-line: no-shadowed-variable
        this.services.Company_SMTP_By_Id(this.companieSelected.Id_Company).subscribe(resp => {
          this.spinner.hide();
          if (resp.result) {
            this.selectedSMTP = resp.data[0];
            this.isNewEditSMTP = false;
            this.isEditSMTP = false;
          }
        });
      }
    });

  }

  cancelSMTP() {
    this.isNewEditSMTP = false;
    this.isEditSMTP = false;
  }

}

