<!-- Comienza encabezado -->
<ng-container>
    <app-title-payment [titleP]="isTitle"></app-title-payment>
</ng-container>

<ng-container *ngIf="isReturn">
    <div class="row ">
        <div class="col-auto position-absolute pos mt-2 positionTop">
            <button (click)="returnToList()" class="btnBackTop">
                <i class="fas fa-arrow-left"></i>
            </button>
        </div>
    </div>
</ng-container>

<div class="row justify-content-between ">
    <div class="col-auto">
        <div class="row ">
            <div class="col-auto">
                <input name="search" [(ngModel)]="searchTerm" type="text" (input)="searchInput($event)"
                    (click)="returnToList()" class="input-search form-control"
                    placeholder="{{ 'GENERAL.searchby' | translate }}...">
                <a class="ico-btn-search">
                    <i class="ti-search"></i>
                </a>
            </div>
            <!-- <div class="col-auto" *ngIf="!isReturn">
                <select (change)="selectLanguage($event.target.value)"
                    class="custom-select inputPer mb-1" id="language" name="language">
                    <option value="">Selecciona lenguaje:</option>
                    <option value="0">
                        Español
                    </option>
                    <option value="1">
                        Inglés
                    </option>
                </select>
            </div> -->
        </div>
    </div>
    <ng-container *ngIf="!isReturn">
        <div class="col-auto mt-2 mt-sm-0">
            <button (click)="createNewMenu()" class="btnSta mt-1" placement="top" ngbTooltip="Nuevo">
                <i class="fas fa-plus-square fa-lg"></i>
            </button>
        </div>
    </ng-container>
</div>
<!-- Termina encabezado -->

<!--Canvas-->
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <button type="button" class="btnArrow" data-bs-dismiss="offcanvas">
            <span><i class="fa fa-arrow-left"></i></span>
        </button>
        <div class="col-auto">
            <div class="col-auto">
                <select (change)="selectFilter($event.target.value)" class="form-select form-select-sm" aria-label=".form-select-sm example">
                    <option value="">Selecciona filtro:</option>
                    <option value="1">
                        Aplicación
                    </option>
                    <option value="2">
                        Tag
                    </option>
                    <option value="3">
                        Menú Padre
                    </option>
                    <option value="4">
                        Estatus
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class="col-auto px-0">
            <div *ngIf="nameColumn === 'A'" class="multiS">
                <app-application-multiselect (idAppC)="filterByApp($event)"></app-application-multiselect>
            </div>
            <div *ngIf="nameColumn === 'T'" class="multiS">
                <app-tag-multiselect (idTagC)="filterByTag($event)"></app-tag-multiselect>
            </div>
            <ng-container *ngIf="nameColumn === 'F'">
                <div *ngIf="this.idApp === 0">
                    <div class="alert alert-warning alert-dismissible fade show" role="alert">
                        <strong>Debes de filtrar por aplicación primero.</strong>
                    </div>
                </div>
                <div *ngIf="this.idApp !== 0" class="multiS">
                    <app-menu-multiselect (idMenuC)="filterByFather($event)" [idAppChildren]="idApp"></app-menu-multiselect>
                </div>
            </ng-container>
            <div *ngIf="nameColumn === 'S'" class="row text-center">
                <div class="col-12">
                    <p class="mb-1">Filtra por estatus: </p>
                    <label class="switch hideShow mb-3">
                        <input #status [(ngModel)]="statusMenu" (change)="updateStatus($event)" name="status" type="checkbox" id="status"/>
                        <span class="slider round-in "></span>
                    </label>
                </div>
            </div>
            <div *ngIf="nameColumn !== 'S'" class="d-grid gap-2 mt-3">
                <button (click)="filterData()" type="button" data-bs-dismiss="offcanvas" class="btn btn-success btnFilter">Filtrar</button>
            </div>
        </div>
    </div>
</div>
<!--termina canvas-->

<!-- Comienza contenido -->
<div class="row mt-2 pb-2">
    <ng-container *ngIf="showPrincipal">
        <div class="col-md-12 col-lg-12 col-sm-12 divPrinc">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="table-responsive tablePayments heig">
                            <table sortable-table (sorted)="onSorted($event)" class="table table-striped">
                                <thead>
                                    <tr class="purple">
                                        <th style="width: 50px;">
                                            <button
                                            *ngIf="isRest || idApp != 0 || idTag != 0 || idMenuFather != null || pagination != 10 || statusMenu != true"
                                            class="tooltipp btnRes" (click)="resetTable()" placement="top"
                                            ngbTooltip="Restablecer Filtros"><i class="fas fa-trash-restore-alt p-1"></i></button>
                                        </th>
                                        <th sortable-table="padre" sortable-column="idx" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown"
                                                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <span *ngIf="azIdx"><i class="fas fa-sort-numeric-up-alt"></i></span>
                                                    <span *ngIf="zaIdx"><i class="fas fa-sort-numeric-down-alt"></i></span>
                                                    Idx
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="onSortedA($event)" class="dropdown-item"
                                                            href="javascript:void(0);">Ascendente</a></li>
                                                    <li><a (click)="onSortedD($event)" class="dropdown-item"
                                                            href="javascript:void(0);">Descendente</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th class="th-sort" sortable-table="padre" sortable-column="mnu" scope="col">
                                            <div class="dropdown ps-1">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown"
                                                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                                    <span *ngIf="azMen"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="zaMen"><i class="fas fa-sort-alpha-down-alt"></i></span>
                                                    Menú
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="onSortedA($event)" class="dropdown-item"
                                                            href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD($event)" class="dropdown-item"
                                                            href="javascript:void(0);">De la Z a la A</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="padre" sortable-column="app" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown"
                                                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <span *ngIf="azApp"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="zaApp"><i class="fas fa-sort-alpha-down-alt"></i></span>
                                                    <span *ngIf="idApp != 0" class="icon"><i class="fas fa-filter"></i></span>
                                                    Aplicación
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="onSortedA($event)" class="dropdown-item"
                                                            href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD($event)" class="dropdown-item"
                                                            href="javascript:void(0);">De la Z a la A</a></li>
                                                    <li><a (click)="selectColumn('A')" class="dropdown-item"
                                                        type="button" data-bs-toggle="offcanvas"
                                                        data-bs-target="#offcanvasRight"
                                                        aria-controls="offcanvasRight">Filtrar aplicación</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="padre" sortable-column="tag" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown"
                                                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <span *ngIf="azTag"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="zaTag"><i class="fas fa-sort-alpha-down-alt"></i></span>
                                                    <span *ngIf="idTag != 0" class="icon"><i class="fas fa-filter"></i></span>
                                                    Tag
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="onSortedA($event)" class="dropdown-item"
                                                            href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD($event)" class="dropdown-item"
                                                            href="javascript:void(0);">De la Z a la A</a></li>
                                                    <li><a (click)="selectColumn('T')" class="dropdown-item"
                                                            type="button" data-bs-toggle="offcanvas"
                                                            data-bs-target="#offcanvasRight"
                                                            aria-controls="offcanvasRight">Filtrar Tag</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="padre" sortable-column="action" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown"
                                                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <span *ngIf="azAct"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="zaAct"><i class="fas fa-sort-alpha-down-alt"></i></span>
                                                    Acción del Menú
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="onSortedA($event)" class="dropdown-item"
                                                            href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD($event)" class="dropdown-item"
                                                            href="javascript:void(0);">De la Z a la A</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="padre" sortable-column="mnuf" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown"
                                                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <span *ngIf="azAct"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="zaAct"><i class="fas fa-sort-alpha-down-alt"></i></span>
                                                    <span *ngIf="idMenuFather != null" class="icon"><i class="fas fa-filter"></i></span>
                                                    Menú padre
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="onSortedA($event)" class="dropdown-item"
                                                            href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD($event)" class="dropdown-item"
                                                            href="javascript:void(0);">De la Z a la A</a></li>
                                                    <li><a (click)="selectColumn('F')" class="dropdown-item"
                                                        type="button" data-bs-toggle="offcanvas"
                                                        data-bs-target="#offcanvasRight"
                                                        aria-controls="offcanvasRight">Filtrar Menú Padre</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="padre" sortable-column="sta" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown"
                                                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    Estatus
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="selectColumn('S')" class="dropdown-item"
                                                        type="button" data-bs-toggle="offcanvas"
                                                        data-bs-target="#offcanvasRight"
                                                        aria-controls="offcanvasRight">Filtrar Estatus</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let menu of listMenus">
                                        <td style="width: 50px;">
                                            <button (click)="selectMenu(menu.Id_Menu)" class="btnEdit ps-1"><i class="fas fa-file-invoice"></i></button>
                                            <button *ngIf="menu.AssignedProfiles === 0 && menu.ChildMenus === 0" class="btnEdit ps-1" (click)="deleteMenu(menu.Id_Menu, menu.Labels[0].Text)"><i class="fas fa-trash-alt ml-1"></i></button>
                                        </td>
                                        <td style="width: 50px;" class="center">{{menu.Idx}}</td>
                                        <td scope="row">{{menu.Menu}}</td>
                                        <td>{{menu.ApplicationName}}</td>
                                        <td class="center">{{menu.TagDescription}}</td>
                                        <td class="center">{{menu.ActionTypeDescription}}</td>
                                        <td class="center">{{menu.MenuFather || 'Sin Menú Padre'}}</td>
                                        <td style="width: 150px;" class="center">
                                            <span *ngIf="menu.Active === true" class="badge badge-pill badge-success mb-1">Activo</span>
                                            <span *ngIf="menu.Active === false" class="badge badge-pill badge-secondary mb-1">Inactivo</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <app-pagination-global [callbackFunction]="getMenus" [actualPagination]="pagination" [collectionDinamic]="collectionSize" (changePagination)="changeAmountView($event)" (changePageEmi)="changePage($event)"></app-pagination-global>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isView">
        <div class="col-md-12 col-lg-12 col-sm-12 generalTab divPrinc">
            <!-- <div class="card grey pb-3"> -->
                <app-mytab-menus (goBack)="returnToList()" [idMenuDetail]="idMenu"></app-mytab-menus>
                <!-- <app-detail-menu [idMenuDetail]="idMenu" (goBack)="returnToList()"></app-detail-menu> -->
            <!-- </div> -->
        </div>
    </ng-container>

</div>

<app-back-to-top></app-back-to-top>
<!-- Termina contenido -->
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>