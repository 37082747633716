import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import Swal from 'sweetalert2';
import { CompanyEdit } from '../companies';

@Component({
  selector: 'app-branch-offices-company',
  templateUrl: './branch-offices-company.component.html',
  styleUrls: ['./branch-offices-company.component.css']
})
export class BranchOfficesCompanyComponent implements OnInit {
  @Input() idCompany;
  public Branch_Offices: any[] = [];
  public BranchOffice: any = {};
  public CostCenters: any;
  public companieSelected = new CompanyEdit();

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private services: ServicesService
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    this.Company_Branch_Offices_List(this.idCompany);
  }

  Company_Branch_Offices_List(Id_Company) {
    this.spinner.show();
    this.services.Company_Branch_Office_List(this.idCompany).subscribe(resp => {
      this.spinner.hide();
      if (resp.result){
        this.Branch_Offices = resp.data;
      }
    });
  }

  Company_Center_Cost_List(Id_Company) {
    this.spinner.show();
    this.services.Company_Center_Cost_List(this.idCompany).subscribe(resp => {
      this.spinner.hide();
      if (resp.result){
        this.CostCenters = resp.data;
      }
    });
  }

  deleteBranchOffice(Id_BusinessPlace) {
    Swal.fire({
      title: '¿Estás seguro de querer eliminar esta Sucursal?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#746AB0',
      cancelButtonColor: '#E83845',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.services.Company_Branch_Office_Delete(Id_BusinessPlace).subscribe(resp => {
          if (resp.result) {
            this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));
            this.Company_Branch_Offices_List(this.companieSelected.Id_Company);
          }
        });
      }
    });
  }

  Company_Branch_Office_New() {
    this.services.Company_Branch_Office_New(this.BranchOffice.Description, this.BranchOffice.ERP_Id, this.idCompany).subscribe(resp => {
      if (resp.result) {
        this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));
        this.Company_Branch_Offices_List(this.companieSelected.Id_Company);
      }
    });
    this.BranchOffice.Description = '';
    this.BranchOffice.ERP_Id = 0;
  }

}
