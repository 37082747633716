<div class="card dataCompany mt-3 ms-3 me-4">
    <div class="card-header bg-transparent border-0 ">
        <div>
            <h4 class="card-title"><b>Access:</b> {{profileSelected.ProfileDescription}} </h4>
        </div>
        <div>
            <div class="row gx-0">
                <div class="col-auto">
                    <h5 class="card-title pull-left mt-1">Filtrar por status</h5>
                </div>
                <div class="col-auto">
                    <label class="switch">
                        <input name="status" [(ngModel)]="isActiveStatus" (change)="updateStatus($event)"
                            type="checkbox" id="status" />
                        <span class="slider round-in"></span>
                    </label>
                </div>
                <ng-container *ngIf="statusAccess !== -1">
                    <div class="col-auto">
                        <h5 class="card-title pull-left mt-1">Limpiar filtro por status</h5>
                    </div>
                    <div class="col-auto">
                        <button class="noP ps-1" (click)="clearStatus()"><i class="fas fa-eraser"></i></button>
                    </div>
                </ng-container>
            </div>
        </div>
         <div class="row gx-0 mt-1">
            <div class="col-lg-12 col-md-12 col-sm-12"> 
                <div *ngFor="let ac of listAccess; index as i" class="table-responsive">
                    <table *ngIf="ac.Access;" class="table table-striped ">
                        <thead>
                            <tr>
                                <th style="width: 250px;">{{ac.Application}} (Accessos)</th>
                                <th class="text-nowrap" style="width: 200px;">Key Access</th>
                                <th class="text-nowrap" style="width: 50px;">Estatus</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let access of ac.Access">
                                <td>{{access.Accesss}}</td>
                                 <td>{{access.KeyAccess}}</td>
                                 <td class="text-nowrap">
                                    <div class="col-">
                                        <label class="switch">
                                            <input [checked]="access.Active == 1? true:false"
                                                (change)="changeStatusAccess(access.Id_Accesss,$event.target.checked)"
                                                name="status{{access.Id_Accesss}}" type="checkbox"
                                                id="status{{access.Id_Accesss}}" />
                                            <span class="slider round-in"></span>
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
     </div>
</div> 