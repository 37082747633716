import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-column-status',
  templateUrl: './column-status.component.html',
  styleUrls: ['./column-status.component.css']
})
export class ColumnStatusComponent implements OnInit {

  @Output() statusSel = new EventEmitter<string>();

  typeSta = '';

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    if(localStorage.getItem('staSto') !== null) {
      this.typeSta = localStorage.getItem('staSto');
    }
  }

  selectSta(e) {
    this.typeSta = e.target.value;
    localStorage.setItem('staSto', String(this.typeSta));
    this.statusSel.emit(this.typeSta);
  }

}
