import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { AccessComponent } from './access/access.component';

import { AccountComponent } from './account/account.component';
import { AppAccountComponent } from './app-account.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChannelComponent } from './channel/channel.component';
import { CompanyComponent } from './company/company.component';
import { CustomerComponent } from './customer/customer.component';
import { GruposComponent } from './grupos/grupos.component';
import { IndicatorsComponent } from './indicators/indicators.component';
import { MenuComponent } from './menu/menu.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { PaymentsControlComponent } from './payments-control/payments-control.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { ProfileComponent } from './profile/profile.component';
import { UserComponent } from './user/user.component';


const routes: Routes = [
  { path: 'callback/:amb/:ut/:at', component: AppAccountComponent, pathMatch: 'full' },
  {
    path: 'account', component: AppAccountComponent, canActivate: [AuthGuard],
    children: [
      { path: '', component: AccountComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'payments', component: PaymentsControlComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'indicators', component: IndicatorsComponent, pathMatch: 'full', canActivate: [AuthGuard]},
      { path: 'channel', component: ChannelComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'company', component: CompanyComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'customer', component: CustomerComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'group', component: GruposComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'me', component: MyAccountComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'preferences', component: PreferencesComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'password', component: ChangePasswordComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'users', component: UserComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'access', component: AccessComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'menu', component: MenuComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'profile', component: ProfileComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    ]
  }
];

// account/channel
// account/customer
// account/astatement
// account/company
// account/users
// account/groups
// account/me
// account/preferences
// account/password

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
