import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { ServicesService } from 'src/app/data/services/services.service';
import { GlobalService } from 'src/app/global/global.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { environment } from '../../environments/environment';

@Component({
  templateUrl: 'login.component.html',
  animations: [
    trigger('slideView', [
      state('true', style({ transform: 'translateX(100%)', opacity: 0 })),
      state('false', style({ transform: 'translateX(0)', opacity: 1 })),
      transition(
        '0 => 1',
        animate('500ms', style({ transform: 'translateX(0)', opacity: 1 }))
      ),
      transition(
        '1 => 1',
        animate('500ms', style({ transform: 'translateX(100%)', opacity: 0 }))
      ),
    ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate(
          '600ms ease-in',
          style({ transform: 'translateX(0%)', opacity: 1 })
        ),
      ]),

      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate(
          '0ms ease-in',
          style({ transform: 'translateX(100%)', opacity: 0 })
        ),
      ]),
    ]),
  ],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  contract: '';
  ambientes = [1, 2, 3];

  /*
        ScreenStep
        1- Environment
        2- App
        3- Login User
        4- Login Psw
        5- MFA
        6- Forget Psw
        7- Forget Psw 2
        8- New Psw
        9- New Psw User
        10- Code Psw
        11- Preferences
        12- SearchUser
    */

  ScreenStep = 0;

  fieldTextType: boolean;

  appToken = '';
  enviroment = 0;
  amb = 0;
  appVersion: string = environment.appVersion;

  sesUsers = [];

  comefromUS = false;

  data = {};

  terms = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private allService: ServicesService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    //console.log('entra ngOnInit Constructor', );

    if (localStorage.getItem('appToken') === null || localStorage.getItem('appToken') === '') {
        this.terms = false;
    } else {
        this.terms = true;
    }

    this.sesUsers = JSON.parse(localStorage.getItem('sesUsers'));
    if (this.sesUsers == null) this.sesUsers = [];

    this.route.paramMap.subscribe((params) => {
        this.ScreenStep = 0;
        this.appToken = '';

        if (params.has('amb')) 
        {
          this.enviroment = parseInt(params.get('amb'));
            localStorage.setItem('amb', this.enviroment.toString());

            if (params.has('at')) {
              this.appToken = params.get('at');
              localStorage.setItem('appToken', this.appToken);

                if (localStorage.getItem('sesUsers') !== null) {

                  if (!this.comefromUS)
                  {
                    if (this.ScreenStep === 12) {
                      this.ScreenStep = 3;
                    } 
                    else {
                        this.ScreenStep = 12;
                    }
                  }
                  else{
                    console.log("Viene se user Sessions");
                    this.ScreenStep = 3;
                    return;
                  }

                } else {
                    this.ScreenStep = 3;
                }
                
                this.getEnvData(this.enviroment, this.appToken);
            } 
            else 
              this.ScreenStep = 2;
            
        } 
        else 
          this.ScreenStep = 1;

          // if (localStorage.getItem('amb') == null || localStorage.getItem('amb') == '' || localStorage.getItem('amb') == '0')
          // {
          //   this.ScreenStep = 1;
          //   if (params.has('at')) this.appToken = params.get('at');
          // }
          // else
          // {
          //   this.enviroment = parseInt(localStorage.getItem('amb'));

          //   if (localStorage.getItem('appToken') == null || localStorage.getItem('appToken') == '')
          //   {
          //       this.ScreenStep = 2;
          //   }
          //   else
          //   {
          //     this.ScreenStep = 3;
          //   }

          // }


        
        
        //localStorage.setItem('screenStep', this.ScreenStep.toString());
    });

  }

  getEnvData(enviroment, appToken) {

    this.allService
      .appTokenInfo({
        enviroment,
        data: {
          appToken
        },
      })
      .subscribe(
        (response) => {
          if (response.result) {
            localStorage.setItem('callBackUrl', response.data.callBackUrl);
            localStorage.setItem('appName', response.data.name);
          } else {
            this.router.navigateByUrl('/');
          }
        },
        (error) => {
          this.router.navigateByUrl('/');
        }
      );

  }

  backUS(){
    this.ScreenStep = 12;    
  }

  backApps(){
    this.router.navigateByUrl("/login/" + this.enviroment);
  }

   backAmb() {

    this.router.navigateByUrl("/");

      // this.ScreenStep = step;
      // this.terms = false;

  //   // switch(step){
  //   //   case 2: 
  //   //     localStorage.removeItem('email');
  //   //     localStorage.removeItem('appToken');
  //   //     break;
  //   //   case 1:
  //   //     localStorage.removeItem('amb');
  //   //     break;        
  //   // }
  }

  validApp(e) {

    if (e.trim().length > 0) {
      let appTk = e.trim();
    
      if (this.enviroment > 0) {
        this.appToken = appTk;
        localStorage.setItem('appToken', appTk.toString());
        this.router.navigateByUrl("/login/" + this.enviroment + "/" + appTk);
      }

    }

    // if (e.trim().length > 0) {
    //   this.appToken = e.trim();

    //   this.enviroment = parseInt(localStorage.getItem('amb'));

    //   if (this.enviroment > 0) {
    //     this.getEnvData();

    //     localStorage.setItem('appToken', this.appToken.toString());

    //     if (this.sesUsers.length > 0)
    //       this.ScreenStep = 12;
    //     else
    //       this.ScreenStep = 3;

    //   } 
    //   else this.ScreenStep = 1;

    // }

    // if (localStorage.getItem('appToken') !== null || localStorage.getItem('appToken') !== '') {
    //     this.terms = true;
    // }

  }

  validEnvironmet(e) {
    
    if (e > 0) {
      let env = parseInt(e);

      if (this.ambientes.includes(env)) {
        this.router.navigateByUrl("/login/" + env);
      }

      //localStorage.setItem('amb', this.enviroment.toString());
      // if (localStorage.getItem('appToken').trim().length > 0){
      //     this.appToken = localStorage.getItem('appToken');
      //     this.getEnvData();
      //     this.ScreenStep = 3;
      // }
      // else
      //this.ScreenStep = 2;
    }
  }

  validUser(e) {
    this.ScreenStep = 4;
  }

  validPass(e) {
    this.ScreenStep = 0;
  }

  backToLogin(e) {
    this.comefromUS = true;
    this.ScreenStep = 3;
  }

  passRecovery(e) {
    this.ScreenStep = 10;
  }

  passCode(e) {
    this.ScreenStep = 10;
  }

  newPass(e) {
    this.ScreenStep = 8;
  }

  newPassUser() {
    this.ScreenStep = 9;
  }

  viewTerms() {
    this.TermsAndConditionsService();
  }

  viewAdvice() {
    this.PrivacyPolicyService();
  }

  destroyTerms() {
    this.contract = '';
  }

  viewPreferences() {
    this.ScreenStep = 11;
  }

  TermsAndConditionsService() {
    let appToken = this.appToken;

    this.data = {
      appToken,
    };

    this.allService
      .TermsAndConditions(this.data, this.enviroment)
      .subscribe((resp) => {
        this.contract = resp.data.description;
      });
  }

  PrivacyPolicyService() {
    let appToken = this.appToken;

    this.data = {
      appToken,
    };

    this.allService.PrivacyPolicy(this.data, this.enviroment).subscribe((resp) => {
      if (this.globalService.verifyResponse(resp)) {
        this.contract = resp.data.description;
      }
    });
  }
}
