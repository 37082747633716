import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from 'src/app/data/services/services.service';
import { Company_SMTP } from './companies';
import { CompanyList } from 'src/app/data/models/company-list.model';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {

  companyLst = new CompanyList();
  idComp = 0;
  public page: number = 0;
  public listCompanies = [];
  public Id_CustomerSelected: Number = 0;
  public selectedSMTP = new Company_SMTP();
  EditCompany = false;
  isReturn = false;
  results = true;
  viewCompanie = true;
  isNewEdit = false;
  isEdit = false;
  isView = false;
  AZcom = false;
  ZAcom = false;
  AZalt = false;
  ZAalt = false;
  AZcus = false;
  ZAcus = false;
  AZrfc = false;
  ZArfc = false;
  filterType = '';
  Title = 'Companías';
  selectedPage: any = 1;
  collectionSize = 0;
  totalPages = 0;
  StatusComp = false;
  isRest = false;
  lstPagina = [];

  constructor(
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private services: ServicesService
  ) {

    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
    if (localStorage.getItem('comStoId') !== null) {
      this.companyLst.Id_Company = +localStorage.getItem('comStoId');
    }
    if (localStorage.getItem('cusStoId') !== null) {
      this.companyLst.Id_Customer = +localStorage.getItem('cusStoId');
    }
    if (localStorage.getItem('paginationStorage') !== null) {
      this.companyLst.Pagination = +localStorage.getItem('paginationStorage');
    }

  }

  ngOnInit(): void {

    const myOffcanvas = document.querySelector('#offcanvasRight') as any;

    if (myOffcanvas !== null) {
      myOffcanvas.addEventListener('hidden.bs.offcanvas', () => {
        this.filterType = '';
      });
    }

    this.LoadCompanies();
  }

 
  filterSel(e) {
    const ftro = Number(e);
    if (ftro === 1) {
      this.filterType = 'C';
    } else if (ftro === 2) {
      this.filterType = 'O';
    } else if (ftro === 3) {
      this.filterType = 'E';
    } 
  }

  onSearchChange() {
    this.page = 0;
    this.LoadCompanies();
  }

  filterChannel(e) {
    if (e === 'C') {
      this.filterType = e;
    } else if (e === 'O') {
      this.filterType = e;
    } else if (e === 'E') {
      this.filterType = e;
    }
  }
  
  filterByCompany(e) {
    this.companyLst.Id_Company = e;
  }

  filterByCustomer(e) {
    this.companyLst.Id_Customer = e;
  }

  filterData() {
    this.LoadCompanies();
  }

  selectCompanieId(Id_Company) {
    this.selectedSMTP = new Company_SMTP();
    this.results = false;
    this.isReturn = true;
    this.isView = true;
    this.isNewEdit = false;
    this.isEdit = false;
    this.viewCompanie = true;
    this.idComp = Id_Company;
  }

  returnToList() {
    this.isEdit = false;
    this.isView = false;
    this.results = true;
    this.isNewEdit = false;
    this.isReturn = false;
    this.idComp = 0;
    this.LoadCompanies();
  }

  newEditCompanie(edit) {
    this.idComp = 0;
    this.isReturn = true;
    this.viewCompanie = false;
    this.isNewEdit = true;
    this.results = false;
    this.EditCompany = edit;
    this.isView = true;
  }

  returnToView() {
    this.isView = false;
    this.isNewEdit = false;
    this.isView = false;
    this.results = true;
    this.viewCompanie = true;
    this.isReturn = false;
    this.LoadCompanies();
    localStorage.removeItem('cusStoId');
  }

  changeAmountView(e) {
    this.companyLst.Pagination = e.target.value;
    this.LoadCompanies();
  }

  changePage(e) {
    this.companyLst.Page = e;
    this.LoadCompanies();
  }

  updateStatus(e) {
    if (!e.target.checked) {
      this.companyLst.Status = 'I';
    } else {
      this.companyLst.Status = 'A';
    }
    this.isRest = true;
    this.LoadCompanies();
  }

  onSorted(e) {
    this.companyLst.Order_Column = e.sortColumn;
  }

  onSortedA(e) {
    this.companyLst.Order_Dir = 'A';
    if (this.companyLst.Order_Column === 'com' && this.companyLst.Order_Dir === 'A'){
      this.isRest = true;
      this.AZcom = true;
      this.ZAcom = false;
      this.AZalt = false;
      this.ZAalt = false;
      this.AZcus = false;
      this.ZAcus = false;
      this.AZrfc = false;
      this.ZArfc = false;
    }else if (this.companyLst.Order_Column === 'alt' && this.companyLst.Order_Dir === 'A'){
      this.isRest = true;
      this.AZcom = false;
      this.ZAcom = false;
      this.AZalt = true;
      this.ZAalt = false;
      this.AZcus = false;
      this.ZAcus = false;
      this.AZrfc = false;
      this.ZArfc = false;
    }else if (this.companyLst.Order_Column === 'cus' && this.companyLst.Order_Dir === 'A'){
      this.isRest = true;
      this.AZcom = false;
      this.ZAcom = false;
      this.AZalt = false;
      this.ZAalt = false;
      this.AZcus = true;
      this.ZAcus = false;
      this.AZrfc = false;
      this.ZArfc = false;
    }else if (this.companyLst.Order_Column === 'rfc' && this.companyLst.Order_Dir === 'A'){
      this.isRest = true;
      this.AZcom = false;
      this.ZAcom = false;
      this.AZalt = false;
      this.ZAalt = false;
      this.AZcus = false;
      this.ZAcus = false;
      this.AZrfc = true;
      this.ZArfc = false;
    }
    this.LoadCompanies();
  }

  onSortedD(e) {
    this.companyLst.Order_Dir = 'D';
    if (this.companyLst.Order_Column === 'com' && this.companyLst.Order_Dir === 'D'){
      this.isRest = true;
      this.AZcom = false;
      this.ZAcom = true;
      this.AZalt = false;
      this.ZAalt = false;
      this.AZcus = false;
      this.ZAcus = false;
      this.AZrfc = false;
      this.ZArfc = false;
    }else if (this.companyLst.Order_Column === 'alt' && this.companyLst.Order_Dir === 'D'){
      this.isRest = true;
      this.AZcom = false;
      this.ZAcom = false;
      this.AZalt = false;
      this.ZAalt = true;
      this.AZcus = false;
      this.ZAcus = false;
      this.AZrfc = false;
      this.ZArfc = false;
    }else if (this.companyLst.Order_Column === 'cus' && this.companyLst.Order_Dir === 'D'){
      this.isRest = true;
      this.AZcom = false;
      this.ZAcom = false;
      this.AZalt = false;
      this.ZAalt = false;
      this.AZcus = false;
      this.ZAcus = true;
      this.AZrfc = false;
      this.ZArfc = false;
    }else if (this.companyLst.Order_Column === 'rfc' && this.companyLst.Order_Dir === 'D'){
      this.isRest = true;
      this.AZcom = false;
      this.ZAcom = false;
      this.AZalt = false;
      this.ZAalt = false;
      this.AZcus = false;
      this.ZAcus = false;
      this.AZrfc = false;
      this.ZArfc = true;
    }
    this.LoadCompanies();
  }

  resetTable() {
    localStorage.removeItem('cusStoId');
    localStorage.removeItem('chaStoNa');
    localStorage.removeItem('comStoId');
    localStorage.removeItem('comStoNa');
    localStorage.removeItem('paginationStorage');
    this.companyLst.Id_Customer = 0;
    this.companyLst.Id_Company = 0;
    this.companyLst.Order_Column = '';
    this.companyLst.Order_Dir = '';
    this.companyLst.Status = '';
    this.companyLst.Page = 1;
    this.companyLst.Pagination = 10;
    this.AZcom = false;
    this.ZAcom = false;
    this.AZalt = false;
    this.ZAalt = false;
    this.AZcus = false;
    this.ZAcus = false;
    this.AZrfc = false;
    this.ZArfc = false;
    this.isRest = false;
    this.StatusComp = false;
  return this.LoadCompanies();
  }

  // changeCustomer(Id_CustomerSelected: number) {
  //   this.Id_CustomerSelected = Id_CustomerSelected;
  //   this.page = 0;
  //   this.LoadCompanies();
  // }

  LoadCompanies() {
    this.spinner.show();
    this.services.Company_List(this.companyLst).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.listCompanies = resp.data;
        this.lstPagina = this.listCompanies;
        // console.log(this.lstPagina);
      }
      for (let i = 0; i < resp.data.length; i++) {
        this.totalPages = resp.data[i].Pages;
        this.collectionSize = resp.data[i].Pages * 10;
      }
      if (resp.data.length === 0) {
        this.totalPages = 1;
        this.collectionSize = 1;
        this.selectedPage = 1;
      }
    });
  }

}
