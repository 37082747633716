import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Profile } from 'src/app/data/models/profile.model';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-menus-profiles',
  templateUrl: './menus-profiles.component.html',
  styleUrls: ['./menus-profiles.component.css']
})
export class MenusProfilesComponent implements OnInit {

  @Input() idProfileMenu: number;

  profileSelected = new Profile();
  listApps = [];
  listMenus = [];
  listMenusFirstLevel = [];
  listMenusSecondLevel = [];
  idMenu = 0;
  showSubmenus = false;
  showSubmenus2 = false;
  idxApp: any;
  idxMenu: any;
  idxSubmenu: any;
  titleMenuGlobal = '';
  statusByProfile = -1;
  isActiveStatus = false;

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
   }

  ngOnInit(): void {
    this.profileById();
    this.menusByProfile();
  }

  updateStatus(e) {
    this.idxApp = -1;
    this.idxMenu = -1;
    if (!e.target.checked) {
      this.statusByProfile = 0;
    } else {
      this.statusByProfile = 1;
    }
    this.menusByProfile();
  }

  clearStatus() {
    this.statusByProfile = -1;
    this.isActiveStatus = false;
    this.menusByProfile();
  }

  profileById() {
    this.services.post({
      entidad: 'Profile',
      funcion: 2,
      data: {
        Id_Profile: this.idProfileMenu
      }
    }).subscribe(result => {
      if (result.data) {
        this.profileSelected = result.data;
      }
    }, error => {
      console.log('Error', error);
    });
  }

  menusByProfile() {
    this.services.post({
      entidad: 'Profile',
      funcion: 11,
      data: {
        Id_Profile: this.idProfileMenu,
        Active: this.statusByProfile
      }
    }).subscribe(result => {
      if (result.data) {
        this.listApps = result.data;
      }
    }, error => {
      console.log('Error', error);
    });
  }

  showSubmenu(appPos, menuPos) {
    this.idxApp = appPos;
    this.idxMenu = menuPos;
    this.idxSubmenu = -1;
    this.showSubmenus = true;
  }

  cancel() {
    this.idxApp = -1;
    this.idxMenu = -1;
    this.showSubmenus = false
  }

  showSubmenu2(appPos, menuPos, submenuPos) {
    this.idxApp = appPos;
    this.idxMenu = menuPos;
    this.idxSubmenu = submenuPos;
    this.showSubmenus2 = true;
  }

  cancel2() {
    this.idxSubmenu = -1;
    this.showSubmenus2 = false;
  }

  selectMenu(Id_Menu) {
    this.idMenu = Id_Menu;
  }

  changeStatus(Id_Menu, status, titleMenu) {
    this.idMenu = Id_Menu;
    this.titleMenuGlobal = titleMenu;
    if (status === true) {
      this.addMenu();
    } else if (status === false) {
      this.deleteMenu();
    }
  }

  addMenu() {
    this.services.post({
      entidad: 'Profile',
      funcion: 8,
      data: {
        Id_Profile: this.profileSelected.Id_Profile,
        Id_Menu: this.idMenu
      }
    }).subscribe(result => {
      if (result.result === true) {
        this.toastr.success(`Menú '${this.titleMenuGlobal}' agregado correctamente al perfil '${this.profileSelected.ProfileDescription}'`, 'Perfiles');
        this.menusByProfile();
        this.idMenu = 0;
      }
    }, error => {
      console.log('Error', error);
    });
  }

  deleteMenu() {
    this.services.post({
      entidad: 'Profile',
      funcion: 9,
      data: {
        Id_Profile: this.profileSelected.Id_Profile,
        Id_Menu: this.idMenu
      }
    }).subscribe(result => {
      if (result.result === true) {
        this.toastr.success(`Menú '${this.titleMenuGlobal}' eliminado correctamente del perfil '${this.profileSelected.ProfileDescription}'`, 'Perfiles');
        this.menusByProfile();
      }
    }, error => {
      console.log('Error', error);
    });
  }

}
