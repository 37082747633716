<div class="card dataCompany ms-3 me-4 mt-2">
    <div class="card-header bg-transparent border-0 generalTab">
        <div>
            <h4 class="card-title"><b>{{ "GENERAL.user" | translate }}:</b> {{UserSelected.Name}}</h4>
            <div class="row gx-0">
                <div class="col-auto">
                    <h5 class="card-title pull-left mt-1">{{ "USERS.accessAllCompanies" | translate }}</h5>
                </div>
                <div class="col-auto">
                    <label class="switch">
                        <input [(ngModel)]="UserSelected.Multicompany" [checked]="UserSelected.Multicompany" (change)="User_Multicompany_Change_Status($event.target.checked, UserSelected.Id_User)" name="status" type="checkbox" id="status"/>
                        <span class="slider round-in"></span>
                    </label>
                </div>
            </div>
        </div>
        <div *ngIf="!UserSelected.Multicompany" class="row gx-0 mt-2">
            <div class="col-auto">
                <form class="app-search pull-right">
                    <input [(ngModel)]="searchCompany" name="searchCompany" type="text" (input)="onSearchCompany($event.target.value)" class="input-search form-control" placeholder="{{ 'GENERAL.searchby' | translate }}..." />
                    <a class="ico-btn-search">
                        <i class="ti-search"></i>
                    </a>
                </form>
            </div>
        </div>
        <div *ngIf="UserSelected.Multicompany" class="row gx-0">
            <div class="col-lg-12 col-md-8">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>{{ "GENERAL.company" | translate }}</th>
                                <th>Card Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let multicompany of listMultiCompany">
                                <td>{{multicompany.Name}}</td>
                                <td>
                                    <div class="row">
                                        <div class="col-auto">
                                            <a *ngIf="!multicompany.edit" (click)="editCC(multicompany)" href="javascript:void(0)" class="ml-4 btnEdit"><i class="fas fa-edit"></i></a>
                                            <a *ngIf="multicompany.edit" (click)="saveCC(multicompany)" href="javascript:void(0)" class="ml-4 btnE"><i class="far fa-save"></i></a>
                                        </div>
                                        <div *ngIf="!multicompany.edit" class="col-auto">
                                            {{multicompany.CardCode}}
                                        </div>
                                        <div *ngIf="multicompany.edit" class="col-auto">
                                            <input [(ngModel)]="multicompany.CardCode" name="cardCode" class="form-control" type="text" placeholder="Card Code..." id="cardCode" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="!UserSelected.Multicompany" class="row gx-0 mt-3">
            <div class="col-lg-12 col-md-9">
                <div class="table-responsive">
                    <table sortable-table (sorted)="onSorted($event)" class="table table-striped">
                        <thead>
                            <tr>
                                <th sortable-column="Company_Name" style="cursor: pointer;">{{ "GENERAL.company" | translate }}</th>
                                <th class="text-nowrap">{{ "GENERAL.status" | translate }}</th>
                                <th>Card Code</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let multicompany of listMultiCompany">
                                <td>{{multicompany.Name}}</td>
                                <td class="text-nowrap">
                                    <div class="col-">
                                        <label class="switch">
                                            <input [checked]="multicompany.IsSelected" (change)="changeStatusMulticompany(multicompany.Id_Company,$event.target.checked)" name="status" type="checkbox" id="status"/>
                                            <span class="slider round-in"></span>
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <div class="row">
                                        <div class="col-auto">
                                            <a *ngIf="!multicompany.edit" (click)="editCC(multicompany)" href="javascript:void(0)" class="ml-4 btnEdit"><i class="fas fa-edit"></i></a>
                                            <a *ngIf="multicompany.edit" (click)="saveCC(multicompany)" href="javascript:void(0)" class="ml-4 btnE"><i class="far fa-save"></i></a>
                                        </div>
                                        <div *ngIf="!multicompany.edit" class="col-auto">
                                            {{multicompany.CardCode}}
                                        </div>
                                        <div *ngIf="multicompany.edit" class="col-auto">
                                            <input [(ngModel)]="multicompany.CardCode" name="cardCode" class="form-control" type="text" placeholder="Card Code..." id="cardCode" />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>