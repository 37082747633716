import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DragulaService } from 'ng2-dragula';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import Swal from 'sweetalert2';
import { CompanyEdit, PAC } from '../companies';

@Component({
  selector: 'app-pacsimple-company',
  templateUrl: './pacsimple-company.component.html',
  styleUrls: ['./pacsimple-company.component.css']
})

export class PacsimpleCompanyComponent implements OnInit {

  @Input() idCompany;

  public listPacAssigned: PAC[] = [];
  public companieSelected = new CompanyEdit();
  public listFreePacs: PAC[] = [];
  public Require_Password = false;
  public newPacFre = new PAC();

  listDetailPac = [];
  listCompanyPac = [];

  isAddPac = true;
  isAddPassPac = false;
  inPAC = false;
  showButton = false;
  isHide = 'OK';

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private services: ServicesService,
    private dragulaService: DragulaService
  ) {
    dragulaService.drop('VAMPIRES').subscribe(({ name, el, target, source, sibling }) => {
      this.onDrop(name, el, target, source, sibling);
    });
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }

  }

  private onDrop(name, el, target, source, sibling) {
    this.orderPAC();
    this.inPAC = true;
  }

  ngOnInit(): void {
    const myOffcanvas = document.querySelector('#offcanvasRightCPac') as any;
    if (myOffcanvas !== null) {
      myOffcanvas.addEventListener('hidden.bs.offcanvas', () => {
        this.isHide = '';
      });
    }
    this.companyById();
    this.Company_PAC_List();
  }

  selectPac(value: string) {
    let params = value.split(',');
    this.newPacFre.Id_PAC = +params[0];
    this.newPacFre.PAC_Name = params[1];
    if (params[2] == 'V') {
      this.Require_Password = true;
      this.newPacFre.Require_Password = 'V';
    }
    else {
      this.Require_Password = false;
      this.newPacFre.Require_Password = 'F';
    }
  }

  Company_PAC_List() {
    this.spinner.show();
    this.services.Company_PAC_List(this.idCompany).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.listPacAssigned = resp.data;
        this.orderPAC();
        this.PAC_Available_List();
      }
    });
  }

  PAC_Available_List() {
    this.spinner.show();
    this.services.PAC_Available_List(this.idCompany).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.listFreePacs = resp.data;
        if (resp.data.length <= 0) {
          this.showButton = false;
        } else {
          this.showButton = true;
        }
      }
    });
  }

  addPac() {
    this.newPacFre.Id_Company = this.idCompany;
    this.spinner.show();
    this.services.Company_NewPAC(this.newPacFre).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.toastr.success(this.translate.instant('CODEALERTS.coda15'));
        this.newPacFre = new PAC();
        this.Company_PAC_List();
      }
    });
    this.isHide = '';
  }

  changeStatusPac(checked: boolean, pac: PAC) {
    this.spinner.show();
    this.services.Company_PAC_ChangesStatus(pac.Id_Company, pac.Id_PAC, checked).subscribe(resp => {
      this.spinner.hide();
      if (resp.result){
        this.toastr.success(this.translate.instant('CODEALERTS.coda37'));
      }
    });
  }

  viewDetailPAC(pac: any) {
    this.services.Company_PAC_Microservices(pac.Id_PAC).subscribe(resp => {
      if (resp.result) {
        this.listDetailPac = resp.data;
        this.viewAlertPac(pac.Id_Pac, pac.PAC_Name);
      }
    });
  }

  viewAlertPac(Id_Pac, PAC_Name) {
    if (PAC_Name === '' || PAC_Name === undefined || PAC_Name === null) {
      PAC_Name = this.newPacFre.PAC_Name;
    }
    let list = '<h1>Servicios que ofrece el PAC: ' + PAC_Name + '</h1><hr><div style="max-height: 200px; overflow: auto; overflow-x:hidden;">';
    for (const detail in this.listDetailPac) {
      list += '<div class="row">';
      list += '<h4 style="text-align: left;" class="center-with-margin col-md-8">' + this.listDetailPac[detail].Description + '</h4>';
      if (this.listDetailPac[detail].Available != 0) {
        list += '<i class="fa fa-check-square text-success center-with-margin col-md-4" style="font-size: 23px !important;"></i>';
      }
      list += '</div>';
    }
    list += '</div>';
    Swal.fire({
      html: list,
      showCancelButton: true,
      confirmButtonText: 'Terminos',
      cancelButtonText: 'Cerrar'
    }).then((result) => {
      if (result.value) {
        const redirect = '/#/termspac?id=' + Id_Pac;
        window.open(redirect, '_blank');
      }
    });
  }

  deletePac(pac: PAC, title: String) {
    Swal.fire({
      title: `¿Estás seguro de querer eliminar el pac ${title}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#746AB0',
      cancelButtonColor: '#E83845',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.services.Company_DeletePAC(pac.Id_Company, pac.Id_PAC).subscribe(resp => {
          this.spinner.hide();
          if (resp.result) {
            this.toastr.success(this.translate.instant('CODEALERTS.coda16'));
            this.Company_PAC_List();
          }
        });
      }
    });
  }

  changePriority(automatic: boolean) {
    this.companieSelected.PriorityOrder = automatic ? 'A' : 'M';
    this.spinner.show();
    this.services.Company_PriorityOrder(this.idCompany, automatic).subscribe(resp => {
      this.spinner.hide();
      if (resp.result){}
        // tslint:disable-next-line: align
        this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));
    });
  }

  orderPAC() {
    const list = this.listPacAssigned;
    for (let i = 0, len = list.length; i < len; i++) {
      list[i].OrderNo = i + 1;
    }
    this.listPacAssigned = list;
  }

  saveOrderPAC() {
    this.inPAC = false;
    this.services
      .post({
        entidad: 'Company',
        funcion: 16,
        data: this.listPacAssigned
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.toastr.success(`Reordenamiento de Pac's correcto.`, 'Compañías');
            this.Company_PAC_List();
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

  cancelOrderPAC() {
    this.inPAC = false;
    this.Company_PAC_List();
  }

  companyById() {
    this.spinner.show();
    this.services.Company_ById(this.idCompany).subscribe(resp => {

      if (resp.result) {
        this.companieSelected = resp.data;
      }
      this.spinner.hide();
    });
  }

}
