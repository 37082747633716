<div class="card dataCompany ms-3 me-4 mt-2">
  <div class="card-header bg-transparent border-0 generalTab">
    <h4 class="card-title mt-2 mb-2"><b>{{ "GENERAL.group" | translate }}:</b> {{crew.Description}}</h4>
    <!-- <ng-container *ngIf="usersActive"> -->
      <div>
        <div class="row gx-0">
          <div class="col-auto">
            <h5 class="card-title pull-left mt-1">Filtrar por status</h5>
          </div>
          <div class="col-auto">
            <label class="switch">
              <input name="status" [(ngModel)]="isStatusUser" (change)="updateStatus($event)" type="checkbox"
                id="status" />
              <span class="slider round-in"></span>
            </label>
          </div>
          <ng-container *ngIf="statusUser !== ''">
            <div class="col-auto">
              <h5 class="card-title pull-left mt-1">Limpiar filtro por status</h5>
            </div>
            <div class="col-auto">
              <button class="noP ps-1" (click)="clearStatus()"><i class="fas fa-eraser"></i></button>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="row gx-0 mt-2">
        <div class="col-auto">
          <form class="app-search pull-right">
            <input [(ngModel)]="searchUser" name="searchUser" type="text" (input)="onSearchUser($event)"
              class="input-search form-control" placeholder="{{ 'GENERAL.searchby' | translate }}..." />
            <a class="ico-btn-search">
              <i class="ti-search"></i>
            </a>
          </form>
        </div>
      </div>
      <div class="row gx-0 mt-3">
        <div class="col-lg-12 col-md-8">
          <div class="table-responsive">
            <table sortable-table (sorted)="onSorted($event)" class="table table-striped">
              <thead>
                <tr>
                  <th sortable-column="User_Name" style="cursor: pointer;">Usuario</th>
                  <th sortable-column="Company_Name" style="cursor: pointer;">Compañía</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of UsersGroup">
                  <td>{{user.UserName}}</td>
                  <td>{{user.CompanyName}}</td>
                  <td class="text-nowrap">
                    <div class="col-">
                      <label class="switch">
                        <input (change)="activeUser($event.target.checked, user.Id_User, user.Id_Crew, user.Id_User_Crew)"
                          [checked]="user.Status == 'A'? true: false" name="statusMFA" type="checkbox" id="statusMFA">
                        <span class="slider round-in"></span>
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row gx-1  justify-content-between">
          <div class="col-auto ps-5 ">
            <select class="select-css" id="dropAmounts" aria-label="Default select example"
              (change)="changeAmountView($event)" [(ngModel)]="this.Pagination" name="dropAmounts">
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="col-auto pe-5 mt-2 mt-sm-0">
            <ngb-pagination [collectionSize]="collectionSize" [(page)]="this.Page" [maxSize]="4"
              (pageChange)="changePage($event)" [rotate]="true"></ngb-pagination>
          </div>
        </div>
      </div>
    <!-- </ng-container> -->
    <!-- <div *ngIf="!usersActive" class="alert alert-primary d-flex align-items-center mt-3" role="alert">
      <i class="fas fa-exclamation-triangle mr-2"></i>
      <div>
        La organización {{crew.Customer}} no cuenta con usuarios activos.
      </div>
    </div> -->
  </div>
</div>