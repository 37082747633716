<div class="row mt-0">
    <div class="col" style="min-width: 627px;">
        <div class="card mt-0">
            <div class="card-body">
                <div class="table-responsive tablePayments" style="min-height: 250px;">
                    <table sortable-table (sorted)="onSorted($event)" class="table table-striped">
                        <thead>
                            <tr class="purple ">
                                <th style="width: 50px;">
                                    <button *ngIf="isRest || chanelId != '' || customerId != '' || projectId != '' || yearId != '' || monthId != '' || annuityId != '' || moduleId != '' || Pagination != 10" (click)="resetTable()" class="tooltipp btnRes" placement="top" ngbTooltip="Restablecer Filtros"><i class="fas fa-trash-restore-alt p-1"></i></button>
                                </th>
                                <th *ngIf="isAccess" class="th-sort" sortable-table="padre" sortable-column="cha" scope="col">
                                    <div class="dropdown ps-1">
                                        <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                            <span *ngIf="AZcan" ><i class="fas fa-sort-alpha-up"></i></span>
                                            <span  *ngIf="ZAcan"><i class="fas fa-sort-alpha-down-alt"></i></span>
                                            <span *ngIf="chanelId != ''" class="icon"><i class="fas fa-filter"></i></span>
                                            Canal
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">De la A a la Z</a></li>
                                            <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">De la Z a la A</a></li>
                                            <li><a (click)="filterChannel('C')" class="dropdown-item" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Filtrar canales</a></li>
                                        </ul>
                                    </div>
                                </th>
                                <th sortable-table="padre" sortable-column="org" scope="col">
                                    <div class="dropdown">
                                        <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                           <span *ngIf="AZorg" ><i class="fas fa-sort-alpha-up"></i></span>
                                           <span *ngIf="ZAorg" ><i class="fas fa-sort-alpha-down-alt"></i></span>
                                           <span *ngIf="customerId != ''" class="icon"><i class="fas fa-filter"></i></span>
                                            Organización
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);" value="AZ">De la A a la Z</a></li>
                                            <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);" value="ZA">De la Z a la A</a></li>
                                            <li><a (click)="filterChannel('O')" class="dropdown-item" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" id="A" value="FO">Filtrar Organización </a></li>
                                        </ul>
                                    </div>
                                </th>
                                <th sortable-table="padre" sortable-column="pro" scope="col">
                                    <div class="dropdown">
                                        <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                           <span *ngIf="AZpro" ><i class="fas fa-sort-alpha-up"></i></span>
                                           <span *ngIf="ZApro" ><i class="fas fa-sort-alpha-down-alt"></i></span>
                                           <span *ngIf="projectId != ''" class="icon" ><i class="fas fa-filter"></i></span>
                                            Proyecto
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">De la A a la Z</a></li>
                                            <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">De la Z a la A</a></li>
                                            <li><a (click)="filterChannel('P')" class="dropdown-item" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" id="A">Filtrar Proyecto</a></li>
                                        </ul>
                                    </div>
                                </th>
                                <th sortable-table="padre" sortable-column="year" scope="col" style="width: 60px;">
                                    <div class="dropdown">
                                        <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span *ngIf="AZyear" ><i class="fas fa-sort-numeric-up-alt"></i></span>
                                           <span  *ngIf="ZAyear"><i class="fas fa-sort-numeric-down-alt"></i></span>
                                           <span *ngIf="yearId != ''" class="icon"><i class="fas fa-filter"></i></span>
                                            Año
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">Ascendente</a></li>
                                            <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">Descendente</a></li>
                                            <li><a (click)="filterChannel('A')" class="dropdown-item" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" id="A">Filtrar Año</a></li>
                                        </ul>
                                    </div>
                                </th>
                                <th sortable-table="padre" sortable-column="month" scope="col" style="width: 70px;">
                                    <div class="dropdown">
                                        <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span *ngIf="AZmonth" ><i class="fas fa-sort-alpha-up"></i></span>
                                           <span  *ngIf="ZAmonth"><i class="fas fa-sort-alpha-down-alt"></i></span>
                                           <span *ngIf="monthId != ''" class="icon" ><i class="fas fa-filter"></i></span>
                                            Mes
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">De la A a la Z</a></li>
                                            <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">De la Z a la A</a></li>
                                            <li><a (click)="filterChannel('M')" class="dropdown-item" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" id="A">Filtrar Mes</a></li>
                                        </ul>
                                    </div>
                                </th>
                                <th scope="col">
                                    <div class="dropdown">
                                        <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span *ngIf="annuityId != ''" class="icon" ><i class="fas fa-funnel-dollar"></i></span>
                                            Anualidad
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a (click)="filterChannel('AN')" class="dropdown-item" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" id="A">Filtra Anualidad</a></li>
                                        </ul>
                                    </div>
                                </th>
                                <th scope="col">
                                    <div class="dropdown">
                                        <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span *ngIf="moduleId != ''" class="icon"><i class="fas fa-funnel-dollar"></i></span>
                                            Módulos
                                        </button>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a (click)="filterChannel('MO')" class="dropdown-item" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" id="A">Filtrar Módulos</a></li>
                                        </ul>
                                    </div>
                                </th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let s of Services; let i = index">
                                <td style="width: 50px;">
                                    <ng-container *ngIf="i != idx">
                                        <button (click)="editService(s, i)" class="btnEdit ps-3"><i
                                                class="fas fa-edit"></i></button>
                                    </ng-container>

                                    <ng-container *ngIf="i == idx">
                                        <button (click)="cancel()" type="button" class="btnClo ps-3">
                                            <i class="far fa-times-circle"></i></button>
                                    </ng-container>
                                </td>
                                <th *ngIf="isAccess" scope="row">&#160;{{s.Name_Channel}}</th>
                                <td style="width:310px;">{{s.Organization}}</td>
                                <td style="width:120px;">{{s.Proyecto}}</td>
                                <td class="center" style="width: 60px;">{{s.Year}}</td>
                                <td class="center" style="width: 70px;">{{s.Month_Name}}</td>
                                <td class="center">
                                    <ng-container *ngIf="i != idx">
                                        <span *ngIf="s.Payment_Status === 'V'" class="badge badge-pill badge-success mb-1">Pagado</span>
                                        <span *ngIf="s.Payment_Status === 'F'" class="badge badge-pill badge-secondary mb-1">No Pagado</span>
                                    </ng-container>
                                    <ng-container *ngIf="i == idx">
                                        <label class="switch hideShow">
                                        <input (change)="updateAnnuity($event, s.Id_Customer_Project, s.Year, s.Month, s.Id_Payments_Customer_Project)" [checked]="s.Payment_Status === 'V'" name="status" type="checkbox" id="status">
                                        <span class="slider round-in"></span>
                                    </label>
                                    </ng-container>
                                </td>
                                <td class="center">
                                    <div class="justify-content-between row pe-3" *ngFor="let ms of s.Modules">
                                        <ng-container>
                                            <div class="col-auto ms-2 me-auto">{{ms.Description}}</div>
                                            <div class="col-auto" *ngIf="i == idx">
                                                <ng-container>
                                                    <label class="switch hideShow">
                                                        <input (change)="updateModule($event, ms.Id_Customer_Module, s.Year, s.Month, ms.Id_Payments_Customer_Module)" [checked]="ms.Payment_Status === 'V'" name="status" type="checkbox" id="status">
                                                        <span class="slider round-in"></span>
                                                    </label>
                                                </ng-container>
                                            </div>
                                            <div class="col-auto">
                                                <ng-container *ngIf="i != idx">
                                                    <span class="badge badge-pill badge-success mb-1" *ngIf="ms.Payment_Status === 'V'">Pagado</span>
                                                    <span class="badge badge-pill badge-secondary mb-1" *ngIf="ms.Payment_Status === 'F'">No Pagado</span>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- <div class="row gx-1  justify-content-between">
                <div class="col-auto ps-5 ">
                    <select class="select-css" id="dropAmounts" aria-label="Default select example" (change)="changeAmountView($event)" [(ngModel)]="this.Pagination" name="dropAmounts">
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div class="col-auto pe-5 mt-2 mt-sm-0">
                    <ngb-pagination [collectionSize]="collectionSize" [(page)]="this.Page" [maxSize]="4" (pageChange)="changePage($event)" [rotate]="true"></ngb-pagination>
                </div>
            </div> -->
            <app-pagination-global [callbackFunction]="typePayment" [actualPagination]="Pagination" [collectionDinamic]="collectionSize" (changePagination)="changeAmountView($event)" (changePageEmi)="changePage($event)"></app-pagination-global>
        </div>
    </div>
</div>
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <button type="button" class="btnArrow" data-bs-dismiss="offcanvas">
        <span><i class="fa fa-arrow-left "></i></span></button>

        <div class="col-auto">
            <div>
                <app-filters (filterSel)="filterSel($event)"></app-filters>
            </div>
        </div>
    </div>

    <div class="offcanvas-body">
        <ng-container *ngIf="filterType === 'C'">
            <app-channel-payment *ngIf="isAccess" (channelSel)="filterByChannel($event)"></app-channel-payment>
        </ng-container>

        <ng-container *ngIf="filterType === 'O'">
            <app-organization (customerSel)="filterByCustomer($event)"></app-organization>
        </ng-container>

        <ng-container *ngIf="filterType === 'P'">
            <app-project (projectSel)="filterByProject($event)"></app-project>
        </ng-container>

        <ng-container *ngIf="filterType === 'A'">
            <app-year (yearSel)="filterByYear($event)"></app-year>
        </ng-container>

        <ng-container *ngIf="filterType === 'M'">
            <app-month (monthSel)="filterByMonth($event)"></app-month>
        </ng-container>

        <ng-container *ngIf="filterType === 'AN'">
            <app-annuity (annSel)="filterByAnnuity($event)"></app-annuity>
        </ng-container>

        <ng-container *ngIf="filterType === 'MO'">
            <app-module (moduleSel)="filterByModule($event)"></app-module>
        </ng-container>

        <div class="d-grid gap-2 mt-3">
            <button (click)="filterData()" type="button" data-bs-dismiss="offcanvas" class="btn btn-success btnFilter">Filtrar</button>
        </div>

    </div>
</div>

<app-back-to-top></app-back-to-top>

<ngx-spinner bdColor="rgba(255, 160, 122,0.8)" size="medium" color="#5b2e7e" type="ball-clip-rotate-multiple" [fullScreen]="true">
    <p style="color: white"> Cargando... </p>
</ngx-spinner>