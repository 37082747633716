<select [(ngModel)]="typeSta" (change)="selectSta($event)" class="form-select form-select-sm" aria-label=".form-select-sm example">
    <option value="">Selecciona estatus de usuario:</option>
    <option value="A">
        Activo
    </option>
    <option value="I">
        Inactivo
    </option>
    <option value="R">
        Reinicio de contraseña
    </option>
</select>