import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-menu-multiselect',
  templateUrl: './menu-multiselect.component.html',
  styleUrls: ['./menu-multiselect.component.css']
})
export class MenuMultiselectComponent implements OnInit {

  @Output() idMenuC = new EventEmitter<Number>();
  @Input() idAppChildren: number;

  dropdownSettings: IDropdownSettings = {};
  listMenusFather = [];
  selectedItems: any = [];
  idMenu = 0;

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'Id_Menu',
      textField: 'Menu',
      searchPlaceholderText: 'Buscar:',
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
      enableCheckAll: true,
      defaultOpen: true
    };
   }

  ngOnInit(): void {
    if (localStorage.getItem('menuStoId') == null || localStorage.getItem('menuStoId') == undefined) {
      this.selectedItems = [];
    }else{
      this.selectedItems = [
        { Id_Menu: localStorage.getItem('menuStoId'), Menu: localStorage.getItem('menuStoNa') },
      ];
    }
    this.getFathers(this.idAppChildren);
  }

  onItemSelect(e) {
    this.idMenu = e.Id_Menu;
    localStorage.setItem('menuStoId', String(this.idMenu));
    localStorage.setItem('menuStoNa', String(e.Menu));
    this.idMenuC.emit(this.idMenu);
  }

  onItemDeselect() {
    this.selectedItems = [];
    this.idMenu = 0;
    localStorage.removeItem('menuStoId');
    localStorage.removeItem('menuStoNa');
    this.idMenuC.emit(this.idMenu);
  }

  getFathers(idApplication: number) {
    this.services.post({
      entidad: 'Menu',
      funcion: 9,
      data: {
        Id_Application: idApplication
      }
    }).subscribe(result => {
      if (result.data) {
        this.listMenusFather = result.data;
      } 
    }, error => {
      console.log('Error', error);
    });
  }

}
