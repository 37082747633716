<div class="row animate__animated animate__flip">
  <div class="col">
    <div class="row mt-1 justify-content-between">
      <div class="col-auto">
        <div class="row">
          <div class="col-auto tittleKPICard">
            <h2 class="mb-0">{{dtlofKPI.Titulo}}</h2>
            <small>{{dtlofKPI.Proyecto}}</small>
          </div>

          <div class="col-auto">
            <h5>
              <span *ngIf="dtlofKPI.Status === 'A'" class="badge badge-success">Status: Activo</span>
              <span *ngIf="dtlofKPI.Status === 'I'" class="badge badge-warning">Status: Inactivo</span>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-auto mt-4 menuOPC">
        <div class="dropdown">
          <button class="btn btn-secondary dropdown-toggle btnDrop" type="button" id="dropdownMenuButton"
            data-toggle="dropdown" aria-expanded="false">
            <i class="fas fa-ellipsis-v"></i>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item" href="javascript:void(0)" (click)="addNewConfig('C')">Configuración</a>
            <a class="dropdown-item" href="javascript:void(0)"(click)="addNewCf('C')">Filtros</a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="viewLogH(dtlofKPI.Id_KPI)">Log de Actualización</a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="dupItem(dtlofKPI.Id_KPI)">Duplicar</a>
            <a class="dropdown-item" href="javascript:void(0)" (click)="delItem(dtlofKPI.Id_KPI)">Eliminar</a>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-2 mb-1" />
    <div class="row mt-0">
      <div class="col-auto">
        <div class="form-group">
          <p for="typeFilters" class="subTittleCard mb-0">{{dtlofKPI.TipoKPI}}</p>
          <p class="descriptionText">{{dtlofKPI.Descripcion}}.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="chartjs-container">
          <canvas baseChart [datasets]="chartData" [labels]="barChartLabels" [options]="barChartOptions"
            [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType" [colors]="ChartColors">
          </canvas>
        </div>
      </div>
    </div>
    <hr class="mt-2 mb-1" />
    <div class="row justify-content-around filtersBottom">
      <ng-container *ngFor="let f of dtlofKPI.Filters">
        <div class="col-auto">
          <small>
            {{ f.Value }}
          </small>
        </div>
      </ng-container>
    </div>
  </div>
</div>