import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Access } from 'src/app/data/models/access.model';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-detail-access',
  templateUrl: './detail-access.component.html',
  styleUrls: ['./detail-access.component.css']
})
export class DetailAccessComponent implements OnInit {
  
  @Output() goBack = new EventEmitter();
  @Input() idAccessDetail: number;

  viewAccess = true;
  newAccess = false;
  isNewEdit = false;
  accessSelected = new Access();
  notStorage = true;

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
   }

  ngOnInit(): void {
    if (this.idAccessDetail === 0) {
      this.newAccess =  true;
    } else {
      this.accessById();
    }
  }

  editAccess() {
    this.isNewEdit = true;
    this.viewAccess = false;
  }

  onSelectApp(e) {
    this.accessSelected.Id_Application = e;
  }
  
  onDeselectApp() {
    this.accessSelected.Id_Application = 0;
  }

  onSelectTag(e) {
    this.accessSelected.Id_Tag = e;
  }

  onDeselectTag() {
    this.accessSelected.Id_Tag = 0;
  }

  cancelEdit() {
    // this.newAccess = false;
    this.isNewEdit = false;
    this.viewAccess = true;
    this.accessById();
  }

  cancelNewAccess() {
    this.viewAccess = false;
    this.isNewEdit = false;
    if (this.idAccessDetail === 0) {
      this.goBack.emit();
    }
  }

  onSubmitEdit() {
    if (this.idAccessDetail === 0) {
      this.createAccess(this.accessSelected);
      this.goBack.emit();
    } else {
      this.accessNewEdit(this.accessSelected);
      this.viewAccess = true;
      this.isNewEdit = false;
      this.newAccess = false;
    }
  }

  accessById() {
    this.services.post({
      entidad: 'Access',
      funcion: 2,
      data: {
        Id_Accesss: this.idAccessDetail
      }
    }).subscribe(result => {
      if (result.data) {
        this.accessSelected = result.data;
      }
    }, error => {
      console.log('Error', error);
    });
  }

  accessNewEdit(access: Access) {
    this.services.post({
      entidad: 'Access',
      funcion: 4,
      data: access
    }).subscribe(result => {
      if (result.result === true) {
        this.accessById();
        this.toastr.success('Acceso modificado correctamente', 'Accesos');
      }
    }, error => {
      console.log('Error', error);
    });
  }

  createAccess(access: Access) {
    this.services.post({
      entidad: 'Access',
      funcion: 3,
      data: access
    }).subscribe(result => {
      if (result.result === true) {
        this.toastr.success('Acceso creado correctamente', 'Accesos');
      }
    }, error => {
      console.log('Error', error);
    });
  }

}
