<!-- Comienza encabezado -->
<div class="row page-titles mt-5">
  <div class="col-md-3 col-sm-12 col-lg-3 align-self-center">
    <h3 class="text-primary m-b-0 m-t-0">{{ "GENERAL.myAccount" | translate }}</h3>
  </div>
</div>

<div class="row mt-3">
  <ng-container>
    <div class="col-md-12 col-lg-12 col-sm-12" style="margin-left: auto; margin-right: auto;">
      <div class="card">
        <div class="card-body generalTab ">

          <div class="row">

            <div class="col">
              <ng-container *ngIf="isIfo">
                <h4 class="card-title" style="margin-top: 20px;"><b>{{ "GENERAL.name" | translate
                    }}:</b>
                  {{UserInfo.Name}}</h4>

                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-6 m-t-25 row">
                    <div class="u-img image-upload">
                      <label for="file-input">
                        <img [src]="UserInfo.Img_Path" style="max-width: 200px;" *ngIf="UserInfo.Img_Path">
                        <img style="max-width: 200px;" *ngIf="!UserInfo.Img_Path"
                        src="../../../assets/images/avatar.png" alt="user">

                      </label>
                      <input #fileInput (change)="uploaded($event)" id="file-input" type="file" accept="image/*" style="display: none;">
                    </div>                    
                  </div>

                </div>

                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-6 m-t-25 row">
                    <label for="example-text-input" class="col-md-6 col-form-label">{{
                      "GENERAL.company" | translate }}</label>
                    <div class="col-md-6">
                      <label for="example-text-input" class="col-form-label">{{UserInfo.CompanyName}}</label>
                    </div>
                  </div>

                </div>

                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-6 m-t-25 row">
                    <label for="example-text-input" class="col-md-6 col-form-label">{{
                      "GENERAL.email2" | translate }}</label>
                    <div class="col-md-6">
                      <label for="example-text-input" class="col-form-label">{{UserInfo.EMail}}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-6 m-t-25 row">
                    <label for="example-text-input" class="col-md-6 col-form-label">{{
                      "GENERAL.organization" | translate }}</label>
                    <div class="col-md-6">
                      <label for="example-text-input" class="col-form-label">{{UserInfo.CustomerName}}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-6 m-t-25 row">
                    <label for="example-text-input" class="col-md-6 col-form-label">{{
                      "GENERAL.employee" | translate }}</label>
                    <div class="col-md-6">
                      <label for="example-text-input" class="col-form-label">{{UserInfo.Hierarchy}}</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-6 m-t-25 row">
                    <label for="example-text-input" class="col-md-6 col-form-label">{{
                      "GENERAL.collaborator" | translate }}</label>
                    <div class="col-md-6">
                      <label for="example-text-input" class="col-form-label">{{UserInfo.CreditorType}}</label>
                    </div>
                  </div>
                </div>
                

              </ng-container>
              
            </div>

          </div>


        </div>
      </div>
    </div>
  </ng-container>



</div>