import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityService } from '../data/services/security.service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  urlLogin: string = "";
  baseUrl: string = document.getElementsByTagName('base')[0].href;

  constructor(
    private securityService: SecurityService,
    private router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.securityService.isLogged())
      return true;
    
    this.urlLogin = this.baseUrl + "login/" + environment.AppToken;
    window.location.href = this.urlLogin;
    //this.router.navigate(['/'])
    return false;
  }

}
