<div class="row me-2">
    <div class="text-end mt-1 mb-1 ">
        <button *ngIf="viewCustomer" (click)="newEditCustomer(true)" class="btnEd">
            <i class="fas fa-edit"></i>
            {{ "GENERAL.edit" | translate }}
        </button>
    </div>
</div>

<form [formGroup]="form" ngNativeValidate (submit)="onSubmit()">
    <ng-container>
        <div class="col-md-12 col-lg-12 col-sm-12" style="margin-left: auto; margin-right: auto;">
            <div class="card mt-1">
                <div class="card-body ">
                    <ng-container>
                        <div class="row justify-content-left ps-3 mt-5 dataChannel">
                            <div class="col-auto">
                                <span class=" pl-3  textP">{{ "GENERAL.organization" | translate }}</span>
                                <p *ngIf="viewCustomer" class="pl-4 ml-3">{{customerSelected.Name}}</p>
                                <p *ngIf="isNewEdit">
                                    <input formControlName="Name" (change)="setName($event.target.value)" name="name"
                                        class="form-control" required type="text"
                                        placeholder="{{ 'GENERAL.name' | translate }}..." id="name"
                                        pattern="[A-Za-z0-9áéíóúüñÑ_., -]+" title="{{ 'PATTERNS.name' | translate}}">
                                </p>
                            </div>
                            <div class="col-auto">
                                <span class=" pl-3  textP">{{ "GENERAL.email2" | translate }}</span>
                                <p *ngIf="viewCustomer" class="pl-4 ml-3">{{customerSelected.Email}}</p>
                                <p *ngIf="isNewEdit">
                                    <input formControlName="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}$"
                                        (change)="selectEmail($event.target.value)"
                                        title="{{ 'PATTERNS.email' | translate}}" required name="email"
                                        class="form-control" type="email"
                                        placeholder="{{ 'GENERAL.email' | translate }}..." id="email">
                                    <!-- <b style="font-weight: 300; margin-top: 4px; margin-bottom: -8px;"> {{ "GENERAL.iusedmail" | translate }} </b> -->
                                </p>
                            </div>
                            <div class="col-auto">
                                <span class=" pl-3  textP">{{ "GENERAL.channel" | translate }}</span>
                                <p *ngIf="viewCustomer" class="pl-4 ml-3">{{customerSelected.ChannelName}}</p>
                                <p *ngIf="isNewEdit">
                                    <select class="custom-select" id="inlineFormCustomSelect2"
                                        name="inlineFormCustomSelect2" (change)="selectChannel($event.target.value)"
                                        formControlName="Id_Channel">
                                        <option *ngIf="!customerSelected.ChannelName" value="0" selected="">{{
                                            "GENERAL.selectorganization" | translate }}...</option>
                                        <option *ngIf="customerSelected.ChannelName != ''" value="0" selected="">
                                            {{customerSelected.ChannelName}}</option>
                                        <option *ngFor="let channel of listAllChannel" value="{{channel.Id_Channel}}">
                                            {{channel.Name}}</option>
                                    </select>
                                </p>
                            </div>
                        </div>
                        <div *ngIf="isEdit || viewCustomer"
                            class="col-sm-auto px-0 text-center position-absolute contDate">
                            <div class="row margFech w-100">
                                <div class="col- text-center px-0">
                                    <p class="mb-0 textP">{{ "GENERAL.dischargeDate" | translate }}</p>
                                    <p class="mb-0">{{customerSelected.Date_Add | date:'dd/MM/yyyy'}}</p>
                                </div>
                            </div>
                            <div class="row margFech w-100 text-center">
                                <div class="col- text-center">
                                    <p class="mb-0 textP">{{ "GENERAL.status" | translate }}</p>
                                    <label class="switch hideShow" [ngClass]="{'fade': !isNewEdit}">
                                        <input #status [checked]="(customerSelected.Status == 'A')?true:false"
                                            (change)="changeStatus(status.checked)" name="status" type="checkbox"
                                            id="status" [disabled]="!isNewEdit" />
                                        <span class="slider round-in"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="row margFech w-100">
                                <div class="col- text-center">
                                    <p class="mb-0 textP">{{ "GENERAL.status" | translate }} MFA</p>
                                    <label class="switch hideShow" [ngClass]="{'fade': !isNewEdit}">
                                        <input #statusMFA [checked]="(customerSelected.MFA == 'A'?true:false)"
                                            (change)="changeStatusMFA(statusMFA.checked)" name="statusMFA"
                                            type="checkbox" id="statusMFA" [disabled]="!isNewEdit" />
                                        <span class="slider round-in"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="row justify-content-between ps-1 mt-2">
                <div class="col-auto">
                    <div *ngIf="form.invalid && isNewEdit" class="alert alert-warning" role="alert">
                        <label> *{{GetErrorMessage(1)}}</label>
                    </div>
                </div>
                <div class="col-auto ml-3 ml-ms-3 pl-3 noPad">
                    <button *ngIf="isNewEdit" [disabled]="form.invalid" type="submit"
                        class="btn btnSave waves-effect waves-light"
                        style="float: right; width: 88px; margin-right: 15px;">{{ "GENERAL.save" | translate }}</button>
                    <button *ngIf="isEdit" (click)="cancelEdit()" class="btn btnCancel waves-effect waves-light"
                        style="float: right; width: 88px; margin-right: 15px;">{{ "GENERAL.cancel" | translate
                        }}</button>
                    <button *ngIf="!isEdit && isNewEdit" (click)="cancelCustomer()"
                        class="btn btnCancel waves-effect waves-light"
                        style="float: right; width: 88px; margin-right: 15px;">{{ "GENERAL.cancel" | translate
                        }}</button>
                </div>
            </div>
        </div>
    </ng-container>
</form>