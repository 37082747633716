import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomerList } from 'src/app/data/models/customer-list.model';
import { ServicesService } from 'src/app/data/services/services.service';
import { Customer, CustomerApp } from '../customer';

@Component({
  selector: 'app-aplication',
  templateUrl: './aplication.component.html',
  styleUrls: ['./aplication.component.css']
})
export class AplicationComponent implements OnInit {

  @Input() idCustomer: number;
  @Input() newEdit: boolean;

  customerLst = new CustomerList();
  public listCustomersApp: CustomerApp[];
  public customerSelected = new Customer();
  public listAppBack = [];
  public listApp = [];
  public listAllChannel = [];
  public CustomerModuleList: any;
  idCust = 0;

  constructor(
    private services: ServicesService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private formBuilder2: UntypedFormBuilder,

    private translate: TranslateService) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }


  }

  ngOnInit(): void {
    this.Customer_Application_List();
    this.Customer_Detail();
  }

  changeStatusModule(Status: boolean, Id_Module: Number, Id_Customer: Number) {
    this.Customer_Module_Add_Edit(Id_Customer, Id_Module, Status ? 'V' : 'F');
  }

  Customer_Module_Add_Edit(Id_Customer: Number, Id_Module: Number, Status: string) {
    this.spinner.show();
    this.services.Customer_Module_Add_Edit(Id_Customer, Id_Module, Status).subscribe(resp => {
      this.spinner.hide();

      if (resp.result) {
        this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));
      }

    });
    this.Customer_Application_List();
   
    // this.LoadAllChannel();
    // this.Customer_Detail();
  }

  changeDate(e, key, a, b, obj, pos, type) {

    let month = '';

    if (key.month > 10) {
      month = '0' + key.month;
    } else {
      month = key.month;
    }

    if (type === 1) {

      if (this.listCustomersApp[pos].Date_Init < this.listCustomersApp[pos].Date_Expiration) {
        this.listCustomersApp[pos].Date_Init = key;
      } else {
        this.toastr.warning(this.translate.instant('FORM.VALIDATORS.InitalDate'));
        return;
      }

    } else {

      if (this.listCustomersApp[pos].Date_Expiration > this.listCustomersApp[pos].Date_Init) {
        this.listCustomersApp[pos].Date_Expiration = key;
      } else {
        this.toastr.warning(this.translate.instant('FORM.VALIDATORS.EndDate'));
        return;
      }

    }

    const elementoA = document.querySelector(a);
    const elementoB = document.querySelector(b);

    if (elementoB != null) {
      elementoA.style.display = 'block';
      elementoB.style.display = 'none';
    }
    this.Customer_Application_NewEdit(this.listCustomersApp[pos]);
  }

  Customer_Application_NewEdit(customerApp: CustomerApp) {

    this.spinner.show();
    this.services.Customer_Application_NewEdit(customerApp).subscribe(resp => {
      this.spinner.hide();

      if (resp.result) {
        // tslint:disable-next-line: max-line-length
        this.toastr.success(this.translate.instant('GENERAL.Organization') + ' ' + (customerApp.Id_Customer_Application === 0 ? this.translate.instant('GENERAL.saved') : this.translate.instant('GENERAL.modified')) + ' ' + this.translate.instant('GENERAL.correctly'));
        this.Customer_Application_List();
      }

    });
  }

  Customer_Detail() {
    this.spinner.show();
    this.services.Customer_By_Id(this.idCustomer).subscribe(resp => {
      this.spinner.hide();

      if (resp.result)
        this.customerSelected = resp.data;

    });
  }

  Customer_Application_List() {
    this.spinner.show();
    this.services.Customer_Application_List(this.idCustomer).subscribe(resp => {
      this.spinner.hide();

      if (resp.result) {

        this.listCustomersApp = resp.data;

        this.listCustomersApp.forEach(element => {
          element.isActive = element.Validity == 'A' ? true : false;
          this.services.Customer_Type_Pay_List(element.Id_Application).subscribe(res => {

            if (res.result) {

              this.listCustomersApp.filter(x => x.Id_Application === element.Id_Application)[0].typePayList = res.data;

            }
          });

        });
      }
    });
  }

  LoadAllChannel() {
    this.spinner.show();
    this.services.Channel_All().subscribe(resp => {
      this.spinner.hide();

      if (resp.result) {
        this.listAllChannel = resp.data;
      }

    });
  }

  keyFunc(e, a, b) {
    if (e.key === 'Escape') {
      const elementoA = document.querySelector(a);
      const elementoB = document.querySelector(b);
      if (elementoB != null) {
        elementoA.style.display = 'block';
        elementoB.style.display = 'none';
      }
    }
  }

  compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onSorted(sort) {

    let data = this.listCustomersApp.slice();

    let sortedData = data.sort((a, b) => {
      const isAsc = sort.sortDirection === 'asc';
      switch (sort.sortColumn) {
        case 'Application_Name': return this.compare(a.Application_Name, b.Application_Name, isAsc);
        case 'Date_Init': return this.compare(a.Date_Init, b.Date_Init, isAsc);
        case 'Date_Expiration': return this.compare(a.Date_Expiration, b.Date_Expiration, isAsc);
        default: return 0;
      }
    });

    this.listCustomersApp = sortedData;
  }

  updateCustomerApp(a, b) {
    const elementoA = document.querySelector(a);
    const elementoB = document.querySelector(b);
    if (elementoA != null) {
      elementoA.style.display = 'none';
      elementoB.style.display = 'block';
      elementoB.focus();
    }
  }

  Customer_Module_List_By_CustomerId(Id_Customer: Number, Id_Project: Number) {
    this.spinner.show();

    this.services.Customer_Module_List_By_CustomerId(Id_Customer, Id_Project).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.CustomerModuleList = resp.data;
      }

    });
  }

  viewModule(app: CustomerApp) {

    if (app.Validity != 'A') {
      this.toastr.warning(this.translate.instant('FORM.VALIDATORS.ActivateApp'));
      return;
    }

    app.Hide = !app.Hide;
    this.listCustomersApp.forEach(element => {
      if (element.Id_Application !== app.Id_Application) {
        element.Hide = false;
      }
    });

    this.CustomerModuleList = [];

    this.Customer_Module_List_By_CustomerId(app.Id_Customer, app.Id_Application);
  }

  changeTypePay(typePay: string, app: CustomerApp) {

    app.Folio_Descount_Type = typePay;

    if (typePay.trim() != '') {
      if (app.Validity == 'A') {
        this.Customer_Application_NewEdit(app);
      }
    }
  }

  changeStatusApp($event, app: CustomerApp) {
    if (app.Validity != 'A' && app.Folio_Descount_Type === " " || app.Folio_Descount_Type === "") {
      this.toastr.warning('Seleccione un tipo de pago, por favor.', 'Organizaciones');
      $event.target.checked = false;
      return;
    }
    if (app.Validity === 'A') {
      app.Folio_Descount_Type = "";
    } else {
      app.Folio_Descount_Type = app.Folio_Descount_Type
    }
    
    const idApplication = $event.target.value;
    const isChecked = $event.target.checked;

    if (isChecked && Array.isArray(app.typePayList) && app.typePayList.length > 1 && (app.Folio_Descount_Type.trim() == '' || app.Folio_Descount_Type.trim() == '0')) {
      this.toastr.warning(this.translate.instant('FORM.VALIDATORS.PayType'));
      $event.target.checked = false;
      return;
    }
    else {

      this.listCustomersApp = this.listCustomersApp.map((d) => {
        if (d.Id_Application == idApplication) {
          d.isActive = isChecked;
          d.Validity = isChecked ? 'A' : 'I';
          return d;
        }
        return d;
      });

      this.Customer_Application_NewEdit(app);
    }
  }

  FilterApps() {

    let appsActivates = this.listCustomersApp.filter(x => x.Validity === 'A').map(y => y.Id_Application);
    let apps = [];

    this.listAppBack.forEach(element => {
      if (appsActivates.includes(element.Id_Project))
        apps.push(element);
    });

    this.listApp = apps;
  }

}
