<div class="row me-2">
    <div class="text-end mt-1 mb-1 ">
        <button *ngIf="showButton" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightFolio" aria-controls="offcanvasRight"
            class="btnEd"><i class="fas fa-plus"></i>
            Agregar Idioma</button>
    </div>
</div>

<div class="card dataCompany ms-3 me-4">
    <div class="card-header bg-transparent border-0 generalTab">
        <div>
            <h4 class="card-title"><b>Menú:</b> {{title}}</h4>
        </div>
        <div class="row gx-0 mt-3">
            <div class="col-lg-12 col-md-9">
                <div class="table-responsive">
                    <table sortable-table class="table table-striped smallTable">
                        <thead>
                            <tr>
                                <th sortable-column="language" style="cursor: pointer;">Idioma</th>
                                <th sortable-column="title" style="cursor: pointer;">Título</th>
                                <th class="text-nowrap">Eliminar etiqueta</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let label of lstLabels; let i = index">
                                <td>{{label.Language}}</td>
                                <td>{{label.Text}}</td>
                                <td>
                                    <button *ngIf="i != 0" class="btnEdit ps-5" (click)="deleteLabel(label.Id_Language, label.Language, title)">
                                        <i class="fas fa-trash-alt mr-1"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRightFolio" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <button type="button" class="btnArrow" data-bs-dismiss="offcanvas">
            <span><i class="fa fa-arrow-left"></i></span>
        </button>
        <h5 id="offcanvasRightLabel" class="ml-3"> Agregar Idioma</h5>
    </div>
    <div class="offcanvas-body">
        <div class="row">
            <form ngNativeValidate (submit)="createLabel(newLabel)">
                <div class="col-auto mt-2">
                    <span for="language">Selecciona el idioma:</span><br>
                    <select (change)="changeLanguage($event.target.value)"
                        class="custom-select pull-right"
                        id="language" name="language"
                        style="width: 345px; margin-right: 10px;" required>
                        <option value="">Selecciona idioma:</option>
                        <option *ngFor="let language of languagesLst" value="{{language.Id}}"
                            [selected]="0">
                            {{language.Value}}
                        </option>
                    </select>
                </div>
                <div class="col-auto mt-2">
                    <span>Ingresa el nombre del menú:</span>
                    <input [(ngModel)]="newLabel.MenuText" name="code" class="form-control" type="text"
                        placeholder="Nombre del menú..." id="code" style="width: 345px;" required>  
                </div>
                <button *ngIf="this.newLabel.Id_Language != 0 && newLabel.MenuText != ''" class="btn btnSave ms-3 mt-2" style="width: 345px;" type="submit"
                    data-bs-dismiss="offcanvas"><i class="fas fa-plus"></i> 
                    {{ "GENERAL.add" | translate }}
                </button>
                <button *ngIf="this.newLabel.Id_Language == 0 || newLabel.MenuText == ''" class="btn btnSave ms-3 mt-2" style="width: 345px;" type="submit">
                    <i class="fas fa-plus"></i> 
                    {{ "GENERAL.add" | translate }}
                </button>
            </form>
        </div>
    </div>
</div>