import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styles: [
  ]
})
export class ProjectComponent implements OnInit {

  @Output() projectSel = new EventEmitter<string>();

  ltsProjectGet: any [];
  typeProje = '';


  constructor(
    private services: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    if(localStorage.getItem('projectSto') !== null) {
      this.typeProje = localStorage.getItem('projectSto');
    }

    this.typeProject();
  }

  selectProject(e) {
    this.typeProje = e.target.value;
    localStorage.setItem('projectSto', String(this.typeProje));
    this.projectSel.emit(this.typeProje);
  }

  //Services
  typeProject() {
    this.services
      .post({
        entidad: 'Payments',
        funcion: 6,
        data: {
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.ltsProjectGet = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

}
