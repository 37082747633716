import { Label } from "./label.model";

export class Menu {
  MenuText = ''; //Solo para nuevos menús
  Id_Menu = 0;
  Id_Application = 0;
  ApplicationName = '';
  Id_Tag = 0;
  TagDescription = '';
  Id_Label = 0;
  Id_MenuFather = 0;
  Id_Module = 0;
  ModuleDescription = '';
  Idx = 0;
  Active = false;
  ActionType = '';
  ActionTypeDescription = '';
  Action = '';
  Image = '';
  Settings = {};
  Labels: Label[];
}
