import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-customer-multiselect',
  templateUrl: './customer-multiselect.component.html',
  styleUrls: ['./customer-multiselect.component.css']
})
export class CustomerMultiselectComponent implements OnInit {

  @Output() custSelect = new EventEmitter<Number>();

  dropdownSettings: IDropdownSettings = {};
  lstCustomerGet: any[] = [];
  selectedItems: any = [];
  Id_Custo = 0;

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'Id_Customer',
      textField: 'Name',
      searchPlaceholderText: 'Buscar:',
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
      enableCheckAll: true,
      defaultOpen: true
    };
   }

  ngOnInit(): void {
    this.loadCustomers();
  }

  onItemSelect(e) {
    this.Id_Custo = e.Id_Customer;
    this.custSelect.emit(this.Id_Custo);
  }

  onItemDeselect(e) {
    this.selectedItems = [];
    this.Id_Custo = 0;
    this.custSelect.emit(this.Id_Custo);
  }

  loadCustomers() {
    this.services
      .post({
        entidad: 'Customer',
        funcion: 3,
        data: {
          Page: -1
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.lstCustomerGet = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

}
