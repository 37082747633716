import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import Swal from 'sweetalert2';
import { User } from '../users';

@Component({
  selector: 'app-groups-user',
  templateUrl: './groups-user.component.html',
  styleUrls: ['./groups-user.component.css']
})
export class GroupsUserComponent implements OnInit {
  
  @Input() id_User: Number;

  public UserSelected: User = new User();
  groupsLst = [];

  constructor(
    private services: ServicesService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.User_By_Id();
    this.UserGroups_List();
  }

  User_By_Id() {
    this.spinner.show();
    this.services.User_By_Id(this.id_User).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.UserSelected = resp.data[0];
        // this.SelectUser(this.UserSelected);
      }
    });
  }

  UserGroups_List() {
    this.services
    .post({
      entidad: 'Crew',
      funcion: 5,
      data: {
        Id_User: this.id_User
      },
    })
    .subscribe(
      (result) => {
        if (result.code === 1) {
          this.groupsLst = result.data;
        }
      },
      (error) => {
        console.log('Error', error);
      }
    );
  }

  deleteUser(Id_User_Crew: Number, Id_User: Number, Id_Crew: Number, User: String, Crew: String) {
    Swal.fire({
      title: `¿Estás seguro de querer eliminar al usuario ${User} del grupo ${Crew}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#746AB0',
      cancelButtonColor: '#E83845',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.services.post({
          entidad: 'Crew',
          funcion: 21,
          data: {
            Id_User_Crew: Id_User_Crew,
            Id_User: Id_User,
            Id_Crew: Id_Crew,
            Status: 'A'
          }
        }).subscribe(result => {
          if (result.code === 1) {
            this.toastr.success(`Eliminado correctamente a ${User} del grupo ${Crew}.`, 'Usuarios');
            this.UserGroups_List();
          }
          else {
            this.toastr.error(result.message, 'Usuarios');
          }
        }, error => {
          console.log(error),
            this.toastr.error('Error al eliminar el usuario del grupo.', 'Usuarios');
        }, () => { });
      }
    });
  }

  deleteAllGroups(Id_UserP: Number, User: String) {
    Swal.fire({
      title: `¿Estás seguro de querer eliminar el usuario ${User} de todos los grupos en los que se encuentra?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#746AB0',
      cancelButtonColor: '#E83845',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.services.post({
          entidad: 'Crew',
          funcion: 6,
          data: {
            Id_User: Id_UserP
          }
        }).subscribe(result => {
          if (result.code === 1) {
            this.toastr.success('Usuario eliminado correctamente de todos los grupos a los que pertenecía.', 'Usuarios');
            this.UserGroups_List();
          }
          else {
            this.toastr.error(result.message, 'Usuarios');
          }
        }, error => {
          console.log(error),
            this.toastr.error('Error al eliminar el usuario de los grupos.', 'Usuarios');
        }, () => { });
      }
    });
  }

  compare(a: Number | String | Date, b: Number | String | Date, isAsc: Boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onSorted(sort) {

    let data = this.groupsLst.slice();

    let sortedData = data.sort((a, b) => {
      const isAsc = sort.sortDirection === 'asc';
      switch (sort.sortColumn) {
        case 'Crew_Name': return this.compare(a.CrewName, b.CrewName, isAsc);
        // case 'Company_Name': return this.compare(a.CompanyName, b.CompanyName, isAsc);
        default: return 0;
      }
    });

    this.groupsLst = sortedData;
  }

}
