<ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item text-center colorTab">
        <a (click)="viewDetail()" class="nav-link active" id="detailPro-tab" data-toggle="tab" href="#detailPro" role="tab" aria-controls="detailPro" aria-selected="true"><i class="fas fa-tasks fa-2x"></i><br>Detalle</a>
    </li>
    <li *ngIf="!isNew" class="nav-item text-center colorTab">
        <a (click)="viewMenu()" class="nav-link" id="menu-tab" data-toggle="tab" href="#menuPro" role="tab" aria-controls="menuPro" aria-selected="false"><i class="fas fa-bars fa-2x"></i> <br> Menús</a>
    </li>
    <li *ngIf="!isNew" class="nav-item text-center colorTab">
        <a (click)="viewAccess()" class="nav-link" id="access-tab" data-toggle="tab" href="#accessPro" role="tab" aria-controls="accessPro" aria-selected="false"><i class="fas fa-fingerprint fa-2x"></i> <br> Accesos</a>
    </li>
</ul>

<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="detailPro" role="tabpanel" aria-labelledby="detailPro-tab">
        <div *ngIf="detailView" class="card pb-3" style="background-color:#dcdcdc; ">
            <app-detail-profile [idProfileDetail]="idProfileTab" (goBack)="returnToList()"></app-detail-profile>
        </div>
    </div>

    <div class="tab-pane fade" id="menuPro" role="tabpanel" aria-labelledby="menu-tab">
        <div *ngIf="menuView" class="card pb-3" style="background-color:#dcdcdc; ">
            <app-menus-profiles [idProfileMenu]="idProfileTab"></app-menus-profiles>
        </div>
    </div>

    <div class="tab-pane fade" id="accessPro" role="tabpanel" aria-labelledby="access-tab">
        <div *ngIf="accessView" class="card pb-3" style="background-color:#dcdcdc; ">
            <app-access-profiles [idProfileAccess]="idProfileTab"></app-access-profiles>
        </div>
    </div>
</div>