import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-application-multiselect',
  templateUrl: './application-multiselect.component.html',
  styleUrls: ['./application-multiselect.component.css']
})
export class ApplicationMultiselectComponent implements OnInit {

  @Output() idAppC = new EventEmitter<Number>();
  @Output() noSave = new EventEmitter();
  @Input() storage: boolean;
  @Input() idApp: number;
  @Input() nameApp: string;
  

  dropdownSettings: IDropdownSettings = {};
  listApplication: any [];
  selectedItems: any = [];
  idApplication = 0;

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
  ) { 
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'Id_Application',
      textField: 'Application',
      searchPlaceholderText: 'Buscar:',
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
      enableCheckAll: true,
      defaultOpen: true
    };
  }
  
  ngOnInit(): void {
    if (localStorage.getItem('appStoId') == null || localStorage.getItem('appStoId') == undefined) {
      this.selectedItems = [];
    }else{
      this.selectedItems = [
        { Id_Application: +localStorage.getItem('appStoId'), Application: localStorage.getItem('appStoNa') },
      ];
    }
    if (this.idApp && this.nameApp) {
      this.selectedItems = [
        { Id_Application: this.idApp, Application: this.nameApp },
      ];
    }
    
    this.getApplications();
  }

  onItemSelect(e) {
    this.idApplication = e.Id_Application;
    if (!this.storage) {
      localStorage.setItem('appStoId', String(this.idApplication));
      localStorage.setItem('appStoNa', String(e.Application));
    } 
    this.idAppC.emit(this.idApplication);
  }

  onItemDeselect(e) {
    this.selectedItems = [];
    this.idApplication = 0;
    localStorage.removeItem('appStoId');
    localStorage.removeItem('appStoNa');
    this.idAppC.emit(this.idApplication);
    this.noSave.emit(true);
  }

  getApplications() {
    this.services
      .post({
        entidad: 'Application',
        funcion: 3,
        data: {
          Use_Folios: ""
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.listApplication = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

}
