import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.css']
})
export class PreferencesComponent implements OnInit {

  public language = '0';
  public Preferences_Notification_List: any = [];
  private Id_User = 0;

  constructor(
    private toastr: ToastrService,
    private allService: ServicesService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    this.Get_Preferences_Notification_List_By_TokenService();
    this.User_Info();
  }

  User_Info() {
    this.spinner.show();
    this.allService.User_Info().subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.language = resp.data.Id_Language;
        this.Id_User = resp.data.Id_User;
      }
    });
  }

  changeNotif(Id_Preferences_Notification: Number, Active: Boolean) {
    this.Update_Preferences_Notification_By_TokenService(Id_Preferences_Notification, Active ? 'V' : 'F');
  }

  Get_Preferences_Notification_List_By_TokenService() {
    this.spinner.show();
    this.allService.Get_Preferences_Notification_List_By_Token('').subscribe(resp => {
      this.spinner.hide();
      this.Preferences_Notification_List = [];

      resp.data.forEach(parameter => {
        parameter.oList.forEach(param => {
          if (param.value === 'V') {
            param.value = true;
          } else {
            param.value = false;
          }
        });
      });

      this.Preferences_Notification_List = resp.data;

    });
  }

  Update_Preferences_Notification_By_TokenService(Id_Preferences_Notification: Number, Value: string) {
    let data = {
      Id_Preferences_Notification,
      Value
    };
    this.spinner.show();
    this.allService.Update_Preferences_Notification_By_Token(data).subscribe(resp => {
      this.spinner.hide();
      this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));

    });
  }

  User_Lenguaje_Update() {


    if (this.language != '0') {

      this.spinner.show();
      this.allService.User_Lenguaje_Update(this.Id_User, +this.language).subscribe(resp => {
        this.spinner.hide();

        if (this.language == '1')
          localStorage.setItem('languaje', String('es'));

        if (this.language == '2')
          localStorage.setItem('languaje', String('en'));


        if (localStorage.getItem('languaje') === 'es') {

          this.translate.use('es');

        }
        else
          if (localStorage.getItem('languaje') === 'en') {
            this.translate.use('en');
          }
          else {
            this.translate.setDefaultLang('es');
            localStorage.setItem('languaje', String('es'));
          }

        this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));

      });
    }
  }

}
