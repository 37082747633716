<ng-container>
  <div class="row me-2">
    <div class="text-end mt-1 mb-1">
      <ng-container *ngIf="operation == 'D'">
        <button class="btn textP " type="button" (click)="editCrew()"><i class="fas fa-pencil-alt"></i> {{"GENERAL.edit"
          | translate}}</button>
      </ng-container>
      <!--  -->
    </div>
  </div>
</ng-container>

<div class="card ms-3 me-4">
  <div class="card-body">
    <ng-container *ngIf="operation == 'D'">
      <div class="row">
        <div class="col-auto">
          <span class="pl-3 textP"> {{ "GENERAL.name" | translate}}: </span>
          <p class="pl-4 ml-3">{{crew.Description}}</p>
        </div>
        <div class="col-auto">
          <span class="pl-3 textP"> {{ "GENERAL.organization" | translate}}: </span>
          <p class="pl-4 ml-3">{{crew.Customer}}</p>
        </div>
        <div class="col-auto">
          <span class="pl-3 textP"> {{ "GENERAL.state" | translate}}: </span>
          <p class="pl-4 ml-3">{{crew.StatusName}}</p>
        </div>
        <div class="col-auto">
          <span class="pl-3 textP"> {{ "GENERAL.comments" | translate}}: </span>
          <p class="pl-4 ml-3">{{crew.Comments}}</p>
        </div>
      </div>
    </ng-container>

    <!-- New / Edit-->
    <ng-container *ngIf="operation == 'N' || operation == 'E'">
      <div class="row page-titles mt-1">
        <div *ngIf="isNew" class="col-md-3 col-sm-12 col-lg-3 align-self-center">
          <h3 class="text-primary m-b-0 m-t-0">{{ "GENERAL.new" | translate}}</h3>
        </div>
      </div>
      <form [formGroup]="formaCrew">
        <div class="row ">
          <div class="col- ps-2 pe-2">
            <div class="row">
              <div class="col-auto">
                <span class="textP"> {{ "GENERAL.name" | translate}}: </span>
                <div *ngIf="isNew">
                  <input class="form-control" type="text" id="Description" formControlName="Description"
                    placeholder="{{ 'GENERAL.name' | translate}}" [class.is-invalid]="descriptionNoValido" />
                </div>
                <div *ngIf="!isNew">
                  <input class="form-control" type="text" id="Description" formControlName="Description"
                    placeholder="{{ 'GENERAL.name' | translate}}" [class.is-invalid]="descriptionNoValido"
                    [attr.disabled]="isNew" />
                </div>
              </div>

              <div class="col-auto">
                <span class="textP"> {{ "GENERAL.organization" | translate}}: </span>
                <div *ngIf="!isNew">
                  <input class="form-control" type="text" id="Description" formControlName="Customer"
                    placeholder="{{ 'GENERAL.Customer' | translate}}" [class.is-invalid]="descriptionNoValido"
                    [attr.disabled]="isNew" />
                </div>
                <div *ngIf="isNew" class="muul mi input1">
                  <app-customer-multiselect (custSelect)="sendCustomer($event)"></app-customer-multiselect>
                  <!-- <ng-multiselect-dropdown [placeholder]="'Selecciona organización:'"
                      [settings]="dropdownSettingsCus" [data]="lstCustomerGet"
                      (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeselect($event)"
                      name="customer" required>
                  </ng-multiselect-dropdown> -->
                </div>
              </div>

              <div class="col-auto">
                <span class="textP"> {{ "GENERAL.state" | translate}}: </span>
                <select class="form-control" id="Status" formControlName="Status"
                  style="width:250px; height: 38px !important;" [class.is-invalid]="statusNoValido">
                  <option value="">Selecciona un estado</option>
                  <option value="A">Activo</option>
                  <option value="I">Inactivo</option>
                </select>
              </div>

              <div class="col-auto">
                <span class="textP"> {{ "GENERAL.comments" | translate}}: </span>
                <textarea style="width: 250px;" class="form-control" rows="3" id="Comments" formControlName="Comments"
                  placeholder="{{ 'GENERAL.comments' | translate}}"></textarea>
              </div>
            </div>
          </div>
        </div>
      </form>

    </ng-container>


  </div>
</div>
<div *ngIf="operation == 'N' || operation == 'E'" class="row text-end gx-0 mt-2 me-4">
  <div class="col-">
    <button [disabled]="crew.Id_Customer === 0" type="button" class="btn btn btnSave waves-effect waves-light buttonform" (click)="saveCrew()"> {{"GENERAL.save" | translate}}</button>
    <button type="button" class="btn btn btnCancel waves-effect waves-light buttonform" (click)="backToCrewList()"> {{ "GENERAL.cancel" | translate}}</button>
  </div>
</div>