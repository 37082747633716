import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core"
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { SecurityService } from '../data/services/security.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityInterceptorService implements HttpInterceptor {

  constructor(
    private securityService: SecurityService,
    private toastr: ToastrService,
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    let loginToken = localStorage.getItem('loginToken') || '';
    
    let headers_: HttpHeaders = req.headers;
    
    headers_ = headers_.append('Authorization', loginToken);
    
    if(headers_.get('xtype') == undefined && headers_.get('Content-Type') == undefined)
      headers_ = headers_.append('Content-Type', 'application/json');

    req = req.clone({
      headers: headers_
    });


    return next.handle(req).pipe(
      tap(evt => {

        if (evt instanceof HttpResponse) {
          if (evt.body)
            if (evt.body.result != undefined)
              if (!evt.body.result) {

                console.log('Interfector error:', evt.body.message);
                this.toastr.error(evt.body.message);

              }
        }
      }),
      catchError((err: any) => {

        if (err instanceof HttpErrorResponse) {

          try {
            if (err.status === 401) {
              this.securityService.loggOff();
            }
            else
              this.toastr.error(err.message);

          } catch (e) {
            console.log('intercept', err);
          }

        }
        return of(err);
      }));

  }

}

