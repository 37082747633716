import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomerList } from 'src/app/data/models/customer-list.model';
import { ServicesService } from 'src/app/data/services/services.service';
import { SelectedValue } from 'src/app/validators/selectedValue';
import { Customer, CustomerApp } from '../customer';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit {

  @Input() idCustomer: number;
  @Input() newEdit: boolean;
  @Output() goBack = new EventEmitter();

  public channelSelected = 0;
  customerLst = new CustomerList();
  public customerSelected = new Customer();
  public listCustomersApp: CustomerApp[];
  public listAllChannel = [];
  public listCustomers = [];
  public titleNewEditCustomer = '';
  public form: UntypedFormGroup;
  public form2: UntypedFormGroup;
  public searchKey = '';
  public viewCustomer = true;
  public isNewEdit = false;
  public isEdit = false;
  public viewMoreItems = true;

  EditCustomer = false;

  constructor(
    private services: ServicesService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private formBuilder2: UntypedFormBuilder,

    private translate: TranslateService) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      Name: ['', { validators: [Validators.required] }],
      Email: ['', { validators: [Validators.email, Validators.required] }],
      Id_Channel: [0, { validators: [Validators.required, SelectedValue()] }],
    });

    this.form2 = this.formBuilder2.group({
      Id_Application: ['0', { validators: [Validators.required, SelectedValue()] }],
      Id_Folio_Selected: ['0', { validators: [Validators.required, SelectedValue()] }],
      Folios: ['0'],
      Folios2: [{ value: '0', disabled: true }]
    });

    if (this.newEdit === false && this.idCustomer === 0) {
      this.isNewEdit = true;
      this.viewCustomer = false;
      this.titleNewEditCustomer = 'GENERAL.new2';
      this.customerSelected = new Customer();
      this.setFormInfo();
      this.LoadAllChannel();
    } else {
      this.Customer_Detail();
    }
  }

  setName(name) {
    this.customerSelected.Name = name;
  }

  selectEmail(email) {
    this.customerSelected.Email = email;
  }

  selectChannel(Id_Channel) {
    this.customerSelected.Id_Channel = Id_Channel;
  }

  changeStatusMFA(statusMFA: boolean) {
    this.customerSelected.MFA = statusMFA ? 'A' : 'I';
  }

  changeStatus(status: boolean) {
    this.customerSelected.Status = status ? 'A' : 'I';
  }

  changeChannelUp(Id_Channel) {
    this.channelSelected = Id_Channel;
    // this.LoadCustomers();
    this.returnToList();
  }


  selectCustomerId(Id_Customer) {
    this.idCustomer = Id_Customer;
  }

  cancelCustomer() {
    this.goBack.emit();
    this.customerSelected = new Customer();
    this.idCustomer = 0;
    this.isNewEdit = false;
    this.viewCustomer = true;
    this.isEdit = false;
  }

  cancelEdit() {
    this.viewCustomer = true;
    this.isNewEdit = false;
    this.isEdit = false;
    this.Customer_Detail();
  }

  return() {
    this.goBack.emit();
  }

  Customer_Detail() {
    this.spinner.show();
    this.services.Customer_By_Id(this.idCustomer).subscribe(resp => {
      this.spinner.hide();

      if (resp.result)
        this.customerSelected = resp.data;

    });
  }

  LoadAllChannel() {
    this.spinner.show();
    this.services.Channel_All().subscribe(resp => {
      this.spinner.hide();

      if (resp.result) {
        this.listAllChannel = resp.data;
      }

    });
  }

  newEditCustomer(edit: boolean) {
    this.isNewEdit = true;
    this.Customer_Detail();
    this.LoadAllChannel();

    if (edit) {
      this.isEdit = true;
      this.viewCustomer = false;

      this.titleNewEditCustomer = 'GENERAL.edit';
    }
    else {
      this.isEdit = false;
      this.viewCustomer = false;
      this.titleNewEditCustomer = 'GENERAL.new2';
      this.customerSelected = new Customer();
    }

    this.setFormInfo();

  }

  setFormInfo() {
    this.form.controls['Name'].setValue(this.customerSelected.Name);
    this.form.controls['Email'].setValue(this.customerSelected.Email);
    this.form.controls['Id_Channel'].setValue(this.customerSelected.Id_Channel);
  }

  Customer_Application_List(Id_Customer) {
    this.spinner.show();
    this.services.Customer_Application_List(Id_Customer).subscribe(resp => {
      this.spinner.hide();

      if (resp.result) {

        this.listCustomersApp = resp.data;

        this.listCustomersApp.forEach(element => {
          element.isActive = element.Validity == 'A' ? true : false;
          this.services.Customer_Type_Pay_List(element.Id_Application).subscribe(res => {

            if (res.result) {

              this.listCustomersApp.filter(x => x.Id_Application === element.Id_Application)[0].typePayList = res.data;

            }
          });

        });
      }
    });
  }

  returnToList() {
    this.customerSelected = new Customer();
    this.isEdit = false;
    // this.isView = false;
    this.isEdit = true;
    this.isNewEdit = false;
  }

  onSubmit() {
    this.spinner.show();
    this.customerSelected.Id_Customer = this.idCustomer;
    if (this.idCustomer === 0) {
      this.services.Customer_New_Edit(this.customerSelected).subscribe(resp => {
        this.spinner.hide();
        if (resp.result) {
          // tslint:disable-next-line: max-line-length
          this.toastr.success(this.translate.instant('GENERAL.Organization') + ' ' + (this.customerSelected.Id_Customer === 0 ? this.translate.instant('GENERAL.saved') : this.translate.instant('GENERAL.modified')) + ' ' + this.translate.instant('GENERAL.correctly'));

          if (this.customerSelected.Id_Customer != 0)
            this.selectCustomerId(this.customerSelected.Id_Customer);
          else
            this.returnToList();

          this.form2.reset();
        }
          this.goBack.emit();
          this.Customer_Detail();
      });

      if (this.isEdit) {
        this.isEdit = false;
        this.isNewEdit = false;
      }
    }else if (this.idCustomer !== 0){
      this.services.Customer_New_Edit(this.customerSelected).subscribe(resp => {
        this.spinner.hide();

        if (resp.result) {
          // tslint:disable-next-line: max-line-length
          this.toastr.success(this.translate.instant('GENERAL.Organization') + ' ' + (this.customerSelected.Id_Customer === 0 ? this.translate.instant('GENERAL.saved') : this.translate.instant('GENERAL.modified')) + ' ' + this.translate.instant('GENERAL.correctly'));

          if (this.customerSelected.Id_Customer != 0)
            this.selectCustomerId(this.customerSelected.Id_Customer);
          else
            this.returnToList();
            this.Customer_Detail();
          this.form2.reset();
        }

      });

      if (this.isEdit) {
        this.isEdit = false;
        this.isNewEdit = false;
      }
    this.viewCustomer = true;
    
    }

  }



  GetErrorMessage(formulario: number) {

    if (formulario === 1) {
      let name = this.form.get('Name');
      if (name.hasError('required')) {
        return this.translate.instant('FORM.VALIDATORS.Name');
      }

      let email = this.form.get('Email');
      if (email.errors) {
        if (email.hasError('required')) {
          return this.translate.instant('FORM.VALIDATORS.Email');
        }
        else {
          return this.translate.instant('FORM.VALIDATORS.EmailFormat');
        }
      }

      let channel = this.form.get('Id_Channel');

      if (channel.hasError('required') || channel.hasError('selectedValue')) {
        return this.translate.instant('FORM.VALIDATORS.Channel');
      }
    }

    if (formulario === 2) {

      let Id_Application = this.form2.get('Id_Application');

      if (Id_Application.hasError('required') || Id_Application.hasError('selectedValue')) {
        return '*' + this.translate.instant('FORM.VALIDATORS.Application');
      }

      let Id_Folio_Selected = this.form2.get('Id_Folio_Selected');

      if (Id_Folio_Selected.hasError('required') || Id_Folio_Selected.hasError('selectedValue')) {
        return '*' + this.translate.instant('FORM.VALIDATORS.Folio');
      }

    }

    return '';
  }

}
