import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(
    private router: Router
  ) { }

  public isLogged(): boolean {
    let loginToken = localStorage.getItem("loginToken");

    if (loginToken) {
      if (loginToken.length < 40)
        return false;
      else
        return true;
    }
    else
      return false;
  }

  public loggOff(): void {

    localStorage.removeItem('loginToken');
    localStorage.removeItem('menuState');
    localStorage.removeItem('amb');
    this.router.navigateByUrl('/');

  }

}
