import { Component, OnInit,  ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import { Channel, Ordercha } from './channels';

import { ExcelService } from 'src/app/data/services/excel.service';
import { UntypedFormGroup } from '@angular/forms';
import { ChannelList } from 'src/app/data/models/channel-list.model';

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.css']
})
export class ChannelComponent implements OnInit {

  channelLst = new ChannelList();
  order1 = new Ordercha();

  public lstChannel = [];
  public listCountry = [];
  public listState = [];
  public List_Users_Ximplify = [];
  public List_Contacts = [];
  public channelListDown = [];
  public channelListDownCSV = [];
  newEdit = '';
  Page: Number = 1;
  Pagination = 3;
  selectedPage: any = 1;
  collectionSize = 0;
  totalPages = 0;
  AZcha = false;
  ZAcha = false;
  AZcon = false;
  ZAcon = false;
  AZpho = false;
  ZApho = false;
  AZema = false;
  ZAema = false;
  AZrfc = false;
  ZArfc = false;
  AZdat = false;
  ZAdat = false;
  chanelId = '';
  orderColumn = '';
  orderDir = '';

  editSel: any = {};
  idx: any;
  filterType = '';

  public editChannelSelected = new Channel();
  public myDate = new Date();

  public viewMoreItems: boolean;
  public isChannelList: boolean = true;
  public isdetailsChannel = false;
  public ischannelEdit: boolean = false;
  public isnewChannel: boolean = false;
  public isList: boolean = false;
  public StatusList: boolean = false;
  public resultsKey: boolean = false;
  public results: boolean = false;
  public Status: boolean = false;
  public isDown: boolean = false;
  isButtonsView = true;
  isRestart = false;

  public searchKey: string = '';
  public Country_Code: string = '';

  public page: number;
  public customerID: number = 0;
  public Id_Channel: number = 0;

  idChan = 0;

  Title = 'Canales';

  @ViewChild('fileInput') fileInput;
  @ViewChild('fileInput1') fileInput1;
  @ViewChild('fileInput2') fileInput2;

  public form: UntypedFormGroup;
  public form2: UntypedFormGroup;
  pagesLst: any[] = [];
  idCountry: any;

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private services: ServicesService,
    private excelService: ExcelService,
  ) {

    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else
      if (localStorage.getItem('languaje') === 'en') {
        translate.use('en');
      } else {
        translate.setDefaultLang('es');
        localStorage.setItem('languaje', String('es'));
      }
    if (localStorage.getItem('chaStoId') !== null) {
      this.channelLst.Id_Channel = +localStorage.getItem('chaStoId');
    }
    if (localStorage.getItem('paginationStorage') !== null) {
      this.channelLst.Pagination = +localStorage.getItem('paginationStorage');
    }
  }

  ngOnInit(): void {
    this.isdetailsChannel =false;

    const myOffcanvas = document.querySelector('#offcanvasRight') as any;

    if (myOffcanvas !== null) {
      myOffcanvas.addEventListener('hidden.bs.offcanvas', () => {
        this.filterType = '';
      });
    }
    
    this.page = 0;
    this.viewMoreItems = true;
    this.loadInfoChannels();
  }


  onSearchChange() {
    this.page = 0;
    this.loadInfoChannels();
  }

  backtoListChannels() {
    this.loadInfoChannels();
    this.isList = true;
    this.isChannelList = true;
    this.isButtonsView = true;
    this.isdetailsChannel = false;
    this.ischannelEdit = false;
    this.isnewChannel = false;
  }

  downloadCSV() {

    this.spinner.show();
    this.services.Channel_List_DownLoad().subscribe(resp => {
      this.spinner.hide();

      const struct = resp.data.map(s => ({
        Id: s.Id_Channel,
        Nombre: s.Name,
        Estatus: s.Status_Name,
        NombreContacto: s.Contact_Person,
        Vendedor: s.Seller,
        Correo: s.Email,
        Telefono: s.Phone,
        Empresa: s.Business_Name,
        RFC: s.RFC,
        UrlLogo: s.Url_Logo,
        Pais: s.Country_Name,
        Estado: s.State_Name
      }));

      this.excelService.exportAsExcelFile(struct, 'ResumeCanales');
    });
  }

  generateNewChannel(N): void {

    this.newEdit = N;
    this.isdetailsChannel = true;
    this.isButtonsView = false;
    this.isChannelList = false;
    this.editChannelSelected = new Channel();
    this.getChannelContacts(this.editChannelSelected.Id_Channel);

  }

  requestInfo() { this.loadInfoChannels(); }

  filterSel(e) {

    const ftro = Number(e);

    if (ftro === 1) {
      this.filterType = 'C';
    } else if (ftro === 2) {
      this.filterType = 'S';
    } 
  }

  updateStatus(e) {
    if (!e.target.checked) {
      this.channelLst.Status = 'I';
    } else {
      this.channelLst.Status = 'A';
    }
    this.loadInfoChannels();
  }

  filterByChannel(e) {
    this.channelLst.Id_Channel = e;
  }

  filterData() {
    this.loadInfoChannels();
  }

  onSorted(e) {
    this.channelLst.Order_Column = e.sortColumn;
  }

  viewdetailChann() {
    this.isButtonsView = true;
    this.isChannelList = true;
    this.isdetailsChannel = false;
    this.loadInfoChannels();
  }

  resetTable() {
    localStorage.removeItem('chaStoId');
    localStorage.removeItem("chaStoNa");
    localStorage.removeItem('paginationStorage');
    this.channelLst.Id_Channel = 0;
    this.channelLst.Order_Column = '';
    this.channelLst.Order_Dir = '';
    this.channelLst.Status = '';
    this.channelLst.Page = 1;
    this.channelLst.Pagination = 10;
    this.AZcha = false;
    this.ZAcha = false;
    this.AZcon = false;
    this.ZAcon = false;
    this.AZpho = false;
    this.ZApho = false;
    this.AZema = false;
    this.ZAema = false;
    this.AZrfc = false;
    this.ZArfc = false;
    this.AZdat = false;
    this.ZAdat = false;
    this.isRestart = false;
    return this.loadInfoChannels();
  }

  onSortedA(e) {
    this.channelLst.Order_Dir = 'A';
    if (this.channelLst.Order_Column === 'cha' && this.channelLst.Order_Dir === 'A'){
      this.AZcha = true;
      this.isRestart = true;
      this.ZAcha = false;
      this.AZcon = false;
      this.ZAcon = false;
      this.AZpho = false;
      this.ZApho = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZrfc = false;
      this.ZArfc = false;
      this.AZdat = false;
      this.ZAdat = false;
    }else if (this.channelLst.Order_Column === 'con' && this.channelLst.Order_Dir === 'A'){
      this.AZcha = false;
      this.ZAcha = false;
      this.AZcon = true;
      this.isRestart = true;
      this.ZAcon = false;
      this.AZpho = false;
      this.ZApho = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZrfc = false;
      this.ZArfc = false;
      this.AZdat = false;
      this.ZAdat = false;
    }else if (this.channelLst.Order_Column === 'pho' && this.channelLst.Order_Dir === 'A'){
      this.AZcha = false;
      this.ZAcha = false;
      this.AZcon = false;
      this.ZAcon = false;
      this.AZpho = true;
      this.isRestart = true;
      this.ZApho = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZrfc = false;
      this.ZArfc = false;
      this.AZdat = false;
      this.ZAdat = false;
    }else if (this.channelLst.Order_Column === 'ema' && this.channelLst.Order_Dir === 'A'){
      this.AZcha = false;
      this.ZAcha = false;
      this.AZcon = false;
      this.ZAcon = false;
      this.AZpho = false;
      this.ZApho = false;
      this.AZema = false;
      this.ZAema = true;
      this.isRestart = true;
      this.AZrfc = false;
      this.ZArfc = false;
      this.AZdat = false;
      this.ZAdat = false;
    }else if (this.channelLst.Order_Column === 'rfc' && this.channelLst.Order_Dir === 'A'){
      this.AZcha = false;
      this.ZAcha = false;
      this.AZcon = false;
      this.ZAcon = false;
      this.AZpho = false;
      this.ZApho = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZrfc = true;
      this.isRestart = true;
      this.ZArfc = false;
      this.AZdat = false;
      this.ZAdat = false;
    }else if (this.channelLst.Order_Column === 'dat' && this.channelLst.Order_Dir === 'A'){
      this.AZcha = false;
      this.ZAcha = false;
      this.AZcon = false;
      this.ZAcon = false;
      this.AZpho = false;
      this.ZApho = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZrfc = false;
      this.ZArfc = false;
      this.AZdat = true;
      this.isRestart = true;
      this.ZAdat = false;
    }
    this.loadInfoChannels();
  }

  onSortedD(e) {
    this.channelLst.Order_Dir = 'D';
    if (this.channelLst.Order_Column === 'cha' && this.channelLst.Order_Dir === 'D'){
      this.AZcha = false;
      this.ZAcha = true;
      this.isRestart = true;
      this.AZcon = false;
      this.ZAcon = false;
      this.AZpho = false;
      this.ZApho = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZrfc = false;
      this.ZArfc = false;
      this.AZdat = false;
      this.ZAdat = false;
    }else if (this.channelLst.Order_Column === 'con' && this.channelLst.Order_Dir === 'D'){
      this.AZcha = false;
      this.ZAcha = false;
      this.AZcon = false;
      this.ZAcon = true;
      this.isRestart = true;
      this.AZpho = false;
      this.ZApho = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZrfc = false;
      this.ZArfc = false;
      this.AZdat = false;
      this.ZAdat = false;
    }else if (this.channelLst.Order_Column === 'pho' && this.channelLst.Order_Dir === 'D'){
      this.AZcha = false;
      this.ZAcha = false;
      this.AZcon = false;
      this.ZAcon = false;
      this.AZpho = false;
      this.ZApho = true;
      this.isRestart = true;
      this.AZema = false;
      this.ZAema = false;
      this.AZrfc = false;
      this.ZArfc = false;
      this.AZdat = false;
      this.ZAdat = false;
    }else if (this.channelLst.Order_Column === 'ema' && this.channelLst.Order_Dir === 'D'){
      this.AZcha = false;
      this.ZAcha = false;
      this.AZcon = false;
      this.ZAcon = false;
      this.AZpho = false;
      this.ZApho = false;
      this.AZema = true;
      this.isRestart = true;
      this.ZAema = false;
      this.AZrfc = false;
      this.ZArfc = false;
      this.AZdat = false;
      this.ZAdat = false;
    }else if (this.channelLst.Order_Column === 'rfc' && this.channelLst.Order_Dir === 'D'){
      this.AZcha = false;
      this.ZAcha = false;
      this.AZcon = false;
      this.ZAcon = false;
      this.AZpho = false;
      this.ZApho = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZrfc = false;
      this.ZArfc = true;
      this.isRestart = true;
      this.AZdat = false;
      this.ZAdat = false;
    }else if (this.channelLst.Order_Column === 'dat' && this.channelLst.Order_Dir === 'D'){
      this.AZcha = false;
      this.ZAcha = false;
      this.AZcon = false;
      this.ZAcon = false;
      this.AZpho = false;
      this.ZApho = false;
      this.AZema = false;
      this.ZAema = false;
      this.AZrfc = false;
      this.ZArfc = false;
      this.AZdat = false;
      this.ZAdat = true;
      this.isRestart = true;
    }
    this.loadInfoChannels();
  }

  filterChannel(e) {
    if (e === 'C') {
      this.filterType = e;
    } else if (e === 'S') {
      this.filterType = e;
    }
  }

  showDetails(Id_Channel, E) {

    this.newEdit = 'E';
    this.isList = false;
    this.isButtonsView = false;
    this.isChannelList = false;
    this.isdetailsChannel = true;
    this.ischannelEdit = false;
    this.isnewChannel = false;
    this.idChan = Id_Channel;

  }

  changeAmountView(e) {
    this.channelLst.Pagination = e.target.value;
    this.loadInfoChannels();
  }

  changePage(e) {
    this.channelLst.Page = e;
    this.loadInfoChannels();
  }

  // tslint:disable-next-line: member-ordering
  optionsTooltip = {
    'placement': 'top',
    'showDelay': 500
  }

  ///Servicios

  changeStatusChannelInt(activo: boolean, Id_Channel: Number) {

    this.spinner.show();

    this.services.Channel_Update_Status(Id_Channel, (activo === true ? 'A' : 'I')).subscribe(resp => {
      this.spinner.hide();

      if (resp.result) {
        this.toastr.success('Canal ' + (activo === true ? '"Activado"' : '"Desactivado"') + ' correctamente');
      }

    });

  }

  loadInfoChannels() {
    this.loadChannels();
  }

  loadChannels() {
    this.spinner.show();
    this.services.Channel_List(this.channelLst)
    .subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.lstChannel = resp.data;
      }
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < resp.data.length; i++) {
        this.totalPages = resp.data[i].Pages;
        this.collectionSize = resp.data[i].Pages * 10;
      }
      if (resp.data.length === 0) {
        this.totalPages = 1;
        this.collectionSize = 1;
        this.selectedPage = 1;
      }
    });
  }

  getChannelContacts(Id_Channel) {

    this.spinner.show();
    this.services.Channel_Contacts(Id_Channel).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        let data = resp.data;

        if (Array.isArray(data) && data.length > 0) {
          this.editChannelSelected.Contacts = data;
        }
      }

    });

  }

}
