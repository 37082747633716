import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Profile } from 'src/app/data/models/profile.model';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-access-profiles',
  templateUrl: './access-profiles.component.html',
  styleUrls: ['./access-profiles.component.css']
})
export class AccessProfilesComponent implements OnInit {
  @Input() idProfileAccess : number;

  profileSelected = new Profile();
  listAccess = [];
  availableAccess = [];
  showButton = false;
  idAccess = 0;
  statusAccess = -1;
  isActiveStatus = false;

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
   }

  ngOnInit(): void {
    this.profileById();
    this.accessByProfile();
  }

  selectAccess(Id_Access) {
    this.idAccess = Id_Access;
  }

  updateStatus(e) {
    if (!e.target.checked) {
      this.statusAccess = 0;
    } else {
      this.statusAccess = 1;
    }
    this.accessByProfile();
  }

  clearStatus() {
    this.statusAccess = -1;
    this.isActiveStatus = false;
    this.accessByProfile();
  }

  changeStatusAccess(IdAccess, activate: boolean){
    this.idAccess = IdAccess;
    if (activate === true){
      this.addAccess();
    }else{
      this.deleteAccess();
    }
  }

  profileById() {
    this.services.post({
      entidad: 'Profile',
      funcion: 2,
      data: {
        Id_Profile: this.idProfileAccess
      }
    }).subscribe(result => {
      if (result.data) {
        this.profileSelected = result.data;
      }
    }, error => {
      console.log('Error', error);
    });
  }

  accessByProfile() {
    this.services.post({
      entidad: 'Profile',
      funcion: 10,
      data: {
        Id_Profile: this.idProfileAccess,
        Active: this.statusAccess
      }
    }).subscribe(result => {
      if (result.data) {
        this.listAccess = result.data;
      }
    }, error => {
      console.log('Error', error);
    });
  }

  addAccess() {
    this.services.post({
      entidad: 'Profile',
      funcion: 6,
      data: {
        Id_Profile: this.profileSelected.Id_Profile,
        Id_Access: this.idAccess
      }
    }).subscribe(result => {
      if (result.result === true) {
        this.toastr.success('Acceso agregado correctamente', 'Perfiles');
      }
    }, error => {
      console.log('Error', error);
    });
  }

  deleteAccess() {
    this.services.post({
      entidad: 'Profile',
      funcion: 7,
      data: {
        Id_Profile: this.profileSelected.Id_Profile,
        Id_Access: this.idAccess
      }
    }).subscribe(result => {
      if (result.result === true) {
        this.toastr.success(`Acceso eliminado correctamente`, 'Access');
      }
    }, error => {
      console.log('Error', error);
    });
  }

}
