<div class="row animate__animated animate__flip">
    <div class="col">
        <div class="row mt-1 justify-content-between align-items-center">
            <div class="col-auto">
                <div class="row">
                    <div class="col-auto" style="padding-right: 5px;">
                        <input (keyup)="onTitle($event)" [ngModel]="Titulo" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Titulo de KPI  " />
                    </div> <span class="asterisk1"><i class="fa fa-asterisk" ></i></span>
                    <div class="col-auto activeIn">
                        Diseño/Producción
                        <label class="switch">
              <input (change)="onCheck($event)" [checked]="Status === 'A'" name="status" type="checkbox" id="status" />
              <span class="slider round-in"></span>
            </label>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div *ngIf="newEditKpi !== 'N'" class="col-auto closeBtn">
                <button class="close">
          <i class="far fa-times-circle" (click)="closeCb()"></i>
        </button>
            </div>
        </div>
        <hr class="mt-2 mb-1" />
        <div class="row mt-0">
            <div class="col-auto">
                <div class="form-group mb-0">
                    <label for="typeFilters" class="subTittleCard">Configuración</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-auto">
                <label for="typeGrafic" class="mb-0 labelText">Top</label>
                <input (change)="selectTop($event)" [(ngModel)]="numberTop" type="number" class="form-control" id="topKpi" aria-describedby="topKPI" />
            </div>
            <div class="col-auto">
                <label for="typeGrafic" class="mb-0 labelText">Tipo de Gráfico  <span class="asteri"><i class="fa fa-asterisk" ></i></span></label>
                <select [(ngModel)]="typeGraph" (change)="selectGraph($event)" class="form-control" id="typeGrafic">
          <option value="">Selecciona una opción</option>
          <option *ngFor="let tChart of lstChartGenerate" value="{{tChart.Id}}">
            {{ tChart.Name }}
          </option>
        </select>
            </div>
            <div class="col-auto">
                <label for="typeVisibility" class="mb-0 labelText">Visibilidad  <span class="asteri"><i class="fa fa-asterisk" ></i></span></label>
                <select [(ngModel)]="typeVisi" (change)="selectVisibility($event)" class="form-control" id="typeVisibility">
          <option value="">Selecciona una opción</option>
          <option *ngFor="let tVisibility of lstVisibilityGenerate" value="{{tVisibility.Id}}">
            {{ tVisibility.Value }}
          </option>
        </select>
            </div>
            <div class="col-auto">
                <label for="typeSize" class="mb-0 labelText">Tamaño  <span class="asteri"><i class="fa fa-asterisk" ></i></span></label>
                <select [(ngModel)]="typeSize" (change)="selectSize($event)" class="form-control" id="typeSize">
          <option value="">Selecciona una opción</option>
          <option *ngFor="let tSize of ltsSizeGenerate" value="{{tSize.Id}}">
            {{ tSize.Value }}
          </option>
        </select>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="descriptionKPI" class="mb-0 labelText">Descripción</label>
                    <textarea [(ngModel)]="descriptonKpi" class="form-control textArea" id="descriptionKPI" rows="3"></textarea>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-auto">
                <a (click)="closeCb()" class="btn btn-warning text-white">Cancelar</a>
            </div>
            <div class="col-auto">
                <button class="btn btn-success succesBtn"  (click)="addNewConfig()"><i class="far fa-save"></i> Guardar</button>
            </div>
        </div>
    </div>
</div>