import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import SearchItem from 'src/app/data/models/search-item.model';

@Component({
  selector: 'app-search-item',
  templateUrl: './search-item.component.html',
  styleUrls: ['./search-item.component.css']
})
export class SearchItemComponent implements OnInit {

  @Input() searchItem: SearchItem = new SearchItem();
  @Output() itemClick = new EventEmitter<number>();

  constructor(private translate: TranslateService) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
  }

  onItemClick(e: any) {

    this.itemClick.emit(e);

  }

}
