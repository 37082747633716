<div class="row me-2">
    <div class="text-end mt-1 mb-1">
        <button *ngIf="viewProfile && !newProfile && !isNewEdit" (click)="newEditProfile()" class="btnEd">
            <i class="fas fa-edit"></i>
            {{ "GENERAL.edit" | translate }}
        </button>
    </div>
</div>

<form ngNativeValidate #f="ngForm" (ngSubmit)="onSubmit()" id="formMenu" class="form">
    <div class="card ms-3 me-3">
        <div class="card-body">
            <h2 class="card-title mb-0">Detalle del Perfil</h2>
            <hr style="margin-top: 0;">
            <div class="row ps-3">
                <div class="col">
                    <div class="row">
                        <div class="col-auto ord mt-1">
                            <b class="textP" for="example-text-input">Nombre del Perfil</b>
                        </div>
                        <div class="col-auto">
                            <label *ngIf="viewProfile && !newProfile && !isNewEdit" class="col-form-label" for="example-text-input">{{profileSelected.ProfileDescription}}</label>
                            <span *ngIf="newProfile || isNewEdit">
                                <input [(ngModel)]="profileSelected.ProfileDescription"  class="form-control inputPer1 mb-p"
                                pattern="[A-Za-z0-9áéíóúüñÑ_., -]+" name="access"  type="text"
                                placeholder="Nombre del perfil..." id="access" required>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto ord mt-1">
                            <b class="textP" for="example-text-input">Aplicación</b>
                        </div>
                        <div class="col-auto">
                            <label *ngIf="viewProfile && !newProfile && !isNewEdit" class="col-form-label" for="example-text-input">{{profileSelected.ApplicationName}}</label>
                            <div *ngIf="newProfile || isNewEdit" class="wi muul inputPer mb-p">
                                <app-application-multiselect (idAppC)="onSelectApp($event)" [storage]="notStorage" [idApp]="profileSelected.Id_Application" [nameApp]="profileSelected.ApplicationName"
                                    (noSave)="onDeselectApp()">
                                </app-application-multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto ord mt-1">
                            <b class="textP" for="example-text-input">Tag</b>
                        </div>
                        <div class="col-auto">
                            <label *ngIf="viewProfile && !newProfile && !isNewEdit" class="col-form-label ellipsis" for="example-text-input">{{profileSelected.TagDescription}}</label>
                            <div *ngIf="newProfile || isNewEdit" class="wi muul inputPer mb-p">
                                <app-tag-multiselect (idTagC)="onSelectTag($event)" [storage]="notStorage" [idTagF]="profileSelected.Id_Tag" [nameTagF]="profileSelected.TagDescription"
                                (noSave)="onDeselectTag()">
                                </app-tag-multiselect>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="newProfile || isNewEdit" class="col-">
        <button type="submit" class="btn btnSave waves-effect waves-light m-r-10 mt-2 mr-3 buttonform"
            [disabled]="profileSelected.Id_Application === 0 || profileSelected.Id_Tag === 0">
            {{ "GENERAL.save" | translate }}
        </button>
        <button *ngIf="isNewEdit" (click)="cancelEdit()" class="btn btnCancel waves-effect waves-light m-r-10 mt-2 buttonform">
            Cancelar
        </button>
        <button *ngIf="!isNewEdit" (click)="cancelNewProfile()" class="btn btnCancel waves-effect waves-light m-r-10 mt-2 buttonform">
            Cancelar
        </button>
    </div>
</form>