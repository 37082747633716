import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import { GlobalService } from 'src/app/global/global.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-sessions',
  templateUrl: './user-sessions.component.html',
  styleUrls: ['./user-sessions.component.css']
})
export class UserSessionsComponent implements OnInit {
  @Output() userValid = new EventEmitter();
  sesUsers: string[] = [];
  email = '';
  loading = false;
  appVersion: string = environment.appVersion;

  constructor(private spinner: NgxSpinnerService, private allService: ServicesService, private globalService: GlobalService,  private toastr: ToastrService) { }
  @Output() backLogin = new EventEmitter();
  @Output() backApp = new EventEmitter();
  ngOnInit(): void {
    this.getHistory();
  }

  getHistory(): void {
    const storedUsers = localStorage.getItem('sesUsers');
    if (storedUsers) {
      this.sesUsers = JSON.parse(storedUsers);
    }
  }

  selectEmail(email: string) {
    this.loading = true;
    this.spinner.show();
  
    const requestData = {
      email: email
    };
  
    this.allService.Validate_Email(requestData).subscribe(resp => {
      if (this.globalService.verifyResponse(resp)) {
        if (resp.result === false) {
          this.toastr.warning('¡Usuario invalido, intentalo nuevamente!', 'Alerta');
          this.spinner.hide();
        } else {
          this.userValid.emit();
          this.toastr.success('¡Usuario valido', 'Bienvenido');
          localStorage.setItem('email', email);
        }
        this.loading = false;
        this.spinner.hide();
      }
    });
  }
  
  deleteEmail(index: number): void {
    this.sesUsers.splice(index, 1); 
    localStorage.setItem('sesUsers', JSON.stringify(this.sesUsers)); 
    this.getHistory();
  }

  backToLogin() {
    this.backLogin.emit();
    localStorage.removeItem('email');
  }

  backApps(){
    this.backApp.emit();
  }
}
