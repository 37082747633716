import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import { GlobalService } from 'src/app/global/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  @Output() userValid = new EventEmitter();
  @Output() backApp = new EventEmitter();
  @Output() backUS = new EventEmitter();

  loginUser: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  appVersion: string = environment.appVersion;

  isLoginUser = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private allService: ServicesService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.loginUser = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

  get f() { return this.loginUser.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.loginUser.invalid) {
      return;
    }

    this.Validate_EmailService();

  }

  Validate_EmailService() {
    this.loading = true;
    this.spinner.show();
    this.allService.Validate_Email(this.loginUser.value).subscribe(resp => {
      if (this.globalService.verifyResponse(resp)) {
        if (resp.result === false) {
          this.toastr.warning('¡Usuario invalido, intentalo nuevamente!', 'Alerta');
        } else {
          this.userValid.emit();
          this.toastr.success('¡Usuario valido', 'Bienvenido');
          localStorage.setItem('email', this.loginUser.value.email);
        }
        this.loading = false;
        this.spinner.hide();
      }
    });
  }

  backApps(){
    this.backApp.emit();
  }

  backUserSessions(){
    this.backUS.emit();
  }

}
