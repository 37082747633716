import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServicesService } from '../data/services/services.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
})
export class NewsComponent implements OnInit {
  newsLst = [];
  newsGrouped = [];
  noticeSel: any = [];
  amb:Number = 0;
  selImg = '';
  public Embed_Code = '';

  constructor(
    private route: ActivatedRoute,
    private services: ServicesService
  ) {}

  ngOnInit(): void {
    this.newListService();
  }

  viewImageBig(img) {
    this.selImg = img;
  }

  viewNotice(n) {
    this.noticeSel = n;
  }

  getVideo(nv: string) {
    return '<iframe width="100%" height="315" src="' + nv + '" frameborder="0" allowfullscreen="allowfullscreen"></iframe>';
  }

  getEncuesta(enc: string) {
    return '<iframe width="500px" height= "480px" src= "' + enc + '" frameborder= "0" marginwidth= "0" marginheight= "0" style= "border: none; max-width:100%; max-height:100vh" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>'
  }

  // Servicios

  newListService() {
    let uno: Number = 1;

    this.amb = parseInt(localStorage.getItem('amb'));
   
    if (this.amb < uno) {
      this.route.paramMap.subscribe(params => {
        if (params.has('amb')) {
          this.amb = parseInt(params.get('amb'));
        }
      });
    }

    this.services.News_Get(this.amb).subscribe((resp) => {
      if (resp.code == 1) {
        this.newsLst = resp.data;
        this.groupNews();
      }
    });
  }

  // Método para agrupar las noticias en grupos de dos
  groupNews() {
    for (let i = 0; i < this.newsLst.length; i += 2) {
      this.newsGrouped.push(this.newsLst.slice(i, i + 2));
    }
  }
}
