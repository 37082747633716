<div class="row me-2">
    <div class="text-end mt-1 mb-1 ">
        <button *ngIf="!isEditFiles" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight1" aria-controls="offcanvasRight" class="btnEd">
            <i class="fas fa-edit"></i>
            {{ "GENERAL.edit" | translate }}
        </button>
        <ng-container *ngIf="isEditFiles">
            <button (click)="cancelFiles()" class="btnEd">
                <i class="fas fa-times"></i>
                Cancelar
            </button>
        </ng-container>
    </div>
</div>


<ng-container>
    <div class="card dataCompany ms-3 me-4">
        <div class="card-header bg-transparent border-0">
            <h5 class="card-title textP"><b>{{ "GENERAL.fiscalFiles" | translate }} CSD</b></h5>
            <!-- <h6 class="card-subtitle textP"> {{ "COMPANIES.headerFiscalFiles" | translate }} </h6> -->
            <h6 class="card-subtitle textP"> Cargar aquí los certificados proporcionados por el sat para realizar el timbrado de facturas electrónicas emitidas por mi empresa. </h6>
            <div class="m-t-25 row">
                <label for="example-text-input" class="col-md-2 col-form-label textP">{{
                    "GENERAL.certificate" | translate }} (.cer)</label>
                <div class="col-md-10">
                    <label for="example-text-input" class="col-md-10 col-form-label textP">{{companieSelected.Certificate_Path ==
                        ''? ("GENERAL.undefined" | translate) : companieSelected.Certificate_Path}}</label>
                </div>
            </div>
            <div class="m-t-25 row">
                <label for="example-text-input" class="col-md-2 col-form-label textP">{{ "GENERAL.keyPriv" |
                    translate }} (.key)</label>
                <div class="col-md-10">
                    <label for="example-text-input" class="col-md-10 col-form-label textP">{{companieSelected.Key_Path
                        == ''? ("GENERAL.undefined" | translate) : companieSelected.Key_Path}}</label>
                </div>
            </div>
            <div class="m-t-25 row">
                <label for="example-text-input" class="col-md-2 col-form-label textP">Validez</label>
                <div class="col-md-10">
                    <label for="example-text-input" class="badge badge-secondary mb-1 badge1">De:
                        {{companieSelected.CSD_Valid_From
                        == undefined ? ("GENERAL.undefined" | translate) : companieSelected.CSD_Valid_From|
                        date:'medium'}} al {{companieSelected.CSD_Valid_Until
                        == undefined ? ("GENERAL.undefined" | translate) : companieSelected.CSD_Valid_Until|
                        date:'medium'}}</label>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<div class="row me-2">
    <div class="text-end mt-1 mb-1 ">
        <button *ngIf="!isEditSAT" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight2" aria-controls="offcanvasRight" class="btnEd">
            <i class="fas fa-edit"></i>
            {{ "GENERAL.edit" | translate }}
        </button>
        <ng-container *ngIf="isEditSAT">
            <button (click)="cancelSAT()" class="btnEd">
                <i class="fas fa-times"></i>
                Cancelar
            </button>
        </ng-container>
    </div>
</div>


<ng-container>
    <div class="card dataCompany ms-3 me-4">
        <div class="card-header bg-transparent border-0">
            <h5 class="card-title textP"><b>eFirma</b></h5>
            <h6 class="card-subtitle textP"> Cargar aquí los certificados de la fiel para ingresar al portal del sat y descargar comprobantes electrónicos. </h6>
            <div class="m-t-25 row">
                <label for="example-text-input" class="col-md-2 col-form-label textP">{{
                    "GENERAL.certificate" | translate }} (.cer)</label>
                <div class="col-md-10">
                    <label for="example-text-input" class="col-md-10 col-form-label textP">{{companieSelected.EFirma_Cer ==
                        ''? ("GENERAL.undefined" | translate) : companieSelected.EFirma_Cer}}</label>
                </div>
            </div>
            <div class="m-t-25 row">
                <label for="example-text-input" class="col-md-2 col-form-label textP">{{
                    "GENERAL.keyPriv" | translate }} (.key)</label>
                <div class="col-md-10">
                    <label for="example-text-input" class="col-md-10 col-form-label textP">{{companieSelected.EFirma_Key == ''?
                        ("GENERAL.undefined" | translate) : companieSelected.EFirma_Key}}</label>
                </div>
            </div>
            <div class="m-t-25 row">
                <label for="example-text-input" class="col-md-2 col-form-label textP">Validez</label>
                <div class="col-md-10">
                    <label for="example-text-input" class="badge badge-secondary mb-1 badge1">De:
                        {{companieSelected.Efirma_Valid_From ==
                        undefined ? ("GENERAL.undefined" | translate) : companieSelected.Efirma_Valid_From |
                        date:'medium'}} al {{companieSelected.Efirma_Valid_Until == undefined ?
                        ("GENERAL.undefined" | translate) : companieSelected.Efirma_Valid_Until |
                        date:'medium'}}</label>
                </div>
            </div>
        </div>
    </div>
</ng-container>


<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight1" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <button type="button" class="btnArrow but" data-bs-dismiss="offcanvas">
            <span><i class="fa fa-arrow-left"></i></span>
        </button>
        <div class="row">
            <div class="col-12">
                <h5 class="ps-3 mt-2" id="offcanvasRightLabel">{{ "GENERAL.fiscalFiles" | translate }} CSD</h5>
                <!-- <h6 class="ms-3 mt-1 card-subtitle sub"> {{ "COMPANIES.headerFiscalFiles" | translate }} </h6> -->
                <h6 class="ms-3 mt-1 card-subtitle sub mt-1"> Cargar aquí los certificados proporcionados por el sat para realizar el timbrado de facturas electrónicas emitidas por mi empresa. </h6>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class="col-auto px-0">
            <div class="row gx-0 px-3">
                <form (ngSubmit)="uploadCSD()" enctype="multipart/form-data" method="POST">
                    <div class="col- d-flex justify-content-center">
                        <ng-container>
                            <div class="csvPolitic form-group dragg">
                                <p class="textP1">{{ "GENERAL.certificate" | translate }} (.cer)</p>
                                <div class="form-group dragAndDrop text-center" style="width: 100%">
                                    <input #fileInputCerUnicCSD (change)="updateCer($event)" required name="fileInputCerUnicCSD" class="custom-file-input form-control-file text-primary font-weight-bold" type="file" accept=".cer" placeholder="{{ 'GENERAL.certificate' | translate }}..." id="fileInputCerUnicCSD">
                                    <span id="span-click" class="custom-file-control span-click" *ngIf="!fileNameChangeCerCSD">
                                        <div class="row justify-content-center">
                                            <div class="col-12">
                                                <p class="h4">
                                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-">
                                                <p>Arrastra o da click para subir tu Certificado</p>
                                            </div>
                                        </div>
                                    </span>
                                    <span id="span-click" class="custom-file-control span-click" *ngIf="fileNameChangeCerCSD">
                                        <div class="row justify-content-center">
                                            <div class="col-12">
                                                <p class="h4 archivoSubido">
                                                    <i class="far fa-check-circle"></i>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-">
                                                <p>{{ fileNameChangeCerCSD }}</p>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col- d-flex justify-content-center">
                        <ng-container>
                            <div class="csvPolitic form-group dragg">
                                <p class="textP1">{{ "GENERAL.keyPriv" | translate }} (.key)</p>
                                <div class="form-group dragAndDrop text-center" style="width: 100%">
                                    <input #fileInputUnicKey (change)="updateKey($event)" required name="fileInputUnicKey" class="custom-file-input form-control-file text-primary font-weight-bold" type="file" accept=".key" placeholder="{{ 'GENERAL.keyPriv' | translate }}..." id="fileInputUnicKey">
                                    <span id="span-click" class="custom-file-control span-click" *ngIf="fileNameChangeKey === ''">
                                        <div class="row justify-content-center">
                                            <div class="col-12">
                                                <p class="h4">
                                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-">
                                                <p>Arrastra o da click para subir tu Llave privada</p>
                                            </div>
                                        </div>
                                    </span>
                                    <span id="span-click" class="custom-file-control span-click" *ngIf="fileNameChangeKey !== ''">
                                        <div class="row justify-content-center">
                                            <div class="col-12">
                                                <p class="h4 archivoSubido">
                                                    <i class="far fa-check-circle"></i>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-">
                                                <p>{{ fileNameChangeKey }}</p>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="justify-content-center">
                        <p style="margin: 0 auto;">{{ "GENERAL.cetificatePassw" | translate }}</p>
                        <div>
                            <show-hide-password size="sm" icon="fontawesome">
                                <input [(ngModel)]="SAT.passwordCertificate" required name="cetificatePassw" class="form-control password" type="password" placeholder="{{ 'GENERAL.cetificatePassw' | translate }}..." id="cetificatePassw">
                            </show-hide-password>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button (click)="cancelFiles()" data-bs-dismiss="offcanvas" class="m-t-15 btn btnCancel waves-effect waves-light bot">{{
                                "GENERAL.cancel" | translate }}</button>
                        </div>
                        <div class="col-6">
                            <button type="submit" class="m-t-15 btn btnSave waves-effect waves-light m-r-10 bot">{{ "GENERAL.save"
                                | translate }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight2" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <button type="button" class="btnArrow but" data-bs-dismiss="offcanvas">
            <span><i class="fa fa-arrow-left"></i></span>
        </button>
        <div class="row">
            <div class="col-12">
                <h5 class="ps-3 mt-2" id="offcanvasRightLabel">eFirma</h5>
                <h6 class="ms-3 mt-1 card-subtitle sub"> Cargar aquí los certificados de la fiel para ingresar al portal del sat y descargar comprobantes electrónicos. </h6>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class="col-auto px-0">
            <div class="row gx-0 px-3">
                <form (ngSubmit)="uploadEF()" enctype="multipart/form-data" method="POST">
                    <div class="col- d-flex justify-content-center">
                        <ng-container>
                            <div class="csvPolitic form-group dragg">
                                <p class="textP1">{{ "GENERAL.certificate" | translate }} (.cer)</p>
                                <div class="form-group dragAndDrop text-center" style="width: 100%">
                                    <input #fileInputCerUnicCSD2 (change)="updateCer2($event)" required name="fileInputCerUnicCSD2" class="custom-file-input form-control-file text-primary font-weight-bold" type="file" accept=".cer" placeholder="{{ 'GENERAL.certificate' | translate }}..." id="fileInputCerUnicCSD2">
                                    <span id="span-click" class="custom-file-control span-click" *ngIf="fileNameChangeCerCSD2 === ''">
                                        <div class="row justify-content-center">
                                            <div class="col-12">
                                                <p class="h4">
                                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-">
                                                <p>Arrastra o da click para subir tu Certificado</p>
                                            </div>
                                        </div>
                                    </span>
                                    <span id="span-click" class="custom-file-control span-click" *ngIf="fileNameChangeCerCSD2 !== ''">
                                        <div class="row justify-content-center">
                                            <div class="col-12">
                                                <p class="h4 archivoSubido">
                                                    <i class="far fa-check-circle"></i>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-">
                                                <p>{{ fileNameChangeCerCSD2 }}</p>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col- d-flex justify-content-center">
                        <ng-container>
                            <div class="csvPolitic form-group dragg">
                                <p class="textP1">{{ "GENERAL.keyPriv" | translate }} (.key)</p>
                                <div class="form-group dragAndDrop text-center" style="width: 100%">
                                    <input #fileInputUnicKey2 (change)="updateKey2($event)" required name="fileInputUnicKey2" class="custom-file-input form-control-file text-primary font-weight-bold" type="file" accept=".key" placeholder="{{ 'GENERAL.keyPriv' | translate }}..." id="fileInputUnicKey2">
                                    <span id="span-click" class="custom-file-control span-click" *ngIf="fileNameChangeKey2 === ''">
                                        <div class="row justify-content-center">
                                            <div class="col-12">
                                                <p class="h4">
                                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-">
                                                <p>Arrastra o da click para subir tu Llave privada</p>
                                            </div>
                                        </div>
                                    </span>
                                    <span id="span-click" class="custom-file-control span-click" *ngIf="fileNameChangeKey2 !== ''">
                                        <div class="row justify-content-center">
                                            <div class="col-12">
                                                <p class="h4 archivoSubido">
                                                    <i class="far fa-check-circle"></i>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row justify-content-center">
                                            <div class="col-">
                                                <p>{{ fileNameChangeKey2 }}</p>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="justify-content-center">
                        <p style="margin: 0 auto;">{{ "GENERAL.passEfirma" | translate }}</p>
                        <div>
                            <show-hide-password size="sm" icon="fontawesome">
                                <input [(ngModel)]="SATNEW.Password" required name="cetificatePasswF" class="form-control password" type="password" placeholder="{{ 'GENERAL.passEfirma' | translate }}..." id="cetificatePasswF">
                            </show-hide-password>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button (click)="cancelSAT()" data-bs-dismiss="offcanvas" class="m-t-15 btn btnCancel waves-effect waves-light bot">{{ "GENERAL.cancel" | translate
                            }}</button>
                        </div>
                        <div class="col-6">
                            <button type="submit" class="m-t-15 btn btnSave waves-effect waves-light m-r-10 bot">{{ "GENERAL.save"
                                | translate }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- <div class="card dataCompany ms-3 me-4">
    <div class="card-header bg-transparent border-0">
        <h4 class="card-title" style="margin-top: 20px;">
            <b>{{ "GENERAL.company" | translate }}:</b> {{companieSelected.Name}}
        </h4>

        <h5 class="card-title" style="margin-top: 20px;">{{ "GENERAL.fiscalFiles" | translate }} CSD</h5>
        <h6 class="card-subtitle"> {{ "COMPANIES.headerFiscalFiles" | translate }} </h6>
        <div *ngIf="isEditFiles">
            <form (ngSubmit)="upload()" enctype="multipart/form-data" method="POST">
                <div class="m-t-15 row">
                    <label for="example-text-input" class="col-md-2 col-form-label">{{
                        "GENERAL.certificate" | translate }} (.cer)</label>
                    <div class="col-md-10">
                        <input #fileInput required name="rfc" class="form-control" type="file" accept=".cer" placeholder="{{ 'GENERAL.certificate' | translate }}..." id="certificate">
                    </div>
                </div>
                <div class="m-t-15 row">
                    <label for="example-text-input" class="col-md-2 col-form-label">{{
                        "GENERAL.keyPriv" | translate }} (.key)</label>
                    <div class="col-md-10">
                        <input #fileInput2 required name="rfc" class="form-control" type="file" accept=".key" placeholder="{{ 'GENERAL.keyPriv' | translate }}..." id="keyPriv">
                    </div>
                </div>
                <div class="m-t-15 row">
                    <label for="example-text-input" class="col-md-2 col-form-label">{{
                        "GENERAL.cetificatePassw" | translate }}</label>
                    <div class="col-md-10">
                        <show-hide-password size="sm" icon="fontawesome">
                            <input [(ngModel)]="SAT.passwordCertificate" required name="rfc" class="form-control password" type="password" placeholder="{{ 'GENERAL.cetificatePassw' | translate }}..." id="cetificatePassw">
                        </show-hide-password>
                    </div>
                </div>
                <button type="submit" class="m-t-15 btn btnSave waves-effect waves-light m-r-10" style="float: right; width: 88px; margin-left: 10px;">{{ "GENERAL.save" |
                    translate }}</button>
                <button (click)="cancelFiles()" class="m-t-15 btn btnCancel waves-effect waves-light" style="float: right; width: 88px;">{{ "GENERAL.cancel" | translate
                    }}</button>
            </form>
        </div>

        <div *ngIf="!isEditFiles">
            <div class="m-t-25 row">
                <label for="example-text-input" class="col-md-2 col-form-label">{{
                    "GENERAL.certificate" | translate }} (.cer)</label>
                <div class="col-md-10">
                    <label for="example-text-input" class="col-md-10 col-form-label">{{infoFilesSAT.Certificate_Path ==
                        ''? ("GENERAL.undefined" | translate) : infoFilesSAT.Certificate_Path}}</label>
                </div>
            </div>
            <div class="m-t-25 row">
                <label for="example-text-input" class="col-md-2 col-form-label">{{
                    "GENERAL.keyPriv" | translate }}
                    (.key)</label>
                <div class="col-md-10">
                    <label for="example-text-input" class="col-md-10 col-form-label">{{infoFilesSAT.Key_Path == ''?
                        ("GENERAL.undefined" | translate) : infoFilesSAT.Key_Path}}</label>
                </div>
            </div>
            <div class="m-t-25 row">
                <label for="example-text-input" class="col-md-2 col-form-label">{{ "GENERAL.validfrom" | translate
                    }}</label>
                <div class="col-md-10">
                    <label for="example-text-input" class="col-md-10 col-form-label">{{companieSelected.CSD_Valid_From
                        == undefined ? ("GENERAL.undefined" | translate) : companieSelected.CSD_Valid_From|
                        date:'medium'}}</label>
                </div>
                <label for="example-text-input" class="col-md-2 col-form-label">{{ "GENERAL.validuntil" | translate
                    }}</label>
                <div class="col-md-10">
                    <label for="example-text-input" class="col-md-10 col-form-label">{{companieSelected.CSD_Valid_Until
                        == undefined ? ("GENERAL.undefined" | translate) : companieSelected.CSD_Valid_Until|
                        date:'medium'}}</label>
                </div>
            </div>
            <button (click)="clickFiles()" type="button" class="m-t-15 btn btnSave waves-effect waves-light m-r-10" style="float: right; width: 95px; margin-left: 10px;">{{ "GENERAL.update" |
                translate }}</button>

        </div>
        <div class="form" style="margin-top: 90px;">
            <h5 class="card-title" style="margin-top: 20px;">eFirma</h5>
            <div *ngIf="isEditSAT">
                <form (ngSubmit)="uploadNew()" enctype="multipart/form-data" method="POST">
                    <div class="m-t-15 row">
                        <label for="example-text-input" class="col-md-2 col-form-label">{{
                            "GENERAL.certificate" | translate }} (.cer)</label>
                        <div class="col-md-10">
                            <input #fileInput3 required name="certificateF" class="form-control" type="file" accept=".cer" placeholder="{{ 'GENERAL.certificate' | translate }}..." id="certificateF">
                        </div>
                    </div>
                    <div class="m-t-15 row">
                        <label for="example-text-input" class="col-md-2 col-form-label">{{
                            "GENERAL.keyPriv" | translate }} (.key)</label>
                        <div class="col-md-10">
                            <input #fileInput4 required name="keyPrivF" class="form-control" type="file" accept=".key" placeholder="{{ 'GENERAL.keyPriv' | translate }}..." id="keyPrivF">
                        </div>
                    </div>
                    <div class="m-t-15 row">
                        <label for="example-text-input" class="col-md-2 col-form-label">{{
                            "GENERAL.passEfirma" | translate }}</label>
                        <div class="col-md-10">
                            <show-hide-password size="sm" icon="fontawesome">
                                <input [(ngModel)]="SATNEW.Password" required name="cetificatePasswF" class="form-control password" type="password" placeholder="{{ 'GENERAL.passEfirma' | translate }}..." id="cetificatePasswF">
                            </show-hide-password>
                        </div>
                    </div>
                    <button type="submit" class="m-t-15 btn btnSave waves-effect waves-light m-r-10" style="float: right; width: 88px; margin-left: 10px;">{{ "GENERAL.save"
                        | translate }}</button>
                    <button (click)="cancelSAT()" class="m-t-15 btn btnCancel waves-effect waves-light" style="float: right; width: 88px;">{{ "GENERAL.cancel" | translate
                        }}</button>

                </form>

            </div>

            <div *ngIf="!isEditSAT">


                <div class="m-t-25 row">
                    <label for="example-text-input" class="col-md-2 col-form-label">{{
                        "GENERAL.certificate" | translate }} (.cer)</label>
                    <div class="col-md-10">
                        <label for="example-text-input" class="col-md-10 col-form-label">{{infoFilesSATF.EFirma_Cer ==
                            ''?
                            ("GENERAL.undefined" | translate) : infoFilesSATF.EFirma_Cer}}</label>
                    </div>
                </div>

                <div class="m-t-25 row">
                    <label for="example-text-input" class="col-md-2 col-form-label">{{
                        "GENERAL.keyPriv" | translate }} (.key)</label>
                    <div class="col-md-10">
                        <label for="example-text-input" class="col-md-10 col-form-label">{{infoFilesSATF.EFirma_Key ==
                            ''?
                            ("GENERAL.undefined" | translate) : infoFilesSATF.EFirma_Key}}</label>
                    </div>
                </div>
                <div class="m-t-25 row">
                    <label for="example-text-input" class="col-md-2 col-form-label">{{ "GENERAL.validfrom" | translate
                        }}</label>
                    <div class="col-md-10">
                        <label for="example-text-input" class="col-md-10 col-form-label">{{companieSelected.Efirma_Valid_From ==
                            undefined ?
                            ("GENERAL.undefined" | translate) : companieSelected.Efirma_Valid_From |
                            date:'medium'}}</label>
                    </div>
                    <label for="example-text-input" class="col-md-2 col-form-label">{{ "GENERAL.validuntil" | translate
                        }}</label>
                    <div class="col-md-10">
                        <label for="example-text-input" class="col-md-10 col-form-label">{{companieSelected.Efirma_Valid_Until
                            == undefined ?
                            ("GENERAL.undefined" | translate) : companieSelected.Efirma_Valid_Until |
                            date:'medium'}}</label>
                    </div>
                </div>
                <button (click)="clickSAT()" type="button" class="m-t-15 btn btnSave waves-effect waves-light m-r-10" style="float: right; width: 95px; margin-left: 10px;">{{ "GENERAL.update" |
                    translate }}</button>

            </div>
        </div>
    </div>
</div> -->