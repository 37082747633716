import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NewDimension } from 'src/app/data/models/company-list.model';
import { CustomerList } from 'src/app/data/models/customer-list.model';
import { ServicesService } from 'src/app/data/services/services.service';
import Swal from 'sweetalert2';
import { Customer } from '../../customer/customer';
import { CompanyEdit, Company_SMTP, Dimension, PAC, TaxRegime } from '../companies';

@Component({
  selector: 'app-cost-center-company',
  templateUrl: './cost-center-company.component.html',
  styleUrls: ['./cost-center-company.component.css']
})
export class CostCenterCompanyComponent implements OnInit {
  @Input() idCompany;
  customerLst = new CustomerList();

  public ERP_Projects: any[] = [];
  public Project: any = {};
  public All_Creditors = false;
  public CostCenters: any;
  public CostCentersSelect: any = {};
  public UsersCostCenter: any;
  public showUsersCostCenters = false;
  public listCustomer: Customer[] = [];
  public companieSelected = new CompanyEdit();
  public UserInfo: any;
  public Dimensions: Dimension[] = [];
  public dimension: NewDimension = { Id_Company: 0, Dimension: 0, Description: '', ERP_Code: '' };
  canva = 0;
  isAddCenterCost = true;

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
    private services: ServicesService
  ) {

    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }

  }

  agregar(){
    this.canva = 1;
  }

  acreedores(){
    this.canva = 2;
  }

  ngOnInit(): void {
    this.Catalog_Dimensions();
    this.Company_Center_Cost_List(this.companieSelected.Id_Company);
  }

  User_Info() {
    this.services.User_Info().subscribe(resp => {
      if (resp.result){
        this.UserInfo = resp.data[0];
      }
    });
  }

  LoadAllCustomers() {
    this.spinner.show();
    this.services.Customer_List(this.customerLst).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.listCustomer = resp.data;
      }

    });
  }

  Company_Project_List(Id_Company) {
    this.spinner.show();
    this.services.Company_Project_List(Id_Company).subscribe(resp => {
      this.spinner.hide();
      if (resp.result){
        this.ERP_Projects = resp.data;
      }
    });

  }

  deleteCenterCost(Id_CostCentre) {
    Swal.fire({
      title: '¿Estás seguro de querer eliminar este Centro de Costos?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#746AB0',
      cancelButtonColor: '#E83845',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.services.Company_Center_Cost_Delete(Id_CostCentre).subscribe(resp => {
          this.spinner.hide();
          if (resp.result) {
            this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));
            this.Company_Center_Cost_List(this.companieSelected.Id_Company);
          }
          this.UsersCostCenter = [];
          this.showUsersCostCenters = false;
        });
      }
    });
  }


  Catalog_Dimensions() {
    this.services.Catalog_Dimensions().subscribe(resp => {
      this.Dimensions = resp.data;
    });
  }

  Company_Center_Cost_New() {
      this.isAddCenterCost = true;
      this.showUsersCostCenters = false;
      if (this.dimension.Dimension === 0) {
        this.toastr.warning(this.translate.instant('GENERAL.selectordimension'));
        return;
      }
      this.dimension.Id_Company = this.idCompany;
      this.spinner.show();
      // tslint:disable-next-line: max-line-length
      this.services.Company_Center_Cost_New(this.dimension.Id_Company, this.dimension.Dimension, this.dimension.Description, this.dimension.ERP_Code).subscribe(resp => {
        this.spinner.hide();
        if (resp.result) {
          this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));
          this.Company_Center_Cost_List(this.companieSelected.Id_Company);
          this.dimension = { Id_Company: 0, Dimension: 0, Description: '', ERP_Code: '' };
          this.UsersCostCenter = [];
          this.showUsersCostCenters = false;
        }
      });
  }

  Company_Center_Cost_List(Id_Company) {
    this.spinner.show();
    this.services.Company_Center_Cost_List(this.idCompany).subscribe(resp => {
      this.spinner.hide();
      if (resp.result){
        this.CostCenters = resp.data;
      }
    });
  }

  UsersCostCenters(Id_CostCentre, All_Creditors: string) {
    this.canva = 2;
    this.CostCentersSelect.Id_CostCentre = Id_CostCentre;
    if (this.showUsersCostCenters) {
       this.showUsersCostCenters = false;
       return;
    }
    this.showUsersCostCenters = true;
    this.spinner.show();
    this.services.Company_Center_Cost_User_List(Id_CostCentre).subscribe(resp => {
      this.spinner.hide();

      if (resp.result) {
        if (All_Creditors === 'V')
          this.All_Creditors = true;
        else
          this.All_Creditors = false;
        this.UsersCostCenter = resp.data;
      }
    });

  }

  activateCreditor(checked: boolean, Id_User, Id_UserCostCenter) {
    this.spinner.show();
    // tslint:disable-next-line: max-line-length
    this.services.Company_Center_Cost_Add_User(this.CostCentersSelect.Id_CostCentre, Id_User, checked, Id_UserCostCenter).subscribe(resp => {
      this.spinner.hide();
      if (resp.result){
        this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));
      }
    });
  }

  activateAllCreditors(checekd: boolean) {
    this.All_Creditors = checekd;
    this.spinner.show();
    this.services.Company_Center_Cost_AllCreditors(this.CostCentersSelect.Id_CostCentre, checekd).subscribe(resp => {
      this.spinner.hide();

      if (resp.result){
        this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));
      }
    });
  }

  deleteProyect(Id_ERP_Project) {
    this.services.Company_Project_Delete(Id_ERP_Project).subscribe(resp => {
      if (resp.result) {
        this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));
        this.Company_Project_List(this.companieSelected.Id_Company);
      }
    });
  }



  Company_Project_New() {

    this.services.Company_Project_New(this.Project.Description, this.Project.ERP_Code, this.companieSelected.Id_Company).subscribe(resp => {
      if (resp.result) {
        this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));
        this.Company_Project_List(this.companieSelected.Id_Company);
      }
    });

    this.Project.Description = '';
    this.Project.ERP_Code = '';
  }

}
