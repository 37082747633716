<!-- Comienza encabezado -->
<ng-container>
    <app-title-payment [titleP]="Title"></app-title-payment>
</ng-container>

<ng-container *ngIf="isReturn">
    <div class="row ">
        <div class="col-auto position-absolute pos mt-2 positionTop">
            <button (click)="returnToList()" class="btnBackTop">
                <i class="fas fa-arrow-left"></i>
            </button>
        </div>
    </div>
</ng-container>

<div class="row justify-content-between mt-2">
    <div class="col-auto">
        <div class="row">
            <div class="col-auto">
                <input name="search" [(ngModel)]="userLst.SearchKey" type="text" (input)="onSearchChange()" (click)="returnToView()" class="input-search form-control" placeholder="{{ 'GENERAL.searchby' | translate }}..." />
                <a class="ico-btn-search">
                    <i class="ti-search"></i>
                </a>
            </div>
        </div>
    </div>
    <div class="col-auto mt-2 mt-sm-0">
        <div class="row gx-0">
            <ng-container *ngIf="!isReturn">
                <div class="col-auto mt-2 mt-sm-0">
                    <button *ngIf="isDown" (click)="returnToView();downloadCSV();" class="tooltipp btnSta mt-1" placement="top" ngbTooltip="Descargar .CSV" style="margin-left: 10px;">
                        <i class="fas fa-file-download fa-lg"></i>
                    </button>
                    <button (click)="NewUser()" class="tooltipp btnSta mt-1" placement="top" ngbTooltip="Nuevo" style="margin-left: 5px;">
                        <i class="fas fa-plus-square fa-lg"></i>
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<!-- Termina encabezado -->
<!--Canvas-->
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <button type="button" class="btnArrow" data-bs-dismiss="offcanvas">
            <span><i class="fa fa-arrow-left"></i></span>
        </button>
        <div class="col-auto">
            <div>
                <app-filters-user (filSelU)="filterSel($event)"></app-filters-user>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class="col-auto px-0">
            <ng-container *ngIf="filterType === 'C'">
                <app-column-company (companySel)="filterByCompany($event)"></app-column-company>
            </ng-container>
            <ng-container *ngIf="filterType === 'O'">
                <app-organization (customerSel)="filterByCustomer($event)"></app-organization>
            </ng-container>
            <ng-container *ngIf="filterType === 'E'">
                <app-column-status (statusSel)="filterByStatus($event)"></app-column-status>
            </ng-container>
            <div class="d-grid gap-2 mt-3">
                <button (click)="filterData()" type="button" data-bs-dismiss="offcanvas" class="btn btn-success btnFilter">Filtrar</button>
            </div>
        </div>
    </div>
</div>
<!--termina canvas-->
<!-- Comienza contenido -->

<ng-container *ngIf="isResults">
    <div class="row mt-2 pb-2">
        <div class="col-md-12 col-lg-12 col-sm-12" style="margin-left: auto; margin-right: auto;">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="table-responsive tablePayments" style="min-height: 250px;">
                            <table sortable-table (sorted)="onSorted1($event)" class="table table-striped">
                                <thead>
                                    <tr class="purple">
                                        <th style="width: 50px;">
                                            <button *ngIf="isRest || userLst.Id_Company != 0 || userLst.Id_Customer != 0 || userLst.Status != '' || userLst.Pagination != 10" class="tooltipp btnRes" (click)="resetTable()" placement="top" ngbTooltip="Restablecer Filtros"><i
                                                    class="fas fa-trash-restore-alt p-1"></i></button>
                                        </th>
                                        <th class="th-sort" sortable-table="padre" sortable-column="nam" scope="col">
                                            <div class="dropdown ps-1">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown">
                                                    <span *ngIf="AZnam"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="ZAnam"><i
                                                            class="fas fa-sort-alpha-down-alt"></i></span>
                                                    Nombre
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">De la Z a la A</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="padre" sortable-column="ema" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span *ngIf="AZema"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="ZAema"><i
                                                            class="fas fa-sort-alpha-down-alt"></i></span>
                                                    Correo
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">De la Z a la A</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="padre" sortable-column="cus" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span *ngIf="AZcus"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="ZAcus"><i
                                                            class="fas fa-sort-alpha-down-alt"></i></span>
                                                    <span *ngIf="userLst.Id_Customer != 0" class="icon"><i
                                                            class="fas fa-filter"></i></span>
                                                    Organización
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">De la Z a la A</a></li>
                                                    <li><a (click)="filterChannel('O')" class="dropdown-item" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Filtrar organización</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="padre" sortable-column="com" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span *ngIf="AZcom"><i class="fas fa-sort-alpha-up"></i></span>
                                                    <span *ngIf="ZAcom"><i
                                                            class="fas fa-sort-alpha-down-alt"></i></span>
                                                    <span *ngIf="userLst.Id_Company != 0" class="icon"><i
                                                            class="fas fa-filter"></i></span>
                                                    Compañía
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="onSortedA($event)" class="dropdown-item" href="javascript:void(0);">De la A a la Z</a></li>
                                                    <li><a (click)="onSortedD($event)" class="dropdown-item" href="javascript:void(0);">De la Z a la A</a></li>
                                                    <li><a (click)="filterChannel('C')" class="dropdown-item" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Filtrar compañía</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                        <th sortable-table="padre" scope="col">
                                            <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle btnDropdown" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span *ngIf="userLst.Status != ''" class="icon"><i
                                                            class="fas fa-filter"></i></span>
                                                    Estatus
                                                </button>
                                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li><a (click)="filterChannel('E')" class="dropdown-item" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Filtrar estatus</a></li>
                                                </ul>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let user of Users">
                                        <td style="width: 50px;">
                                            <ng-container>
                                                <button (click)="SelectUser(user)" class="btnEdit ps-3"><i
                                                        class="fas fa-file-invoice"></i></button>
                                            </ng-container>
                                        </td>
                                        <td scope="row">{{user.Name}}</td>
                                        <td>{{user.Email}}</td>
                                        <td>{{user.CustomerName}}</td>
                                        <td>{{user.CompanyName || 'Sin Compañía'}}</td>
                                        <td style="width: 150px;" class="center">
                                            <ng-container>
                                                <span *ngIf="user.Status === 'A'" class="badge badge-pill badge-success mb-1">Activo</span>
                                                <span *ngIf="user.Status === 'I'" class="badge badge-pill badge-secondary mb-1">Inactivo</span>
                                                <span *ngIf="user.Status === 'R'" class="badge badge-pill badge-warning mb-1">Reiniciar
                                                    Contraseña</span>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- <div class="row gx-1  justify-content-between">
                    <div class="col-auto ps-5 ">
                        <select class="select-css" id="dropAmounts" aria-label="Default select example" (change)="changeAmountView($event)" [(ngModel)]="userLst.Pagination" name="dropAmounts">
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <div class="col-auto pe-5 mt-2 mt-sm-0">
                        <ngb-pagination [collectionSize]="collectionSize" [(page)]="userLst.Page" [maxSize]="4" (pageChange)="changePage($event)" [rotate]="true"></ngb-pagination>
                    </div>
                </div> -->
                <app-pagination-global [callbackFunction]="GetUsers" [actualPagination]="userLst.Pagination" [collectionDinamic]="collectionSize" (changePagination)="changeAmountView($event)" (changePageEmi)="changePage($event)"></app-pagination-global>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isView">
    <div class="col-md-12 col-lg-12 col-sm-12 mt-3" style="margin-left: auto; margin-right: auto;">
        <app-my-tab-user (goBack)="viewDetailUser()" [id_User]="idUser"></app-my-tab-user>
    </div>
</ng-container>

<app-back-to-top></app-back-to-top>
<!-- Termina contenido -->
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white;">Loading...</p>
</ngx-spinner>