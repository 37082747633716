import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent {

  constructor(
    private route: ActivatedRoute,
    private services: ServicesService,
    private router: Router,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {

      if (params.has("ut") && params.has("amb")) {
        let userToken = params.get("ut");
        let enviroment = parseInt(params.get("amb"));
        let appToken = environment.AdminToken;

        localStorage.setItem("amb", enviroment.toString());

        this.services.Get_JWT(userToken, appToken).subscribe(result => {
          if (result.result) {
            localStorage.setItem("loginToken", result.message);
            this.router.navigateByUrl('/store');
          }
          else {
            this.toastr.error(result.message);
            this.router.navigateByUrl('/login/' + enviroment + '/' + appToken);
          }
        },
          (error) => {
            this.router.navigateByUrl('/login/' + enviroment + '/' + appToken);
          }
        );
      }

    });

  }

}
