import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit {

  @Output() filterSel = new EventEmitter<string>();

  ltsFilterGet: any [];
  typeFilte = '';
  //isAccess = false;

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    if(localStorage.getItem('filterSto') !== null) {
      this.typeFilte = localStorage.getItem('filterSto');
    }

    this.typeFilter();
  }

  selectFilter(e) {
    this.typeFilte = e.target.value;
    //localStorage.setItem('filterSto', String(this.typeFilte));
    this.filterSel.emit(this.typeFilte);
  }


  //Services
  typeFilter() {
    this.services
      .post({
        entidad: 'Payments',
        funcion: 10,
        data: {
          KeyAccess: "MPX_CMBCHNL"
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.ltsFilterGet = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }


}
