<div class="row me-2">
    <div class="text-end mt-1 mb-1 ">
        <button *ngIf="viewMenu && !newMenu && !isNewEdit" (click)="newEditMenu()" class="btnEd">
            <i class="fas fa-edit"></i>
            {{ "GENERAL.edit" | translate }}
        </button>
    </div>
</div>

<form ngNativeValidate #f="ngForm" (ngSubmit)="onSubmit()" id="formMenu" class="form">
    <div class="card ms-3 me-3">
        <div class="card-body">
            <h2 class="card-title mb-0">Detalle del Menú</h2>
            <hr style="margin-top: 0;">
            <div class="row ps-3">
                <div class="col">
                    <div class="row">
                        <div class="col-auto ord mt-1">
                            <b class="textP" for="example-text-input">Nombre del Menú</b>
                        </div>
                        <div class="col-auto">
                            <label *ngIf="viewMenu && !newMenu && !isNewEdit" class="col-form-label" for="example-text-input">{{title}}</label>
                            <span *ngIf="newMenu || isNewEdit">
                                <input [(ngModel)]="title" (change)="newMenuTitle($event.target.value)" class="form-control inputPer mb-p"
                                pattern="[A-Za-z0-9áéíóúüñÑ_., -]+" name="access"  type="text" style="height:30px;"
                                placeholder="Nombre del menú..." id="access" required>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto ord mt-1">
                            <b class="textP" for="example-text-input">Aplicación</b>
                        </div>
                        <div class="col-auto">
                            <label *ngIf="viewMenu && !newMenu && !isNewEdit" class="col-form-label" for="example-text-input">{{menuSelected.ApplicationName}}</label>
                            <div *ngIf="newMenu || isNewEdit" class="wi muul inputPer mb-p">
                                <app-application-multiselect (idAppC)="onSelectApp($event)" [storage]="notStorage" [idApp]="menuSelected.Id_Application" [nameApp]="menuSelected.ApplicationName"
                                    (noSave)="onDeselectApp()">
                                </app-application-multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto ord mt-1">
                            <b class="textP" for="example-text-input">Tag</b>
                        </div>
                        <div class="col-auto">
                            <label *ngIf="viewMenu && !newMenu && !isNewEdit" class="col-form-label ellipsis" for="example-text-input">{{menuSelected.TagDescription}}</label>
                            <div *ngIf="newMenu || isNewEdit" class="wi muul inputPer ok mb-p">
                                <app-tag-multiselect (idTagC)="onSelectTag($event)" [storage]="notStorage" [idTagF]="menuSelected.Id_Tag" [nameTagF]="menuSelected.TagDescription"
                                (noSave)="onDeselectTag()">
                                </app-tag-multiselect>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col-auto ord mt-1">
                            <b class="textP" for="example-text-input">Acción del Menú</b>
                        </div>
                        <div class="col-auto">
                            <label *ngIf="viewMenu && !newMenu && !isNewEdit" class="col-form-label ellipsis" for="example-text-input">{{menuSelected.Action}}</label>
                            <span *ngIf="newMenu || isNewEdit">
                                <input [(ngModel)]="menuSelected.Action" class="form-control inputPer mb-p"
                                    name="action"  type="text"
                                    placeholder="Acción del Menú..." id="action" required>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto ord mt-1">
                            <b class="textP" for="example-text-input">Tipo de Acción</b>
                        </div>
                        <div class="col-auto">
                            <label *ngIf="viewMenu && !newMenu && !isNewEdit" class="col-form-label" for="action">{{menuSelected.ActionTypeDescription}}</label>
                            <div *ngIf="newMenu || isNewEdit" class="muul">
                                <select (change)="changeAction($event.target.value)"
                                    class="custom-select inputPer mb-p" id="action" name="action" required>
                                    <option value="">Selecciona la Acción</option>
                                    <option *ngFor="let action of listActions" value="{{action.Id}}"
                                        [selected]="menuSelected.ActionType == action.Id">
                                        {{action.Value}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto ord mt-1">
                            <b class="textP" for="example-text-input">Módulo</b>
                        </div>
                        <div class="col-auto">
                            <label *ngIf="viewMenu && !newMenu && !isNewEdit" class="col-form-label" for="module">{{menuSelected.ModuleDescription || 'Sin Módulo'}}</label>
                            <div *ngIf="newMenu || isNewEdit" class="muul">
                                <select (change)="changeModule($event.target.value)"
                                    class="custom-select inputPer mb-p" id="module" name="module">
                                    <option value="">Selecciona el Módulo</option>
                                    <option *ngFor="let module of listModules" value="{{module.Id}}"
                                        [selected]="menuSelected.Id_Module == module.Id">
                                        {{module.Value}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div *ngIf="!newMenu" class="row">
                        <div class="col-auto ord mt-1">
                            <b class="textP" for="example-text-input">{{ "GENERAL.activate" | translate }}/{{
                                "GENERAL.desactivate" | translate }}</b>
                        </div>
                        <div class="col-auto">
                            <label class="switch hideShow mb-1" [ngClass]="{'fade': !isNewEdit}">
                                <input #statusInt [checked]="menuSelected.Active == true ? true:false"
                                    (change)="changeStatus($event.target.checked)"
                                    name="statusInt" type="checkbox" id="statusInt" [disabled]="!isNewEdit" />
                                <span class="slider round-in"></span>
                            </label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto ord mt-1">
                            <b class="textP" for="example-text-input">Idx</b>
                        </div>
                        <div class="col-auto">
                            <label *ngIf="viewMenu && !newMenu && !isNewEdit" class="col-form-label" for="example-text-input">{{menuSelected.Idx}}</label>
                            <span *ngIf="newMenu || isNewEdit">
                                <input [(ngModel)]="menuSelected.Idx" class="form-control inputPer mb-p"
                                    name="idx"  type="number" placeholder="Acción del Menú..." id="idx" 
                                    title="Idx solo numérico." required>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto ord mt-1">
                            <b class="textP" for="example-text-input">Menú Padre</b>
                        </div>
                        <div class="col-auto">
                            <label *ngIf="viewMenu && !newMenu && !isNewEdit" class="col-form-label" for="father">{{menuSelected.Id_MenuFather || 'Sin Menú Padre'}}</label>
                            <div *ngIf="newMenu || isNewEdit" class="mb-p">
                                <select (change)="changeMenuFather($event.target.value)"
                                    class="custom-select inputPer mb-p" id="father" name="father">
                                    <option value="">Selecciona el Menú padre</option>
                                    <option *ngFor="let menu of listMenusByApp" value="{{menu.Id_Menu}}"
                                        [selected]="menuSelected.Id_MenuFather == menu.Id_Menu">
                                        {{menu.Menu}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row ps-3">
                <div class="col-auto">
                    <div class="row">
                        <div class="col-auto ord">
                            <b class="textP" for="example-text-input">Imagen</b>
                        </div>
                        <div class="col-auto">
                            <label *ngIf="viewMenu && !newMenu && !isNewEdit" class="col-form-label" for="example-text-input">{{menuSelected.Image || 'Sin Imagen'}}</label>
                            <span *ngIf="newMenu || isNewEdit">
                                <input [(ngModel)]="menuSelected.Image" class="form-control inputPer1 mb-p"
                                    name="image"  type="text"
                                    placeholder="Imagen del Menú..." id="image">
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto ord mt-1">
                            <b class="textP" for="example-text-input">Configuración</b>
                        </div>
                        <div class="col-auto">
                            <label *ngIf="viewMenu && !newMenu && !isNewEdit" class="col-form-label" for="example-text-input">{{menuSelected.Settings || 'Sin Configuración'}}</label>
                            <span *ngIf="isNewEdit">
                                <textarea [(ngModel)]="menuSelected.Settings || objectTemplate" name="settings" rows="3"
                                (change)="enterSettings($event.target.value)" type="text" placeholder="Configuración del Menú..."
                                id="settings" class="areaCustom"> </textarea>
                            </span>
                            <span *ngIf="newMenu">
                                <textarea [(ngModel)]="objectTemplate" name="settings" rows="3"
                                (change)="enterSettings($event.target.value)" type="text" placeholder="Configuración del Menú..."
                                id="settings" class="areaCustom"> </textarea>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="newMenu || isNewEdit" class="col-">
        <button type="submit" class="btn btnSave waves-effect waves-light m-r-10 mt-2 mr-3 buttonform"
            [disabled]="menuSelected.Id_Application === 0 || menuSelected.Id_Tag === 0">
            {{ "GENERAL.save" | translate }}
        </button>
        <button *ngIf="isNewEdit" (click)="cancelEdit()" class="btn btnCancel waves-effect waves-light m-r-10 mt-2 buttonform">
            Cancelar
        </button>
        <button *ngIf="!isNewEdit" (click)="cancelNewMenu()" class="btn btnCancel waves-effect waves-light m-r-10 mt-2 buttonform">
            Cancelar
        </button>
    </div>
</form>