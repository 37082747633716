<div class="row me-2">
    <div class="text-end mt-1 mb-1 ">
        <button data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightFolio" aria-controls="offcanvasRight"
            class="btnEd" (click)="addFolio()"><i class="fas fa-plus"></i>
            {{ "GENERAL.add" | translate }} Folio</button>
    </div>
</div>

<!-- (click)="addFolio()" -->

<div class="col-md-12 col-lg-12 col-sm-12" style="margin-left: auto; margin-right: auto;">
    <div class="card ">
        <div class="card-body ">
            <h4 class="card-title "><b>{{ "GENERAL.organization" |
                    translate }}:</b> {{customerSelected.Name}}
            </h4>

            <div class="table-responsive">
                <table sortable-table (sorted)="onSorted2($event)" class="table table-striped m-t-30">
                    <thead>
                        <tr>
                            <th sortable-column="Comments" style="cursor: pointer; min-width: 105px;">{{
                                "GENERAL.description"
                                | translate }}</th>
                            <th sortable-column="Application_Name" style="cursor: pointer; min-width: 105px;">{{
                                "GENERAL.application"
                                | translate }}</th>
                            <th sortable-column="Company_Name" style="cursor: pointer; min-width: 105px;">{{
                                "GENERAL.company" |
                                translate }}</th>
                            <th sortable-column="Estatus_Name" style="cursor: pointer; min-width: 105px;">{{
                                "GENERAL.status" |
                                translate }}</th>
                            <th sortable-column="Folios" style="cursor: pointer; min-width: 105px;">
                                Folios</th>
                            <th sortable-column="Registration_Date" style="cursor: pointer; min-width: 105px;">{{
                                "GENERAL.date" |
                                translate }}</th>
                            <th sortable-column="User_Name" style="cursor: pointer; width: 145px;">{{
                                "GENERAL.responsible"
                                | translate }}</th>
                            <th class="text-center">Observaciones</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let folio of listFolio; let i = index">
                            <td>{{folio.Comments}}</td>
                            <td>{{folio.Application_Name}}</td>
                            <td>{{folio.Company_Name}}</td>
                            <td>{{folio.Status_Name}}</td>
                            <td class="tae">{{folio.Folios | number}}</td>
                            <td>{{folio.Registration_Date | date:'mediumDate'}}</td>
                            <td>{{folio.UserName}}</td>
                            <td>{{folio.Description}}</td>
                            <!-- placement="top" ngbTooltip="{{folio.Description}}" -->
                            <td>
                                <i *ngIf="ifViewTrash(folio.Status, folio.Registration_Date)"
                                    (click)="deleteFolio(folio.Id_Customer_Application_Folio)" class="fa fa-trash"
                                    style="font-size: 28px; cursor: pointer;"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>



            <!-- <div *ngIf="!isNewFolio">
    <button (click)="addFolio()" class="btn btn-success waves-effect waves-light m-t-15"
        style="width: 185px; margin-left: 10px;">{{
        "GENERAL.add" | translate }} Folio</button>
</div> -->
        </div>
    </div>
</div>

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRightFolio" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
        <button type="button" class="btnArrow" data-bs-dismiss="offcanvas">
            <span><i class="fa fa-arrow-left"></i></span>
        </button>
        <h5 id="offcanvasRightLabel" class="ml-3"> Agregar Folios</h5>
    </div>
    <div class="offcanvas-body">

        <div class="row">
            <form [formGroup]="form2" ngNativeValidate (submit)="onSubmitFolios()">
                <div class="col-auto">
                    <label for="drop-app" class="col-form-label">{{ "GENERAL.application" | translate }}</label>
                    <select formControlName="Id_Application" [(ngModel)]="CustomerfolioSelected.Id_Application"
                        (change)="changeAppFolio($event.target.value)" name="dropAppLicense"
                        class="custom-select lgInp" id="dropAppLicense">
                        <option value="0" selected="">{{ "GENERAL.selectorapplication" |
                            translate }}...</option>
                        <option *ngFor="let app of listApp" value="{{app.Id_Application}}">
                            {{app.Name}}</option>
                    </select>
                </div>


                <div class="col-auto">
                    <label for="drop-app" class="col-form-label">Folios</label>

                    <select formControlName="Id_Folio_Selected" (change)="changeFolio($event.target.value)"
                        name="dropCurrency" class="custom-select lgInp" id="dropCurrency">
                        <option value="0" selected="">{{ "GENERAL.selectorfolio" |
                            translate }}...</option>
                        <option value="free">{{ "GENERAL.free" | translate }}</option>
                        <option *ngFor="let plan of listPlanFolio" value="{{plan.Id_Application_Plan_Folio}}">
                            {{plan.Description}}
                        </option>
                    </select>
                </div>


                <div class="col-auto">
                    <label for="example-text-input" class="col-form-label">{{"GENERAL.quantity" | translate }}</label>

                    <input formControlName="Folios2" *ngIf="!isFree" style="width: 345px;" name="totalCost" step="1"
                        (change)="setFoliosPackage($event.target.value)" type="number" class="form-control" oninput="validity.valid||(value='');" min="1"
                        placeholder="{{ 'GENERAL.quantity' | translate }}..." id="name">
                    <input formControlName="Folios" *ngIf="isFree" style="width: 345px;" name="totalCost" type="number"
                        (change)="setFoliosPackage($event.target.value)" class="form-control" oninput="validity.valid||(value='');" min="1"
                        placeholder="{{ 'GENERAL.quantity' | translate }}..." id="name">
                </div>



                <div class="col-auto">
                    <label for="example-text-input" class="col-form-label ">Concepto</label>
                    <textarea formControlName="Description" name="dropDescriptio" [required]="isFree" rows="3"
                        (change)="descriptionFolios($event.target.value)" type="text" placeholder="Concepto..."
                        id="Description" class="lgInp"> </textarea>
                </div>



                <button [disabled]="form2.invalid" type="submit" class="btn btnSave ml-3 mb-2 lgInp"
                    style="float: center; " data-bs-dismiss="offcanvas"
                    aria-label="Close">{{
                    "GENERAL.save" | translate }}</button>
                <!-- <button (click)="cancelFolio()" class="btn btn-secondary waves-effect waves-light m-t-15"
                    style="float: right; width: 88px;">{{
                    "GENERAL.cancel" | translate }}</button> -->
                    <div *ngIf="form2.invalid && form2.dirty"  class="ml-2 alert alert-warning ms-2" role="alert">
                        <label style="color:red;"> {{GetErrorMessage(2)}}</label>
                    </div>
              

                    <div *ngIf="isFree" class="ml-2 alert alert-primary d-flex align-items-center" role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                        </svg>
                        <div>
                            ¡La descripción del concepto será visible para el cliente!
                        </div>
                      </div>
            </form>
        </div>

    </div>
</div>