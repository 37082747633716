import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-channel-payment',
  templateUrl: './channel-payment.component.html',
  styleUrls: ['./channel-payment.component.css']
})
export class ChannelPaymentComponent implements OnInit {

  @Output() channelSel = new EventEmitter<string>();

  ltsChannelGet: [];
  selectedItems = [];
  typeChann = '';
  dropdownSettings: IDropdownSettings = {};


  constructor(
    private services: ServicesService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'Id_Channel',
      textField: 'Name',
      searchPlaceholderText: 'Buscar:',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter : true
    };

    if (localStorage.getItem('chaStoId') == null && localStorage.getItem('chaStoNa') == null) {
      this.selectedItems = [];
    }else{
      this.selectedItems = [
        { Id_Channel: +localStorage.getItem('chaStoId'),
          Name : localStorage.getItem('chaStoNa') },
      ];
    }
    
    if (localStorage.getItem('chaStoId') !== null) {
      this.typeChann = localStorage.getItem('chaStoId');
    }
    this.typeChannel();
  }

  onItemSelect(e) {
    this.typeChann = e.Id_Channel;
    localStorage.setItem('chaStoId', String(this.typeChann));
    localStorage.setItem('chaStoNa', String(e.Name));
    this.channelSel.emit(this.typeChann);
  }

  onItemDeselect(e){
    this.selectedItems = [];
    this.typeChann = '';
    localStorage.removeItem('chaStoId');
    localStorage.removeItem('chaStoNa');
    this.channelSel.emit(this.typeChann);
  }
  //services

  typeChannel() {
    this.services
      .post({
        entidad: 'Channel',
        funcion: 7,
        data: {
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.ltsChannelGet = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }
  
}
