import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';
import { Application, User } from '../users';

@Component({
  selector: 'app-profiles-user',
  templateUrl: './profiles-user.component.html',
  styleUrls: ['./profiles-user.component.css']
})
export class ProfilesUserComponent implements OnInit {

  @Input() id_User: Number;

  public UserSelected: User = new User();
  public Applications: Application[] = [];
  public SelectDrop: String = '';
  public ProfilesApp: any[] = [];
  public isNew = false;
  public isView = false;
  public isResults = true;
  listApp : Application [] = [];
  isStatusUser = false;
  statusUser = -1;
  
  data = [];

  constructor(
    private services: ServicesService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) { 
    translate.get('GENERAL.selectaoption').subscribe((res: string) => {
      this.SelectDrop = res;
    });
  }

  ngOnInit(): void {
    if (this.id_User === 0) {
      this.isNew = true;
      this.isView = true;
      this.isResults = false;
    } else {
      this.User_By_Id();
      this.Applications_List();
    }
  }

  clearStatus() {
    this.statusUser = -1;
    this.isStatusUser = false;
    this.Applications_List();
  }

  updateStatus(e) {
    if (!e.target.checked) {
      this.statusUser = 0;
    } else {
      this.statusUser = 1;
    }
    this.Applications_List();
  }

  Applications_List() {
    this.services
    .post({
      entidad: 'Application',
      funcion: 9,
      data: {
        Id_User: this.id_User,
        IsActive: this.statusUser
      },
    })
    .subscribe(
      (result) => {
        if (result.code === 1) {
          this.listApp = result.data;
          this.data = Object.values(this.listApp);
        }
      },
      (error) => {
        console.log('Error', error);
      }
    );
  }

  SelectUser(user: User) {
    this.UserSelected = user;
  }

  User_By_Id() {
    this.spinner.show();
    this.services.User_By_Id(this.id_User).subscribe(resp => {
      this.spinner.hide();
      if (resp.result) {
        this.UserSelected = resp.data[0];
        this.SelectUser(this.UserSelected);
      }
    });
  }

  UserProfile_Change(Id_User: Number, Id_Profile: Number, Activate: boolean) {
    this.spinner.show();
    this.services.UserProfile_Change(Id_User, Id_Profile, Activate).subscribe(resp => {
      this.spinner.hide();

      if (resp.result) {
        this.toastr.success(this.translate.instant('GENERAL.modified') + ' ' + this.translate.instant('GENERAL.correctly'));
      }
    });
  }

  ChangeStatusProfile(Id_Profile: number, activated: boolean) {
    this.UserProfile_Change(this.UserSelected.Id_User, Id_Profile, activated);
  }

  GetProfileList( appName: String) {
    this.SelectDrop = appName;
    this.spinner.show();
    this.services.UserProfile_List(this.UserSelected.Id_User).subscribe(resp => {
      this.spinner.hide();

      if (resp.result) {
        this.ProfilesApp = resp.data;
      }
    });
    
  }

  compare(a: Number | String | Date | String[], b: Number | String | Date | String[] , isAsc: Boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onSorted(sort) {

    let data = this.listApp.slice();
    const rute = "Description";

    let sortedData = data.sort((a, b) => {
      const isAsc = sort.sortDirection === 'asc';
      switch (sort.sortColumn) {
        case 'Description': return this.compare(a.Profiles['Description'], b.Profiles['Description'], isAsc);
        default: return 0;
      }
    });

    this.listApp = sortedData;
  }

}
