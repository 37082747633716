import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ToastrService } from 'ngx-toastr';
import { ServicesService } from 'src/app/data/services/services.service';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styles: [
  ]
})
export class OrganizationComponent implements OnInit {

  @Output() customerSel = new EventEmitter<string>();

  lstCustomerGet: any [];
  selectedItems: any = [];
  typeCust = '';
  dropdownSettings: IDropdownSettings = {};

  constructor(
    private services: ServicesService,
    private translate: TranslateService,
  ) {
    if (localStorage.getItem('languaje') === 'es') {
      translate.use('es');
    } else if (localStorage.getItem('languaje') === 'en') {
      translate.use('en');
    } else {
      translate.setDefaultLang('es');
      localStorage.setItem('languaje', String('es'));
    }
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'Id_Customer',
      textField: 'Name',
      searchPlaceholderText: 'Buscar:',
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: true,
      enableCheckAll: true,
      defaultOpen: true
    };

    if (localStorage.getItem('cusStoId') == null || localStorage.getItem('cusStoId') == undefined) {
      this.selectedItems = [];
    }else{
      this.selectedItems = [
        { Id_Customer: +localStorage.getItem('cusStoId'), Name: localStorage.getItem('cusStoNa') },
      ];
    }

    if (localStorage.getItem('cusStoId') !== null) {
      this.typeCust = localStorage.getItem('cusStoId');
    }

    this.typeCustomer();
  }

  onItemSelect(e) {
    this.typeCust = e.Id_Customer;
    localStorage.setItem('cusStoId', String(this.typeCust));
    localStorage.setItem('cusStoNa', String(e.Name));
    this.customerSel.emit(this.typeCust);
  }

  onItemDeselect(e) {
    this.selectedItems = [];
    this.typeCust = '';
    localStorage.removeItem('cusStoId');
    localStorage.removeItem('cusStoNa');
    this.customerSel.emit(this.typeCust);
  }

  //services
  typeCustomer() {
    this.services
      .post({
        entidad: 'Customer',
        funcion: 3,
        data: {
          Page: -1
        },
      })
      .subscribe(
        (result) => {
          if (result.code === 1) {
            this.lstCustomerGet = result.data;
          }
        },
        (error) => {
          console.log('Error', error);
        }
      );
  }

}
