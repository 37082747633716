<div id="wrapper" [ngClass]="ref.click === true? 'toggled' :''">
    <div class="overlay" [ngStyle]="{disply:ref.click === true? 'block' :'none'}"></div>
    <!-- <app-nav-menu ></app-nav-menu> -->
    <!-- Sidebar -->
    <nav class="navbar navbar-inverse navbar-fixed-top" id="sidebar-wrapper" role="navigation">
        <div class="row">
            <div class="col- logoSide pt-2 pb-0 pl-4">
                <a href="/account">
                    <img src="https://xam-app.s3.us-west-2.amazonaws.com/images/general/logoXim_Horizontal.png" alt="homepage" class="dark-logo" style="max-width: 140px; max-height: 70px; margin-top:4px;" />
                </a>
            </div>
            <div class="col-">
                <button appSidebar #ref="appSidebar" type="button" class="hamburger is-closed menuSide" [ngClass]="{'scrollBars': scroll > 10 }" data-toggle="offcanvas">
                    <i class="fas fa-bars"></i>
                </button>
            </div>
        </div>
        <div class="row mt-2 pt-0 pl-3 pr-3 cardsMenu">
            <ng-container *ngFor="let mnu of menus">
                <div class="col-12">
                    <p class="tittleMenu mb-0 mt-3 pb-1">{{mnu.Text}}</p>
                </div>
                <ng-container *ngFor="let sm of mnu.SubMenus">
                    <div class="col-12 pl-1 pe-1 mt-2 btnMnu" [ngClass]="{'backgroundMenu': urlActual === sm.Action }">
                        <a href="{{sm.Action}}">
                            <div class="card px-2">
                                <div class="row h-100">
                                    <div class="col-12">
                                        <div class="iconColor {{texttoObjetc(sm.Settings)}}">
                                            <p class="mb-0 mt-1"><i class="{{sm.Image}}" aria-hidden="true"></i> <span class="pl-3">{{sm.Text}}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </ng-container>

            </ng-container>

            <!-- <div class="col-12">
                <p class="tittleMenu mb-0 mt-3 pb-1">Seguridad</p>
            </div> -->

            <div class="col-12 pl-1 pe-1 mt-2 btnMnu">
                <a href="javascript:void(0)" (click)="logOff()">
                    <div class="card px-2">
                        <div class="row h-100">
                            <div class="col-12">
                                <div class="iconColor">
                                    <p class="mb-0 mt-1"><i class="fas fa-sign-out-alt" aria-hidden="true"></i> <span class="pl-3">Salir</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>

        </div>
    </nav>
    <!-- /#sidebar-wrapper -->

    <!-- Page Content -->
    <div id="page-content-wrapper">
        <!-- <button appSidebar #ref="appSidebar" type="button" class="hamburger is-closed menuSide" data-toggle="offcanvas">
            <i class="fas fa-bars"></i>
        </button> -->
        <div class="container-fluid gx-0 pt-0">
            <div class="row gx-0">
                <div class="col-12 col-lg-offset-2 gx-0" style="margin-bottom: 25px;">
                    <router-outlet>
                    </router-outlet>
                </div>
            </div>
        </div>
    </div>
    <!-- /#page-content-wrapper -->
</div>

<!-- <router-outlet *ngIf="!IsLogued">
</router-outlet> -->

<!-- /#wrapper -->