export class Channel {
  Id_Channel: Number = 0;
  Name: String = '';
  Contact_Person: String = '';
  Seller: String = '';
  Email: String = '';
  Phone: String = '';
  Status: String = 'A';
  Date_Add: any = Date();
  RFC: String = '';
  Business_Name: String = '';
  Id_State: Number = 0;
  Url_Logo: string = '';
  Id_User_Ximplify: Number = 0;
  Url_Contract: string = '';
  Id_Country: Number = 0;
  Contacts: Array<ChannelContact>;

  Country_Code : string;
  User_Ximplify_Name: String = '';
  CountryName: String = '';
  StateName: String = '';
  LogoFile:File;
  ContractFile:any;
}

export class ChannelContact{
  Id_Channel_Business_Position: Number = 0;
  Name_Position: string = '';
  Id_Channel: Number = 0;
  Id_Channel_Contacts: Number = 0;
  Name: string = '';
  Email: string = '';
  Cellular: string = '';
  Phone: string = '';
}

export class Ordercha{
  AZcha = true;
  isRestart = true;
  ZAcha = false;
  AZcon = false;
  ZAcon = false;
  AZpho = false;
  ZApho = false;
  AZema = false;
  ZAema = false;
  AZrfc = false;
  ZArfc = false;
  AZdat = false;
  ZAdat = false;
}
