<ul class="nav nav-tabs" id="myTab" role="tablist" >
    <li class="nav-item text-center colorTab">
        <a (click)="ViewGeneral()" class="nav-link active" id="general-tab" data-toggle="tab" href="#general" role="tab"
            aria-controls="general" aria-selected="true"><i class="fas fa-tasks fa-2x"></i><br>General</a>
    </li>
    <li class="nav-item text-center colorTab" *ngIf="isNew">
        <a (click)="ViewProfile()" class="nav-link" id="perfiles-tab" data-toggle="tab" href="#perfiles" role="tab"
            aria-controls="perfiles" aria-selected="false"><i class="far fa-address-card fa-2x"></i><br>Perfiles</a>
    </li>
    <li class="nav-item text-center colorTab" *ngIf="isNew">
        <a (click)="ViewCompany()" class="nav-link" id="companies-tab" data-toggle="tab" href="#companies" role="tab"
            aria-controls="companies" aria-selected="false"><i class="far fa-building fa-2x"></i><br>Compañías</a>
    </li>
    <li class="nav-item text-center colorTab" *ngIf="isNew">
        <a (click)="ViewGroup()" class="nav-link" id="groups-tab" data-toggle="tab" href="#groups" role="tab"
            aria-controls="groups" aria-selected="false"><i class="fas fa-users fa-2x"></i><br>Grupos</a>
    </li>
</ul>

<div class="tab-content" id="myTabContent">
    <div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">
        <ng-container *ngIf="isGeneralView">
            <div class="card pb-3" style="background-color:#dcdcdc; ">
                <app-general-user (goBack)="returnToList()" [id_User]="id_User"></app-general-user>
            </div>
        </ng-container>
    </div>
    <div class="tab-pane fade" id="perfiles" role="tabpanel" aria-labelledby="perfiles-tab">
        <ng-container *ngIf="isProfileView">
            <div class="card pb-3" style="background-color:#dcdcdc; ">
                <app-profiles-user [id_User]="id_User"></app-profiles-user>
            </div>
        </ng-container>
    </div>
    <div class="tab-pane fade" id="companies" role="tabpanel" aria-labelledby="companies-tab">
        <ng-container *ngIf="isCompanyView">
            <div class="card pb-3" style="background-color:#dcdcdc; ">
                <app-companies-user [id_User]="id_User"></app-companies-user>
            </div>
        </ng-container>
    </div>
    <div class="tab-pane fade" id="groups" role="tabpanel" aria-labelledby="groups-tab">
        <ng-container *ngIf="isGroupView">
            <div class="card pb-3" style="background-color:#dcdcdc; ">
                <app-groups-user [id_User]="id_User"></app-groups-user>
            </div>
        </ng-container>
    </div>
</div>