<!-- Comienza encabezado -->
<div class="row page-titles mt-5">
  <div class="col-md-3 col-sm-12 col-lg-3 align-self-center">
    <h3 class="text-primary m-b-0 m-t-0">{{ "GENERAL.preferences" | translate }}</h3>
  </div>
</div>

<div class="row mt-3">
  <ng-container>
    <div class="col-md-12 col-lg-12 col-sm-12" style="margin-left: auto; margin-right: auto;">
      <div class="card">
        <div class="card-body generalTab ">

          <div class="row">

            <div class="col">
              <ng-container>

                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-6 m-t-15 row">
                    <label for="drop-app" class="col-md-5 col-form-label">{{ "GENERAL.languaje" |
                      translate }}</label>
                    <div class="col-md-7">
                      <select [(ngModel)]="language" required class="custom-select col-12" id="languaje" (change)="User_Lenguaje_Update()"
                        name="languaje">
                        <option value="0" selected="">{{ "GENERAL.languaje" | translate
                          }}...</option>
                        <option value="1" selected="">{{ "GENERAL.spanish" | translate
                          }}</option>
                        <option value="2" selected="">{{ "GENERAL.english" | translate
                          }}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12 m-t-25">
                    <ul class="list-group list-group-flush">
                      <ng-container>
                        <div class="form-group mt-3">


                          <div *ngFor="let category of Preferences_Notification_List" class="col-12">
                            <!-- <div class="col-lg-6 col-md-7"> -->
                            <div class="row">
                              <div class="col-12">
                                <p class="font-weight-bold mb-2">
                                  {{category.category_Name}}
                                </p>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <ul class="list-group list-group-flush">
                                  <li *ngFor="let preference of category.oList"
                                    class="list-group-item d-flex justify-content-between align-items-center pb-1 pr-0">
                                    <div class="row mr-0 ml-0">
                                      <div class="col-">
                                        <div class="row mr-0 ml-0">
                                          <div class="col-">
                                            <p class="mb-0 font-medium">
                                              {{preference.parameter_Name}}
                                            </p>
                                          </div>
                                        </div>
                                        <div class="row mr-0 ml-0">
                                          <div class="col-">
                                            <small class="text-muted">{{preference.description}}</small>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <span>
                                      <label class="switch hideShow">
                                        <input [(ngModel)]="preference.value"
                                          [checked]="preference.value == 'V'? true: false"
                                          [disabled]="!preference.modifiable"
                                          (change)="changeNotif(preference.id_Preferences_Notification, $event.target.checked)"
                                          name="chk{{preference.id_Preferences_Notification}}"
                                          id="chk{{preference.id_Preferences_Notification}}" type="checkbox">
                                        <span class="slider round-in"></span>
                                      </label>
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                      </ng-container>

                    </ul>
                  </div>
                </div>

              </ng-container>

            </div>

          </div>

        </div>
      </div>
    </div>
  </ng-container>

</div>
