<div class="row pe-3">
    <div class="text-end mt-1 mb-1">
        <button *ngIf="isView && !isNew && !isEdit" (click)="EditUser()" class="btnEd">
            <i class="fas fa-edit"></i>
            {{ "GENERAL.edit" | translate }}
        </button>
        <!-- <ng-container *ngIf="isEdit">
            <button *ngIf="isEdit" (click)="CancelEdit()" class="btnEd">
                <i class="fas fa-times"></i>
                Cancelar
            </button>
        </ng-container> -->
    </div>
</div>

<form ngNativeValidate #f="ngForm" (ngSubmit)="onSubmitNewEdit(f)" id="loginform" class="form">
    <ng-container>
        <div class="col-md-12 col-lg-12 col-sm-12 generalTab">
            <div class="card">
                <!-- <h6 class="card-subtitle">{{ "GENERAL.fillfields" | translate }}</h6> -->
                <div class="card-body">
                    <div class="row justify-content-left ps-3 mt-3 dataChannel">
                        <div class="col-auto">
                            <span class=" pl-3 textP">{{ "GENERAL.name" | translate }}</span>
                            <p *ngIf="isView && !isNew && !isEdit" class="pl-4 ml-3">{{UserSelected.Name}}</p>
                            <p *ngIf="isNew || isEdit"><input [(ngModel)]="UserSelected.Name"
                                    pattern="[A-Za-z0-9áéíóúüñÑ_., -]+" name="name" class="form-control input1"
                                    type="text" placeholder="{{ 'GENERAL.name' | translate }}..." id="name" required>
                            </p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3 textP">{{ "GENERAL.email2" | translate }}</span>
                            <p *ngIf="isView && !isNew && !isEdit" class="pl-4 ml-3">{{UserSelected.Email}}</p>
                            <p *ngIf="isNew || isEdit"><input [(ngModel)]="UserSelected.Email"
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" name="email"
                                    class="form-control input1" type="email"
                                    placeholder="{{ 'GENERAL.email' | translate }}..." id="email" required
                                    [disabled]="isEdit"></p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3 textP">{{ "GENERAL.organization" | translate }}</span>
                            <p *ngIf="isView && !isNew && !isEdit" class="pl-4 ml-3">{{UserSelected.CustomerName}}</p>
                            <div *ngIf="isNew || isEdit" class="muul mi input1">
                                <ng-multiselect-dropdown [placeholder]="'Selecciona organización:'"
                                    [settings]="dropdownSettingsCus" [data]="lstCustomerGet" [(ngModel)]="selectedItems"
                                    (onSelect)="onItemSelect1($event)" (onDeSelect)="onItemDeselect1($event)"
                                    name="customer" required>
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3 textP">{{ "GENERAL.company" | translate }}</span>
                            <p *ngIf="isView && !isNew && !isEdit" class="pl-4 ml-3">{{UserSelected.CompanyName}}</p>
                            <div *ngIf="isNew || isEdit" class="muul mi input1">
                                <ng-multiselect-dropdown [placeholder]="'Selecciona compañía:'" 
                                [settings]="dropdownSettingsCom" [data]="lstCompanyGet" [(ngModel)]="selectedItems1" (onSelect)="onItemSelect2($event)" (onDeSelect)="onItemDeselect2($event)" name="company">
                                </ng-multiselect-dropdown>
                            </div>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3 textP">{{ "GENERAL.boss" | translate }}</span>
                            <p *ngIf="isView && !isNew && !isEdit" class="pl-4 ml-3">{{UserSelected.Boss_User || 'Sin Jefe'}}</p>
                            <p *ngIf="isNew || isEdit">
                                <select (change)="changeBossUser($event.target.value)" class="custom-select col-12"
                                    id="inlineFormCompanySelect">
                                    <option value="" selected="">{{ "GENERAL.selectoption" | translate }}...</option>
                                    <option *ngFor="let user of UsersCustomer" value="{{user.Id_User}}"
                                        [selected]="UserSelected.Id_Boss_User == user.Id_User">{{user.Name}} </option>
                                </select>
                            </p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3 textP">{{ "GENERAL.hierarchy" | translate }}</span>
                            <p *ngIf="isView && !isNew && !isEdit" class="pl-4 ml-3">{{UserSelected.Hierarchy}}</p>
                            <p *ngIf="isNew || isEdit">
                                <select (change)="changeHierarchy($event.target.value)" class="custom-select col-12"
                                    id="inlineFormCompanySelect" required>
                                    <option value="" selected="">{{ "GENERAL.selectoption" | translate }}...</option>
                                    <option *ngFor="let hierarchy of Hierarchies" value="{{hierarchy.Id_Hierarchy}}"
                                        [selected]="UserSelected.Id_Hierarchy == hierarchy.Id_Hierarchy">
                                        {{hierarchy.Description}} </option>
                                </select>
                            </p>
                        </div>
                        <div class="col-auto">
                            <span class=" pl-3 textP">{{ "GENERAL.creditorType" | translate }}</span>
                            <p *ngIf="isView && !isNew && !isEdit" class="pl-4 ml-3">{{UserSelected.CreditorType}}</p>
                            <p *ngIf="isNew || isEdit">
                                <select (change)="changeCreditorType($event.target.value)" class="custom-select col-12"
                                    id="inlineFormCompanySelect" required>
                                    <option value="" selected="">{{ "GENERAL.selectoption" | translate }}...</option>
                                    <option *ngFor="let type of TypeEmployees" value="{{type.Id_CreditorType}}"
                                        [selected]="UserSelected.Id_CreditorType == type.Id_CreditorType">
                                        {{type.Description}}
                                    </option>
                                </select>
                            </p>
                        </div>
                        <div *ngIf="isEdit || isView && !isNew" class="col-auto">
                            <span class=" pl-3 textP">{{ "GENERAL.status" | translate }}</span>
                            <p *ngIf="isView && !isNew && !isEdit" class="pl-4 ml-3">{{UserSelected.Status_Name}}</p>
                            <p *ngIf="isEdit">
                                <select (change)="changeStatus($event.target.value)" class="custom-select col-12"
                                    id="inlineFormStatusSelect" required>
                                    <option value="" selected="">{{ "GENERAL.selectstatus" | translate }}...</option>
                                    <option *ngFor="let lstat of listStatusGral" value="{{lstat.id}}"
                                        [selected]="UserSelected.Status == lstat.id">{{lstat.name}}</option>
                                </select>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isNew || isEdit" class="col-">
                <button type="submit" [disabled]="UserSelected.Id_Customer === 0"
                    class="btn btnSave waves-effect waves-light m-r-10  m-t-15 buttonform">
                    {{ "GENERAL.save" | translate }}
                </button>
                <button *ngIf="isEdit" (click)="CancelEdit()" class="btn btnCancel waves-effect waves-light m-r-10  m-t-15 buttonform">
                    Cancelar
                </button>
            </div>
        </div>
    </ng-container>
</form>