export class CompanyList {
  Page = 1;
  Id_Company = 0;
  Id_Customer = 0;
  SearchKey = '';
  Status = '';
  Order_Column = '';
  Order_Dir = '';
  Pagination = 10;
}

export interface NewDimension {
  Id_Company: number;
  Dimension: number;
  Description: string;
  ERP_Code: string;
}